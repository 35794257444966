import config from "../config.json";
import { getRandomString } from "./AuthService";
import { getEnvironment } from "./EnvironmentVariable";
import { isMobile } from "react-device-detect";

export const getCurrentTimeOff = async () => {
  const environ = getEnvironment();
  let timeOffApi = config.timeOffURL;
  let timeOffApiKey = config.timeOffKey;
  console.log("timeOffService.getCurrentTimeOff environ", environ);
  if (environ !== "DEFAULT") {
    if (isMobile) {
      timeOffApi = config.Mobile_test_timeOffURL;
    } else {
      timeOffApi = config.test_timeOffURL;
      timeOffApiKey = config.test_timeOffKey;
    }
  }

  // const user = JSON.parse(sessionStorage.getItem("user"));
  const userInfo = JSON.parse(sessionStorage.getItem("userinfo"));
  const message = getRandomString(25);
  const token = sessionStorage.getItem("access_token");

  let headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("Authorization", "Bearer " + token);
  headers.append("X-NW-Message-ID", message);
  headers.set("client_id", timeOffApiKey);

  const now = new Date();
  const m = "00" + (now.getMonth() + 1);
  const d = "00" + now.getDate();
  const y = now.getFullYear();
  const dt = y + "-" + m.substr(m.length - 2) + "-" + d.substr(d.length - 2);
  const shortName = userInfo.shortName
    ? userInfo.shortName.toUpperCase()
    : userInfo.username.toUpperCase();
  const body = {
    shortName: shortName,
    accrualType: "Your Time",
    asOfDate: dt
  };
  try {
    const response = await fetch(timeOffApi, {
      method: "post",
      headers: headers,
      body: JSON.stringify(body)
    });
    if (response.ok) {
      try {
        const jsondata = await response.json();
        return jsondata;
      } catch (e) {
        return null;
      }
    }
  } catch (E) {
    console.log("timeOffService error", E);
    return null;
  }
};

export const getYearEndTimeOff = async () => {
  const environ = getEnvironment();
  let timeOffApi = config.timeOffURL;
  let timeOffApiKey = config.timeOffKey;
  console.log("timeOffService.getCurrentTimeOff environ", environ);
  if (environ !== "DEFAULT") {
    if (isMobile) {
      timeOffApi = config.Mobile_test_timeOffURL;
    } else {
      timeOffApi = config.test_timeOffURL;
      timeOffApiKey = config.test_timeOffKey;
    }
  }
  // const user = JSON.parse(sessionStorage.getItem("user"));
  const userInfo = JSON.parse(sessionStorage.getItem("userinfo"));
  const message = getRandomString(25);
  const token = sessionStorage.getItem("access_token");

  let headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("Authorization", "Bearer " + token);
  headers.append("X-NW-Message-ID", message);
  headers.set("client_id", timeOffApiKey);

  const now = new Date();

  const y = now.getFullYear();
  const dt = y + "-12-31";
  const shortName = userInfo.shortName
    ? userInfo.shortName.toUpperCase()
    : userInfo.username.toUpperCase();
  const body = {
    shortName: shortName,
    accrualType: "Your Time",
    asOfDate: dt
  };

  try {
    const response = await fetch(timeOffApi, {
      method: "post",
      headers: headers,
      body: JSON.stringify(body)
    });

    if (response.ok) {
      try {
        const jsondata = await response.json();
        return jsondata;
      } catch (e) {
        return null;
      }
    }
  } catch (E) {
    console.log("timeOffService error", E);
    return null;
  }
};

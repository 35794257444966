import React, { Component } from "react";

import config from "../config.json";
import { getEnvironment } from "../services/EnvironmentVariable";
import { toast } from "react-toastify";
const APIGEE_CLIENT_ID =
  getEnvironment() === "DEFAULT" ? config.client_id : config.test_client_id;

class TeamsCallback extends Component {
  state = { error: false, message: "" };

  async componentDidMount() {
    //Callback loaded so decode the idToken from Azure
    try {
      console.log("TeamsCallback.componentDidMount", new Date());
      const idToken = this.props.match.params.token;
      console.log("TeamsCallback.componentDidMount idToken", idToken);
    } catch (e) {
      toast.error("Error during callback.componentDidMount.   Error: " + e);
    }
  }

  render() {
    return (
      <React.Fragment>Teams {this.props.match.params.token}</React.Fragment>
    );
  }
}

export default TeamsCallback;

import config from "../config.json";
import { getRandomString } from "../services/AuthService";
import { getEnvironment } from "./EnvironmentVariable";
import { isMobile } from "react-device-detect";

export const getGivingData = async () => {
  const environ = getEnvironment();
  let portalApi = config.portalApi;
  let portalKey = config.client_id;
  if (environ !== "DEFAULT") {
    console.log("portalsService.getPortals environ", environ);
    if (isMobile) {
      portalApi = config.Mobile_test_portalApi;
    } else {
      portalApi = config.test_portalApi;
      portalKey = config.test_client_id;
    }
  }

  let headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("Access-Control-Allow-Origin", "*");
  const token = sessionStorage.getItem("access_token");
  headers.append("Authorization", "Bearer " + token);
  headers.append("client_id", portalKey);
  const message = getRandomString(25);
  headers.append("X-NW-Message-ID", message);
  const response = await fetch(portalApi + "givingHistory/", {
    method: "get",
    headers: headers
  });

  const jsondata = await response.json();
  console.log("GivingService.GetGivingData API: ", jsondata);

  if (jsondata.userMessage) {
    return { error: "Error retrieving Giving data" };
  }
  return jsondata;
};

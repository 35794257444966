// import React, { Component } from "react";
import config from "../config.json";
import { getCallbackURI } from "../services/EnvironmentVariable";
import { getEnvironment } from "./EnvironmentVariable";
import { isMobile } from "react-device-detect";
const AZURE_CLIENT_ID = config.azureClientId;
const AZURE_TENANT_ID = config.azureTenantId;

export function CanViewReports() {
  const environ = getEnvironment();
  console.log("AuthService.CanViewReports start");
  let reportGroup = config.reportGroup;
  if (environ !== "DEFAULT") {
    reportGroup = config.test_reportGroup;
  }

  try {
    const contact = JSON.parse(sessionStorage.getItem("contact"));
    //2/8/2021 updating the API.  If the reportAccess property exists then use it.
    if (contact.reportsAccess) {
      console.log(
        "AuthService.CanViewReports contact.reportsAccess",
        contact.reportsAccess
      );
      return contact.reportsAccess === "Y";
    }

    //The reportAccess property does not exist so look at the groups
    console.log("AuthService.CanViewReports check group memberships");
    const userinfo = contact.userinfo;

    const reportGroups = reportGroup.split(",");
    if (userinfo.groups) {
      const userGroups = userinfo.groups;
      for (let i = 0; i < userGroups.length; i++) {
        const foundGroup = reportGroups.find(function(group) {
          return group === userGroups[i];
        });
        if (foundGroup) {
          return true;
        }
      }
    }
    return false;
  } catch (e) {
    console.log("AuthService.CanViewReports Error2", e);
  }

  return false;
}
export function UserIsAdmin() {
  const environ = getEnvironment();
  let adminGroup = config.adminGroup;
  if (environ !== "DEFAULT") {
    adminGroup = config.testAdminGroup;
  }
  try {
    const contact = JSON.parse(sessionStorage.getItem("contact"));
    //2/8/2021 updating the API.  If the isPortalAdmin property exists then use it.
    if (contact.isPortalAdmin) {
      console.log(
        "AuthService.UserIsAdmin contact.isPortalAdmin",
        contact.isPortalAdmin
      );
      return contact.isPortalAdmin === "Y";
    }

    //The reportAccess property does not exist so look at the groups
    console.log("AuthService.UserIsAdmin check group memberships");
    const admingroups = adminGroup.split(",");
    const userinfo = contact.userinfo;
    if (userinfo.groups) {
      const userGroups = userinfo.groups;

      for (let i = 0; i < userGroups.length; i++) {
        const foundGroup = admingroups.find(function(group) {
          return group === userGroups[i];
        });
        if (foundGroup) {
          return true;
        }
      }
    }

    return false;
  } catch (e) {
    try {
      console.log("AuthService.UserIsAdmin Error", e);
      const admingroups = adminGroup.split(",");
      const userinfo = JSON.parse(sessionStorage.getItem("userinfo"));

      //2/8/2021 updating the API.  If the reportsAllowed property exists then use it.
      if (userinfo.isAdmin) {
        console.log(
          "AuthService.UserIsAdmin userinfo.isAdmin",
          userinfo.isAdmin
        );
        return userinfo.isAdmin;
      }
      if (userinfo.groups) {
        const userGroups = userinfo.groups;
        // console.log("AuthService UserIsAdmin adminGroup", adminGroup);
        // console.log("AuthService UserIsAdmin userGroups", userGroups);
        for (let i = 0; i < userGroups.length; i++) {
          const foundGroup = admingroups.find(function(group) {
            return group === userGroups[i];
          });
          if (foundGroup) {
            return true;
          }
        }
      }

      return false;
    } catch (e2) {
      console.log("AuthService.UserIsAdmin Error2", e);
    }

    return false;
  }
}

export function getRandomString(length) {
  let text = "";
  const possible =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  for (var i = 0; i < length; i++) {
    text += possible.charAt(Math.floor(Math.random() * possible.length));
  }
  return text;
}

export function getNwRefreshMS() {
  const nwTokenRefreshMinutes = config.nwTokenRefreshMinutes;
  const result =
    nwTokenRefreshMinutes === 0
      ? 15 * 60 * 1000
      : nwTokenRefreshMinutes * 60 * 1000;
  return result;
}

export function getAzureAuthUrl() {
  /*const REDIRECT_URL = encodeURIComponent(config.redirect_uri);*/
  //const REDIRECT_URL = encodeURIComponent(config.redirect_uri);
  const REDIRECT_URL = encodeURIComponent(getCallbackURI());
  // const REDIRECT_URL ="https://hrconnect.apps-dev.aws.e1.nwie.net/oidc-client-callback";
  const nonce = getRandomString(12);
  const state = getRandomString(12);
  return `https://login.microsoftonline.com/${AZURE_TENANT_ID}/oauth2/authorize?client_id=${AZURE_CLIENT_ID}&response_type=id_token&redirect_uri=${REDIRECT_URL}&scope=openid&response_mode=fragment&state=${state}&nonce=${nonce}`;
}

export function refreshAzureToken() {
  console.log("AuthService.refreshAzureToken");
  sessionStorage.setItem("refreshing", "true");
  const nonce = getRandomString(12);
  const state = getRandomString(12);
  const newWin = window.open(
    getAzureAuthUrl(nonce, state),
    "_blank",
    "height=100,width=100,location=yes,menubar=no,resizable=no,titlebar=yes"
  );
  if (!newWin || newWin.closed || typeof newWin.closed == "undefined") {
    return false;
  } else {
    return true;
  }
}

export function isLoggedIn() {
  const expires_at_sec = sessionStorage.getItem("expires_at_sec");
  const user = sessionStorage.getItem("user");
  if (expires_at_sec && user) {
    const expDate = new Date(expires_at_sec * 1000);
    const now = new Date();
    if (expDate - now < 3000) {
      return false;
    } else {
      return true;
    }
  } else {
    return false;
  }
}

export function refreshInterval() {
  const sec = sessionStorage.getItem("expires_at_sec");
  const user = sessionStorage.getItem("user");
  if (sec && user) {
    const expires_at_sec = parseInt(sec);
    const expDate = new Date(expires_at_sec * 1000);
    const now = new Date();
    const refreshWhen = expDate - now;
    if (refreshWhen < 3000) return 3000;
    return refreshWhen;
  } else {
    console.log(
      "AuthService.refreshInterval did not find expires_at_sec && user",
      sec && user
    );
    return 0;
  }
}

export const getAccessToken = async idToken => {
  const environ = getEnvironment();
  console.log("AuthService.getAccessToken environ", environ);
  // let APIGEE_AUTHORITY = config.authority;
  // let APIGEE_CLIENT_ID = config.client_id;
  let APIGEE_AUTHORITY = config.portalApi;
  let APIGEE_CLIENT_ID = config.client_id;
  if (environ !== "DEFAULT") {
    // APIGEE_AUTHORITY = config.test_authority;
    if (isMobile) {
      APIGEE_AUTHORITY = config.Mobile_test_portalApi;
    } else {
      APIGEE_AUTHORITY = config.test_portalApi;
      APIGEE_CLIENT_ID = config.test_client_id;
    }
  }

  const body = `azureToken=${encodeURIComponent(idToken)}`;
  let headers = new Headers();
  headers.append("client_id", APIGEE_CLIENT_ID);
  headers.append("Content-Type", "application/x-www-form-urlencoded");
  const message = getRandomString(25);
  headers.append("X-NW-Message-ID", message);

  const response = await fetch(APIGEE_AUTHORITY + "auth", {
    method: "post",
    headers: headers,
    body: body
  });
  if (response.ok) {
    const fulljson = await response.json();
    const jsondata = fulljson.data;
    // console.log("requestAccessToken jsondata", jsondata);
    const access_token = jsondata.access_token;
    const refresh_token = jsondata.refresh_token;
    sessionStorage.setItem("access_token", access_token);
    sessionStorage.setItem("refresh_token", refresh_token);
    let d = new Date();
    d.setSeconds(d.getSeconds() + parseInt(jsondata.expires_in));
    console.log("AuthService.getAccessToken store expires_at", d);
    const sec = Math.round(d.getTime() / 1000);
    sessionStorage.setItem("expires_at_sec", sec);

    const now = new Date();
    const diff = d - now;
    console.log("AuthService.getAccessToken setTimeout diff", diff);
    return jsondata;
  } else {
    return { error: "Unable to retrieve" };
  }
};

export const getUserInformation = async access_token => {
  // const APIGEE_USERINFO = config.user_info;

  const environ = getEnvironment();
  // console.log("AuthService.getAccessToken environ", environ);
  console.log("AuthService.getUserInformation environ", environ);
  let APIGEE_USERINFO = config.user_info;
  let APIGEE_CLIENT_ID = config.client_id;
  if (environ !== "DEFAULT") {
    if (isMobile) {
      APIGEE_USERINFO = config.Mobile_test_user_info;
    } else {
      APIGEE_USERINFO = config.test_user_info;
      APIGEE_CLIENT_ID = config.test_client_id;
    }
  }
  console.log(
    "AuthService.getUserInformation  APIGEE_USERINFO=" + APIGEE_USERINFO
  );
  console.log(
    "AuthService.getUserInformation  APIGEE_CLIENT_ID=" + APIGEE_CLIENT_ID
  );

  // APIGEE_USERINFO = "https://thisisnotreal.nwie.net";
  try {
    let headers = new Headers();
    headers.append("Authorization", "Bearer " + access_token);
    const response = await fetch(APIGEE_USERINFO, {
      method: "get",
      headers: headers
    });
    const jsondata = await response.json();
    try {
      const emplid = jsondata.employeeNumber;
      const contractor = emplid.startsWith("P");
      if (!contractor) {
        sessionStorage.setItem("userinfo", JSON.stringify(jsondata));
      }
    } catch (e) {
      console.log("AuthService.getUserInformation error=" + e);
    }

    return jsondata;
  } catch (e1) {
    console.log("AuthService.getUserInformation error=" + e1);
  }
  return "Error fetching from " + APIGEE_USERINFO;
};

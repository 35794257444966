import config from "../config.json";
import { getRandomString } from "../services/AuthService";
import { getEnvironment } from "./EnvironmentVariable";
import { isMobile } from "react-device-detect";

export const getPortals = async () => {
  const environ = getEnvironment();
  let portalApi = config.portalApi;
  let portalKey = config.client_id;
  if (environ !== "DEFAULT") {
    console.log("portalsService.getPortals environ", environ);
    if (isMobile) {
      portalApi = config.Mobile_test_portalApi;
    } else {
      portalApi = config.test_portalApi;
      portalKey = config.test_client_id;
    }
  }

  let headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("Access-Control-Allow-Origin", "*");
  const token = sessionStorage.getItem("access_token");
  headers.append("Authorization", "Bearer " + token);
  headers.append("client_id", portalKey);
  const message = getRandomString(25);
  headers.append("X-NW-Message-ID", message);
  const response = await fetch(portalApi + "portals/", {
    method: "get",
    headers: headers
  });

  const jsondata = await response.json();
  console.log("portalsService.getPortals portal API: ", jsondata);
  return jsondata;
};

export const addPortalForUser = async (portal_id, orderNumber) => {
  const environ = getEnvironment();
  let portalApi = config.portalApi;
  let portalKey = config.client_id;
  if (environ !== "DEFAULT") {
    console.log("portalsService.getPortals environ", environ);
    if (isMobile) {
      portalApi = config.Mobile_test_portalApi;
    } else {
      portalApi = config.test_portalApi;
      portalKey = config.test_client_id;
    }
  }
  let headers = new Headers();
  headers.append("Content-Type", "application/x-www-form-urlencoded");
  headers.append("Access-Control-Allow-Origin", "*");
  const token = sessionStorage.getItem("access_token");
  headers.append("Authorization", "Bearer " + token);
  headers.append("client_id", portalKey);
  const message = getRandomString(25);
  headers.append("X-NW-Message-ID", message);
  const body = `portal_id=${encodeURIComponent(
    portal_id
  )}&ordernum=${encodeURIComponent(orderNumber)}`;

  const response = await fetch(portalApi + "portals/", {
    method: "post",
    headers: headers,
    body: body
  });

  const jsondata = await response.json();
  return jsondata;
};

export const deletePortalForUser = async portal_id => {
  const environ = getEnvironment();
  let portalApi = config.portalApi;
  let portalKey = config.client_id;
  if (environ !== "DEFAULT") {
    console.log("portalsService.getPortals environ", environ);
    if (isMobile) {
      portalApi = config.Mobile_test_portalApi;
    } else {
      portalApi = config.test_portalApi;
      portalKey = config.test_client_id;
    }
  }
  let headers = new Headers();
  headers.append("Content-Type", "multipart/form-data");
  headers.append("Access-Control-Allow-Origin", "*");
  const token = sessionStorage.getItem("access_token");
  headers.append("Authorization", "Bearer " + token);
  headers.append("client_id", portalKey);
  const message = getRandomString(25);
  headers.append("X-NW-Message-ID", message);
  const response = await fetch(portalApi + "portals/" + portal_id, {
    method: "delete",
    headers: headers
  });
  const jsondata = await response.json();
  return jsondata;
};

export const simulation = async () => {
  const environ = getEnvironment();
  let portalApi = config.portalApi;
  let portalKey = config.client_id;
  if (environ !== "DEFAULT") {
    console.log("portalsService.getPortals environ", environ);
    if (isMobile) {
      portalApi = config.Mobile_test_portalApi;
    } else {
      portalApi = config.test_portalApi;
      portalKey = config.test_client_id;
    }
  }
  let headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("Access-Control-Allow-Origin", "*");
  const token = sessionStorage.getItem("access_token");
  headers.append("Authorization", "Bearer " + token);
  headers.append("client_id", portalKey);
  const message = getRandomString(25);
  headers.append("X-NW-Message-ID", message);
  const response = await fetch(portalApi + "sim/", {
    method: "get",
    headers: headers
  });

  const jsondata = await response.json();
  return jsondata;
};

export const saveUserPortlets = async portlets => {
  const environ = getEnvironment();
  let portalApi = config.portalApi;
  let portalKey = config.client_id;
  if (environ !== "DEFAULT") {
    console.log("portalsService.getPortals environ", environ);
    if (isMobile) {
      portalApi = config.Mobile_test_portalApi;
    } else {
      portalApi = config.test_portalApi;
      portalKey = config.test_client_id;
    }
  }
  let headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("Access-Control-Allow-Origin", "*");
  const token = sessionStorage.getItem("access_token");
  headers.append("Authorization", "Bearer " + token);
  headers.append("client_id", portalKey);
  const message = getRandomString(25);
  headers.append("X-NW-Message-ID", message);
  const body = JSON.stringify(portlets);
  console.log("portalsService.saveUserPortlets body", body);
  const response = await fetch(portalApi + "portals/saveuserportlets", {
    method: "post",
    headers: headers,
    body: body
  });
  if (response.status === 204) {
    console.log("portalsService.saveUserPortlets Portals updated");
    return null;
  } else {
    const jsondata = await response.json();
    return jsondata;
  }
};

import config from "../config.json";
import { getRandomString } from "../services/AuthService";
import { getEnvironment } from "./EnvironmentVariable";
import { isMobile } from "react-device-detect";

export const isAdminUser = async () => {
  const token = sessionStorage.getItem("access_token");
  // const azureToken = sessionStorage.getItem("idToken");
  // console.log("adminUserService.isAdminUser checking", token);
  const environ = getEnvironment();
  console.log("adminUserService.isAdminUser environ", environ);

  if (token) {
    let portalApi = config.portalApi;
    let portalKey = config.client_id;
    if (environ !== "DEFAULT") {
      if (isMobile) {
        portalApi = config.Mobile_test_portalApi;
      } else {
        portalApi = config.test_portalApi;
        portalKey = config.test_client_id;
      }
    }

    let headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Access-Control-Allow-Origin", "*");
    headers.append("Authorization", "Bearer " + token);
    // headers.append("assertion", azureToken);
    headers.append("client_id", portalKey);
    const message = getRandomString(25);
    headers.append("X-NW-Message-ID", message);
    const response = await fetch(portalApi + "adminuser/isadmin", {
      method: "post",
      headers: headers
    });

   //this is just for testing http errors and responses
    /* const response = {
    ok: false,
    status: 500,
    statusText: "Bad Gateway",
    type: "cors",
    URL: portalApi + "adminuser/isadmin"
    }; */

    if (response.ok) {
      try {
        // console.log("adminUserService.isAdminUser response", response);
        const jsondata = await response.json();
        if (jsondata.errorMessage) {
          console.log(
            "adminUserService.isAdminUser error",
            jsondata.errorMessage.userMessage
          );
        }
        return jsondata;
      } catch (e) {
        console.log("adminUserService.isAdminUser error", e);
        return null;
      }
    } else {
      console.log(
        "adminUserService.isAdminUser There was a problem with the call to " +
          portalApi +
          "adminuser/isadmin",
        response.status,
        response.statusText
      );
      return {
        city: "",
        emplid: "",
        firstName: "",
        fullName: "",
        id: 0,
        isPortalAdmin: "",
        lastName: "",
        mailDrop: "",
        middleName: "",
        reportsAccess: "",
        shortName: "",
        state: "",
        userinfo: {
          id: 0,
          username: "",
          shortName: "",
          isAdmin: false,
          reportsAllowed: false
        },
        errorMessage: {
          userMessage:
            "There was a problem retrieving your User information.  Please try again later",
          developerMessage:
            "There was a problem with the call to " +
            portalApi +
            "adminuser/isadmin"
        }
      };
    }
  } else {
    return null;
  }
};

// export const adminUserList = async () => {
//   const token = sessionStorage.getItem("access_token");
//   // const token = localStorage.getItem("access_token");
//   if (token) {
//     const environ = getEnvironment();
//     let portalApi = config.portalApi;
//     let portalKey = config.client_id;
//     if (environ !== "DEFAULT") {
//       console.log("adminUserService.adminUserList environ", environ);
//       if (isMobile) {
//         portalApi = config.Mobile_test_portalApi;
//       } else {
//         portalApi = config.test_portalApi;
//         portalKey = config.test_client_id;
//       }
//     }

//     let headers = new Headers();
//     headers.append("Content-Type", "application/json");
//     headers.append("Access-Control-Allow-Origin", "*");
//     headers.append("Authorization", "Bearer " + token);
//     headers.append("client_id", portalKey);
//     const message = getRandomString(25);
//     headers.append("X-NW-Message-ID", message);
//     const response = await fetch(portalApi + "adminuser/search/", {
//       method: "post",
//       headers: headers
//     });

//     const jsondata = await response.json();
//     return jsondata;
//   } else {
//     return null;
//   }
// };

// export const deleteAdminUser = async userid => {
//   const environ = getEnvironment();
//   let portalApi = config.portalApi;
//   let portalKey = config.client_id;
//   if (environ !== "DEFAULT") {
//     console.log("adminUserService.deleteAdminUser environ", environ);
//     portalApi = config.test_portalApi;
//     portalKey = config.test_client_id;
//   }

//   let headers = new Headers();
//   headers.append("Content-Type", "multipart/form-data");
//   headers.append("Access-Control-Allow-Origin", "*");
//   const token = sessionStorage.getItem("access_token");
//   headers.append("Authorization", "Bearer " + token);
//   headers.append("client_id", portalKey);
//   const message = getRandomString(25);
//   headers.append("X-NW-Message-ID", message);
//   const result = await fetch(portalApi + "adminuser/" + userid, {
//     method: "delete",
//     headers: headers
//   });
//   console.log("deleteAdminUser result", result);
// };

// export const addAdminUser = async userid => {
//   const environ = getEnvironment();
//   let portalApi = config.portalApi;
//   let portalKey = config.client_id;
//   if (environ !== "DEFAULT") {
//     console.log("adminUserService.addAdminUser environ", environ);
//     portalApi = config.test_portalApi;
//     portalKey = config.test_client_id;
//   }
//   let headers = new Headers();
//   headers.append("Content-Type", "application/x-www-form-urlencoded");
//   headers.append("Access-Control-Allow-Origin", "*");
//   const token = sessionStorage.getItem("access_token");
//   headers.append("Authorization", "Bearer " + token);
//   headers.append("client_id", portalKey);
//   const message = getRandomString(25);
//   headers.append("X-NW-Message-ID", message);
//   const body = `userid=${encodeURIComponent(userid)}`;
//   const response = await fetch(portalApi + "adminuser/", {
//     method: "post",
//     headers: headers,
//     body: body
//   });

//   const jsondata = await response.json();
//   return jsondata;
// };

import config from "../config.json";
import { getRandomString } from "./AuthService";
import { getEnvironment } from "./EnvironmentVariable";
import { isMobile } from "react-device-detect";
export const getMyHealth = async () => {
  let data = null;
  try {
    const environ = getEnvironment();
    let myHealthURL = config.myHealthURL;
    let myHealthKey = config.myHealthKey;
    if (environ !== "DEFAULT") {
      console.log("myHealthService.getMyHealth environ", environ);
      if (isMobile) {
        myHealthURL = config.Mobile_test_myHealthURL;
      } else {
        myHealthURL = config.test_myHealthURL;
        myHealthKey = config.test_myHealthKey;
      }
    }
    const userInfo = JSON.parse(sessionStorage.getItem("userinfo"));
    const message = getRandomString(25);
    const token = sessionStorage.getItem("access_token");

    let headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Authorization", "Bearer " + token);
    headers.append("X-NW-Message-ID", message);
    headers.set("client_id", myHealthKey);
    console.log("myHealthService.getMyHealth myHealthURL", myHealthURL);
    const shortName = userInfo.shortName
      ? userInfo.shortName.toUpperCase()
      : userInfo.username.toUpperCase();
    const body = {
      shortName: shortName
    };

    try {
      const response = await fetch(myHealthURL, {
        method: "post",
        headers: headers,
        body: JSON.stringify(body)
      });
      if (response.ok) {
        try {
          data = await response.json();
        } catch (e) {
          console.error("myHealthService.getMyHealth  error", e);
          return { error: e };
        }
      } else {
        console.error("myHealthService.getMyHealth  error");
        return { error: 'My Health returned error code' };
      }
    } catch (E) {
      console.error("myHealthService.getMyHealth  error", E);
      return { error: E };
    }

    if (data) {
      if (data.userMessage) {
        console.error("myHealthService.getMyHealth  error", data);
        return { error: data.userMessage };
      }
      const monthNames = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "June",
        "July",
        "Aug",
        "Sept",
        "Oct",
        "Nov",
        "Dec"
      ];
      const aryEndDate = data.progEndDate.split("-");
      const aryHireDate = data.hireDate.split("-");
      const aryNewHireCutOffDate = data.newHireCutoffDate.split("-");
      const today = new Date();
      const dueDate =
        monthNames[parseInt(aryEndDate[0]) - 1] + " " + aryEndDate[1];

      let programDueDate = new Date();
      programDueDate.setMonth(parseInt(aryEndDate[0]) - 1);
      programDueDate.setDate(parseInt(aryEndDate[1]));
      console.log("myHealthService.getMyHealth programDueDate", programDueDate);

      // hireDate: "2020-12-31",
      let hiredDate = new Date();
      console.log(
        "myHealthService.getMyHealth  - hire date array",
        aryHireDate
      );
      hiredDate.setMonth(parseInt(aryHireDate[1]) - 1);
      hiredDate.setDate(parseInt(aryHireDate[2]));
      hiredDate.setFullYear(parseInt(aryHireDate[0]));

      let newHireCutoffDt = new Date();
      // newHireCutoffDate: "07-31",
      console.log(
        "myHealthService.getMyHealth  - hire cut off date array",
        aryNewHireCutOffDate
      );
      //   let m = aryNewHireCutOffDate[1].startsWith("0")
      //   ? aryNewHireCutOffDate[1].replace("0", "")
      //   : aryNewHireCutOffDate[1];
      // let d = aryNewHireCutOffDate[2].startsWith("0")
      //   ? aryNewHireCutOffDate[2].replace("0", "")
      //   : aryNewHireCutOffDate[2];
      newHireCutoffDt.setMonth(parseInt(aryNewHireCutOffDate[0]) - 1);
      newHireCutoffDt.setDate(parseInt(aryNewHireCutOffDate[1]));
      console.log(
        "myHealthService.getMyHealth  myHealthService.newHireCutoffDt",
        newHireCutoffDt
      );

      const warningDays = parseInt(data.warningDays);
      let warningDate = new Date();
      warningDate.setMonth(programDueDate.getMonth());
      warningDate.setDate(programDueDate.getDate() - warningDays);

      const pastCutOff = today >= programDueDate;

      const newHire = hiredDate >= newHireCutoffDt;
      const completed = parseInt(data.awardEarning) >= 100 || newHire;
      const warning = today >= warningDate && !completed;

      const result = {
        pastCutOff,
        completed,
        dueDate,
        newHire,
        warning,
        ...data
      };
      return result;
    }
  } catch (e) {
    console.error("myHealthService.getMyHealth  error", e);
    return { error: e };
  }
};

import config from "../config.json";
import { getRandomString } from "../services/AuthService";
import { getEnvironment } from "./EnvironmentVariable";
import { isAdminUser } from "../services/adminUserService";

export const getFromServiceNow = async searchFor => {
  // const snApi = config.serviceNowApi; //https://api-int-dev.nwie.net/sn-knowledge/sn-api
  // const clientId = config.SNclient_id; //i2txvOphSn3kQdA44Q1Xxu1DG2Jgx1jz
  const environ = getEnvironment();
  const APIGEE_CLIENT_ID = config.client_id;
  let APIGEE_AUTHORITY = "";
  let token = "";

  if (environ !== "DEFAULT") {
    console.log("SNOW API Not in production");
    APIGEE_AUTHORITY = config.stage_authority;
  } else {
    console.log("SNOW API in production");
    APIGEE_AUTHORITY = config.prod_authority;
  }

  const idToken = sessionStorage.getItem("idToken");
  token = sessionStorage.getItem("SNOW_AT");
  const SNOW_expires = sessionStorage.getItem("SNOW_expires");
  const expDate = new Date(SNOW_expires * 1000);
  const now = new Date();

  //IF NO TOKEN OR EXPIRED TOKEN
  if (token === "" || expDate - now < 3000) {
    console.log(
      "ServiceNowService.getFromServiceNow SNOW API token is blank or expired"
    );

    const body = [
      `${encodeURIComponent("grant_type")}=${encodeURIComponent(
        "urn:ietf:paramsoap:oauth:grant-type:jwt-bearer"
      )}`,
      `${encodeURIComponent("assertion")}=${encodeURIComponent(idToken)}`,
      `${encodeURIComponent("client_id")}=${encodeURIComponent(
        APIGEE_CLIENT_ID
      )}`,
      `${encodeURIComponent("scope")}=${encodeURIComponent("openid")}`,
      `${encodeURIComponent("realm")}=${encodeURIComponent("employee")}`,
      `${encodeURIComponent("auth_method")}=${encodeURIComponent("azure-jwt")}`,
      `${encodeURIComponent("identity_method")}=${encodeURIComponent("nwie")}`
    ].join("&");

    const response = await fetch(APIGEE_AUTHORITY, {
      method: "post",
      headers: {
        "Content-Type": `application/x-www-form-urlencoded`
      },
      body: body
    });
    console.log(
      "ServiceNowService.getFromServiceNow SNOW API token response",
      response
    );

    if (response.ok) {
      const jsondata = await response.json();
      console.log("SNOW API token jsondata response", jsondata);
      token = jsondata.access_token;
      sessionStorage.setItem("SNOW_AT", token);
      let d = new Date();
      d.setSeconds(d.getSeconds() + parseInt(jsondata.expires_in));
      const sec = Math.round(d.getTime() / 1000);
      sessionStorage.setItem("SNOW_expires", sec);
      console.log("SNOW API token jsondata ", token);
    } else {
      token = "";
      console.log("SNOW API token jsondata error");
    }
  }

  console.log(
    "ServiceNowService.getFromServiceNow SNOW API token jsondata Final Value",
    token
  );

  let snApi = config.serviceNowApi;
  let clientId = config.SNclient_id;
  if (environ !== "DEFAULT") {
    console.log("serviceNowService.getFromServiceNow environ", environ);
    snApi = config.test_serviceNowApi;
    // clientId = config.test_SNclient_id;
  }

  const messageId = getRandomString(25);
  try {
    let headers = new Headers();
    headers.append("client_id", clientId);
    headers.append("X-NW-Message-ID", messageId);
    headers.append("Content-Type", "application/json");
    headers.append("Authorization", "Bearer " + token);
    let contact = JSON.parse(sessionStorage.getItem("contact"));
    if (!contact) {
      contact = await isAdminUser();
      sessionStorage.setItem("contact", JSON.stringify(contact));
    }
    console.log("ServiceNowService.getFromServiceNow contact", contact);
    console.log(
      "ServiceNowService.getFromServiceNow contact.state",
      contact.state
    );
    const body = {
      searchkey: encodeURIComponent(searchFor),
      isManager: "1",
      office: contact.state,
      isHRBP: "0"
    };
    const response = await fetch(snApi, {
      method: "post",
      headers: headers,
      body: JSON.stringify(body)
    });
    console.log("ServiceNowService.getFromServiceNow  response", response);
    const jsondata = await response.json();
    console.log("ServiceNowService.getFromServiceNow  jsondata", jsondata);
    return jsondata;
  } catch (e) {
    console.log(
      "ServiceNowService.getFromServiceNow ERROR GETTING DATA FROM SERVICE NOW",
      e
    );
  }
};

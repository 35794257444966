import React, { Component } from "react";
import {
  CollapsedPortlet,
  PortletLoading,
  WorkdayPortletwithDate
} from "../common/portlet";
import { PortletInfo } from "../common/portletInfo";
// import { Gauge } from "../common/gauge";
import "@nationwide-bolt/css-core/dist/css/bolt-core.css";
import "react-toastify/dist/ReactToastify.css";
import Spinner from "../common/spinner";
import config from "../config.json";
import "../main.css";

class MYHEALTH extends Component {
  state = {
    blurred: "yes",
    totalId: 0,
    displayInfo: false
  };

  componentDidMount() {
    this.overall = 0;
  }
  handleBlurChange = () => {
    if (this.state.blurred === "yes") {
      this.setState({ blurred: "no" });
    } else {
      this.setState({ blurred: "yes" });
    }
  };

  handleRefresh = async () => {
    this.props.populateMyHealthPortal(true);
    // toast.success("Your Time has been refreshed");
  };
  showInformation = () => {
    const { displayInfo } = this.state;
    console.log("myHealth.showInformation displayInfo", displayInfo);
    this.setState({ displayInfo: !displayInfo });
  };
  returnOverall(myHealthData) {
    const { blurred } = this.state;
    // const today = new Date();
    // const year = today.getFullYear();
    let imgSource = "myhealth0.png";
    // const blurdata = blurred === "yes";
    if (myHealthData) {
      const completed = myHealthData.completed;
      const warning = myHealthData.warning;
      if (myHealthData.awardEarning >= 25) {
        imgSource = "myhealth25.png";
      }
      if (myHealthData.awardEarning >= 50) {
        imgSource = "myhealth50.png";
      }

      if (completed) {
        imgSource = "myhealth100.png";
      }
      if (blurred === "yes") {
        imgSource = "myhealth0.png";
        return (
          <React.Fragment>
            <div className="myHealthCompletion">
              <img
                src={imgSource}
                alt="progress  bar"
                className="myhealthImage"
              />
              ,
              <div style={completed ? { display: "none" } : {}}>
                <div className="myhealthBlockText myhealthText25">
                  XXXXXX XXXXXXXXXXX
                </div>
                <div className="myhealthBlockText myhealthText25">
                  XXXXXXXXX XXXXXXXXX
                </div>
                <div className="myhealthBlockText myhealthText50">
                  XXXXXXXXXX XXX XX XXXXXXXX XXXXXXXXX
                </div>
              </div>
              <div
                style={warning && !completed ? {} : { display: "none" }}
                className="myHealthWarning"
              >
                XXX{" "}
                <span style={{ fontWeight: "bold" }}>
                  {myHealthData.dueDate}
                </span>{" "}
                XXXXXXXXXX XXXXXXXX XX XXXX XXXXXXXXXXXX
                <br />
                <span style={{ fontWeight: "bold" }}>XXXX XXXXXX XXXX</span>
              </div>
              <div
                style={
                  completed
                    ? {
                        fontSize: "14px",
                        paddingLeft: "25px",
                        paddingRight: "20px"
                      }
                    : { display: "none" }
                }
              >
                <div style={{ fontWeight: "bold" }}>
                  XXXXXXXXXXXXXXX XX XXXXXXXXXX XXX XXXXXXXX
                </div>
                <div>
                  XXX XXXX XXXXXX XXXX XX XXXXXX XXXXXX XXXXXXX XXX XXXX XXXXX
                </div>
              </div>
            </div>
          </React.Fragment>
        );
      }
      return (
        <React.Fragment>
          <div className="myHealthCompletion">
            <img
              src={imgSource}
              alt="progress  bar"
              className="myhealthImage"
            />
            ,
            <div style={completed ? { display: "none" } : {}}>
              <div className="myhealthBlockText myhealthText25">
                Health
                <br />
                Assessment
              </div>
              <div className="myhealthBlockText myhealthText25">
                Biometric
                <br />
                Screening
              </div>
              <div className="myhealthBlockText myhealthText50">
                Biometrics met or
                <br />
                Coaching completed
              </div>
            </div>
            <div
              style={warning && !completed ? {} : { display: "none" }}
              className="myHealthWarning"
            >
              The{" "}
              <span style={{ fontWeight: "bold" }}>{myHealthData.dueDate}</span>{" "}
              completion deadline is fast approaching.
              <br />
              <span style={{ fontWeight: "bold" }}>Take action now!</span>
            </div>
            <div
              style={
                completed
                  ? {
                      fontSize: "14px",
                      paddingLeft: "25px",
                      paddingRight: "20px"
                    }
                  : { display: "none" }
              }
            >
              <div style={{ fontWeight: "bold" }}>
                Congratulations on completing the program!
              </div>
              <div>
                You have earned your My Health reward dollars for next year.
              </div>
            </div>
          </div>
        </React.Fragment>
      );
    }
  }

  returnNotEligible = (
    displayInfo,
    MyHealthDefaultActive,
    deactivateMyHealth
  ) => {
    const { blurred } = this.state;
    // const informationMessge = `Results for other sections will be reported once the Health Assessment has been completed. Progress displayed for associate only. If your spouse is enrolled, have them log onto Rally to verify their progress.`;
    if (blurred === "yes") {
      return (
        <div>
          <h2 className="tile-header">
            <button
              style={
                MyHealthDefaultActive === "N"
                  ? { display: "inline-block" }
                  : { display: "none" }
              }
              onClick={deactivateMyHealth}
              className="link"
            >
              <img
                src="/chevron_up.png"
                alt="Click to collapse My Health Program"
                width="19px"
                style={{ marginRight: "10px" }}
              />
            </button>
            My Health Program
          </h2>
          <div className="tile-blur">
            <button
              className="btn btn-link"
              onClick={this.handleBlurChange}
              title={
                this.state.blurred === "yes" ? "Click to show" : "Click to hide"
              }
            >
              <img
                style={{
                  height: "20px",
                  width: "20px"
                }}
                alt={this.state.blurred === "yes" ? "click to show My Health Program" : "click to hide My Health Program"}
                src={
                  this.state.blurred === "yes"
                    ? "/eye-blue.png"
                    : "hide-blue.png"
                }
              />
            </button>
          </div>
          <hr className="tile-line" />
          <div
            className={this.state.blurred === "yes" ? "obfuscate" : ""}
            style={{
              fontSize: "14px",
              paddingLeft: "20px",
              paddingRight: "20px"
            }}
          >
            XXX XXX XXX XXXXXXXXX XXXXXXXX XX XXXXXXXXXXX XX XXX{" "}
            <span style={{ fontWeight: "bold" }}>XX XXXXX XXXXXXX</span>. XX XX
            XXXXXXXXX XXX XXXX XX XXXXXXXX XX X XXXXXXXXXX XXXXXXX XXXXXX
          </div>
        </div>
      );
    }
    return (
      <div>
        <h2 className="tile-header">
          <button
            style={
              MyHealthDefaultActive === "N"
                ? { display: "inline-block" }
                : { display: "none" }
            }
            onClick={deactivateMyHealth}
            className="link"
          >
            <img
              src="/chevron_up.png"
              alt="Click to collapse My Health Program"
              width="19px"
              style={{ marginRight: "10px" }}
            />
          </button>
          My Health Program
        </h2>
        <div className="tile-blur">
          <button
            className="btn btn-link"
            onClick={this.handleBlurChange}
            title={
              this.state.blurred === "yes" ? "Click to show" : "Click to hide"
            }
          >
            <img
              style={{
                height: "20px",
                width: "20px"
              }}
              alt={this.state.blurred === "yes" ? "click to show My Health Program" : "click to hide My Health Program"}
              src={
                this.state.blurred === "yes" ? "/eye-blue.png" : "hide-blue.png"
              }
            />
          </button>
        </div>
        <hr className="tile-line" />
        <div
          className={this.state.blurred === "yes" ? "obfuscate" : ""}
          style={{
            fontSize: "14px",
            paddingLeft: "20px",
            paddingRight: "20px"
          }}
        >
          You are not currently eligible to participate in the{" "}
          <span style={{ fontWeight: "bold" }}>My Health Program</span>. To be
          eligible, you must be enrolled in a Nationwide medical plan.
        </div>
      </div>
    );
  };

  returnBlurred = (MyHealthDefaultActive, deactivateMyHealth) => {
    // const { blurred } = this.state;
    return (
      <div>
        <h2 className="tile-header">
          <button
            style={
              MyHealthDefaultActive === "N"
                ? { display: "inline-block" }
                : { display: "none" }
            }
            onClick={deactivateMyHealth}
            className="link"
          >
            <img
              src="/chevron_up.png"
              alt="Click to collapse My Health Program"
              width="19px"
              style={{ marginRight: "10px" }}
            />
          </button>
          My Health Program
        </h2>
        <div className="tile-blur">
          <button
            className="btn btn-link"
            onClick={this.handleBlurChange}
            title={
              this.state.blurred === "yes" ? "Click to show" : "Click to hide"
            }
          >
            <img
              style={{
                height: "20px",
                width: "20px"
              }}
              alt={this.state.blurred === "yes" ? "click to show my health program" : "click to hide my health program"}
              src={
                this.state.blurred === "yes" ? "/eye-blue.png" : "hide-blue.png"
              }
            />
          </button>
        </div>
        <hr className="tile-line" />
        <div
          className="obfuscate"
          style={{
            fontSize: "14px",
            paddingLeft: "20px",
            paddingRight: "20px"
          }}
        >
          XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX{" "}
          XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX{" "}
          XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX{" "}
          XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX{" "}
          XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX{" "}
          XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX{" "}
          XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX{" "}
          XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX{" "}
          XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX{" "}
          XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX{" "}
        </div>
      </div>
    );
  };

  returnProgramClosedNotCompleted = (
    displayInfo,
    MyHealthDefaultActive,
    deactivateMyHealth
  ) => {
    const { blurred } = this.state;
    const informationMessge = `The My Health Program has closed for the current year. If this is a mistake, please email My Health at myhealth@nationwide.com. Results displayed for associate only.`;
    if (blurred === "yes") {
      return (
        <div>
          <h2 className="tile-header">
            <button
              style={
                MyHealthDefaultActive === "N"
                  ? { display: "inline-block" }
                  : { display: "none" }
              }
              onClick={deactivateMyHealth}
              className="link"
            >
              <img
                src="/chevron_up.png"
                alt="Click to collapse My Health Program"
                width="19px"
                style={{ marginRight: "10px" }}
              />
            </button>
            My Health Program
          </h2>
          <div className="tile-blur">
            <button
              className="btn btn-link"
              onClick={this.handleBlurChange}
              title={
                this.state.blurred === "yes" ? "Click to show" : "Click to hide"
              }
            >
              <img
                style={{
                  height: "20px",
                  width: "20px"
                }}
                alt={this.state.blurred === "yes" ? "click to show My Health program" : "click to hide My Health program"}
                src={
                  this.state.blurred === "yes"
                    ? "/eye-blue.png"
                    : "hide-blue.png"
                }
              />
            </button>
          </div>
          <hr className="tile-line" />
          <div className="obfuscate fowSubTitle">
            XXXXX XXX XXXXXX XXX XXXXXXXX XXXX XXXXX{" "}
            <span className="infoLink" onClick={this.showInformation}>
              {displayInfo ? "X" : "?"}
            </span>
          </div>
          <div
            className={this.state.blurred === "yes" ? "obfuscate" : ""}
            style={{
              fontSize: "14px",
              paddingLeft: "20px",
              paddingRight: "20px"
            }}
          >
            XXX XX XXXXXX XXXXXXX XXX XXXXXX XXX XXXX XXXXX XXXX XXX XXXXXXXXXXX
            XXXX XXX XXXX XXXXXX XXXXXXXX XXXXXXXXX XXXXXXX XX
          </div>
          <div className="tile-subbox" style={{ marginTop: "15px" }}>
            <div
              className="tile-rectangle"
              style={{
                marginLeft: "18px",
                width: "230px",
                height: "38px"
              }}
            >
              <img
                className="tile-externallink"
                alt="external-link"
                src="/external-link.svg"
              />
              <a
                className="tile-recttitle"
                href={config.myHealthProgramGuideUrl}
                rel="noopener noreferrer"
                target="_blank"
              >
                My Health / Rally
              </a>
            </div>
          </div>
          <div style={{ paddingTop: "8px" }}></div>
          <PortletInfo
            position="-40px"
            id="myhealthMissedDeadline"
            message={informationMessge}
            showing={displayInfo}
            title="Looks like you missed the deadline this year."
          />
        </div>
      );
    }
    return (
      <div>
        <h2 className="tile-header">
          <button
            style={
              MyHealthDefaultActive === "N"
                ? { display: "inline-block" }
                : { display: "none" }
            }
            onClick={deactivateMyHealth}
            className="link"
          >
            <img
              src="/chevron_up.png"
              alt="Click to collapse My Health Program"
              width="19px"
              style={{ marginRight: "10px" }}
            />
          </button>
          My Health Program
        </h2>
        <div className="tile-blur">
          <button
            className="btn btn-link"
            onClick={this.handleBlurChange}
            title={
              this.state.blurred === "yes" ? "Click to show" : "Click to hide"
            }
          >
            <img
              style={{
                height: "20px",
                width: "20px"
              }}
              alt={this.state.blurred === "yes" ? "click to show My Health program" : "click to hide My Health program"}
              src={
                this.state.blurred === "yes" ? "/eye-blue.png" : "hide-blue.png"
              }
            />
          </button>
        </div>
        <hr className="tile-line" />
        <div className="fowSubTitle">
          Oops, you missed the deadline this year!{" "}
          <span className="infoLink" onClick={this.showInformation}>
            {displayInfo ? "X" : "?"}
          </span>
        </div>
        <div
          className={this.state.blurred === "yes" ? "obfuscate" : ""}
          style={{
            fontSize: "14px",
            paddingLeft: "20px",
            paddingRight: "20px"
          }}
        >
          The My Health Program has closed for this year. Look for information
          soon for next year’s program, beginning January 1.
        </div>
        <div className="tile-subbox" style={{ marginTop: "15px" }}>
          <div
            className="tile-rectangle"
            style={{
              marginLeft: "18px",
              width: "230px",
              height: "38px"
            }}
          >
            <img
              className="tile-externallink"
              alt="external-link"
              src="/external-link.svg"
            />
            <a
              className="tile-recttitle"
              href={config.myHealthProgramGuideUrl}
              rel="noopener noreferrer"
              target="_blank"
            >
              My Health / Rally
            </a>
          </div>
        </div>
        <div style={{ paddingTop: "8px" }}></div>
        <PortletInfo
          position="-62px"
          id="myhealthMissedDeadline"
          message={informationMessge}
          showing={displayInfo}
          title="Looks like you missed the deadline this year."
        />
      </div>
    );
  };
  returnProgramClosedCompleted = (
    displayInfo,
    MyHealthDefaultActive,
    deactivateMyHealth
  ) => {
    const { blurred } = this.state;
    const informationMessge = `Results for other sections will be reported once the Health Assessment has been completed. Progress displayed for associate only. If your spouse is enrolled, have them log onto Rally to verify their progress.`;
    if (blurred === "yes") {
      return (
        <div>
          <h2 className="tile-header">
            <button
              style={
                MyHealthDefaultActive === "N"
                  ? { display: "inline-block" }
                  : { display: "none" }
              }
              onClick={deactivateMyHealth}
              className="link"
            >
              <img
                src="/chevron_up.png"
                alt="Click to collapse My Health Program"
                width="19px"
                style={{ marginRight: "10px" }}
              />
            </button>
            My Health Program
          </h2>
          <div className="tile-blur">
            <button
              className="btn btn-link"
              onClick={this.handleBlurChange}
              title={
                this.state.blurred === "yes" ? "Click to show" : "Click to hide"
              }
            >
              <img
                style={{
                  height: "20px",
                  width: "20px"
                }}
                alt={this.state.blurred === "yes" ? "click to show My Health program" : "click to hide My Health program"}
                src={
                  this.state.blurred === "yes"
                    ? "/eye-blue.png"
                    : "hide-blue.png"
                }
              />
            </button>
          </div>
          <hr className="tile-line" />
          <div className="obfuscate fowSubTitle">
            XXXXXXXXXXXXXXX XX XXXXXXXXXX XXX XXXXXXXX{" "}
            <span className="infoLink" onClick={this.showInformation}>
              {displayInfo ? "X" : "?"}
            </span>
          </div>
          <div
            className={this.state.blurred === "yes" ? "obfuscate" : ""}
            style={{
              fontSize: "14px",
              paddingLeft: "25px",
              paddingRight: "20px"
            }}
          >
            <div>
              XXX XXXX XXXXXX XXXX XX XXXXXX XXXXXX XXXXXXX XXX XXXX XXXXX
            </div>
          </div>
          <div className="tile-subbox" style={{ marginTop: "15px" }}>
            <div className="row">
              <div className="col">
                <div className="tile-rectangle myhealthButtonContainer1">
                  <img
                    className="tile-externallink"
                    alt="external-link"
                    src="/external-link.svg"
                  />
                  <a
                    className="tile-recttitle"
                    href={config.myHealthProgramGuideUrl}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    My Health / Rally
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div style={{ paddingTop: "8px" }}></div>
          <PortletInfo
            position="-26px"
            message={informationMessge}
            showing={displayInfo}
            title="Completion of Health Assessment"
          />
        </div>
      );
    }
    return (
      <div>
        <h2 className="tile-header">
          <button
            style={
              MyHealthDefaultActive === "N"
                ? { display: "inline-block" }
                : { display: "none" }
            }
            onClick={deactivateMyHealth}
            className="link"
          >
            <img
              src="/chevron_up.png"
              alt="Click to collapse My Health Program"
              width="19px"
              style={{ marginRight: "10px" }}
            />
          </button>
          My Health Program
        </h2>
        <div className="tile-blur">
          <button
            className="btn btn-link"
            onClick={this.handleBlurChange}
            title={
              this.state.blurred === "yes" ? "Click to show" : "Click to hide"
            }
          >
            <img
              style={{
                height: "20px",
                width: "20px"
              }}
              alt={this.state.blurred === "yes" ? "click to show My Health program" : "click to hide My Health program"}
              src={
                this.state.blurred === "yes" ? "/eye-blue.png" : "hide-blue.png"
              }
            />
          </button>
        </div>
        <hr className="tile-line" />
        <div className="fowSubTitle">
          Congratulations on completing the program!{" "}
          <span className="infoLink" onClick={this.showInformation}>
            {displayInfo ? "X" : "?"}
          </span>
        </div>
        <div
          className={this.state.blurred === "yes" ? "obfuscate" : ""}
          style={{
            fontSize: "14px",
            paddingLeft: "25px",
            paddingRight: "20px"
          }}
        >
          <div>
            You have earned your My Health reward dollars for next year.
          </div>
        </div>
        <div className="tile-subbox" style={{ marginTop: "15px" }}>
          <div className="row">
            <div className="col">
              <div className="tile-rectangle myhealthAwsButtonContainer">
                <img
                  className="tile-externallink"
                  alt="external-link"
                  src="/external-link.svg"
                />
                <a
                  className="tile-recttitle"
                  href={config.awsSharepointUrl}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  AWS site
                </a>
              </div>
            </div>
          </div>
        </div>
        <div style={{ paddingTop: "8px" }}></div>
        <PortletInfo
          position="-26px"
          message={informationMessge}
          showing={displayInfo}
          title="Completion of Health Assessment"
        />
      </div>
    );
  };

  returnNewHire = (displayInfo, MyHealthDefaultActive, deactivateMyHealth) => {
    const { blurred } = this.state;
    const informationMessge = `Associates hired July 1 - December 31 automatically receive the My Health credit.`;
    if (blurred === "yes") {
      return (
        <div>
          <h2 className="tile-header">
            <button
              style={
                MyHealthDefaultActive === "N"
                  ? { display: "inline-block" }
                  : { display: "none" }
              }
              onClick={deactivateMyHealth}
              className="link"
            >
              <img
                src="/chevron_up.png"
                alt="Click to collapse My Health Program"
                width="19px"
                style={{ marginRight: "10px" }}
              />
            </button>
            My Health Program
          </h2>
          <div className="tile-blur">
            <button
              className="btn btn-link"
              onClick={this.handleBlurChange}
              title={
                this.state.blurred === "yes" ? "Click to show" : "Click to hide"
              }
            >
              <img
                style={{
                  height: "20px",
                  width: "20px"
                }}
                alt={this.state.blurred === "yes" ? "click to show My Health program" : "click to hide My Health program"}
                src={
                  this.state.blurred === "yes"
                    ? "/eye-blue.png"
                    : "hide-blue.png"
                }
              />
            </button>
          </div>
          <hr className="tile-line" />
          <div className="obfuscate fowSubTitle">
            XXX XX XXXX XXX XXXX XXXXX XXX XXXX XXXXXXXXXXXXX XXXXXXX XXXX XX
            XXXXXX XXXXXX XXX XXXX XXXXX{" "}
            <span className="infoLink" onClick={this.showInformation}>
              {displayInfo ? "X" : "?"}
            </span>
          </div>
          <div
            className={this.state.blurred === "yes" ? "obfuscate" : ""}
            style={{
              fontSize: "14px",
              paddingLeft: "25px",
              paddingRight: "20px",
              height: "30px"
            }}
          >
            {/* <div>
              You have earned your My Health reward dollars for next year.
            </div> */}
          </div>
          <div className="tile-subbox" style={{ marginTop: "15px" }}>
            <div className="row">
              <div className="col">
                <div
                  className="tile-rectangle myhealthButtonContainer1"
                  // style={{
                  //   marginLeft: "18px",
                  //   width: "230px",
                  //   height: "38px"
                  // }}
                >
                  <img
                    className="tile-externallink"
                    alt="external-link"
                    src="/external-link.svg"
                  />
                  <a
                    className="tile-recttitle"
                    href={config.myHealthProgramGuideUrl}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    My Health / Rally
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div style={{ paddingTop: "8px" }}></div>
          <PortletInfo
            position="-40px"
            message={informationMessge}
            showing={displayInfo}
            title="My Health Program"
            top="20px"
          />
        </div>
      );
    }
    return (
      <div>
        <h2 className="tile-header">My Health Program</h2>
        <div className="tile-blur">
          <button
            className="btn btn-link"
            onClick={this.handleBlurChange}
            title={
              this.state.blurred === "yes" ? "Click to show" : "Click to hide"
            }
          >
            <img
              style={{
                height: "20px",
                width: "20px"
              }}
              alt={this.state.blurred === "yes" ? "click to show My Health program" : "click to hide My Health program"}
              src={
                this.state.blurred === "yes" ? "/eye-blue.png" : "hide-blue.png"
              }
            />
          </button>
        </div>
        <hr className="tile-line" />
        <div className="fowSubTitle">
          Due to your new hire date, you will automatically receive your My
          Health credit for next year.{" "}
          <span className="infoLink" onClick={this.showInformation}>
            {displayInfo ? "X" : "?"}
          </span>
        </div>
        <div
          className={this.state.blurred === "yes" ? "obfuscate" : ""}
          style={{
            fontSize: "14px",
            paddingLeft: "25px",
            paddingRight: "20px",
            height: "30px"
          }}
        >
          {/* <div>
            You have earned your My Health reward dollars for next year.
          </div> */}
        </div>
        <div className="tile-subbox" style={{ marginTop: "15px" }}>
          <div className="row">
            <div className="col">
              <div
                className="tile-rectangle myhealthButtonContainer1"
                // style={{
                //   marginLeft: "18px",
                //   width: "230px",
                //   height: "38px"
                // }}
              >
                <img
                  className="tile-externallink"
                  alt="external-link"
                  src="/external-link.svg"
                />
                <a
                  className="tile-recttitle"
                  href={config.myHealthProgramGuideUrl}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  My Health / Rally
                </a>
              </div>
            </div>
          </div>
        </div>
        <div style={{ paddingTop: "8px" }}></div>
        <PortletInfo
          position="-40px"
          message={informationMessge}
          showing={displayInfo}
          title="My Health Program"
          top="20px"
        />
      </div>
    );
  };

  returnContent(myHealthData, MyHealthDefaultActive, deactivateMyHealth) {
    const { displayInfo } = this.state;
    const informationMessge = `Results for other sections will be reported once the Health Assessment has been completed. Progress displayed for associate only. If your spouse is enrolled, have them log onto Rally to verify their progress.`;
    if (myHealthData) {
      // const today = new Date();
      // const year = today.getFullYear();
      const newHire = myHealthData.newHire;
      const pastCutOff = myHealthData.pastCutOff;
      const completed = myHealthData.completed;
      if (myHealthData.isEnrolled === "N") {
        //NOt enrolled in health insurance
        console.log(
          "myHealth.returnContent - Not enrolled in health insurance"
        );
        return this.returnNotEligible(
          displayInfo,
          MyHealthDefaultActive,
          deactivateMyHealth
        );
      }

      if (newHire) {
        //new Hire
        console.log("myHealth.returnContent - New Hire");
        return this.returnNewHire(
          displayInfo,
          MyHealthDefaultActive,
          deactivateMyHealth
        );
      }
      if (pastCutOff && !completed) {
        //past due not completed
        console.log("myHealth.returnContent - Past Due, not completed");
        return this.returnProgramClosedNotCompleted(
          displayInfo,
          MyHealthDefaultActive,
          deactivateMyHealth
        );
      }

      return myHealthData.error ? (
        <div>
          <div className="tile-apierroricon">
            <div className="portletErrorAlert">!</div>
            {/* <bolt-icon name= "notification-error" label = "error" color = "red" size = "lg"></bolt-icon> */}
          </div>
          <div className="tile-apierrortext">Oops, this is unexpected.</div>
          <div className="tile-apierrortext1">Error code: 500</div>
          <div className="tile-apierrortext1">
            An error has occurred and we’re working to fix the issue.
          </div>
          <div className="tile-apierrortext1">
            <span>If the issue persists please </span>
            <a
              className="tile-apierrorcontactus"
              href="https://nwproduction.service-now.com/csm"
              rel="noopener noreferrer"
              target="_blank"
            >
              contact us.
            </a>
          </div>
          <div
            className="tile-apierror-refresh"
            onClick={this.handleRefresh}
            title="Refresh"
          >
            Refresh
          </div>
          <div style={{ paddingTop: "25px" }}></div>
        </div>
      ) : (
        <div>
          <h2 className="tile-header">
            <button
              style={
                MyHealthDefaultActive === "N"
                  ? { display: "inline-block" }
                  : { display: "none" }
              }
              onClick={deactivateMyHealth}
              className="link"
            >
              <img
                src="/chevron_up.png"
                alt="Click to collapse My Health Program"
                width="19px"
                style={{ marginRight: "10px" }}
              />
            </button>
            My Health Program
          </h2>
          <div className="tile-blur">
            <button
              className="btn btn-link"
              onClick={this.handleBlurChange}
              title={
                this.state.blurred === "yes" ? "Click to show" : "Click to hide"
              }
            >
              <img
                style={{
                  height: "20px",
                  width: "20px"
                }}
                alt={this.state.blurred === "yes" ? "click to show My Health program" : "click to hide My Health program"}
                src={
                  this.state.blurred === "yes"
                    ? "/eye-blue.png"
                    : "hide-blue.png"
                }
              />
            </button>
          </div>
          <hr className="tile-line" />
          <div className="fowSubTitle">
            My Progress{" "}
            <span className="infoLink" onClick={this.showInformation}>
              {displayInfo ? "X" : "?"}
            </span>
            <span
              style={{ float: "right", fontSize: "11px", fontWeight: "bold" }}
            >
              Due: {myHealthData.dueDate}
            </span>
          </div>
          <div className={this.state.blurred === "yes" ? "obfuscateMore" : ""}>
            {this.returnOverall(myHealthData)}
          </div>

          <div className="tile-subbox" style={{ marginTop: "15px" }}>
            <div className="row">
              <div className="col">
                <div
                  className={
                    completed
                      ? "tile-rectangle myhealthAwsButtonContainer"
                      : "tile-rectangle myhealthButtonContainer1"
                  }
                  // style={
                  //   completed
                  //     ? {
                  //         marginLeft: "18px",
                  //         width: "112px",
                  //         height: "38px"
                  //       }
                  //     : {
                  //         marginLeft: "18px",
                  //         width: "230px",
                  //         height: "38px"
                  //       }
                  // }
                >
                  <img
                    className="tile-externallink"
                    alt="external-link"
                    src="/external-link.svg"
                  />
                  <a
                    className="tile-recttitle"
                    href={
                      completed
                        ? config.awsSharepointUrl
                        : config.myHealthProgramGuideUrl
                    }
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    {completed ? "AWS site" : "My Health / Rally"}
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div style={{ paddingTop: "8px" }}></div>
          <PortletInfo
            position="-300px"
            message={informationMessge}
            showing={displayInfo}
            title="Completion of Health Assessment"
          />
        </div>
      );
    } else {
      return <Spinner />;
    }
  }

  render() {
    const {
      MyHealthData,
      activePortal,
      fetchMyHealth,
      MyHealthDefaultActive,
      deactivateMyHealth
    } = this.props;

    // const { blurred } = this.state;
    this.overall = 0;
    if (activePortal === "Y") {
      console.log("myHealth.render ...  MyHealthData", MyHealthData);
      if (MyHealthData) {
        const content = this.returnContent(
          MyHealthData,
          MyHealthDefaultActive,
          deactivateMyHealth
        );
        return (
          <WorkdayPortletwithDate content={content} datetimeLabel={"As of"} />
        );
      } else {
        console.log("myHealth.render ... call populateMyHealthPortal");
        this.props.populateMyHealthPortal();
        return <PortletLoading title="My Health Program" />;
      }
    } else {
      return (
        <CollapsedPortlet
          // activateAwsPortal={this.handleExpand}
          dataFetch={fetchMyHealth}
          title="My Health Program"
        ></CollapsedPortlet>
      );
    }
  }
}

export default MYHEALTH;

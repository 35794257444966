import React, { Component } from "react";
import {
  CollapsedPortlet,
  PortletLoading,
  WorkdayPortlets
} from "../common/portlet";
import Spinner from "../common/spinner";
// import { toast } from "react-toastify";
import "@nationwide-bolt/css-core/dist/css/bolt-core.css";
import "react-toastify/dist/ReactToastify.css";
import { PortletInfo } from "../common/portletInfo";
import "../main.css";
import { getEnvironment } from "../services/EnvironmentVariable";

class BENEFITS extends Component {
  state = {
    displayInfo: false,
    blurred: "yes"
  };

  handleBlurChange = () => {
    if (this.state.blurred === "yes") {
      this.setState({ blurred: "no" });
    } else {
      this.setState({ blurred: "yes" });
    }
  };

  showInformation = () => {
    this.setState({ displayInfo: !this.state.displayInfo });
  };

  returnActiveContent(benefitsData, benefitsDefaultActive, deactivateBenefits) {
    console.log("benefitsData", benefitsData);
    if (benefitsData) {
      let status = "";
      let informationMessge = "";
      let image = "";
      let position = "5px";
      
      if (benefitsData.Status === "Submitted") {
        status = "Submitted";
        image = "submitted.svg";
        informationMessge = (
          <div>
            You have submitted your elections for next year. You can make
            changes until {benefitsData.DueDate}.
          </div>
        );
      }
      if (benefitsData.Status === "In Progress") {
        status = "In Progress";
        // image = "in-progress.svg";
        image = "incomplete.png";
        informationMessge =
          "You have started your enrollment, but have not yet submitted your elections for next year. You must submit for changes to be effective.";
      }
      if (benefitsData.Status === "On Hold") {
        status = "On Hold";
        image = "on-hold.svg";
        informationMessge = (
          <div>
            Your election is on hold as another event is open. Please 
           <a href="https://nwproduction.service-now.com/kb_view.do?sysparm_article=KM340654" target="_blank"> complete or cancel </a>
           any other benefit events in your Workday inbox so you can complete your enrollment.
          </div>
        );
      }
      if (benefitsData.Status === "Not Available") {
        status = "Not Available";
        image = "not-available.svg";
        informationMessge =
          "As a recent hire, your benefit elections are made using the new hire enrollment process.";
      }
      if (benefitsData.Status === "Not Started") {
        status = "Not Started";
        image = "not-started.svg";
        informationMessge =
          "You have not yet started your enrollment for next year.";
      }
      return (
        <div>
          <h2 className="tile-header">
            <button
              style={
                benefitsDefaultActive === "N"
                  ? { display: "inline-block" }
                  : { display: "none" }
              }
              onClick={deactivateBenefits}
              className="link"
            >
              <img
                src="/chevron_up.png"
                alt="Click to collapse benefits"
                width="19px"
                style={{ marginRight: "10px" }}
              />
            </button>
            Benefit Elections
          </h2>
          <hr className="tile-line" />
          <div className="container-fluid">
            <div className="row">
              <div className="col" style={{ minWidth: "61%" }}>
                <div
                  className="tile-recttitle"
                  style={{ paddingLeft: "10px", fontSize: "18px" }}
                >
                  Open Enrollment is here
                </div>
                <div
                  className="tile-title"
                  style={{
                    paddingLeft: "10px",
                    fontSize: "11px",
                    fontWeight: "bold"
                  }}
                >
                  BENEFIT ELECTIONS ARE DUE
                </div>
                <span
                  className="tile-benefits"
                  style={{ paddingLeft: "10px", paddingTop: "-10px" }}
                >
                  {benefitsData.DueDate} at 11:55 p.m. EST
                </span>
              </div>

              <div
                className="col tile-box-benefits"
                style={benefitsData.Status == "New Hire" ?{  display: "none" }: {whiteSpace: "nowrap"}}
              >
                <img
                  src={image}
                  alt="status"
                  width="18px"
                  style={{ marginLeft: "-5px" }}
                />
                <span
                  style={{
                    border: "1px solid #BCBDBC",
                    width: "0px",
                    height: "34px",
                    marginLeft: "5px"
                  }}
                ></span>
                <span
                  className="tile-recttitle"
                  style={status !== "Not Available" ? { fontSize: "15px", marginTop: "5px", paddingLeft: "2px" }: {fontSize: "12px", marginTop: "5px"}}
                >
                  {status}
                </span>
                <span
                  className="infoLink"
                  id="benefitsInfoLink"
                  onClick={this.showInformation}
                >
                  {this.state.displayInfo ? "X" : "?"}
                </span>
              </div>
            </div>
            {status !== "Not Available" & benefitsData.Status !== "New Hire" ? (
              <div
                className="col tile-title myBenefitsWarning"
                style={{ paddingTop: "8px", fontSize: "12.8px" }}
              >
                Open Enrollment for Associates is now active.<br></br>
                <b>
                  There are {benefitsData.Daysremaining} days left to make your
                  elections for next year!
                </b>
              </div>
            ) : (
              <div
                className="col tile-title myBenefitsWarning"
                style={benefitsData.Status == "New Hire" ? { paddingTop: "8px", fontSize: "12.8px" }: {display:"none"}}
              >
                New associates must complete their Hire enrollment event before completing Open Enrollment. See <a href="https://onyourside.sharepoint.com/sites/Associate-Benefits/SitePages/New-Associates.aspx" target="_blank" rel="noreferrer noopener" title="Click Link - New Associate Benefits">New Associate Benefits</a> for more information.
              </div>
            )}
          </div>
          <div className="tile-subbox" style={{ marginTop: "15px" }}>
            <div className="row">
              <div className="col tileTwocolumn">
                <div
                  className="tile-rectangle"
                  style={{ marginLeft: "18px", width: "190px", height: "38px" }}
                >
                  <img
                    className="tile-externallink"
                    alt="external-link"
                    src="/external-link.svg"
                  />
                  <a
                    className="tile-recttitle"
                    href="https://onyourside.sharepoint.com/sites/Associate-Benefits/SitePages/Open-Enrollment.aspx"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Open Enrollment Site
                  </a>
                </div>
              </div>
              <div className="col tileTwocolumn">
                <div
                  className="tile-rectangle"
                  style={{
                    marginLeft: "18px",
                    width: "125px",
                    height: "38px"
                  }}
                >
                  <img
                    className="tile-externallink"
                    alt="external-link"
                    src="/external-link.svg"
                  />
                  <a
                    className="tile-recttitle"
                    href={benefitsData.EnrollNow}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Enroll Now
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div style={{ paddingTop: "8px" }}></div>
          {/* <PortletBenefitInfo
            position={position}
            message={informationMessge}
            showing={this.state.displayInfo}
            title={status}
            top="26px"
          /> */}
          <PortletInfo
            position={position}
            id="benefitsInfo"
            message={informationMessge}
            showing={this.state.displayInfo}
            title={status}
          />
        </div>
      );
    } else {
      return <Spinner />;
    }
  }

  returnFinalizedContent(
    benefitsData,
    benefitsDefaultActive,
    deactivateBenefits
  ) {
    console.log("benefitsData", benefitsData);
    if (benefitsData) {
      const status = "Finalized";
      var link = (
        <a
          href="https://nwproduction.service-now.com/kb_view.do?sysparm_article=KM326855"
          rel="noopener noreferrer"
          target="_blank"
        >
          What are my options to make changes?
        </a>
      );
      var informationMessge = (
        <div>
          Your benefit elections for Jan. 1 are finalized based on your actions
          during Open Enrollment. {link}
        </div>
      );
      const position = "-250px";
      return (
        <div>
          <h2 className="tile-header">
            <button
              style={
                benefitsDefaultActive === "N"
                  ? { display: "inline-block" }
                  : { display: "none" }
              }
              onClick={deactivateBenefits}
              className="link"
            >
              <img
                src="/chevron_up.png"
                alt="click to collapse benefits"
                width="19px"
                style={{ marginRight: "10px" }}
              />
            </button>
            Benefit Elections
          </h2>
          <hr className="tile-line" />
          <div className="container-fluid">
            <div className="row tile-box-benefits-final">
              <div
                className="col"
                style={{ marginTop: "22px", maxWidth: "15%" }}
              >
                <img src="finalized.svg" alt="statusimage" width="20px" />
              </div>
              <div className="tile-benefits-SeperatorLine"></div>
              <div className="col">
                <span
                  className="row tile-title"
                  style={{
                    fontSize: "11px",
                    marginTop: "5px",
                    fontWeight: "bold",
                    paddingLeft: "6px"
                  }}
                >
                  OPEN ENROLLMENT HAS ENDED AND <br></br>BENEFIT ELECTIONS FOR
                  JAN. 1 ARE
                </span>
                <div className="row">
                  <span className="tile-recttitle" style={{ fontSize: "18px" }}>
                    {status}
                  </span>
                  <span
                    className="infoLink"
                    style={{
                      color: "#1C57A5",
                      marginLeft: "8px",
                      marginTop: "12px"
                    }}
                    onClick={this.showInformation}
                  >
                    {this.state.displayInfo ? "X" : "?"}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="tile-subbox" style={{ marginTop: "15px" }}>
            <div className="row">
              <div className="col tileTwocolumn">
                <div
                  className="tile-rectangle"
                  style={{ marginLeft: "18px", width: "190px", height: "38px" }}
                >
                  <img
                    className="tile-externallink"
                    alt="external-link"
                    src="/external-link.svg"
                  />
                  <a
                    className="tile-recttitle"
                    href="https://onyourside.sharepoint.com/sites/Associate-Benefits/SitePages/Open-Enrollment.aspx"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Open Enrollment Site
                  </a>
                </div>
              </div>
              <div className="col tileTwocolumn">
                <div
                  className="tile-rectangle"
                  style={{
                    marginLeft: "18px",
                    width: "212px",
                    height: "38px"
                  }}
                >
                  <img
                    className="tile-externallink"
                    alt="external-link"
                    src="/external-link.svg"
                  />
                  <a
                    className="tile-recttitle"
                    href="https://www.myworkday.com/nationwide/d/task/2998$3215.htmld"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Current Benefit Elections
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div style={{ paddingTop: "8px" }}></div>
          <PortletInfo
            position={position}
            message={informationMessge}
            showing={this.state.displayInfo}
            title={status}
            top="45px"
          />
        </div>
      );
    } else {
      return <Spinner />;
    }
  }

  returnInactiveContent(
    benefitsData,
    benefitsDefaultActive,
    deactivateBenefits
  ) {
    console.log("benefitsData", benefitsData);
    var link = (
      <a
        href="https://www.myworkday.com/nationwide/d/inst/13102!CK5mGhEKBggDEMenAhIHCgUI1A0QJg~~/cacheable-task/2997$2151.htmld#backheader=true"
        rel="noopener noreferrer"
        target="_blank"
      >
        qualified life status change
      </a>
    );
    const environ = getEnvironment();
    if (environ !== "DEFAULT") {
      link = (
        <a
          href="https://impl.workday.com/nationwide2/d/inst/13102!CK5mGhEKBggDEMenAhIHCgUI1A0QJg~~/cacheable-task/2997$2151.htmld#backheader=true"
          rel="noopener noreferrer"
          target="_blank"
        >
          qualified life status change
        </a>
      );
    }
    let informationMessge = (
      <div>
        <div style={{ fontSize: "14px", fontWeight: "bold" }}>
          Qualified Benefit Event
        </div>
        <div>
          This is an event in your life – like getting married, having a baby,
          or gain/loss of other coverage – that allows you to change your
          benefit elections during the calendar year. Changes to your HSA can be
          made any time throughout the year for the next pay.
        </div>
      </div>
    );
    let position = "-320px";
    let status = "Open Enrollment";
    let MedicalPlan = benefitsData.medicalPlan
      ? benefitsData.medicalPlan
      : "Medical";
    let MedicalCoverage = benefitsData.medicalCoverage
      ? benefitsData.medicalCoverage
      : "";
    let DentalPlan = benefitsData.dentalPlan
      ? benefitsData.dentalPlan
      : "Dental";
    let DentalCoverage = benefitsData.dentalCoverage
      ? benefitsData.dentalCoverage
      : "";
    let VisionPlan = benefitsData.visionPlan
      ? benefitsData.visionPlan
      : "Vision";
    let VisionCoverage = benefitsData.visionCoverage
      ? benefitsData.visionCoverage
      : "";
    let HSAPlan = benefitsData.hsaPlan ? benefitsData.hsaPlan : "HSA";
    let HSACoverage = benefitsData.hsaCoverage ? benefitsData.hsaCoverage : "";
    if (this.state.blurred === "yes") {
      MedicalPlan = MedicalPlan.replace(/[A-Z]/g, "X").replace(
        /[a-z0-9]/g,
        "x"
      );
      DentalPlan = DentalPlan.replace(/[A-Z]/g, "X").replace(/[a-z0-9]/g, "x");
      VisionPlan = VisionPlan.replace(/[A-Z]/g, "X").replace(/[a-z0-9]/g, "x");
      MedicalCoverage = MedicalCoverage.replace(/[A-Z]/g, "X").replace(
        /[a-z0-9]/g,
        "x"
      );
      DentalCoverage = DentalCoverage.replace(/[A-Z]/g, "X").replace(
        /[a-z0-9]/g,
        "x"
      );
      VisionCoverage = VisionCoverage.replace(/[A-Z]/g, "X").replace(
        /[a-z0-9]/g,
        "x"
      );
      HSAPlan = HSAPlan.replace(/[A-Z]/g, "X").replace(/[a-z0-9]/g, "x");
      HSACoverage = HSACoverage.replace(/[A-Z]/g, "X").replace(
        /[a-z0-9]/g,
        "x"
      );
    }
    return (
      <div>
        <h2 className="tile-header">
          <button
            style={
              benefitsDefaultActive === "N"
                ? { display: "inline-block" }
                : { display: "none" }
            }
            onClick={deactivateBenefits}
            className="link"
          >
            <img
              src="/chevron_up.png"
              alt="click to collapse benefits"
              width="19px"
              style={{ marginRight: "10px" }}
            />
          </button>
          Benefit Elections
          <div className="tile-blur">
            <button
              className="btn btn-link"
              onClick={this.handleBlurChange}
              title={
                this.state.blurred === "yes" ? "Click to show" : "Click to hide"
              }
            >
              <img
                style={{
                  height: "20px",
                  width: "20px"
                }}
                alt={this.state.blurred === "yes" ? "click to show Benefits" : "click to hide Benefits"}
                src={
                  this.state.blurred === "yes"
                    ? "/eye-blue.png"
                    : "hide-blue.png"
                }
              />
            </button>
          </div>
        </h2>
        <hr className="tile-line" />
        <div className="container-fluid">
          <div className="row">
            <span
              className="tile-recttitle"
              style={{ paddingLeft: "25px", fontSize: "18px" }}
            >
              Current Year Benefit Elections
            </span>
            {/* <span
              className="infoLink"
              style={{ color: "#1C57A5", marginLeft: "8px", marginTop: "10px" }}
              onClick={this.showInformation}
            >
              {this.state.displayInfo ? "X" : "?"}
            </span> */}
          </div>
          {/* // BENEFITS WILL GO HERE! */}

          <div
            className={
              this.state.blurred === "yes"
                ? "row benefitPlansHeader obfuscate"
                : "row benefitPlansHeader"
            }
            style={this.state.blurred === "yes" ? { margin: "0px" } : {}}
          >
            <div className="col col-5 benefitPlanTypeHead">Benefit Plan</div>
            <div className="col benefitCoverageHead">Coverage</div>
          </div>
          <div
            className={
              this.state.blurred === "yes"
                ? "row benefitPlansRow obfuscate"
                : "row benefitPlansRow"
            }
            style={this.state.blurred === "yes" ? { margin: "0px" } : {}}
          >
            <div className="col col-5 benefitPlanType">{MedicalPlan}</div>
            <div className="col benefitPlanCoverage">{MedicalCoverage}</div>
          </div>
          <div
            className={
              this.state.blurred === "yes"
                ? "row benefitPlansRow obfuscate"
                : "row benefitPlansRow"
            }
            style={this.state.blurred === "yes" ? { margin: "0px" } : {}}
          >
            <div className="col col-5 benefitPlanType">{DentalPlan}</div>
            <div className="col benefitPlanCoverage">{DentalCoverage}</div>
          </div>
          <div
            className={
              this.state.blurred === "yes"
                ? "row benefitPlansRow obfuscate"
                : "row benefitPlansRow"
            }
            style={this.state.blurred === "yes" ? { margin: "0px" } : {}}
          >
            <div className="col col-5 benefitPlanType">{VisionPlan}</div>
            <div className="col benefitPlanCoverage">{VisionCoverage}</div>
          </div>
          <div
            className={
              this.state.blurred === "yes"
                ? "row benefitPlansRow obfuscate"
                : "row benefitPlansRow"
            }
            style={this.state.blurred === "yes" ? { margin: "0px" } : {}}
          >
            <div className="col col-5 benefitPlanType">{HSAPlan}</div>
            <div className="col benefitPlanCoverage">{HSACoverage}</div>
          </div>

          <div className="row benefitPlansFooter">
            <div className="col">
              Certain elections may be changed mid year within 60 days of a
              Qualified Benefit Event,&nbsp;
              <a
                style={{
                  color: "#1C57A5",
                  textDecoration: "underline",
                  cursor: "pointer"
                }}
                href="https://onyourside.sharepoint.com/sites/Associate-Benefits/SitePages/Enrollment-and-Changes.aspx"
                rel="noopener noreferrer"
                target="_blank"
              >
                learn more
              </a>
              .
              <span
                className="infoLink"
                style={{
                  color: "#1C57A5",
                  marginLeft: "8px",
                  marginTop: "10px"
                }}
                onClick={this.showInformation}
              >
                {this.state.displayInfo ? "X" : "?"}
              </span>
            </div>
          </div>
        </div>
        <div className="tile-subbox" style={{ marginTop: "15px" }}>
          <div className="row">
            <div className="col tileTwocolumn">
              <div
                className="tile-rectangle"
                style={{
                  marginLeft: "18px",
                  width: "212px",
                  height: "38px"
                }}
              >
                <img
                  className="tile-externallink"
                  alt="external-link"
                  src="/external-link.svg"
                />
                <a
                  className="tile-recttitle"
                  href="https://www.myworkday.com/nationwide/d/task/2998$3215.htmld"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  View All Benefit Elections
                </a>
              </div>
            </div>
            <div className="col tileTwocolumn">
              <div
                className="tile-rectangle"
                style={{ marginLeft: "18px", width: "190px", height: "38px" }}
              >
                <img
                  className="tile-externallink"
                  alt="external-link"
                  src="/external-link.svg"
                />
                <a
                  className="tile-recttitle"
                  href="https://onyourside.sharepoint.com/sites/Associate-Benefits"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Benefit Information
                </a>
              </div>
            </div>
          </div>
        </div>
        <div style={{ paddingTop: "8px" }}></div>
        <PortletInfo
          position={position}
          message={informationMessge}
          showing={this.state.displayInfo}
          title={status}
          top="5px"
          upsideDown={true}
        />
      </div>
    );
  }

  returnErrorContent(benefitsData) {
    console.log("Benefits tile error benefitsData", benefitsData);
    return (
      <div>
        <div className="tile-apierroricon">
          <div className="portletErrorAlert">!</div>
        </div>
        <div className="tile-apierrortext">Oops, this is unexpected.</div>
        <div className="tile-apierrortext1">Error code: 500</div>
        <div className="tile-apierrortext1">
          An error has occurred and we’re working to fix the issue.
        </div>
        <div className="tile-apierrortext1">
          <span>If the issue persists please </span>
          <a
            className="tile-apierrorcontactus"
            href="https://nwproduction.service-now.com/csm"
            rel="noopener noreferrer"
            target="_blank"
          >
            contact us.
          </a>
        </div>
        <div style={{ paddingTop: "25px" }}></div>
      </div>
    );
  }
  render() {
    const {
      benefitsData,
      activePortal,
      fetchBenefits,
      benefitsDefaultActive,
      deactivateBenefits
    } = this.props;
    console.log("active portal in benefits", activePortal);
    let content = "";
    if (activePortal === "Y") {
      if (benefitsData) {
        if (benefitsData.error === true) {
          content = this.returnErrorContent(benefitsData);
        } else {
          if (benefitsData.display === "active") {
            content = this.returnActiveContent(
              benefitsData,
              benefitsDefaultActive,
              deactivateBenefits
            );
          }
          if (benefitsData.display === "final") {
            content = this.returnFinalizedContent(
              benefitsData,
              benefitsDefaultActive,
              deactivateBenefits
            );
          }
          if (benefitsData.display === "inactive") {
            content = this.returnInactiveContent(
              benefitsData,
              benefitsDefaultActive,
              deactivateBenefits
            );
          }
        }
        if (content === "") {
          this.props.populateBenefitsPortal();
          return <PortletLoading title="Benefit Elections" />;
        } else {
          return <WorkdayPortlets content={content} />;
        }
      } else {
        this.props.populateBenefitsPortal();
        return <PortletLoading title="Benefit Elections" />;
      }
    } else {
      return (
        <CollapsedPortlet
          dataFetch={fetchBenefits}
          title="Benefit Elections"
        ></CollapsedPortlet>
      );
    }
  }
}

export default BENEFITS;

import React, { Component } from "react";
import { CollapsedPortlet, Portlet, PortletLoading } from "../common/portlet";
import Spinner from "../common/spinner";
// import { toast } from "react-toastify";
import "@nationwide-bolt/css-core/dist/css/bolt-core.css";
import "react-toastify/dist/ReactToastify.css";
import "../main.css";

class AWS extends Component {
  state = {
    blurred: "no"
  };

  handleBlurChange = () => {
    if (this.state.blurred === "yes") {
      this.setState({ blurred: "no" });
    } else {
      this.setState({ blurred: "yes" });
    }
  };

  handleRefresh = async () => {
    this.props.activateAwsPortal(true);
    // toast.success("Your Time has been refreshed");
  };

  returnNurse() {
    const { awsData } = this.props;
    const nurses = awsData.nurses;
    console.log("aws.returnNurse returnNurse nurses", nurses);
    if (!nurses.error && nurses.length > 0) {
      const nurse = nurses[0];
      return (
        <React.Fragment>
          <div className="awsSubTitle">My Nationwide Nurse</div>
          <div className="awsData">
            <div>{nurse.c1}</div>
            <div>{nurse.c2}</div>
            <div>
              <a style={{ color: "#1C57A5" }} href={"mailto:" + nurse.c3}>
                {nurse.c3}
              </a>
            </div>
          </div>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <div className="awsSubTitle">My Nationwide Nurse</div>
          <div className="awsDataError">
            <div className="row">
              <div className="col col-sm-1">
                <div className="smallPortletErrorAlert">!</div>
              </div>
              <div className="col">
                <div
                  style={{
                    color: "#D32426",
                    fontSize: "12px",
                    fontWeight: "normal"
                  }}
                >
                  An error occurred.
                  <div
                    className="tile-apierror-refresh"
                    style={{ paddingTop: "0px", textAlign: "left" }}
                    onClick={this.handleRefresh}
                    title="Refresh"
                  >
                    Refresh
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <div className="tile-apierrorbox">
              <span className="tile-apierrortext">
                An error occurred while loading. Please try again.
              </span>
              <div
                className="tile-apierror-refresh"
                onClick={this.handleRefresh}
                title="Refresh"
              >
                Refresh
              </div>
            </div> */}
        </React.Fragment>
      );
    }
  }

  returnCounselor() {
    const { awsData } = this.props;
      return (
      <React.Fragment>
        <div className="awsSubTitle" style={{ paddingTop: "11px" }}>
          Associate Assistance Program
        </div>
        <div className="awsAppointmentLine">
          24/7 Helpline:{" "}
          <span className="awsAppointmentPhone">888.229.0026</span>
        </div>
        <div className="awsAppointmentLine">
        <a href="http://www.NationwideAAP.com" target="blank">NationwideAAP.com</a>
        </div>
        <div className="awsAppointmentLine">
        Web ID: NationwideAAP
        </div>
      </React.Fragment>
    );

  }
  collapse = () => {
    const { collapse } = this.state;
    this.setState({ collapse });
  };
  returnContent(awsData, awsDefaultActive, deactivateAws) {
    console.log("AWS.returnContent awsData", awsData);
    if (awsData) {
      var nurses = awsData.nurses;
      var counselors = awsData.counselors;
      if ((nurses.error && counselors === undefined) || (nurses.error && counselors.error)) {
        console.log("AWS.returnContent -- NO DATA", awsData);
        return (
          <div className="portletError">
            <div className="portletErrorAlert">!</div>
            <div
              style={{
                fontFamily: "nw-primary",
                fontSize: "20px",
                lineHeight: "24px",
                color: "#4D4F53"
              }}
            >
              Oops, this is unexpected.
            </div>
            <div
              style={{
                fontFamily: "nw-primary",
                fontSize: "12px",
                lineHeight: "15px",
                color: "#4D4F53"
              }}
            >
              Error code: 500
              <br />
              An error has occurred and we're working to fix the issue.
              <br />
              If the issue persists please{" "}
              <a
                style={{ color: "#4D4F53", textDecoration: "underline" }}
                href="https://nwproduction.service-now.com/csm"
                rel="noopener noreferrer"
                target="_blank"
              >
                contact us.
              </a>
            </div>
            <div
              className="tile-apierror-refresh"
              onClick={this.handleRefresh}
              title="Refresh"
            >
              Refresh
            </div>
          </div>
        );
      }
      console.log("AWS.returnContent nurses", nurses);
      console.log("AWS.returnContent counselors", counselors);
      return (
        <div>
          <h2 className="tile-header">
            <button
              style={
                awsDefaultActive === "N"
                  ? { display: "inline-block" }
                  : { display: "none" }
              }
              onClick={deactivateAws}
              className="link"
            >
              <img
                src="/chevron_up.png"
                alt="click to collapse Associate wellbeing and safety info"
                width="19px"
                style={{ marginRight: "10px" }}
              />
            </button>
            Associate Wellbeing &amp; Safety{" "}
          </h2>

          <hr className="tile-line" />
          {this.returnNurse()}
          {this.returnCounselor()}
          <div className="tile-subbox" style={{ marginTop: "15px" }}>
            <div
              className="tile-rectangle awsButtonContainer"
              // style={{
              //   marginLeft: "18px",
              //   width: "250px",
              //   height: "38px"
              // }}
            >
              <img
                className="tile-externallink"
                alt="external-link"
                src="/external-link.svg"
              />
              <a
                className="tile-recttitle"
                href="https://onyourside.sharepoint.com/sites/AssociateWellbeingandSafety"
                rel="noopener noreferrer"
                target="_blank"
              >
                Associate Wellbeing &amp; Safety
              </a>
            </div>
          </div>
          <div style={{ paddingTop: "8px" }}></div>
        </div>
      );
    } else {
      return <Spinner />;
    }
  }

  render() {
    const {
      awsData,
      activePortal,
      fetchAwsData,
      awsDefaultActive,
      deactivateAws
    } = this.props;
    console.log("AWS.render active portal in AWS", activePortal);
    if (activePortal === "Y") {
      console.log("AWS.render ...  awsData", awsData);
      if (awsData) {
        console.log("AWS.render ... activePortal = Y");
        const content = this.returnContent(
          awsData,
          awsDefaultActive,
          deactivateAws
        );
        return <Portlet content={content} />;
      } else {
        console.log("AWS.render ... call activateAwsPortal");
        this.props.activateAwsPortal();
        // return <Spinner />;
        return <PortletLoading title="Associate Wellbeing &amp; Safety" />;
      }
    } else {
      console.log("AWS.render not active portal");
      return (
        <CollapsedPortlet
          // activateAwsPortal={this.handleExpand}
          dataFetch={fetchAwsData}
          title="Associate Wellbeing &amp; Safety"
        ></CollapsedPortlet>
      );
    }
  }
}

export default AWS;

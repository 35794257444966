import React, { Component } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import { isEmpty } from "lodash";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./Announcements.css";
import "./App.css";
import "./Linksbar.css";
import "./Portals.css";
import "./aws.css";
import "./benefits.css";
import { default as Badgateway, default as ErrorPage } from "./components/ErrorPage";
import NotFound from "./components/PageNotFound";
import DisplayAnnouncement from "./components/announcementDisplay";
import Callback from "./components/callback";
import Home from "./components/home";
import LeaderHome from "./components/leaderhome";
import NotAuthorized from "./components/notAuthorized";
import { GetPhotos } from "./components/peoplePhoto.jsx";
import TeamsCallback from "./components/teamsCallback";
import config from "./config.json";
import "./fow.css";
import "./jobs.css";
import "./main.css";
import "./menu.css";
import "./myhealth.css";
import "./mypay.css";
import "./newHires.css";
import { UserIsAdmin } from "./services/AuthService";
import { getHSAData } from "./services/HSAService";
import { getOrgChart, getWorkGroup } from "./services/OrgChartService";
import { isAdminUser } from "./services/adminUserService";
import { getAnnouncement } from "./services/announcementService";
import { getNurses } from "./services/awsService";
import { getBenefits } from "./services/benefitService";
import { getAdminAssistants, getCallupData, getCallupShortName } from "./services/callupService";
import { getGivingData } from "./services/cybergrantsService";
import { getDataFile } from "./services/dataExtractService";
import { getFOW } from "./services/fowService";
import { getJobs, getOpenJobs, getState } from "./services/jobsService";
import { getMyHealth } from "./services/myHealthService";
import { getNewHires } from "./services/newHireService";
import { getSavings } from "./services/nwSavingsService";
import { getPaycheck } from "./services/paycheckService";
import { getPortals } from "./services/portalsService";
import { getProductData } from "./services/productService";
import { addSearchHistory } from "./services/searchHistoryService";
import { getFromServiceNow } from "./services/serviceNowService";
import {
  getCurrentTimeOff,
  getYearEndTimeOff
} from "./services/timeOffService";
import "./timeoff.css";

const minCharactersToSearch = 2;
const maxServiceNowResults = config.maxServiceNowResults;
// let fetchingtimeOff = false;
class App extends Component {
  state = {
    user: null,
    searchQuery: "",
    searchData: [],
    searchDataAll: [],
    isAdmin: false,
    searching: true,
    searchingCallup: true,
    intervalId: 0,
    intervalId2: 0,
    announcementId: 0,
    workingAnnouncement: null,
    unfilteredNumber: 0,
    filteredNumber: 0,
    contact: null,
    portlets: [],
    payslipData: null,
    payslipPortalActive: "N",
    payslipDefaultActive: "N",
    timeoffData: null,
    timeoffPortalActive: "N",
    timeoffDefaultActive: "N",
    jobData: null,
    fowData: null,
    MyHealthData: null,
    MyHealthPortalActive: "N",
    MyHealthDefaultActive: "N",
    jobPortalActive: "N",
    jobDefaultActive: "N",
    savingsPortalActive: "N",
    savingsData: null,
    savingsDefaultActive: "N",

    showPortletSelector: "N",
    awsPortalActive: "N",
    awsDefaultActive: "N",
    fowPortalActive: "N",
    fowDefaultActive: "N",

    benefitsPortalActive: "N",
    benefitsData: null,
    benefitsDefaultActive: "N",

    awsData: null,
    payEndDate: "01/12/2020",
    searchHistoryId: 0,
    preTax: 0,
    preTaxCU: 0,
    roth: 0,
    nonRoth: 0,
    rothCU: 0,
    savingsLength: 0,
    callUpSNData: [],
    shortNameResults: [],
    lastNameResults: [],
    firstNameResults: [],
    preferredNameResults: [],
    preferredNameResultsA: [],
    Callup_Image: [],
    orgChart_Image: [],
    HRK_Error: false,
    People_Error: false,
    HRK_Display: true,
    People_Display: true,
    menuExpanded: false,
    newHires: [],
    displayNewHires: false,
    locations: [],
    selectedLocation: "locs_0",
    locationFilter: "",
    givingData: null,
    givingPortalActive: "N",
    givingDefaultActive: "N",
    productData: null,
    productPortalActive: "N",
    productDefaultActive: "N",
    restrictKnowledgeData: 10,
    AdminNameData: [],
    orgChartData: null,
    Org_people: null,
    WorkGroupData: null,
    hsaData: null,
    hsaPortalActive: "N",
    hsaDefaultActive: "N",
    dataFileResponse: null
  };

  componentDidMount() {
    this.fetchingJobs = false;
    this.fetchingSavings = false;
    this.fetchingtimeOff = false;
    this.fetchingPaycheck = false;
    this.fetchingAws = false;
    this.fetchingFOW = false;
    this.fetchingMyHealth = false;
    this.fetchingPortals = false;
    this.fetchingAdmin = false;
    this.fetchingBenefits = false;
    this.fetchingNewHires = false;
    this.fetchingNewHirePhotos = false;
    this.newHirePhotosRetrieved = false;
    let intervalId = setInterval(this.checkAdmin, 100);
    this.setState({ intervalId });
    this.fetchingGivingData = false;
    this.fetchingProduct = false;
    this.fetchingadminName = false;
    this.fetchingOrgChart = false;
    this.fetchingWorkGroup = false;
    this.fetchingHSA = false;
    this.fetchingDataFile = false;
  }

  handleChangeMenuExpanded = () => {
    const { menuExpanded } = this.state;
    // console.log("App.handleChangeMenuExpanded menuExpanded", menuExpanded);
    if (menuExpanded) {
      // console.log("handleChangeMenuExpanded rotate(0.5turn)");
      document.getElementById("mobileMenuHideShowButton").style.transform =
        "none";
    } else {
      // console.log("handleChangeMenuExpanded rotate(0.5turn)");
      document.getElementById("mobileMenuHideShowButton").style.transform =
        "rotate(0.5turn)";
    }

    this.setState({ menuExpanded: !menuExpanded });

    // console.log("handleChangeMenuExpanded rotate(-0.5turn)");
    // document.getElementById("mobileMenuHideShowButton").style.transform =
    //   "rotate(-0.5turn)";
    // document.getElementById("mobileMenuHideShowButton").style.transform =
    //   "none)";
  };

  handlePayEndDateChange = newValue => {
    this.setState({ payEndDate: newValue });
  };

  handleWorkingAnnouncementChange = workingAnnouncement => {
    this.setState({ workingAnnouncement });
  };

  handleChangeShowPortletSelector = () => {
    const { showPortletSelector } = this.state;
    if (showPortletSelector === "N") {
      this.setState({ showPortletSelector: "Y" });
    } else {
      this.setState({ showPortletSelector: "N" });
    }
  };

  handlePortletsAdminChange = portlets => {
    this.setState({ showPortletSelector: "N" });
    for (var i = 0; i < portlets.length; i++) {
      const static_name = portlets[i].static_name;
      const active = portlets[i].active;
      switch (static_name) {
        case "PAYSLIP":
          // this.setState({ payslipPortalActive: active });
          if (this.state.payslipPortalActive === "N" && active === "N") {
            this.retrievePayslipData();
          }
          this.setState({
            payslipPortalActive: active,
            payslipDefaultActive: active
          });
          break;
        case "TIMEOFF":
          if (this.state.timeoffPortalActive === "N" && active === "N") {
            this.retrieveTimeoffData();
          }
          this.setState({
            timeoffPortalActive: active,
            timeoffDefaultActive: active
          });
          break;
        case "JOBS":
          if (this.state.jobPortalActive === "N" && active === "N") {
            this.retrieveJobData();
          }
          this.setState({ jobPortalActive: active, jobDefaultActive: "N" });
          break;
        case "NSP":
          if (this.state.savingsPortalActive === "N" && active === "N") {
            this.retrieveSavingsData();
          }
          this.setState({
            savingsPortalActive: active,
            savingsDefaultActive: active
          });
          break;
        case "AWS":
          if (this.state.awsPortalActive === "N" && active === "N") {
            this.fetchingAws = false;
            this.retrieveAwsData();
          }
          this.setState({ awsPortalActive: active, awsDefaultActive: active });
          break;
        case "FOW":
          if (this.state.fowPortalActive === "N" && active === "N") {
            this.fetchingFOW = false;
            this.retrieveFOWData();
          }
          this.setState({ fowPortalActive: active, fowDefaultActive: active });
          break;
        case "MYHEALTH":
          if (this.state.MyHealthPortalActive === "N" && active === "N") {
            this.fetchingMyHealth = false;
            this.retrieveMyHealthData();
          }
          this.setState({
            MyHealthPortalActive: active,
            MyHealthDefaultActive: active
          });
          break;
        case "BENEFITS":
          console.log(
            "BenefitsPortalActive = ",
            this.state.benefitsPortalActive
          );
          if (this.state.benefitsPortalActive === "N" && active === "N") {
            this.fetchingBenefits = false;
            this.retrieveBenefitsData();
          }
          this.setState({
            benefitsPortalActive: active,
            benefitsDefaultActive: active
          });
          break;
        case "GIVING":
          if (this.state.givingPortalActive === "N" && active === "N") {
            this.givingPortalActive = false;
            this.retrieveGivingData();
          }
          this.setState({
            givingPortalActive: active,
            givingDefaultActive: active
          });
          break;
        case "PRODUCT":
          if (this.state.productPortalActive === "N" && active === "N") {
            this.productPortalActive = false;
            this.retrieveProductData();
          }
          this.setState({
            productPortalActive: active,
            productDefaultActive: active
          });
          break;
        case "HSA":
          if (this.state.hsaPortalActive === "N" && active === "N") {
            this.hsaPortalActive = false;
            this.retrieveHSAData();
          }
          this.setState({
            hsaPortalActive: active,
            hsaDefaultActive: active
          });
          break;
        default:
      }
    }
    this.setState({ portlets });
  };

  handleUserUpdate(user) {
    this.setState({ user });
    this.fetchingAdmin = false;
    let intervalId = setInterval(this.checkAdmin, 100);
    this.setState({ intervalId });
    this.retrieveNewHires();
  }

  retrievePortals = async () => {
    if (!this.fetchingPortals) {
      this.fetchingPortals = true;
      const portalResults = await getPortals();
      console.log("Portals all", portalResults);
      const data = await portalResults.data;
      if (data) {
        for (var i = 0; i < data.length; i++) {
          const static_name = data[i].static_name;
          const active = data[i].active;
          switch (static_name) {
            case "PAYSLIP":
              this.setState({
                payslipPortalActive: active,
                payslipDefaultActive: active
              });
              break;
            case "TIMEOFF":
              this.setState({
                timeoffPortalActive: active,
                timeoffDefaultActive: active
              });
              break;
            case "JOBS":
              this.setState({
                jobPortalActive: active,
                jobDefaultActive: active
              });
              break;
            case "NSP":
              // this.setState({ savingsPortalActive: "Y" });
              this.setState({
                savingsPortalActive: active,
                savingsDefaultActive: active
              });
              break;
            case "AWS":
              this.setState({
                awsPortalActive: active,
                awsDefaultActive: active
              });
              break;
            case "FOW":
              this.setState({
                fowPortalActive: active,
                fowDefaultActive: active
              });
              break;
            case "MYHEALTH":
              this.setState({
                MyHealthPortalActive: active,
                MyHealthDefaultActive: active
              });
              break;
            case "BENEFITS":
              this.setState({
                benefitsPortalActive: active,
                benefitsDefaultActive: active
              });
              break;
            case "GIVING":
              this.setState({
                givingPortalActive: active,
                givingDefaultActive: active
              });
              break;
            case "PRODUCT":
              this.setState({
                productPortalActive: active,
                productDefaultActive: active
              });
              break;
            case "HSA":
              this.setState({
                hsaPortalActive: active,
                hsaDefaultActive: active
              });
              break;
            default:
          }
        }
      }
      this.setState({ portlets: data });
    }

    /**This is temporary */
    // const test = await getUser();
    // console.log("CADI TEST getUser", test);
  };

  handleAnnouncementIdChange(announcementId) {
    this.setState({ announcementId });
    this.retrieveWorkingAnnouncement(announcementId);
  }

  fetchPayslipData = async () => {
    this.fetchingPaycheck = true;
    this.setState({ payslipPortalActive: "Y" });
    const result = await getPaycheck();
    if (result) {
      if (result.error) {
        toast.error(
          "Unable to retrieve Pay Information. Error message: " + result.error
        );
        const payslipData = {
          shortName: "Error",
          payDate: "Error",
          grossAmt: "0",
          netAmt: "0"
        };
        this.setState({ payslipData });
      } else {
        const payslipData = await result;
        this.setState({ payslipData });
      }
    } else {
      toast.error("Unable to retrieve Pay information.");
      const payslipData = {
        shortName: "Error",
        payDate: "Error",
        grossAmt: "0",
        netAmt: "0"
      };
      this.setState({ payslipData });
    }
    this.fetchingPaycheck = false;
  };
  retrievePayslipData = async () => {
    const { payslipPortalActive } = this.state;
    if (!this.fetchingPaycheck && payslipPortalActive === "Y") {
      // console.log("App.retrievePayslipData fetchPayslipData");
      this.fetchPayslipData();
    }
  };

  refreshTimeOff = () => {
    this.fetchingtimeOff = false;
  };

  fetchTimeOffData = async () => {
    // console.log("App.fetchTimeOffData", this.fetchingtimeOff);
    this.fetchingtimeOff = true;
    this.setState({ timeoffPortalActive: "Y" });
    const result = await getCurrentTimeOff();
    const result2 = await getYearEndTimeOff();
    if (result && result2) {
      if (result.error || result2.error) {
        if (result.error) {
          toast.error(
            "Unable to retrieve Your Time information.  Error message: " +
              result.error
          );
        } else {
          toast.error(
            "Unable to retrieve Your Time information.  Error message: " +
              result2.error
          );
        }

        const timeoffData = {
          currentTime: -9999,
          currentDays: -9999,
          yearendTime: -9999,
          yearendDays: -9999
        };
        this.setState({ timeoffData });
      } else {
        let YEaccrualAmount = "";
        let YEaccrualDays = "";
        if (result2.accrualAmount === "NaN" || isEmpty(result2.accrualAmount)) {
          YEaccrualAmount = 0;
        } else {
          YEaccrualAmount = result2.accrualAmount;
        }
        if (isEmpty(result2.accrualDays)) {
          YEaccrualDays = 0;
        } else {
          YEaccrualDays = result2.accrualDays;
        }
        const timeoffData = {
          currentTime: result.accrualAmount,
          currentDays: result.accrualDays,
          yearendTime: YEaccrualAmount,
          yearendDays: YEaccrualDays
        };
        this.setState({ timeoffData });
      }
    } else {
      toast.error("Unable to retrieve Your Time information.");
      const timeoffData = {
        currentTime: -9999,
        currentDays: -9999,
        yearendTime: -9999,
        yearendDays: -9999
      };
      this.setState({ timeoffData });
    }

    this.fetchingtimeOff = false;
  };

  retrieveTimeoffData = async refreshing => {
    if (refreshing) {
      this.fetchingtimeOff = false;
    }
    if (this.state.timeoffPortalActive === "Y" && !this.fetchingtimeOff) {
      // console.log("App.retrieveTimeoffData refreshing TimeOff", refreshing);
      this.fetchTimeOffData();
    }
  };

  retrieveGivingData = async refreshing => {
    console.log("refreshing GIVING", refreshing);
    //getWHOAMI
    if (refreshing) {
      this.fetchingGivingData = false;
    }
    if (!this.fetchingGivingData && this.state.givingPortalActive === "Y") {
      this.fetchGivingData();
    }
  };

  fetchGivingData = async () => {
    console.log("retrieveGivingData fetchingGiving", this.fetchingGivingData);
    this.fetchingGivingData = true;
    this.setState({ givingPortalActive: "Y" });
    const givingRetrieved = await getGivingData();
    if (givingRetrieved) {
      let givingData = [];
      if (givingRetrieved.userMessage) {
        if (givingRetrieved.userMessage) {
          console.log(
            "APP - retrieveGivingData -- GIVING data contains a user message"
          );
          givingRetrieved = { error: givingRetrieved.userMessage };
          toast.error(
            "Unable to retrieve community giving data.  Error message: " +
              givingRetrieved.userMessage
          );
        }
      } else {
        console.log(
          "APP - retrieveWHOAMIData -- get report data from Future of Work"
        );
        givingData = givingRetrieved;
      }

      this.setState({ givingData });
    }
  };

  fetchDataFile = async () => {
    this.fetchingDataFile = true;
    this.setState({dataFileResponse: {Loading: "Loading"}});
    const fileResponse = await getDataFile(); // This method calls the API then triggers a data file download to user's local machine. 
    if (fileResponse) {
      if (fileResponse.Success) {
        console.log('File download started');
        this.setState({dataFileResponse: fileResponse});
      } else if (fileResponse.error) {
        console.log('Error downloading Callup data Extract file');
        this.setState({dataFileResponse: fileResponse});
        if (fileResponse.error.userMessage) {
          toast.error('Error downloading Callup data Extract file.\nError Response: ', fileResponse.error.userMessage);
        } else {
          toast.error('Error downloading Callup data Extract file.\nError Response: Undefined');
        }
      } else {
        console.log('no data returned from Callup data Extract file');
        toast.error('No data returned from Callup data Extract file. Please try again later.');
        this.setState({dataFileResponse: fileResponse});
      }
    }
  };

  retrieveOrgChartData = async refreshing => {
    console.log("refreshing OrgChart", refreshing);
    //getWHOAMI
    if (refreshing) {
      this.fetchingOrgChart = false;
    }
    if (!this.fetchingOrgChart) {
      this.fetchGivingData();
    }
  };

  fetchOrgChartData = async Person => {
    console.log("retrieveOrgChartData fetchingorgChart", this.fetchingOrgChart);
    this.setState({ orgChartData: null, Org_people: null });
    this.fetchingOrgChart = true;
    const orgChartRetrieved = await getOrgChart(Person);
    if (orgChartRetrieved) {
      let orgChartData = [];
      if (orgChartRetrieved.error) {
        if (orgChartRetrieved.error) {
          console.log(
            "APP - retrieveOrgChartData -- OrgChart Data contains an error"
          );
          orgChartRetrieved = { error: orgChartRetrieved.error };
          toast.error(
            "Unable to retrieve OrgChart data.  Error message: " +
              orgChartRetrieved.error
          );
        }
      } else {
        console.log(
          "APP - retrieveOrgChartData -- get OrgChart Data from callup"
        );
        orgChartData = orgChartRetrieved;
      }
      let people = [];
      let subs = [];

      this.setState({ orgChartData });
      for (let i = 0; i < orgChartData.length; i++) {
        people[i] = orgChartData[i].TeamMate;
      }
      console.log("People for photo", people);
      // Get admin names
      let AdminNameData = this.state.AdminNameData;
      let AdminNameDataNew = await getAdminAssistants(people);
      Array.prototype.push.apply(AdminNameData, AdminNameDataNew);
      this.setState({ AdminNameData });
      // Get photos
      let orgChart_Image = [];
      let orgChart_Image2 = [];
      // Load first 7 photos then do all the rest
      orgChart_Image = await GetPhotos(people.slice(0, 7));
      this.setState({ orgChart_Image });
      orgChart_Image2 = await GetPhotos(people.slice(7));
      Array.prototype.push.apply(orgChart_Image, orgChart_Image2);
      this.setState({ orgChart_Image });
    }
  };

  retrieveWorkGroupData = async refreshing => {
    console.log("refreshing WorkGroup", refreshing);
    //getWHOAMI
    if (refreshing) {
      this.fetchingWorkGroup = false;
    }
    if (!this.fetchingWorkGroup) {
      this.fetchWorkGroupData();
    }
  };

  fetchWorkGroupData = async managerSN => {
    console.log(
      "retrieveWorkGroupData fetchingWorkGroupData",
      this.fetchingWorkGroup
    );
    this.setState({ WorkGroupData: null });
    this.fetchingWorkGroup = true;
    const WorkGroupRetrieved = await getWorkGroup(managerSN);
    if (WorkGroupRetrieved) {
      let WorkGroupData = [];
      if (WorkGroupRetrieved.error) {
        if (WorkGroupRetrieved.error) {
          console.log(
            "APP - retrieveWorkGroupData -- WorkGroup Data contains an error"
          );
          WorkGroupRetrieved = { error: WorkGroupRetrieved.error };
          toast.error(
            "Unable to retrieve WorkGroup data.  Error message: " +
              WorkGroupRetrieved.error
          );
        }
      } else {
        console.log(
          "APP - retrieveWorkGroupData -- get WorkGroup Data from callup"
        );
        WorkGroupData = WorkGroupRetrieved;
      }
      let people = [];

      this.setState({ WorkGroupData });
      for (let i = 0; i < WorkGroupData.length; i++) {
        people[i] = WorkGroupData[i];
      }

      console.log("People for photo", people);
      let orgChart_Image = [];

      let orgChart_Image2 = [];
      orgChart_Image = await GetPhotos(people.slice(0, 7));
      this.setState({ orgChart_Image });
      orgChart_Image2 = await GetPhotos(people.slice(7));
      Array.prototype.push.apply(orgChart_Image, orgChart_Image2);
      this.setState({ orgChart_Image });
      let AdminNameData = this.state.AdminNameData;
      let AdminNameDataNew = await getAdminAssistants(people);
      Array.prototype.push.apply(AdminNameData, AdminNameDataNew);
      this.setState({ AdminNameData });
    }
  };

  retrieveProductData = async refreshing => {
    console.log("refreshing Product", refreshing);
    //getWHOAMI
    if (refreshing) {
      this.fetchingProduct = false;
    }
    if (!this.fetchingProduct && this.state.productPortalActive === "Y") {
      this.fetchProductData();
    }
  };

  fetchProductData = async () => {
    console.log("fetchProductData fetchingWHOAMI", this.fetchingWHOAMI);
    this.fetchingProduct = true;
    this.setState({ productPortalActive: "Y" });
    const productRetrieved = await getProductData();
    if (productRetrieved) {
      let productData = [];
      if (productRetrieved.userMessage) {
        if (productRetrieved.userMessage) {
          console.log(
            "APP - fetchProductData -- Product data contains a user message"
          );
          productData = { error: productRetrieved.userMessage };
          toast.error(
            "Unable to retrieve product.  Error message: " +
              productRetrieved.userMessage
          );
        }
      } else {
        console.log(
          "APP - fetchProductData -- get report data from Future of Work"
        );
        productData = productRetrieved;
      }

      this.setState({ productData });
    }
  };

  retrieveAdminName = async refreshing => {
    console.log("refreshing AdminName", refreshing);

    if (refreshing) {
      this.fetchingAdminName = false;
    }

    if (!this.fetchingAdminName) {
      this.fetchAdminNameData();
    }
  };

  fetchAdminNameData = async (entry, shortname) => {
    console.log(
      "retrieveAdminNAme fetchingAdminNameData",
      this.fetchingAdminNameData
    );

    this.fetchingAdminNameData = true;
    const { AdminNameData } = this.state;
    let current = AdminNameData;

    const AdminNameRetrieved = await getCallupShortName(shortname);
    console.log("getcallupdata for admin", AdminNameRetrieved);

    if (AdminNameRetrieved) {
      if (AdminNameRetrieved.userMessage) {
        console.log(
          "APP - retrieveAdminName --",
          AdminNameRetrieved.userMessage
        );

        toast.error(
          "Unable to retrieve Future of Word.  Error message: " +
            AdminNameRetrieved.userMessage
        );
      } else {
        console.log("APP - retrieveAdminName");
      }
    }
    console.log("Progression of admin", AdminNameRetrieved);
    current.push({ entry, AdminNameRetrieved });
    this.setState({ AdminNameData: current });
  };

  fetchJobsData = async () => {
    this.fetchingJobs = true;
    this.setState({ jobPortalActive: "Y" });
    let numApplications = "";
    let jobCount = "";
    let state = "";
    const contact = JSON.parse(sessionStorage.getItem("contact"));
    if (contact) {
      state = contact.state;
    }
    if (state === "") {
      console.log("App.fetchJobsData contact not in session get from api");
      const stateAPI = await getState();
      if (stateAPI) {
        if (stateAPI.error) {
          state = "";
        } else {
          state = stateAPI.contacts[0] ? stateAPI.contacts[0].state : "";
        }
      }
    }

    const jobresult = await getJobs();
    const jobresult1 = await getOpenJobs(state);
    if (jobresult && jobresult1) {
      if (jobresult.error && jobresult1.error) {
        toast.error("Unable to retrieve Job Applications.");
        const jobData = {
          openJobs: -9999,
          activeapps: -9999,
          state: state
        };
        this.setState({ jobData });
      }
    }
    // console.log("App.fetchJobsData jobresult", jobresult);
    // console.log("App.fetchJobsData jobresult1", jobresult1);
    if (jobresult || jobresult1) {
      if (jobresult) {
        if (jobresult.error) {
          toast.error(
            "Unable to retrieve Job Applications. Error message: " +
              jobresult.error
          );
          numApplications = -9999;
        } else {
          numApplications = jobresult.jobApplication
            ? jobresult.jobApplication.length === undefined
              ? 1
              : jobresult.jobApplication.length
            : 0;
        }
      } else {
        toast.error("Unable to retrieve Job Applications.");
        numApplications = -9999;
      }
      if (jobresult1) {
        if (jobresult1.error) {
          toast.error(
            "Unable to retrieve Job Applications. Error message: " +
              jobresult1.error
          );
          jobCount = -9999;
        } else {
          jobCount = jobresult1.openTotalJobCount
            ? jobresult1.openTotalJobCount
            : 0;
        }
      } else {
        toast.error("Unable to retrieve Job Applications.");
        jobCount = -9999;
      }
      const jobData = {
        openJobs: jobCount,
        activeapps: numApplications,
        state: state
      };
      this.setState({ jobData });
    } else {
      toast.error("Unable to retrieve Job Applications.");
      const jobData = {
        openJobs: -9999,
        activeapps: -9999,
        state: state
      };
      this.setState({ jobData });
    }
    this.fetchingJobs = false;
  };

  retrieveJobData = async refreshing => {
    if (refreshing) {
      this.fetchingJobs = false;
    }
    if (!this.fetchingJobs && this.state.jobPortalActive === "Y") {
      // console.log("App.retrieveJobData refreshing Job Data", refreshing);
      this.fetchJobsData();
    }
  };

  fetchSavingsData = async () => {
    this.fetchingSavings = true;
    this.setState({ savingsPortalActive: "Y" });
    const data = await getSavings();
    if (data) {
      if (data.error) {
        toast.error(
          "Unable to retrieve Nationwide Savings Applications.  Error message: " +
            data.error
        );
        const savingsData = {
          preTax: -9999,
          preTaxCU: -9999,
          roth: -9999,
          nonRoth: -9999,
          rothCU: -9999,
          savingsLength: -9999
        };
        this.setState({ savingsData });
      } else {
        if (data.retirementSavingsElections) {
          if (data.retirementSavingsElections.length === undefined) {
            const plan = data.retirementSavingsElections.retirementPlanID;
            const percent = data.retirementSavingsElections.employeePct;
            switch (plan) {
              case "40401K":
                this.setState({ preTax: percent });
                break;
              case "40401K_Roth":
                this.setState({ roth: percent });
                break;
              case "49401X":
                this.setState({ preTaxCU: percent });
                break;
              case "4B401_AT":
                this.setState({ nonRoth: percent });
                break;
              case "49401X_Roth":
                this.setState({ rothCU: percent });
                break;
              default:
            }
          } else {
            for (var s = 0; s < data.retirementSavingsElections.length; s++) {
              const plan = data.retirementSavingsElections[s].retirementPlanID;
              const percent = data.retirementSavingsElections[s].employeePct;
              switch (plan) {
                case "40401K":
                  this.setState({ preTax: percent });
                  break;
                case "40401K_Roth":
                  this.setState({ roth: percent });
                  break;
                case "49401X":
                  this.setState({ preTaxCU: percent });
                  break;
                case "4B401_AT":
                  this.setState({ nonRoth: percent });
                  break;
                case "49401X_Roth":
                  this.setState({ rothCU: percent });
                  break;
                default:
              }
            }
          }
          const savingsData = {
            preTax: this.state.preTax,
            preTaxCU: this.state.preTaxCU,
            roth: this.state.roth,
            nonRoth: this.state.nonRoth,
            rothCU: this.state.rothCU,
            savingsLength: data.retirementSavingsElections.length
          };
          this.setState({ savingsData });
          // console.log("getSavings 1", savingsData);
        } else {
          const savingsData = {
            preTax: 0,
            preTaxCU: 0,
            roth: 0,
            nonRoth: 0,
            rothCU: 0,
            savingsLength: 0
          };
          this.setState({ savingsData });
        }
      }
    } else {
      toast.error("Unable to retrieve Nationwide Savings information.");
      const savingsData = {
        preTax: -9999,
        preTaxCU: -9999,
        roth: -9999,
        nonRoth: -9999,
        rothCU: -9999,
        savingsLength: -9999
      };
      this.setState({ savingsData });
    }
    this.fetchingSavings = false;
  };
  retrieveSavingsData = async refreshing => {
    // console.log("refreshing Savings", refreshing);
    if (refreshing) {
      this.fetchingSavings = false;
    }
    if (!this.fetchingSavings && this.state.savingsPortalActive === "Y") {
      this.fetchSavingsData();
    }
  };

  retrieveBenefitsData = async refreshing => {
    console.log("refreshing Benefits", refreshing);
    if (refreshing) {
      this.fetchingBenefits = false;
    }
    if (!this.fetchingBenefits && this.state.benefitsPortalActive === "Y") {
      this.fetchBenefitsData();
    }
  };

  retrieveHSAData = async refreshing => {
    console.log("refreshing HSA", refreshing);
    //getWHOAMI
    if (refreshing) {
      this.fetchingHSA = false;
    }
    if (!this.fetchingHSA && this.state.hsaPortalActive === "Y") {
      this.fetchHSAData();
    }
  };

  fetchHSAData = async () => {
    console.log("fetchHSAData ", this.fetchingHSA);
    this.fetchingHSA = true;
    this.setState({ hsaPortalActive: "Y" });
    const hsaRetrieved = await getHSAData();
    if (hsaRetrieved) {
      let hsaData = [];
      if (hsaRetrieved.error) {
        console.log("APP - fetchHSAData -- HSA data contains a user message");
        hsaData = { error: hsaRetrieved.error };
        toast.error(
          "Unable to retrieve HSA.  Error message: " + hsaRetrieved.error
        );
      }
      if (hsaRetrieved.userMessage) {
        if (hsaRetrieved.userMessage) {
          console.log("APP - fetchHSAData -- HSA data contains a user message");
          hsaData = { error: hsaRetrieved.userMessage };
          toast.error(
            "Unable to retrieve HSA.  Error message: " +
              hsaRetrieved.userMessage
          );
        }
      } else {
        console.log("APP - fetchHSAData -- get report data from Health Equity");
        hsaData = hsaRetrieved;
      }

      this.setState({ hsaData });
    }
  };

  fetchBenefitsData = async () => {
    console.log("retrieveBenefitsData fetchingBenefits", this.fetchingBenefits);
    this.fetchingBenefits = true;
    this.setState({ benefitsPortalActive: "Y" });
    let benefitsData = [];
    const benefitsRetrieved = await getBenefits();
    console.log("App.fetchBenefitsData benefitsRetrieved", benefitsRetrieved);
    if (benefitsRetrieved) {
      if (benefitsRetrieved.userMessage) {
        console.log(
          "APP - retrieveBenefitsData -- Benefit data contains a user message"
        );
        benefitsData = { error: true };
        toast.error(
          "Unable to retrieve Benefits Data.  Error message: " +
            benefitsRetrieved.userMessage
        );
      } else {
        console.log(
          "APP - retrieveBenefitsData -- get report data from Benefits"
        );
        console.log("retrieveBenefitsData", benefitsRetrieved);
        const currentDate = new Date();
        console.log("benefit current date", currentDate);
        let dueDate = new Date(
          benefitsRetrieved.finalizeDateOE
            ? benefitsRetrieved.finalizeDateOE
            : currentDate
        );
        dueDate.setHours(dueDate.getHours() + 6, 0, 0, 0);
        dueDate.setHours(22, 59, 0);
        let startDate = new Date(
          benefitsRetrieved.startDateOE
            ? benefitsRetrieved.startDateOE
            : currentDate
        );
        startDate.setHours(startDate.getHours() + 6, 0, 0, 0);
        startDate.setHours(1, 0, 0);
        let endDate = new Date(
          benefitsRetrieved.endDateOE
            ? benefitsRetrieved.endDateOE
            : currentDate
        );
        endDate.setHours(endDate.getHours() + 6, 0, 0, 0);
        endDate.setHours(22, 59, 0);
        console.log("benefit finalize due date", dueDate);
        console.log("benefit finalize start date", startDate);
        console.log("benefit finalize end date", endDate);
        let d = dueDate;
        d.setHours(1, 0, 0);
        let c = currentDate;
        c.setHours(0, 0, 0);
        console.log("benefit days difference cal dates, d: ", d, " c: ", c);
        const diffInMs = Math.round(
          Math.abs(d.getTime() - c) / (1000 * 60 * 60 * 24)
        );
        console.log("benefit date days difference", diffInMs);
        console.log(
          "benefit days diff before rounding",
          Math.abs(d.getTime() - c) / (1000 * 60 * 60 * 24)
        );
        const options = { month: "long", day: "numeric" };
        const OEDate = new Date(dueDate).toLocaleDateString([], options);
        const enrollURL = benefitsRetrieved.oeUrl
          ? benefitsRetrieved.oeUrl
          : "";
        let status = "";
        let display = "";
        if (currentDate < startDate || currentDate > endDate) {
          display = "inactive";
        } else {
          if (currentDate > dueDate) {
            display = "final";
          } else {
            if (benefitsRetrieved.enrollmentStatus) {
              let enrollstatus = benefitsRetrieved.enrollmentStatus;
              enrollstatus = enrollstatus.replace('–', '-');
              enrollstatus = enrollstatus.includes("On Hold")
                ? "On Hold"
                : enrollstatus;

              switch (enrollstatus) {
                case "Finalized":
                  display = "final";
                  break;
                case "Not Started":
                  status = "Not Started";
                  display = "active";
                  break;
                case "Submitted":
                  status = "Submitted";
                  display = "active";
                  break;
                case "In Progress":
                  status = "In Progress";
                  display = "active";
                  break;
                case "Rescinded":
                  status = "Not Available";
                  display = "active";
                  break;
                case "Canceled":
                  status = "Not Available";
                  display = "active";
                  break;
                case "Cancelled":
                  status = "Not Available";
                  display = "active";
                  break;
                case "":
                  status = "Not Available";
                  display = "active";
                  break;
                case "On Hold":
                  status = "On Hold";
                  display = "active";
                  break;
                default:
              }
            } else {
              if (benefitsRetrieved.hireDate) {
                let date = new Date();
                let hireDt = new Date(benefitsRetrieved.hireDate);
                let newHireStart = new Date(date.getFullYear(), 10, 1); 
                let newHireEnd = new Date(date.getFullYear(), 11, 31); 
                if (hireDt.getTime() >= newHireStart.getTime() && hireDt.getTime() <= newHireEnd.getTime()) {
                  status = "New Hire";
                  display = "active"; 
                } else {
                  status = "Not Available";
                  display = "active";
                }
              } else {
                status = "Not Available";
                display = "active";
              }
            }
          }
        }
        benefitsData = {
          DueDate: OEDate,
          Daysremaining: diffInMs,
          Status: status,
          error: false,
          display: display,
          EnrollNow: enrollURL,
          dentalPlan: benefitsRetrieved.dentalPlan,
          dentalCoverage: benefitsRetrieved.dentalCoverage,
          hsaPlan: benefitsRetrieved.hsaPlan,
          hsaCoverage: benefitsRetrieved.hsaCoverage,
          visionPlan: benefitsRetrieved.visionPlan,
          visionCoverage: benefitsRetrieved.visionCoverage,
          medicalPlan: benefitsRetrieved.medicalPlan,
          medicalCoverage: benefitsRetrieved.medicalCoverage
        };
      }
    } else {
      benefitsData = { error: true };
    }
    this.setState({ benefitsData }, () =>
      console.log("benefitsData", this.state.benefitsData)
    );
  };

  fetchFowData = async () => {
    // console.log("retrieveFOWData fetchingFOW", this.fetchingFOW);
    this.fetchingFOW = true;
    this.setState({ fowPortalActive: "Y" });
    const fowRetrieved = await getFOW();

    // console.log("retrieveFOWData fowRetrieved", fowRetrieved);

    if (fowRetrieved) {
      let fowData = [];
      if (fowRetrieved.userMessage) {
        if (fowRetrieved.userMessage) {
          // console.log(
          //   "APP.retrieveJobData -- FOW data contains a user message"
          // );
          fowData = { error: fowRetrieved.userMessage };
          toast.error(
            "Unable to retrieve Future of Work.  Error message: " +
              fowRetrieved.userMessage
          );
        }
      } else {
        // console.log(
        //   "APP.retrieveJobData -- get report data from Future of Work"
        // );
        fowData = fowRetrieved;
      }

      this.setState({ fowData });
    }
  };
  retrieveFOWData = async refreshing => {
    //getFOW
    if (refreshing) {
      this.fetchingFOW = false;
    }
    if (!this.fetchingFOW && this.state.fowPortalActive === "Y") {
      // console.log("App.retrieveFOWData refreshing FOW", refreshing);
      this.fetchFowData();
    }
  };

  fetchMyHealth = async () => {
    this.fetchingMyHealth = true;
    this.setState({ MyHealthPortalActive: "Y" });
    const MyHealthRetrieved = await getMyHealth();

    // console.log("App.fetchMyHealth MyHealthRetrieved", MyHealthRetrieved);

    if (MyHealthRetrieved) {
      let MyHealthData = [];
      if (MyHealthRetrieved.userMessage) {
        if (MyHealthRetrieved.userMessage) {
          // console.log(
          //   "APP.fetchMyHealth -- My Time data contains a user message"
          // );
          MyHealthData = { error: MyHealthRetrieved.userMessage };
          toast.error(
            "Unable to retrieve My Time.  Error message: " +
              MyHealthRetrieved.userMessage
          );
        }
      } else {
        // console.log("APP.fetchMyHealth -- get report data from My Time");
        MyHealthData = MyHealthRetrieved;
      }

      this.setState({ MyHealthData });
    }
  };
  retrieveMyHealthData = async refreshing => {
    if (refreshing) {
      this.fetchingMyHealth = false;
    }
    if (!this.fetchingMyHealth && this.state.MyHealthPortalActive === "Y") {
      // console.log(
      //   "App.retrieveMyHealthData fetchingMyHealth",
      //   this.fetchingMyHealth
      // );
      this.fetchMyHealth();
    }
  };
  deactivateTimeOff = () => {
    const { timeoffPortalActive } = this.state;
    const newActive = timeoffPortalActive === "Y" ? "N" : "Y";
    // console.log("App.deactivateTimeOff", timeoffPortalActive, newActive);
    this.fetchingtimeOff = false;
    this.setState({ timeoffPortalActive: newActive });
  };
  deactivatePayslip = () => {
    const { payslipPortalActive } = this.state;
    const newActive = payslipPortalActive === "Y" ? "N" : "Y";
    // console.log("App.deactivatePayslip", payslipPortalActive, newActive);
    this.fetchingPaycheck = false;
    this.setState({ payslipPortalActive: newActive });
  };
  deactivateAws = () => {
    //Used to expand/collapse the AWS portal when it is not one of the 6 primary active portals
    const { awsPortalActive } = this.state;
    const newActive = awsPortalActive === "Y" ? "N" : "Y";
    // console.log("App.deactivateAws", awsPortalActive, newActive);
    this.fetchingAws = false;
    this.setState({ awsPortalActive: newActive });
  };
  deactivateFow = () => {
    //Used to expand/collapse the AWS portal when it is not one of the 6 primary active portals
    const { fowPortalActive } = this.state;
    const newActive = fowPortalActive === "Y" ? "N" : "Y";
    // console.log("App.deactivateFow", fowPortalActive, newActive);
    this.fetchingFOW = false;
    this.setState({ fowPortalActive: newActive });
  };
  deactivateSavings = () => {
    const { savingsPortalActive } = this.state;
    const newActive = savingsPortalActive === "Y" ? "N" : "Y";
    // console.log("App.deactivateSavings", savingsPortalActive, newActive);
    this.fetchingSavings = false;
    this.setState({ savingsPortalActive: newActive });
  };
  deactivateMyHealth = () => {
    const { MyHealthPortalActive } = this.state;
    const newActive = MyHealthPortalActive === "Y" ? "N" : "Y";
    // console.log("App.deactivateMyHealth", MyHealthPortalActive, newActive);
    this.fetchingMyHealth = false;
    this.setState({ MyHealthPortalActive: newActive });
  };
  deactivateJobs = () => {
    const { jobPortalActive } = this.state;
    const newActive = jobPortalActive === "Y" ? "N" : "Y";
    // console.log("App.deactivateJobs", jobPortalActive, newActive);
    this.fetchingJobs = false;
    this.setState({ jobPortalActive: newActive });
  };
  deactivateBenefits = () => {
    const { benefitsPortalActive } = this.state;
    const newActive = benefitsPortalActive === "Y" ? "N" : "Y";
    console.log("App.deactivateBenefits", benefitsPortalActive, newActive);
    this.fetchingBenefits = false;
    this.setState({ benefitsPortalActive: newActive });
  };

  deactivateGiving = () => {
    const { givingPortalActive } = this.state;
    const newActive = givingPortalActive === "Y" ? "N" : "Y";
    this.fetchingGivingData = false;
    this.setState({ givingPortalActive: newActive });
  };

  deactivateProduct = () => {
    const { productPortalActive } = this.state;
    const newActive = productPortalActive === "Y" ? "N" : "Y";
    this.fetchingProduct = false;
    this.setState({ productPortalActive: newActive });
  };
  deactivateHSA = () => {
    const { hsaPortalActive } = this.state;
    const newActive = hsaPortalActive === "Y" ? "N" : "Y";
    this.fetchingHSA = false;
    this.setState({ hsaPortalActive: newActive });
  };

  fetchAwsData = async () => {
    // console.log("App.fetchAwsData fetchAwsData", this.fetchingAws);

    this.setState({ awsPortalActive: "Y" });

    this.fetchingAws = true;
    const nurses = await getNurses();
    // console.log("App.fetchAwsData nurses", nurses);
    if (nurses) {
      if (nurses.userMessage) {
        console.log(nurses.userMessage, " That is the error message for the nurse api")
        let nursesData = [];
        if (nurses.userMessage) {
          // console.log("App.fetchAwsData-- nurse data contains a user message");
          nursesData = { error: nurses.userMessage };
          toast.error(
            "Unable to retrieve Nurses.  Error message: " + nurses.userMessage
          );
        } else {
          // console.log("App.fetchAwsData -- get report row from nurses");
          nursesData = nurses.data[0].report_row;
        }
        const awsData = {
          nurses: nursesData
        };

        this.setState({ awsData });
      } else {
        // console.log("App.fetchAwsData -- neither has a user message");
        let nursesData = [];
        try {
          nursesData = nurses.data[0].report_row;
        } catch (e) {
          // console.log("App.fetchAwsData AWS NURSES ERROR", e);
          nursesData = { error: e };
        }
        const awsData = {
          nurses: nursesData
        };

        this.setState({ awsData });
      }
    } else {
      // console.log(
      //   "App.fetchAwsData-- No data returned by nurses or counselors"
      // );
      toast.error("Unable to retrieve AWS information.");
      const awsData = {
        nurses: { error: "Unable to retrieve AWS information" }
      };
      this.setState({ awsData });
    }
  };
  retrieveAwsData = async refreshing => {
    // console.log("refreshing AWS", refreshing);
    if (refreshing) {
      this.fetchingAws = false;
    }
    // console.log(
    //   "App.retrieveAwsData",
    //   "fetchingAws",
    //   this.fetchingAws,
    //   "awsPortalActive",
    //   this.state.awsPortalActive
    // );
    // this.setState({ awsPortalActive: "Y" });
    if (!this.fetchingAws && this.state.awsPortalActive === "Y") {
      this.fetchAwsData();
    }
  };

  retrieveWorkingAnnouncement = async announcementId => {
    if (announcementId > 0) {
      const workingAnnouncement = await getAnnouncement(announcementId);
      this.setState({ workingAnnouncement });
    } else {
      const workingAnnouncement = {
        id: 0,
        startOnDate: new Date().toISOString(),
        endOnDate: new Date().toISOString(),
        link: "",
        img: null,
        approvedBy: "",
        priority: "1",
        button1Text: "",
        button2Text: "",
        button1Link: "",
        button2Link: "",
        headline: "",
        summaryText: ""
      };
      this.setState({ workingAnnouncement });
    }
  };

  checkAdmin = () => {
    const idToken = sessionStorage.getItem("access_token");
    // const idToken = localStorage.getItem("idToken");
    if (idToken) {
      clearInterval(this.state.intervalId);
      this.getAdmin();
    }
  };

  checkPortals = () => {
    const idToken = sessionStorage.getItem("access_token");
    if (idToken) {
      clearInterval(this.state.intervalId2);
      this.retrievePortals();
    }
  };

  getAdmin = async () => {
    if (!this.fetchingAdmin) {
      this.fetchingAdmin = true;
      const contact = await isAdminUser();
      if (contact.errorMessage) {
        toast.error(contact.errorMessage.userMessage);
        // alert(JSON.stringify(contact));
        // sessionStorage.clear();
        // window.location = "/errorpage";
        return false;
      }
      // console.log("App.getAdmin returned this", contact);
      sessionStorage.setItem("contact", JSON.stringify(contact));
      // if (contact && contact.isPortalAdmin === "Y") {
      //   this.setState({ isAdmin: true, contact });
      // } else {
      //   this.setState({ isAdmin: false, contact });
      // }
      const isAdmin = UserIsAdmin();
      this.setState({ isAdmin, contact });
      let intervalId2 = setInterval(this.checkPortals, 100);
      this.setState({ intervalId2 });
      this.fetchingAdmin = false;
    }
  };

  checkKbValue = item => {
    return item.kb_knowledge_base.value === "ff2a6c39dbe1bf84c2937b27689619d6";
  };

  APS_PrefNameSearch = async (normalResults, body) => {
    // console.log("normal results", body);
    let APSresponse = "";
    APSresponse = [];
    let outArray = [];
    let map = new Map();
    // Map normal results
    if (normalResults.data) {
      for (let i = 0; i < normalResults.data.contacts.length; i++) {
        let id = normalResults.data.contacts[i].emplid;
        map.set(id, normalResults.data.contacts[i]);
      }
      APSresponse = await getCallupData(body);
      if (APSresponse.data) {
        // Map preferred name results
        for (let i = 0; i < APSresponse.data.contacts.length; i++) {
          let id = APSresponse.data.contacts[i].emplid;
          map.set(id, APSresponse.data.contacts[i]);
        }
      }

      let i = 0;
      for (let value of map.values()) {
        outArray[i] = value;
        i++;
      }
    }

    return outArray;
  };

  handleCallupAdvancedSearch = async (short_name, body_post, body_post2) => {
    // console.log("App.handleCallupAdvancedSearch APS short_name", short_name);
    // console.log("App.handleCallupAdvancedSearch APS body_post", body_post);
    let callUpSNData = [];
    let Callup_Image = [];
    let AdminNameData = [];
    let APSresponse = "";
    let shortNameResults = "";
    let searchQuery = "";
    searchQuery = body_post.lastName
      ? "<i>Last Name: </i><b>" + body_post.lastName + "</b>"
      : searchQuery;
    searchQuery = body_post.firstName
      ? searchQuery + "<i> First Name: </i><b>" + body_post.firstName + "</b>"
      : searchQuery;
    searchQuery =
      body_post.preferredLastName && !body_post.lastName
        ? "<i>Last Name: </i><b>" + body_post.preferredLastName + "</b>"
        : searchQuery;
    searchQuery = body_post.preferredFirstName
      ? searchQuery +
        "<i> First Name: </i><b>" +
        body_post.preferredFirstName +
        "</b>"
      : searchQuery;
    searchQuery = body_post.workPhone
      ? searchQuery + "<i> Phone: </i><b>" + body_post.workPhone + "</b>"
      : searchQuery;
    searchQuery = body_post.mailDrop
      ? searchQuery + "<i> Mail Code: </i><b>" + body_post.mailDrop + "</b>"
      : searchQuery;
    searchQuery = short_name
      ? searchQuery + "<i> Shortname: </i><b>" + short_name + "</b>"
      : searchQuery;
    searchQuery = body_post.locID
      ? searchQuery + "<i> locID: </i><b>" + body_post.locID + "</b>"
      : searchQuery;
    searchQuery = body_post.disbCode
      ? searchQuery + "<i> disbCode: </i><b>" + body_post.disbCode + "</b>"
      : searchQuery;
    this.setState({
      People_Display: true,
      searching: false,
      searchQuery,
      searchData: [],
      HRK_Display: false
    });
    let searchingCallup = true;
    this.setState(
      {
        totalCount: 0,
        data: [],
        searchingCallup,
        callUpSNData: [],
        Callup_Image: []
      },
      () =>
        console.log(
          "App.handleCallupAdvancedSearch APS Image_Array in App pre",
          this.state.Callup_Image
        )
    );

    if (body_post && !isEmpty(body_post)) {
      APSresponse = await getCallupData(body_post);
      let combined = await this.APS_PrefNameSearch(APSresponse, body_post2);
      // console.log("OutArray", combined);
      if (APSresponse) {
        const APSLength = combined ? combined.length : 0;
        if (APSLength > 0) {
          if (APSresponse.userMessage) {
            console.log(
              "App.handleCallupAdvancedSearch GOT A MESSAGE FROM CALLUP API INSTEAD OF A VALID RESPONSE"
            );
            this.setState({ People_Error: true });
            toast.error(
              "Unable to retrieve a People response to your search. Error Message: " +
                APSresponse.userMessage
            );
            return false;
          }
          console.log(
            "App.handleCallupAdvancedSearch people Lastname search:",
            APSLength
          );

          this.setState({
            APSResults: combined
          });
        } else {
          this.setState({ APSResults: [] });
        }
      } else {
        this.setState({ APSResults: [], People_Error: true });
        toast.error(
          "Unable to retrieve a People response to your search.  Please try again later."
        );
      }
    }

    if (short_name && short_name !== "") {
      shortNameResults = await getCallupShortName(short_name);
      console.log(
        "App.handleCallupAdvancedSearch shortNameResults",
        shortNameResults
      );
      if (shortNameResults) {
        const shortNameLength = shortNameResults.contacts.length
          ? shortNameResults.contacts.length
          : 0;
        if (shortNameLength > 0) {
          if (shortNameResults.userMessage) {
            console.log(
              "App.handleCallupAdvancedSearch GOT A MESSAGE FROM CALLUP API INSTEAD OF A VALID RESPONSE ",
              shortNameResults.userMessage
            );
            this.setState({ People_Error: true });
            return false;
          }
          console.log(
            "App.handleCallupAdvancedSearch people shortname search:",
            shortNameLength
          );
          this.setState({ shortNameResults: shortNameResults.contacts });
        } else {
          this.setState({ shortNameResults: [] });
        }
      } else {
        this.setState({ shortNameResults: [], People_Error: true });
      }
    }

    console.log(
      "App.handleCallupAdvancedSearch APS body_post in App",
      this.state.APSResults
    );

    console.log(
      "App.handleCallupAdvancedSearch APS shortNameResults in App",
      this.state.shortNameResults
    );

    if (short_name && short_name !== "") {
      if (body_post && !isEmpty(body_post)) {
        callUpSNData = this.state.APSResults.filter(elem =>
          this.state.shortNameResults.find(
            ({ emplid }) => elem.emplid === emplid
          )
        );

        this.setState({ callUpSNData });
      } else {
        this.setState({ callUpSNData: this.state.shortNameResults });
      }
    } else {
      if (body_post && !isEmpty(body_post)) {
        this.setState({ callUpSNData: this.state.APSResults });
      } else {
        this.setState({ callUpSNData: [] });
      }
    }

    console.log(
      "App.handleCallupAdvancedSearch APS callUpSNData in App",
      this.state.callUpSNData
    );
    this.setState({ searchingCallup: false });

    Callup_Image = await GetPhotos(this.state.callUpSNData.slice(0, 10));
    this.setState({ Callup_Image });
    console.log(
      "App.handleCallupAdvancedSearch APS Image_Array in App",
      this.state.Callup_Image
    );

    AdminNameData = await getAdminAssistants(
      this.state.callUpSNData.slice(0, 10)
    );
    this.setState({ AdminNameData });
    console.log(
      "App.handleCallupAdvancedSearch APS AdminNameData in App",
      this.state.AdminNameData
    );

    let Imagenewload = [];
    let newAdminNameData = [];
    Imagenewload = await GetPhotos(this.state.callUpSNData.slice(10, 20));
    Callup_Image = Callup_Image.concat(Imagenewload);
    this.setState({ Callup_Image });
    console.log(
      "App.handleCallupAdvancedSearch APS Image_Array in App next set",
      this.state.Callup_Image
    );

    newAdminNameData = await getAdminAssistants(
      this.state.callUpSNData.slice(10, 20)
    );
    AdminNameData = AdminNameData.concat(newAdminNameData);
    this.setState({ AdminNameData });
    console.log(
      "App.handleCallupAdvancedSearch APS AdminNameData in App next set",
      this.state.AdminNameData
    );
  };

  handleManagerHyperlink = async short_name => {
    console.log("App.handleManagerHyperlink short_name", short_name);
    let Callup_Image = [];
    let shortNameResults = "";
    this.setState({
      People_Display: true,
      searching: false,
      searchQuery: short_name,
      searchData: [],
      HRK_Display: false
    });
    let searchingCallup = true;
    this.setState({
      totalCount: 0,
      data: [],
      searchingCallup,
      callUpSNData: [],
      Callup_Image: []
    });

    if (short_name && short_name !== "") {
      shortNameResults = await getCallupShortName(short_name);
      console.log(
        "App.handleManagerHyperlink shortNameResults",
        shortNameResults
      );
      if (shortNameResults) {
        const shortNameLength = shortNameResults.contacts.length
          ? shortNameResults.contacts.length
          : 0;
        if (shortNameLength > 0) {
          if (shortNameResults.userMessage) {
            console.log(
              "App.handleManagerHyperlink GOT A MESSAGE FROM CALLUP API INSTEAD OF A VALID RESPONSE ",
              shortNameResults.userMessage
            );
            this.setState({ People_Error: true });
            return false;
          }
          console.log(
            "App.handleManagerHyperlink  shortname search:",
            shortNameLength
          );
          this.setState({ shortNameResults: shortNameResults.contacts });
        } else {
          this.setState({ shortNameResults: [] });
        }
      } else {
        this.setState({ shortNameResults: [], People_Error: true });
      }
    } else {
      this.setState({ shortNameResults: [] });
    }

    this.setState({ callUpSNData: this.state.shortNameResults });

    console.log(
      "App.handleManagerHyperlink  callUpSNData in App",
      this.state.callUpSNData
    );
    this.setState({ searchingCallup: false });

    Callup_Image = await GetPhotos(this.state.callUpSNData);
    this.setState({ Callup_Image });
    console.log(
      "App.handleManagerHyperlink  Image_Array in App",
      this.state.Callup_Image
    );

    let AdminNameData = await getAdminAssistants(this.state.callUpSNData);
    this.setState({ AdminNameData });
    console.log(
      "App.handleCallupAdvancedSearch APS AdminNameData in App",
      this.state.AdminNameData
    );
  };

  handleSearchQueryCallup = async query => {
    let callUpSNData = [];
    let Callup_Image = [];
    let AdminNameData = [];
    let shortNameResults = "";
    if (query.includes("in <b>HR Knowledge</b>")) {
      const searchQuery = query.replace(" in <b>HR Knowledge</b>", "");
      this.setState({
        searchingCallup: false,
        searchQuery,
        callUpSNData: [],
        Callup_Image: [],
        People_Display: false
      });
    } else {
      this.setState({ People_Display: true });
      const searchQuery = query.replace(" in <b>People</b>", "");
      if (searchQuery) {
        const searchText = searchQuery.trim();
        console.log("App.handleSearchQueryCallup searchText", searchText);
        console.log("App.handleSearchQueryCallup searchText query", query);

        let searchingCallup = true;
        this.setState({
          searchText,
          totalCount: 0,
          data: [],
          searchingCallup,
          callUpSNData: [],
          Callup_Image: []
        });

        var body_post = {};
        let lastname_pre = "";
        let firstname_pre = "";
        let preferredname_pre = "";
        let filteredLNResults = [];
        let filteredFNResults = [];
        let filteredPNResults = [];
        let filteredPNResultsA = [];
        let comma = searchText.search(",");
        let space = searchQuery.search(" ");
        let percentile = searchText.search("%");
        let LastNameresponse = "";
        let FirstNameresponse = "";
        let PreferredNameresponse = "";
        let PreferredNameresponseA = "";
        if (comma === -1 && space === -1) {
          if (percentile === -1) {
            //Search for shortname
            shortNameResults = await getCallupShortName(searchText);
            console.log(
              "App.handleSearchQueryCallup shortNameResults",
              shortNameResults
            );
            if (shortNameResults) {
              const shortNameLength = shortNameResults.contacts.length
                ? shortNameResults.contacts.length
                : 0;
              if (shortNameLength > 0) {
                if (shortNameResults.userMessage) {
                  console.log(
                    "App.handleSearchQueryCallup GOT A MESSAGE FROM CALLUP API INSTEAD OF A VALID RESPONSE ",
                    shortNameResults.userMessage
                  );
                  return false;
                }
                console.log(
                  "App.handleSearchQueryCallup people shortname search:",
                  shortNameLength
                );
                this.setState({ shortNameResults: shortNameResults.contacts });
              } else {
                this.setState({ shortNameResults: [] });
              }
            } else {
              this.setState({ shortNameResults: [] });
            }
          } else {
            this.setState({ shortNameResults: [] });
          }
          lastname_pre = searchText;
          firstname_pre = searchText;
          preferredname_pre = searchText;
          //Search for Last name
          body_post["lastName"] = lastname_pre;
          console.log(
            "App.handleSearchQueryCallup BodyPost Last name Call",
            body_post
          );
          LastNameresponse = await getCallupData(body_post);
          if (LastNameresponse) {
            const LastNameLength = LastNameresponse.data.contacts
              ? LastNameresponse.data.contacts.length
              : 0;
            if (LastNameLength > 0) {
              if (LastNameresponse.userMessage) {
                console.log(
                  "App.handleSearchQueryCallup GOT A MESSAGE FROM CALLUP API INSTEAD OF A VALID RESPONSE"
                );
                this.setState({ People_Error: true });
                toast.error(
                  "Unable to retrieve a People response to your search. Error Message: " +
                    LastNameresponse.userMessage
                );
                return false;
              }
              console.log(
                "App.handleSearchQueryCallup people Lastname search:",
                LastNameLength
              );
              this.setState({
                lastNameResults: LastNameresponse.data.contacts
              });
            } else {
              this.setState({ lastNameResults: [] });
            }
          } else {
            this.setState({ lastNameResults: [], People_Error: true });
            toast.error(
              "Unable to retrieve a People response to your search.  Please try again later."
            );
          }
          // Firstname Search
          body_post = {};
          body_post["firstName"] = firstname_pre;
          console.log(
            "App.handleSearchQueryCallup BodyPost first name Call",
            body_post
          );
          FirstNameresponse = await getCallupData(body_post);
          if (FirstNameresponse) {
            const FirstNameLength = FirstNameresponse.data.contacts
              ? FirstNameresponse.data.contacts.length
              : 0;
            if (FirstNameLength > 0) {
              if (FirstNameresponse.userMessage) {
                console.log(
                  "App.handleSearchQueryCallup GOT A MESSAGE FROM CALLUP API INSTEAD OF A VALID RESPONSE ",
                  FirstNameresponse.userMessage
                );
                return false;
              }
              console.log(
                "App.handleSearchQueryCallup people Firstname search:",
                FirstNameLength
              );
              this.setState({
                firstNameResults: FirstNameresponse.data.contacts
              });
            } else {
              this.setState({ firstNameResults: [] });
            }
          } else {
            this.setState({ firstNameResults: [] });
          }

          // PreferredName Search
          body_post = {};
          body_post["preferredFirstName"] = preferredname_pre;
          console.log(
            "App.handleSearchQueryCallup BodyPost Preferred first name Call",
            body_post
          );
          PreferredNameresponse = await getCallupData(body_post);
          if (PreferredNameresponse) {
            const PreferredNameLength = PreferredNameresponse.data.contacts
              ? PreferredNameresponse.data.contacts.length
              : 0;
            if (PreferredNameLength > 0) {
              if (PreferredNameresponse.userMessage) {
                console.log(
                  "App.handleSearchQueryCallup GOT A MESSAGE FROM CALLUP API INSTEAD OF A VALID RESPONSE ",
                  PreferredNameresponse.userMessage
                );
                return false;
              }
              console.log(
                "App.handleSearchQueryCallup people Preferred Firstname search:",
                PreferredNameLength
              );
              this.setState({
                preferredNameResults: PreferredNameresponse.data.contacts
              });
            } else {
              this.setState({ preferredNameResults: [] });
            }
          } else {
            this.setState({ preferredNameResults: [] });
          }

          callUpSNData = this.state.shortNameResults;
          console.log(
            "App.handleSearchQueryCallup callUpSNData - Added SN results",
            callUpSNData
          );

          filteredLNResults = this.state.lastNameResults.filter(
            item => !callUpSNData.find(({ emplid }) => item.emplid === emplid)
          );
          callUpSNData = callUpSNData.concat(filteredLNResults);
          console.log(
            "App.handleSearchQueryCallup callUpSNData - Added LN results",
            callUpSNData
          );

          filteredFNResults = this.state.firstNameResults.filter(
            item => !callUpSNData.find(({ emplid }) => item.emplid === emplid)
          );

          callUpSNData = callUpSNData.concat(filteredFNResults);
          console.log(
            "App.handleSearchQueryCallup callUpSNData - Added FN results",
            callUpSNData
          );

          filteredPNResults = this.state.preferredNameResults.filter(
            item => !callUpSNData.find(({ emplid }) => item.emplid === emplid)
          );

          callUpSNData = callUpSNData.concat(filteredPNResults);
          console.log(
            "App.handleSearchQueryCallup callUpSNData - Added PN results",
            callUpSNData
          );

          this.setState({ callUpSNData });

          console.log(
            "App.handleSearchQueryCallup callUpSNData",
            this.state.callUpSNData
          );
          this.setState({ searchingCallup: false, searchQuery });
        } else {
          // Don't search for shortname if there is a comma or space
          // Search as Last name & First Name
          if (comma === -1) {
            if (space === -1) {
              // do nothing - percentile exists
            } else {
              lastname_pre = searchQuery.slice(0, space).trim();
              firstname_pre = searchQuery
                .slice(space + 1, searchQuery.length)
                .trim();
              preferredname_pre = searchQuery
                .slice(space + 1, searchQuery.length)
                .trim();
            }
          } else {
            lastname_pre = searchText.slice(0, comma).trim();
            firstname_pre = searchText
              .slice(comma + 1, searchText.length)
              .trim();
            preferredname_pre = searchText
              .slice(comma + 1, searchText.length)
              .trim();
          }
          //Last name and first name
          if (lastname_pre) {
            body_post["lastName"] = lastname_pre;
          }
          if (firstname_pre) {
            body_post["firstName"] = firstname_pre;
          }

          console.log("App.handleSearchQueryCallup BodyPost", body_post);
          const LastNameresponse = await getCallupData(body_post);
          if (LastNameresponse) {
            const LastNameLength = LastNameresponse.data.contacts
              ? LastNameresponse.data.contacts.length
              : 0;
            if (LastNameLength > 0) {
              if (LastNameresponse.userMessage) {
                console.log(
                  "App.handleSearchQueryCallup GOT A MESSAGE FROM CALLUP API INSTEAD OF A VALID RESPONSE"
                );
                this.setState({ People_Error: true });
                toast.error(
                  "Unable to retrieve a People response to your search. Error Message: " +
                    LastNameresponse.userMessage
                );
                return false;
              }
              console.log(
                "App.handleSearchQueryCallup people Lastname search:",
                LastNameLength
              );
              this.setState({
                lastNameResults: LastNameresponse.data.contacts
              });
            } else {
              this.setState({ lastNameResults: [] });
            }
          } else {
            this.setState({ lastNameResults: [], People_Error: true });
            toast.error(
              "Unable to retrieve a People response to your search.  Please try again later."
            );
          }

          //Last name and Pref first name
          body_post = {};
          if (lastname_pre) {
            body_post["lastName"] = lastname_pre;
          }
          if (preferredname_pre) {
            body_post["preferredFirstName"] = preferredname_pre;
          }

          console.log("App.handleSearchQueryCallup BodyPost", body_post);
          const PreferredNameresponse = await getCallupData(body_post);
          if (PreferredNameresponse) {
            const PreferredNameLength = PreferredNameresponse.data.contacts
              ? PreferredNameresponse.data.contacts.length
              : 0;
            if (PreferredNameLength > 0) {
              if (PreferredNameresponse.userMessage) {
                console.log(
                  "App.handleSearchQueryCallup GOT A MESSAGE FROM CALLUP API INSTEAD OF A VALID RESPONSE"
                );
                toast.error(
                  "Unable to retrieve a People response to your search. Error Message: " +
                    PreferredNameresponse.userMessage
                );
                return false;
              }
              console.log(
                "App.handleSearchQueryCallup people Prefname search:",
                PreferredNameLength
              );
              this.setState({
                preferredNameResults: PreferredNameresponse.data.contacts
              });
            } else {
              this.setState({ preferredNameResults: [] });
            }
          } else {
            this.setState({ preferredNameResults: [] });
            toast.error(
              "Unable to retrieve a People response to your search.  Please try again later."
            );
          }

          firstname_pre = "";
          lastname_pre = "";
          preferredname_pre = "";
          body_post = {};
          // Search as First name & Last Name
          if (comma === -1) {
            if (space === -1) {
              // do nothing - percentile exists
            } else {
              firstname_pre = searchQuery.slice(0, space).trim();
              preferredname_pre = searchQuery.slice(0, space).trim();
              lastname_pre = searchQuery
                .slice(space + 1, searchQuery.length)
                .trim();
            }
          } else {
            firstname_pre = searchText.slice(0, comma).trim();
            preferredname_pre = searchText.slice(0, comma).trim();
            lastname_pre = searchText
              .slice(comma + 1, searchText.length)
              .trim();
          }
          //First Name & Last name
          if (lastname_pre) {
            body_post["lastName"] = lastname_pre;
          }
          if (firstname_pre) {
            body_post["firstName"] = firstname_pre;
          }

          console.log("App.handleSearchQueryCallup BodyPost", body_post);
          FirstNameresponse = await getCallupData(body_post);
          if (FirstNameresponse) {
            const FirstNameLength = FirstNameresponse.data.contacts
              ? FirstNameresponse.data.contacts.length
              : 0;
            if (FirstNameLength > 0) {
              if (FirstNameresponse.userMessage) {
                console.log(
                  "App.handleSearchQueryCallup GOT A MESSAGE FROM CALLUP API INSTEAD OF A VALID RESPONSE ",
                  FirstNameresponse.userMessage
                );
                return false;
              }
              console.log(
                "App.handleSearchQueryCallup people Firstname search:",
                FirstNameLength
              );
              this.setState({
                firstNameResults: FirstNameresponse.data.contacts
              });
            } else {
              this.setState({ firstNameResults: [] });
            }
          } else {
            this.setState({ firstNameResults: [] });
          }

          //Pref Name & Last name
          body_post = {};
          if (lastname_pre) {
            body_post["lastName"] = lastname_pre;
          }
          if (preferredname_pre) {
            body_post["preferredFirstName"] = preferredname_pre;
          }

          console.log("App.handleSearchQueryCallup BodyPost", body_post);
          PreferredNameresponseA = await getCallupData(body_post);
          if (PreferredNameresponseA) {
            const PreferredNameLengthA = PreferredNameresponseA.data.contacts
              ? PreferredNameresponseA.data.contacts.length
              : 0;
            if (PreferredNameLengthA > 0) {
              if (PreferredNameresponseA.userMessage) {
                console.log(
                  "App.handleSearchQueryCallup GOT A MESSAGE FROM CALLUP API INSTEAD OF A VALID RESPONSE ",
                  PreferredNameresponseA.userMessage
                );
                return false;
              }
              console.log(
                "App.handleSearchQueryCallup people PREFname search:",
                PreferredNameLengthA
              );
              this.setState({
                preferredNameResultsA: PreferredNameresponseA.data.contacts
              });
            } else {
              this.setState({ preferredNameResultsA: [] });
            }
          } else {
            this.setState({ preferredNameResultsA: [] });
          }

          callUpSNData = this.state.lastNameResults;
          filteredFNResults = this.state.firstNameResults.filter(
            item => !callUpSNData.find(({ emplid }) => item.emplid === emplid)
          );

          callUpSNData = callUpSNData.concat(filteredFNResults);

          filteredPNResults = this.state.preferredNameResults.filter(
            item => !callUpSNData.find(({ emplid }) => item.emplid === emplid)
          );
          callUpSNData = callUpSNData.concat(filteredPNResults);

          filteredPNResultsA = this.state.preferredNameResultsA.filter(
            item => !callUpSNData.find(({ emplid }) => item.emplid === emplid)
          );
          callUpSNData = callUpSNData.concat(filteredPNResultsA);

          this.setState({ callUpSNData });

          console.log(
            "App.handleSearchQueryCallup callUpSNData",
            this.state.callUpSNData
          );
          this.setState({ searchingCallup: false, searchQuery });
        }

        Callup_Image = await GetPhotos(this.state.callUpSNData.slice(0, 10));
        this.setState({ Callup_Image });
        console.log(
          "App.handleSearchQueryCallup Image_Array in App",
          this.state.Callup_Image
        );
        let Imagenewload = [];
        Imagenewload = await GetPhotos(this.state.callUpSNData.slice(10, 20));
        Callup_Image = Callup_Image.concat(Imagenewload);
        this.setState({ Callup_Image });
        console.log(
          "App.handleSearchQueryCallup APS Image_Array in App next set",
          this.state.Callup_Image
        );

        AdminNameData = await getAdminAssistants(
          this.state.callUpSNData.slice(0, 10)
        );
        this.setState({ AdminNameData });
        console.log(
          "App.handleSearchQueryCallup AdminNameData_Array in App",
          this.state.AdminNameData
        );
        let newAdminNameData = [];
        newAdminNameData = await getAdminAssistants(
          this.state.callUpSNData.slice(10, 20)
        );
        AdminNameData = AdminNameData.concat(newAdminNameData);
        this.setState({ AdminNameData });
        console.log(
          "App.handleSearchQueryCallup AdminNameData_Array in App next set",
          this.state.AdminNameData
        );
      } else {
        this.setState({
          callUpSNData: [],
          searchQuery,
          Callup_Image: [],
          AdminNameData: []
        });
      }
      // this.setState({ searchQuery });
    }
  };

  handleSearchQueryUpdate = async query => {
    console.log("App.handleSearchQueryUpdate start", query);
    if (query.includes("in <b>People</b>")) {
      const searchQuery = query.replace(" in <b>People</b>", "");
      this.setState({
        searching: false,
        searchQuery,
        searchData: [],
        HRK_Display: false,
        menuExpanded: false,
        restrictKnowledgeData: 10,
        searchDataAll: []
      });
    } else {
      this.setState({ HRK_Display: true, menuExpanded: false });
      const searchQuery = query.replace(" in <b>HR Knowledge</b>", "");
      if (searchQuery) {
        const searchText = searchQuery.trim();
        console.log("App.handleSearchQueryUpdate searchText", searchText);
        let searching = true;

        this.setState({ searchText, totalCount: 0, data: [], searching });
        if (searchText.length > 0 && searchText.length < 2) {
          toast.error(
            `At least ${minCharactersToSearch} characters must be entered to search Service Now`
          );
        }
        const resp = await getFromServiceNow(searchText);
        console.log("App.handleSearchQueryUpdate resp", resp);
        if (!resp) {
          this.setState({ searching: false, HRK_Error: true, searchQuery });
          toast.error(
            "Unable to retrieve a HR Knowledge response to your search.  Please try again later."
          );
          this.addToSearchHistory(searchText, -1);
          return false;
        }
        if (resp.userMessage) {
          console.log(
            "App.handleSearchQueryUpdate HRK - GOT A MESSAGE FROM SERVICE NOW API INSTEAD OF A VALID RESPONSE ",
            resp.userMessage
          );
          toast.error(
            "Unable to retrieve a HR Knowledge response to your search. Error Message: " +
              resp.userMessage
          );
          this.setState({ searching: false, HRK_Error: true, searchQuery });
          this.addToSearchHistory(searchText, -1);
          return false;
        }
        const result = resp.result;
        const unfilteredData = result ? result.articles : null;
        console.log(
          "App.handleSearchQueryUpdate HRK - App>handleSearchQueryUpdate unfilteredData",
          unfilteredData
        );
        if (unfilteredData) {
          const unfilteredNumber = unfilteredData.length;
          // const filteredData = await unfilteredData.filter(this.checkKbValue);
          const filteredData = result.articles;
          const filteredNumber = filteredData.length;
          const searchData = filteredData.slice(0, 10);
          const searchDataAll = filteredData;
          //const searchData = filteredData;
          const totalCount = searchData ? searchData.length : 0;
          searching = false;
          this.setState({
            totalCount,
            searchData,
            searching,
            unfilteredNumber,
            filteredNumber,
            searchDataAll,
            restrictKnowledgeData: 10
          });
          this.addToSearchHistory(searchText, filteredData.length);
        } else {
          this.setState({
            totalCount: 0,
            searchData: [],
            searching: true,
            unfilteredNumber: 0,
            filteredNumber: 0
          });
          this.addToSearchHistory(searchText, 0);
        }
      }
      this.setState({ searchQuery });
    }
  };

  loadMoreKnowledge = () => {
    const { restrictKnowledgeData, searchDataAll } = this.state;
    let tempCount = restrictKnowledgeData + 10;
    let data = searchDataAll.slice(0, tempCount);
    this.setState({ restrictKnowledgeData: tempCount, searchData: data });
  };

  reSearchKnowledge() {
    this.setState({ restrictKnowledgeData: 10 });
  }

  addToSearchHistory = async (searchString, resultCount) => {
    const contactState = this.state.contact ? this.state.contact.state : "";
    const result = await addSearchHistory(
      searchString,
      resultCount,
      contactState
    );
    if (!result.userMessage) {
      console.log("APP.appToSearchHistory  result", result);
      const searchHistoryId = result.data ? result.data.id : 0;
      this.setState({ searchHistoryId });
    }
  };

  filterContacts = (newHires, currentDepartment) => {
    let result = [];
    let deptFound = false;
    let x = 0;
    for (let i = 0; i < newHires.length; i++) {
      if (newHires[i].department === currentDepartment) {
        deptFound = true;
        result[x++] = newHires[i];
      } else {
        if (deptFound) {
          break;
        }
      }
    }
    return result;
  };

  retrieveNewHires = async refreshing => {
    if (refreshing) {
      this.fetchingNewHires = false;
    }

    console.log("App.retrieveNewHires Start", this.fetchingNewHires);
    let locs = [];
    let locationFilter = "";
    let mgrs = [];
    let hireDts = [];
    if (!this.fetchingNewHires) {
      //Not already fetching New Hires
      this.fetchingNewHires = true;
      console.log("App.retrieveNewHires call getNewHires");
      const newHires = await getNewHires();
      console.log("App.retrieveNewHires newHires", newHires);
      let lastDept = "";
      let result = [];
      let x = 0;
      const contact = JSON.parse(sessionStorage.getItem("contact"));

      // const myDepartment = "Actuary -SG".toUpperCase();
      let myDepartment = "";
      try {
        myDepartment = contact.department.toUpperCase().toUpperCase();
      } catch (e) {}
      // myDepartment = "Corp Tech, Doc Sols OH Insrt Op Day Shft".toUpperCase();
      if (newHires) {
        if (newHires.error) {
          result = newHires;
        } else {
          for (let i = 0; i < newHires.length; i++) {
            if (newHires[i].department !== lastDept) {
              lastDept = newHires[i].department;
              const deptCompare = lastDept.toUpperCase();
              let contacts = this.filterContacts(newHires, lastDept);
              if (myDepartment === deptCompare) {
                console.log(
                  "App.retrieveNewHires MATCHED DEPARTMENT",
                  deptCompare
                );
              }
              const on = myDepartment === deptCompare;
              const order = on ? 0 : i + 1;
              console.log(
                "App.retrieveNewHires",
                "|" + myDepartment + "|",
                "|" + deptCompare + "|",
                myDepartment === deptCompare
              );
              result[x] = {
                id: "dept_" + x,
                department: lastDept,
                on,
                order,
                contacts,
                yourDepartment: on
              };
              x++;
            }
          }
          result.sort(function sortLoc(a, b) {
            return a.order > b.order ? 1 : -1;
          });

          console.log("App.retrieveNewHires result", result);

          // this.fetchingNewHirePhotos = true;
          // this.newHirePhotosRetrieved = true;
          console.log("App.");
          //CREATE FILTERS
          const temp = [...newHires];
          let tempLocs = [];

          x = 0;
          for (let i = 0; i < temp.length; i++) {
            if (temp[i].department !== lastDept) {
              lastDept = temp[i].department;
              let contacts = this.filterContacts(newHires, lastDept);
              for (let c = 0; c < contacts.length; c++) {
                tempLocs[x] = {
                  location: contacts[c].location,
                  stateCity: contacts[c].stateCity
                };
                x++;
              }
            }
          }
          // tempLocs.sort(function sortLoc(a, b) {
          //   return a.location > b.location ? 1 : -1;
          // });
          tempLocs.sort(function sortLoc(a, b) {
            return a.stateCity > b.stateCity ? 1 : -1;
          });

          let lastLoc = "";
          locs[0] = {
            id: "locs_0",
            location: "All States and Cities",
            total: newHires.length,
            on: true
          };
          x = 1;
          locationFilter = "All States and Cities (" + newHires.length + ")";
          for (let i = 0; i < tempLocs.length; i++) {
            if (lastLoc.toUpperCase() !== tempLocs[i].stateCity.toUpperCase()) {
              lastLoc = tempLocs[i].stateCity;

              const filterLoc = lastLoc;
              let locFilters = tempLocs.filter(
                loc => loc.stateCity === filterLoc
              );

              locs[x] = {
                id: "locs_" + x,
                location: lastLoc,
                total: locFilters.length,
                on: false
              };
              x++;
            }
          }
          console.log("App.retrieveNewHires locs", locs);

          temp.sort(function sortDept(a, b) {
            return a.managerName > b.managerName ? 1 : -1;
          });
          let lastMgr = "";

          x = 0;
          for (let i = 0; i < temp.length; i++) {
            if (temp[i].managerName !== lastMgr) {
              mgrs[x++] = { managerName: temp[i].managerName, on: false };
              lastMgr = temp[i].managerName;
            }
          }
          temp.sort(function sortHireDate(a, b) {
            return a.hireDate > b.hireDate ? -1 : 1;
          });
          let lastHireDate = "";

          x = 0;
          for (let i = 0; i < temp.length; i++) {
            if (temp[i].hireDate !== lastHireDate) {
              hireDts[x++] = { hireDate: temp[i].hireDate, on: false };
              lastHireDate = temp[i].hireDate;
            }
          }
        }
      }

      this.setState({
        newHires: result,
        locations: locs,
        locationFilter,
        // departments: depts,
        managers: mgrs,
        hireDates: hireDts
        // jobTitles: jobs
      });

      await this.retrieveNewHirePhotos();
    }
  };

  addRemoveLocationFilter = selectedLocation => {
    console.log(
      "App.addRemoveLocationFilter selectedLocation",
      selectedLocation
    );
    const { locations, newHires } = this.state;
    let locationFilter = "";
    for (let i = 0; i < locations.length; i++) {
      const test1 = "" + locations[i].id.toUpperCase();
      const test2 = "" + selectedLocation.toUpperCase();

      if (test1 === test2) {
        console.log(
          "App.addRemoveLocationFilter FOUND!",
          selectedLocation,
          locations[i]
        );
        locations[i].on = true;
        locationFilter = locations[i].location + "(" + locations[i].total + ")";
      } else {
        locations[i].on = false;
      }
    }
    let locFilters = locations.filter(function(e) {
      return e.on;
    });

    let blnLocFiltered = true;

    if ("" + selectedLocation === "locs_0") {
      // console.log("App.addRemoveLocationFilter set blnLocFiltered to false");
      blnLocFiltered = false;
    }

    if (!blnLocFiltered) {
      //no filters
      // console.log("App.addRemoveLocationFilter No Filters -- show all");
      for (let row = 0; row < newHires.length; row++) {
        for (let c = 0; c < newHires[row].contacts.length; c++) {
          newHires[row].contacts[c].showing = "on";
        }
      }
    } else {
      //there are filters

      const locValue = locFilters[0].location;
      // console.log("App.addRemoveLocationFilter There are Filters", locValue);
      for (let row = 0; row < newHires.length; row++) {
        for (let c = 0; c < newHires[row].contacts.length; c++) {
          if (locValue === newHires[row].contacts[c].stateCity) {
            newHires[row].contacts[c].showing = "on";
          } else {
            newHires[row].contacts[c].showing = "off";
          }
        }
      }
    }

    this.setState({ locations, selectedLocation, newHires, locationFilter });
  };

  addRemoveDepartmentsFilter = index => {
    const { departments } = this.state;
    departments[index].on = !departments[index].on;

    this.setState({ departments });
  };
  addRemoveManagerFilter = index => {
    const { managers } = this.state;
    managers[index].on = !managers[index].on;

    this.setState({ managers });
  };

  changeDepartmentDisplay = index => {
    const { newHires } = this.state;
    const on = !newHires[index].on;
    newHires[index].on = on;
    console.log("APp.changeDepartmentDisplay index", index, on);
    this.setState({ newHires });
  };
  retrieveNewHirePhotos = async () => {
    console.log("App.retrieveNewHirePhotos start", !this.fetchingNewHirePhotos);
    if (!this.fetchingNewHirePhotos) {
      console.log("App.retrieveNewHirePhotos start");
      //Not already fetching photo's and Photos do not exist
      this.fetchingNewHirePhotos = true;
      // let teamImages = [];
      const { newHires } = this.state;
      console.log("App.retrieveNewHirePhotos newHires", newHires);
      //TEST
      if (newHires && newHires.length > 0) {
        for (let row = 0; row < newHires.length; row++) {
          const deptRow = newHires[row];
          const deptContacts = deptRow.contacts;
          for (let c = 0; c < deptContacts.length; c++) {
            const member = deptContacts[c];
            const data = [{ emplid: member.emplid }];
            let Callup_Image = [];
            Callup_Image = await GetPhotos(data);
            deptContacts[c].loading = false;
            if (Callup_Image && Callup_Image.length > 0) {
              //The Callup_image object exists and has data
              // newHires[row].image = Callup_Image[0].Image;
              if (Callup_Image[0].userPrincipalName) {
                //A userPrincipalName was returned so set its value
                deptContacts[c].userPrincipalName =
                  Callup_Image[0].userPrincipalName;
              }
              if (!deptContacts[c].workPhone && Callup_Image[0].businessPhone) {
                //Workphone did not exist, but businessPhone was retreived for this user
                deptContacts[c].workPhone = Callup_Image[0].businessPhone;
              }
            }
          }
          deptRow.contacts = deptContacts;

          console.log("App.retrieveNewHirePhotos newHires", newHires);
          this.setState({ newHires });
        }
      }
    }
  };

  handleNewHireDisplay = () => {
    const { displayNewHires } = this.state;
    const show = !displayNewHires;
    if (show) {
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0;
      document.body.style.overflow = "hidden";
      try {
        document.getElementById(
          "payandbenefits-dropdown-item-button1"
        ).tabIndex = "-1";
      } catch (e) {}
      try {
        document.getElementById("career-dropdown-item-button1").tabIndex = "-1";
      } catch (e) {}
      try {
        document.getElementById(
          "peopleandculture-dropdown-item-button1"
        ).tabIndex = "-1";
      } catch (e) {}
      try {
        document.getElementById("logout-dropdown-item-button1").tabIndex = "-1";
      } catch (e) {}
      try {
        document.getElementById("linkbutton-workday").tabIndex = "-1";
      } catch (e) {}
      try {
        document.getElementById("linkbutton-bravo").tabIndex = "-1";
      } catch (e) {}
      try {
        document.getElementById("linkbutton-fow").tabIndex = "-1";
      } catch (e) {}
      try {
        document.getElementById("linkbutton-idrive").tabIndex = "-1";
      } catch (e) {}
      try {
        document.getElementById("linkbutton-aws").tabIndex = "-1";
      } catch (e) {}
      try {
        document.getElementById("linkbutton-inside").tabIndex = "-1";
      } catch (e) {}
      try {
        document.getElementById("linkbutton-newhires").tabIndex = "-1";
      } catch (e) {}
      const tags = document.getElementsByTagName("a");
      for (let i = 0, max = tags.length; i < max; i++) {
        // console.log("handleNewHireDisplay", tags[i]);
        tags[i].tabIndex = "-1";
      }
      const inputtags = document.getElementsByTagName("input");
      for (let i = 0, max = inputtags.length; i < max; i++) {
        // console.log("handleNewHireDisplay", inputtags[i]);
        inputtags[i].tabIndex = "-1";
      }
      const btnTags = document.getElementsByTagName("button");
      for (let i = 0, max = btnTags.length; i < max; i++) {
        // console.log("handleNewHireDisplay", btnTags[i]);
        const classList = btnTags[i].className.split(" ");
        let skip = false;
        for (let c = 0; c < classList.length; c++) {
          if (classList[c] === "newHiresToggleButton") {
            skip = true;
            break;
          }
        }
        if (!skip) {
          btnTags[i].tabIndex = "-1";
        }
      }
    } else {
      document.body.style.overflow = "auto";
      try {
        document.getElementById(
          "payandbenefits-dropdown-item-button1"
        ).tabIndex = "0";
      } catch (e) {}
      try {
        document.getElementById("career-dropdown-item-button1").tabIndex = "0";
      } catch (e) {}
      try {
        document.getElementById(
          "peopleandculture-dropdown-item-button1"
        ).tabIndex = "0";
      } catch (e) {}
      try {
        document.getElementById("logout-dropdown-item-button1").tabIndex = "0";
      } catch (e) {}
      try {
        document.getElementById("linkbutton-workday").tabIndex = "0";
      } catch (e) {}
      try {
        document.getElementById("linkbutton-bravo").tabIndex = "0";
      } catch (e) {}
      try {
        document.getElementById("linkbutton-fow").tabIndex = "0";
      } catch (e) {}
      try {
        document.getElementById("linkbutton-idrive").tabIndex = "0";
      } catch (e) {}
      try {
        document.getElementById("linkbutton-aws").tabIndex = "0";
      } catch (e) {}
      try {
        document.getElementById("linkbutton-inside").tabIndex = "0";
      } catch (e) {}
      try {
        document.getElementById("linkbutton-newhires").tabIndex = "0";
      } catch (e) {}
      const tags = document.getElementsByTagName("a");
      for (let i = 0, max = tags.length; i < max; i++) {
        // console.log("handleNewHireDisplay", tags[i]);
        tags[i].tabIndex = "0";
      }
      const inputtags = document.getElementsByTagName("input");
      for (let i = 0, max = inputtags.length; i < max; i++) {
        // console.log("handleNewHireDisplay", inputtags[i]);
        inputtags[i].tabIndex = "0";
      }
      const btnTags = document.getElementsByTagName("button");
      for (let i = 0, max = btnTags.length; i < max; i++) {
        // console.log("handleNewHireDisplay", btnTags[i]);
        const classList = btnTags[i].className.split(" ");
        let skip = false;
        for (let c = 0; c < classList.length; c++) {
          if (classList[c] === "newHiresToggleButton") {
            skip = true;
            break;
          }
        }
        if (!skip) {
          btnTags[i].tabIndex = "0";
        }
      }
    }
    this.setState({ displayNewHires: show });
    //set a this .setState to work to remove the menu 
    this.setState({ menuExpanded: false });
    this.setState({ changeMenuExpanded: false});
  };

  render() {
    const {
      user,
      searchQuery,
      short_name,
      body_post,
      searchData,
      isAdmin,
      searching,
      searchingCallup,
      announcementId,
      workingAnnouncement,
      unfilteredNumber,
      filteredNumber,
      payslipData,
      payslipPortalActive,
      payslipDefaultActive,

      timeoffData,
      timeoffPortalActive,
      timeoffDefaultActive,
      jobData,
      jobPortalActive,
      jobDefaultActive,
      savingsPortalActive,
      savingsData,
      savingsDefaultActive,
      portlets,
      showPortletSelector,
      awsData,
      awsPortalActive,
      awsDefaultActive,
      fowData,
      fowPortalActive,
      fowDefaultActive,
      MyHealthData,
      MyHealthPortalActive,
      MyHealthDefaultActive,
      benefitsPortalActive,
      benefitsData,
      benefitsDefaultActive,
      searchHistoryId,
      payEndDate,
      callUpSNData,
      Callup_Image,
      HRK_Error,
      People_Error,
      HRK_Display,
      People_Display,
      menuExpanded,
      newHires,
      locations,
      showLocations,
      selectedLocation,
      locationFilter,
      departments,
      managers,
      hireDates,
      jobTitles,
      displayNewHires,
      givingData,
      givingPortalActive,
      givingDefaultActive,
      productData,
      productPortalActive,
      productDefaultActive,
      restrictKnowledgeData,
      AdminNameData,
      orgChartData,
      orgChart_Image,
      WorkGroupData,
      hsaData,
      hsaDefaultActive,
      hsaPortalActive,
      dataFileResponse
    } = this.state;
    return (
      <React.Fragment>
        <BrowserRouter>
          <Switch>
            <Route path="/storeToken/:token" component={TeamsCallback} />
            <Route path="/not-authorized" component={NotAuthorized} />
            <Route path="/oidc-client-callback" component={Callback} />
            <Route path="/auth-callback" component={Callback} />
            <Route path="/leaderconnect" component={LeaderHome} />
            <Route
              path="/displayAnnouncement/:id"
              component={DisplayAnnouncement}
            />
            <Route
              path="/AdvancedPeopleSearch"
              render={props => (
                <Home
                  dataFileResponse={dataFileResponse}
                  fetchDataFile={() => this.fetchDataFile()}
                  hsaData={hsaData}
                  hsaDefaultActive={hsaDefaultActive}
                  hsaPortalActive={hsaPortalActive}
                  activateHSAPortal={this.retrieveHSAData}
                  deactivateHSA={this.deactivateHSA}
                  fetchHSAData={this.fetchHSAData}
                  WorkGroupData={WorkGroupData}
                  fetchWorkGroupData={this.fetchWorkGroupData}
                  orgChart_Image={orgChart_Image}
                  orgChartData={orgChartData}
                  fetchOrgChartData={this.fetchOrgChartData}
                  fetchAdminNameData={this.fetchAdminNameData}
                  AdminNameData={AdminNameData}
                  reSearchKnowledge={this.reSearchKnowledge}
                  restrictKnowledgeData={restrictKnowledgeData}
                  loadMoreKnowledge={this.loadMoreKnowledge}
                  productData={productData}
                  productPortalActive={productPortalActive}
                  productDefaultActive={productDefaultActive}
                  activateProductPortal={this.retrieveProductData}
                  deactivateProduct={this.deactivateProduct}
                  fetchProductData={this.fetchProductData}
                  givingData={givingData}
                  givingPortalActive={givingPortalActive}
                  givingDefaultActive={givingDefaultActive}
                  activateGivingPortal={this.retrieveGivingData}
                  deactivateGiving={this.deactivateGiving}
                  fetchGivingData={this.fetchGivingData}
                  menuExpanded={menuExpanded}
                  changeMenuExpanded={this.handleChangeMenuExpanded}
                  user={user}
                  searchQuery={searchQuery}
                  short_name={short_name}
                  body_post={body_post}
                  searchData={searchData}
                  unfilteredNumber={unfilteredNumber}
                  filteredNumber={filteredNumber}
                  isAdmin={isAdmin}
                  searching={searching}
                  searchingCallup={searchingCallup}
                  onUserUpate={user => this.handleUserUpdate(user)}
                  workingAnnouncement={workingAnnouncement}
                  announcementId={announcementId}
                  onAnnouncementIdUpdate={announcmentId =>
                    this.handleAnnouncementIdChange(announcmentId)
                  }
                  onSearchQueryUpdate={searchQuery =>
                    this.handleSearchQueryUpdate(searchQuery)
                  }
                  onSearchQueryUpdateCallup={searchQuery =>
                    this.handleSearchQueryCallup(searchQuery)
                  }
                  handleManagerHyperlink={searchQuery =>
                    this.handleManagerHyperlink(searchQuery)
                  }
                  onSearchQueryAPS={(short_name, body_post, body_post2) =>
                    this.handleCallupAdvancedSearch(
                      short_name,
                      body_post,
                      body_post2
                    )
                  }
                  callUpSNData={callUpSNData}
                  Callup_Image={Callup_Image}
                  HRK_Error={HRK_Error}
                  People_Error={People_Error}
                  HRK_Display={HRK_Display}
                  People_Display={People_Display}
                  onWorkingAnnouncementChange={workingAnnouncement =>
                    this.handleWorkingAnnouncementChange(workingAnnouncement)
                  }
                  populatePayslipPortal={this.retrievePayslipData}
                  payslipData={payslipData}
                  payslipPortalActive={payslipPortalActive}
                  payslipDefaultActive={payslipDefaultActive}
                  fetchPayslipData={this.fetchPayslipData}
                  deactivatePayslip={this.deactivatePayslip}
                  activateTimeOffPortal={this.retrieveTimeoffData}
                  fetchTimeOffData={this.fetchTimeOffData}
                  deactivateTimeOff={this.deactivateTimeOff}
                  jobData={jobData}
                  jobPortalActive={jobPortalActive}
                  jobDefaultActive={jobDefaultActive}
                  populateJobsPortal={this.retrieveJobData}
                  fetchJobsData={this.fetchJobsData}
                  deactivateJobs={this.deactivateJobs}
                  awsData={awsData}
                  awsPortalActive={awsPortalActive}
                  deactivateAws={this.deactivateAws}
                  awsDefaultActive={awsDefaultActive}
                  activateAwsPortal={this.retrieveAwsData}
                  fetchAwsData={this.fetchAwsData}
                  fowData={fowData}
                  fowPortalActive={fowPortalActive}
                  fowDefaultActive={fowDefaultActive}
                  deactivateFow={this.deactivateFow}
                  populateFOWPortal={this.retrieveFOWData}
                  fetchFowData={this.fetchFowData}
                  MyHealthData={MyHealthData}
                  MyHealthPortalActive={MyHealthPortalActive}
                  MyHealthDefaultActive={MyHealthDefaultActive}
                  populateMyHealthPortal={this.retrieveMyHealthData}
                  deactivateMyHealth={this.deactivateMyHealth}
                  fetchMyHealth={this.fetchMyHealth}
                  benefitsData={benefitsData}
                  benefitsPortalActive={benefitsPortalActive}
                  benefitsDefaultActive={benefitsDefaultActive}
                  populateBenefitsPortal={this.retrieveBenefitsData}
                  deactivateBenefits={this.deactivateBenefits}
                  fetchBenefits={this.fetchBenefitsData}
                  timeoffData={timeoffData}
                  timeoffPortalActive={timeoffPortalActive}
                  timeoffDefaultActive={timeoffDefaultActive}
                  savingsPortalActive={savingsPortalActive}
                  savingsData={savingsData}
                  savingsDefaultActive={savingsDefaultActive}
                  populateSavingsPortal={this.retrieveSavingsData}
                  fetchSavingsData={this.fetchSavingsData}
                  deactivateSavings={this.deactivateSavings}
                  portlets={portlets}
                  showPortletSelector={showPortletSelector}
                  onChangePortletDisplay={this.handleChangeShowPortletSelector}
                  portletUpdate={this.handlePortletsAdminChange}
                  searchHistoryId={searchHistoryId}
                  retrievePortals={this.retrievePortals}
                  handlePayEndDateChange={this.handlePayEndDateChange}
                  payEndDate={payEndDate}
                  expandPortlet={this.handleExpandPortlet}
                  newHires={newHires}
                  locations={locations}
                  selectedLocation={selectedLocation}
                  locationFilter={locationFilter}
                  managers={managers}
                  departments={departments}
                  hireDates={hireDates}
                  jobTitles={jobTitles}
                  displayNewHires={displayNewHires}
                  // filterNewHires={this.filterNewHires}
                  handleNewHireDisplay={this.handleNewHireDisplay}
                  changeDepartmentDisplay={idx =>
                    this.changeDepartmentDisplay(idx)
                  }
                  addRemoveManagerFilter={idx =>
                    this.addRemoveManagerFilter(idx)
                  }
                  setAllManagers={show => this.setAllManagers(show)}
                  addRemoveLocationFilter={idx =>
                    this.addRemoveLocationFilter(idx)
                  }
                  populateNewHires={this.retrieveNewHires}
                  advancedPplUrl={true}
                  {...props}
                />
              )}
            />
            <Route path="/ErrorPage" component={ErrorPage} />
            <Route path="/PageNotFound" component={NotFound} />

            <Route path="/50*" component={Badgateway} />
            <Route
              path="/FAQSearch/:defaultSearch"
              render={props => (
                <Home
                  dataFileResponse={dataFileResponse}
                  fetchDataFile={() => this.fetchDataFile()}
                  advancedPplUrl={false}
                  hsaData={hsaData}
                  hsaDefaultActive={hsaDefaultActive}
                  hsaPortalActive={hsaPortalActive}
                  activateHSAPortal={this.retrieveHSAData}
                  deactivateHSA={this.deactivateHSA}
                  fetchHSAData={this.fetchHSAData}
                  WorkGroupData={WorkGroupData}
                  fetchWorkGroupData={this.fetchWorkGroupData}
                  orgChart_Image={orgChart_Image}
                  orgChartData={orgChartData}
                  fetchOrgChartData={this.fetchOrgChartData}
                  fetchAdminNameData={this.fetchAdminNameData}
                  AdminNameData={AdminNameData}
                  reSearchKnowledge={this.reSearchKnowledge}
                  restrictKnowledgeData={restrictKnowledgeData}
                  loadMoreKnowledge={this.loadMoreKnowledge}
                  productData={productData}
                  productPortalActive={productPortalActive}
                  productDefaultActive={productDefaultActive}
                  activateProductPortal={this.retrieveProductData}
                  deactivateProduct={this.deactivateProduct}
                  fetchProductData={this.fetchProductData}
                  givingData={givingData}
                  givingPortalActive={givingPortalActive}
                  givingDefaultActive={givingDefaultActive}
                  activateGivingPortal={this.retrieveGivingData}
                  deactivateGiving={this.deactivateGiving}
                  fetchGivingData={this.fetchGivingData}
                  menuExpanded={menuExpanded}
                  changeMenuExpanded={this.handleChangeMenuExpanded}
                  user={user}
                  searchQuery={searchQuery}
                  short_name={short_name}
                  body_post={body_post}
                  searchData={searchData}
                  unfilteredNumber={unfilteredNumber}
                  filteredNumber={filteredNumber}
                  isAdmin={isAdmin}
                  searching={searching}
                  searchingCallup={searchingCallup}
                  onUserUpate={user => this.handleUserUpdate(user)}
                  workingAnnouncement={workingAnnouncement}
                  announcementId={announcementId}
                  onAnnouncementIdUpdate={announcmentId =>
                    this.handleAnnouncementIdChange(announcmentId)
                  }
                  onSearchQueryUpdate={searchQuery =>
                    this.handleSearchQueryUpdate(searchQuery)
                  }
                  onSearchQueryUpdateCallup={searchQuery =>
                    this.handleSearchQueryCallup(searchQuery)
                  }
                  handleManagerHyperlink={searchQuery =>
                    this.handleManagerHyperlink(searchQuery)
                  }
                  onSearchQueryAPS={(short_name, body_post, body_post2) =>
                    this.handleCallupAdvancedSearch(
                      short_name,
                      body_post,
                      body_post2
                    )
                  }
                  callUpSNData={callUpSNData}
                  Callup_Image={Callup_Image}
                  HRK_Error={HRK_Error}
                  People_Error={People_Error}
                  HRK_Display={HRK_Display}
                  People_Display={People_Display}
                  onWorkingAnnouncementChange={workingAnnouncement =>
                    this.handleWorkingAnnouncementChange(workingAnnouncement)
                  }
                  populatePayslipPortal={this.retrievePayslipData}
                  payslipData={payslipData}
                  payslipPortalActive={payslipPortalActive}
                  payslipDefaultActive={payslipDefaultActive}
                  fetchPayslipData={this.fetchPayslipData}
                  deactivatePayslip={this.deactivatePayslip}
                  activateTimeOffPortal={this.retrieveTimeoffData}
                  fetchTimeOffData={this.fetchTimeOffData}
                  deactivateTimeOff={this.deactivateTimeOff}
                  jobData={jobData}
                  jobPortalActive={jobPortalActive}
                  jobDefaultActive={jobDefaultActive}
                  populateJobsPortal={this.retrieveJobData}
                  fetchJobsData={this.fetchJobsData}
                  deactivateJobs={this.deactivateJobs}
                  awsData={awsData}
                  awsPortalActive={awsPortalActive}
                  deactivateAws={this.deactivateAws}
                  awsDefaultActive={awsDefaultActive}
                  activateAwsPortal={this.retrieveAwsData}
                  fetchAwsData={this.fetchAwsData}
                  fowData={fowData}
                  fowPortalActive={fowPortalActive}
                  fowDefaultActive={fowDefaultActive}
                  deactivateFow={this.deactivateFow}
                  populateFOWPortal={this.retrieveFOWData}
                  fetchFowData={this.fetchFowData}
                  MyHealthData={MyHealthData}
                  MyHealthPortalActive={MyHealthPortalActive}
                  MyHealthDefaultActive={MyHealthDefaultActive}
                  populateMyHealthPortal={this.retrieveMyHealthData}
                  deactivateMyHealth={this.deactivateMyHealth}
                  fetchMyHealth={this.fetchMyHealth}
                  benefitsData={benefitsData}
                  benefitsPortalActive={benefitsPortalActive}
                  benefitsDefaultActive={benefitsDefaultActive}
                  populateBenefitsPortal={this.retrieveBenefitsData}
                  deactivateBenefits={this.deactivateBenefits}
                  fetchBenefits={this.fetchBenefitsData}
                  timeoffData={timeoffData}
                  timeoffPortalActive={timeoffPortalActive}
                  timeoffDefaultActive={timeoffDefaultActive}
                  savingsPortalActive={savingsPortalActive}
                  savingsData={savingsData}
                  savingsDefaultActive={savingsDefaultActive}
                  populateSavingsPortal={this.retrieveSavingsData}
                  fetchSavingsData={this.fetchSavingsData}
                  deactivateSavings={this.deactivateSavings}
                  portlets={portlets}
                  showPortletSelector={showPortletSelector}
                  onChangePortletDisplay={this.handleChangeShowPortletSelector}
                  portletUpdate={this.handlePortletsAdminChange}
                  searchHistoryId={searchHistoryId}
                  retrievePortals={this.retrievePortals}
                  handlePayEndDateChange={this.handlePayEndDateChange}
                  payEndDate={payEndDate}
                  expandPortlet={this.handleExpandPortlet}
                  newHires={newHires}
                  locations={locations}
                  selectedLocation={selectedLocation}
                  locationFilter={locationFilter}
                  managers={managers}
                  departments={departments}
                  hireDates={hireDates}
                  jobTitles={jobTitles}
                  displayNewHires={displayNewHires}
                  // filterNewHires={this.filterNewHires}
                  handleNewHireDisplay={this.handleNewHireDisplay}
                  changeDepartmentDisplay={idx =>
                    this.changeDepartmentDisplay(idx)
                  }
                  addRemoveManagerFilter={idx =>
                    this.addRemoveManagerFilter(idx)
                  }
                  setAllManagers={show => this.setAllManagers(show)}
                  addRemoveLocationFilter={idx =>
                    this.addRemoveLocationFilter(idx)
                  }
                  populateNewHires={this.retrieveNewHires}
                  {...props}
                />
              )}
            />
            <Route
              path="/"
              render={props => (
                <Home
                  dataFileResponse={dataFileResponse}
                  fetchDataFile={() => this.fetchDataFile()}
                  advancedPplUrl={false}
                  hsaData={hsaData}
                  hsaDefaultActive={hsaDefaultActive}
                  hsaPortalActive={hsaPortalActive}
                  activateHSAPortal={this.retrieveHSAData}
                  deactivateHSA={this.deactivateHSA}
                  fetchHSAData={this.fetchHSAData}
                  WorkGroupData={WorkGroupData}
                  fetchWorkGroupData={this.fetchWorkGroupData}
                  orgChart_Image={orgChart_Image}
                  orgChartData={orgChartData}
                  fetchOrgChartData={this.fetchOrgChartData}
                  fetchAdminNameData={this.fetchAdminNameData}
                  AdminNameData={AdminNameData}
                  reSearchKnowledge={this.reSearchKnowledge}
                  restrictKnowledgeData={restrictKnowledgeData}
                  loadMoreKnowledge={this.loadMoreKnowledge}
                  productData={productData}
                  productPortalActive={productPortalActive}
                  productDefaultActive={productDefaultActive}
                  activateProductPortal={this.retrieveProductData}
                  deactivateProduct={this.deactivateProduct}
                  fetchProductData={this.fetchProductData}
                  givingData={givingData}
                  givingPortalActive={givingPortalActive}
                  givingDefaultActive={givingDefaultActive}
                  activateGivingPortal={this.retrieveGivingData}
                  deactivateGiving={this.deactivateGiving}
                  fetchGivingData={this.fetchGivingData}
                  menuExpanded={menuExpanded}
                  changeMenuExpanded={this.handleChangeMenuExpanded}
                  user={user}
                  searchQuery={searchQuery}
                  short_name={short_name}
                  body_post={body_post}
                  searchData={searchData}
                  unfilteredNumber={unfilteredNumber}
                  filteredNumber={filteredNumber}
                  isAdmin={isAdmin}
                  searching={searching}
                  searchingCallup={searchingCallup}
                  onUserUpate={user => this.handleUserUpdate(user)}
                  workingAnnouncement={workingAnnouncement}
                  announcementId={announcementId}
                  onAnnouncementIdUpdate={announcmentId =>
                    this.handleAnnouncementIdChange(announcmentId)
                  }
                  onSearchQueryUpdate={searchQuery =>
                    this.handleSearchQueryUpdate(searchQuery)
                  }
                  onSearchQueryUpdateCallup={searchQuery =>
                    this.handleSearchQueryCallup(searchQuery)
                  }
                  handleManagerHyperlink={searchQuery =>
                    this.handleManagerHyperlink(searchQuery)
                  }
                  onSearchQueryAPS={(short_name, body_post, body_post2) =>
                    this.handleCallupAdvancedSearch(
                      short_name,
                      body_post,
                      body_post2
                    )
                  }
                  callUpSNData={callUpSNData}
                  Callup_Image={Callup_Image}
                  HRK_Error={HRK_Error}
                  People_Error={People_Error}
                  HRK_Display={HRK_Display}
                  People_Display={People_Display}
                  onWorkingAnnouncementChange={workingAnnouncement =>
                    this.handleWorkingAnnouncementChange(workingAnnouncement)
                  }
                  populatePayslipPortal={this.retrievePayslipData}
                  payslipData={payslipData}
                  payslipPortalActive={payslipPortalActive}
                  payslipDefaultActive={payslipDefaultActive}
                  fetchPayslipData={this.fetchPayslipData}
                  deactivatePayslip={this.deactivatePayslip}
                  activateTimeOffPortal={this.retrieveTimeoffData}
                  fetchTimeOffData={this.fetchTimeOffData}
                  deactivateTimeOff={this.deactivateTimeOff}
                  jobData={jobData}
                  jobPortalActive={jobPortalActive}
                  jobDefaultActive={jobDefaultActive}
                  populateJobsPortal={this.retrieveJobData}
                  fetchJobsData={this.fetchJobsData}
                  deactivateJobs={this.deactivateJobs}
                  awsData={awsData}
                  awsPortalActive={awsPortalActive}
                  deactivateAws={this.deactivateAws}
                  awsDefaultActive={awsDefaultActive}
                  activateAwsPortal={this.retrieveAwsData}
                  fetchAwsData={this.fetchAwsData}
                  fowData={fowData}
                  fowPortalActive={fowPortalActive}
                  fowDefaultActive={fowDefaultActive}
                  deactivateFow={this.deactivateFow}
                  populateFOWPortal={this.retrieveFOWData}
                  fetchFowData={this.fetchFowData}
                  MyHealthData={MyHealthData}
                  MyHealthPortalActive={MyHealthPortalActive}
                  MyHealthDefaultActive={MyHealthDefaultActive}
                  populateMyHealthPortal={this.retrieveMyHealthData}
                  deactivateMyHealth={this.deactivateMyHealth}
                  fetchMyHealth={this.fetchMyHealth}
                  benefitsData={benefitsData}
                  benefitsPortalActive={benefitsPortalActive}
                  benefitsDefaultActive={benefitsDefaultActive}
                  populateBenefitsPortal={this.retrieveBenefitsData}
                  deactivateBenefits={this.deactivateBenefits}
                  fetchBenefits={this.fetchBenefitsData}
                  timeoffData={timeoffData}
                  timeoffPortalActive={timeoffPortalActive}
                  timeoffDefaultActive={timeoffDefaultActive}
                  savingsPortalActive={savingsPortalActive}
                  savingsData={savingsData}
                  savingsDefaultActive={savingsDefaultActive}
                  populateSavingsPortal={this.retrieveSavingsData}
                  fetchSavingsData={this.fetchSavingsData}
                  deactivateSavings={this.deactivateSavings}
                  portlets={portlets}
                  showPortletSelector={showPortletSelector}
                  onChangePortletDisplay={this.handleChangeShowPortletSelector}
                  portletUpdate={this.handlePortletsAdminChange}
                  searchHistoryId={searchHistoryId}
                  retrievePortals={this.retrievePortals}
                  handlePayEndDateChange={this.handlePayEndDateChange}
                  payEndDate={payEndDate}
                  expandPortlet={this.handleExpandPortlet}
                  newHires={newHires}
                  locations={locations}
                  selectedLocation={selectedLocation}
                  locationFilter={locationFilter}
                  managers={managers}
                  departments={departments}
                  hireDates={hireDates}
                  jobTitles={jobTitles}
                  displayNewHires={displayNewHires}
                  // filterNewHires={this.filterNewHires}
                  handleNewHireDisplay={this.handleNewHireDisplay}
                  changeDepartmentDisplay={idx =>
                    this.changeDepartmentDisplay(idx)
                  }
                  addRemoveManagerFilter={idx =>
                    this.addRemoveManagerFilter(idx)
                  }
                  setAllManagers={show => this.setAllManagers(show)}
                  addRemoveLocationFilter={idx =>
                    this.addRemoveLocationFilter(idx)
                  }
                  populateNewHires={this.retrieveNewHires}
                  {...props}
                />
              )}
            />
          </Switch>
        </BrowserRouter>
        <ToastContainer />
      </React.Fragment>
    );
  }
}
export default App;

import React, { Component } from "react";

class LeaderHome extends Component {
  render() {
    const {
      user,
      searchQuery,
      isAdmin,
      menuExpanded,
      changeMenuExpanded
    } = this.props;
    // const userInfo = sessionStorage.getItem("userinfo");

    return (
      <React.Fragment>
        <h2>HOWDY DUDE</h2>
      </React.Fragment>
    );
  }
}

export default LeaderHome;

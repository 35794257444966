import jwt_decode from "jwt-decode";
import React, { Component } from "react";
import { getAccessToken } from "../services/AuthService";
import { isAdminUser } from "../services/adminUserService";

import { ToastContainer, toast } from "react-toastify";
import Spinner from "../common/spinner";
import config from "../config.json";
import { getEnvironment } from "../services/EnvironmentVariable";

const APIGEE_CLIENT_ID =
  getEnvironment() === "DEFAULT" ? config.client_id : config.test_client_id;

class Callback extends Component {
  state = { error: false, message: "" };

  async componentDidMount() {
    //Callback loaded so decode the idToken from Azure
    try {
      console.log("Callback.componentDidMount", new Date());
      const idToken = this.getIdTokenFromUrl();
      const decoded = jwt_decode(idToken);
      sessionStorage.setItem("idToken", idToken);
      const family_name = decoded.family_name;
      const given_name = decoded.given_name;
      const unique_name = decoded.unique_name.toUpperCase();
      const userId = unique_name.replace("@NATIONWIDE.COM", "");
      const user = {
        firstName: given_name,
        lastName: family_name,
        userId: userId
      };
      sessionStorage.setItem("user", JSON.stringify(user));
      const exp = parseInt(decoded.exp);
      const d = new Date(exp * 1000);
      sessionStorage.setItem("Azure_Expiration_date", d);
      this.requestAccessToken(idToken);
    } catch (e) {
      toast.error("Error during callback.componentDidMount.   Error: " + e);
    }
  }

  getIdTokenFromUrl() {
    console.log("Callback.getIdTokenFromUrl start");
    let idToken;
    try {
      window.location.hash
        .substring(1) // Remove '#' character
        .split("&") // Divide into key/value pairs
        .forEach(hashPart => {
          const [key, value] = hashPart.split("=");
          // console.log("callback.getIdTokenFromUrl ", "|" + key + "|", value);
          if (key) {
            const lKey = key.toLowerCase();
            // console.log(
            //   "callback.getIdTokenFromUrl key.toLowerCase()  ",
            //   "|" + lKey + "|"
            // );
            // console.log(
            //   "callback.getIdTokenFromUrl key.toLowerCase()  ",
            //   lKey === "error_description"
            // );
            switch (lKey) {
              case "id_token":
                idToken = value;
                break;
              case "error_description":
                const keyValue = decodeURIComponent(
                  (value + "").replace(/\+/g, "%20")
                );
                // console.log(
                //   "error_description FOUND ERROR DESCRIPTION",
                //   keyValue
                // );
                toast.error(
                  "Error during callback.getIdTokenFromUrl.  Error: " +
                    decodeURIComponent(keyValue)
                );
                break;
            }
          }
        });
    } catch (e) {
      toast.error("Error during callback.getIdTokenFromUrl.  Error: " + e);
    }

    // return stateMatches && idToken;
    return idToken;
  }

  encodeKeyValuePair(key, value) {
    return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
  }

  getAccessTokenRequestBody(jwt) {
    return [
      this.encodeKeyValuePair(
        "grant_type",
        "urn:ietf:paramsoap:oauth:grant-type:jwt-bearer"
      ),
      this.encodeKeyValuePair("assertion", jwt),
      this.encodeKeyValuePair("client_id", APIGEE_CLIENT_ID),
      this.encodeKeyValuePair("scope", "openid"),
      this.encodeKeyValuePair("realm", "employee"),
      this.encodeKeyValuePair("auth_method", "azure-jwt"),
      this.encodeKeyValuePair("identity_method", "nwie")
    ].join("&");
  }

  requestAccessToken = async idToken => {
    console.log("callback.requestAccessToken from apigee");
    try {
      //START HERE WHEN APIGEE CALL IS UPDATED AND READY TO GO.
      const jsondata = await getAccessToken(idToken);
      const access_token = jsondata.access_token;
      this.requestUserInformation(access_token);
    } catch (e) {
      toast.error("Error during callback.requestAccessToken.  Error: " + e);
    }
  };

  requestUserInformation = async () => {
    console.log("callback.requestUserInformation from apigee");
    let contact;
    let jsondata;
    try {
      contact = await isAdminUser();
      // contact = {
      //   id: 0,
      //   city: "",
      //   emplid: "",
      //   firstName: "",
      //   fullName: "",
      //   isPortalAdmin: "N",
      //   lastName: "",
      //   mailDrop: "",
      //   managerFlag: "",
      //   middleName: "",
      //   reportsAccess: "N",
      //   shortName: "",
      //   state: "",
      //   errorMessage: {
      //     developerMessage: "There was a problem retriving your UserInfo",
      //     userMessage:
      //       "There was a problem retriving your UserInfo.  Please refresh and try again later.",
      //     messageId: "3399fb56-e3ed-47c3-981e-1e391c8e968c"
      //   },
      //   workPhone: ""
      // };
      console.log("callback.requestUserInformation contact", contact);
      if (!contact) {
        toast.error("There was a problem retrieiving your user information");
        sessionStorage.clear();
        window.location = "/errorpage";
        return false;
      } else {
        if (contact.errorMessage) {
          toast.error(contact.errorMessage.userMessage);
          sessionStorage.clear();
          window.location = "/errorpage";
          return false;
        }
      }
      console.log("callback.requestUserInformation contact", contact);
      const userinfo = contact.userinfo;
      // console.log("callback.requestUserInformation userinfo", userinfo);
      let authorizationInformation;
      if (userinfo) {
        authorizationInformation = userinfo.authorizationInformation;
        console.log(
          "callback.requestUserInformation authorizationInformation",
          authorizationInformation
        );
      }
      //const authorizationInformation = userinfo.authorizationInformation;

      if (authorizationInformation) {
        // console.log(
        //   "callback.requestUserInformation authorizationInformation exists",
        //   authorizationInformation
        // );
        const access_token = authorizationInformation.access_token;
        const refresh_token = authorizationInformation.refresh_token;
        sessionStorage.setItem("access_token", access_token);
        sessionStorage.setItem("refresh_token", refresh_token);
        let d = new Date();
        d.setSeconds(
          d.getSeconds() + parseInt(authorizationInformation.expires_in)
        );
        console.log("callback.requestUserInformation store expires_at", d);
        const sec = Math.round(d.getTime() / 1000);
        sessionStorage.setItem("expires_at_sec", sec);

        // const now = new Date();
        // const diff = d - now;
      }
      // console.log("callback.requestUserInformation contact", contact);
      // console.log("callback.requestUserInformation userinfo", userinfo);
      sessionStorage.setItem("userinfo", JSON.stringify(userinfo));

      //jsondata = await getUserInformation(access_token);
      jsondata = userinfo;
      // console.log("callback.requestUserInformation retrieved", jsondata);
      const emplid = contact.emplid;
      const contractor = emplid.startsWith("P");
      // console.log("callback.requestUserInformation emplid", emplid);
      // console.log("callback.requestUserInformation contractor", contractor);
      if (contractor) {
        sessionStorage.clear();
        window.location = "/not-authorized";
        return false;
      }
    } catch (e) {
      const error = true;
      const message = jsondata
        ? "There was an error loggin you into the application. " +
          jsondata.developerMessage
        : "There was an error logging you into the application.  Please try again later.";
      this.setState({ error, message });
      console.log("callback.requestUserInformation error", e);
      toast.error("Error during callback.requestUserInformation.  Error: " + e);
      toast.error(jsondata);
      return false;
    }

    const refreshing = sessionStorage.getItem("refreshing");
    console.log("callback.requestUserInformation refreshing", refreshing);
    // sessionStorage.setItem("user_info", JSON.stringify(jsondata));
    if (refreshing) {
      sessionStorage.removeItem("refreshing");
      // window.close();
      console.log(
        "callback.requestUserInformation Refreshing Done close window"
      );
      localStorage.setItem("idToken", sessionStorage.getItem("idToken"));
      localStorage.setItem(
        "Azure_Expiration_date",
        sessionStorage.getItem("Azure_Expiration_date")
      );
      localStorage.setItem("userinfo", sessionStorage.getItem("userinfo"));
      localStorage.setItem(
        "access_token",
        sessionStorage.getItem("access_token")
      );
      localStorage.setItem("contact", sessionStorage.getItem("contact"));
      window.close();
    } else {
      console.log("callback.requestUserInformation Refreshing Done");

      //window.location = "/";
      let loc = localStorage.getItem("openedlocation");
      if (loc) {
        localStorage.removeItem("openedLocation");
        window.location = loc; 
      } else {
        window.location = loc;

      }
    }
  };

  render() {
    const { error, message } = this.state;
    if (error) {
      return <h2>{message}</h2>;
    }
    return (
      <React.Fragment>
        <Spinner />
        <ToastContainer />
      </React.Fragment>
    );
  }
}

export default Callback;

import config from "../config.json";
import { getRandomString } from "./AuthService";
import { getEnvironment } from "./EnvironmentVariable";
import { isMobile } from "react-device-detect";

export const getNewHires = async () => {
  let data = null;
  try {
    const environ = getEnvironment();
    let newHiresAPI = config.newHiresAPI;
    let newHiresKey = config.newHiresKey;
    if (environ !== "DEFAULT") {
      console.log("newHiresService.getNewHires environ", environ);
      if (isMobile) {
        //These values will likely change in the future
        newHiresAPI = config.Mobile_test_newHiresAPI;
      } else {
        newHiresAPI = config.test_newHiresAPI;
        newHiresKey = config.test_newHiresKey;
      }
    }
    const message = getRandomString(25);
    const token = sessionStorage.getItem("access_token");
    console.log("newHiresService.getNewHires newHiresAPI" + newHiresAPI);
    console.log("newHiresService.getNewHires newHiresKey" + newHiresKey);
    let headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Authorization", "Bearer " + token);
    headers.append("X-NW-Message-ID", message);
    headers.set("client_id", newHiresKey);
    console.log("newHiresService.getNewHires newHiresAPI", newHiresAPI);

    try {
      const response = await fetch(newHiresAPI, {
        method: "get",
        headers: headers
      });
      if (response.ok) {
        try {
          data = await response.json();
        } catch (e) {
          console.error("newHiresService.getNewHires  error", e);
          return { error: e };
        }
      } else {
        data = await response.json();
      }
    } catch (E) {
      console.error("newHiresService.getNewHires  error", E);
      return { error: E };
    }
  } catch (e) {
    console.error("newHiresService.getNewHires  error", e);
    return { error: e };
  }
  let results = [];
  if (data) {
    if (data.userMessage) {
      console.error("myHealthService.getMyHealth  error", data);
      return { error: data.userMessage };
    }
    if (data.newHires && data.newHires.length > 0) {
      const newHires = data.newHires;

      for (let row = 0; row < newHires.length; row++) {
        const dataRow = newHires[row];
        const lName = dataRow.prefLastName
          ? dataRow.prefLastName
          : dataRow.lastName;
        const fName = dataRow.prefFirstName
          ? dataRow.prefFirstName
          : dataRow.firstName;
        let mName = "";
        if (dataRow.middleName) {
          mName = dataRow.prefMiddleName
            ? dataRow.prefMiddleName
            : dataRow.middleName;
          if (mName === dataRow.prefFirstName) mName = "";
        }
        const resultRow = {
          showing: "on",
          userPrincipalName: "",
          loading: true,
          emplid: dataRow.emplid,
          name: lName + ", " + fName + " " + mName,
          userid: dataRow.shortName,
          jobTitle: dataRow.jobTitle,
          managerName: dataRow.managerPrefName
            ? dataRow.managerPrefName
            : dataRow.managerLastName + ", " + dataRow.managerFirstName,
          hireDate: dataRow.hireDate,
          department: dataRow.supOrgDescr,
          location: dataRow.locName,
          workState: dataRow.state,
          workCity: dataRow.city,
          stateCity: dataRow.state + ", " + dataRow.city
        };
        results[row] = resultRow;
      }

      results.sort(function sortDept(a, b) {
        return a.department > b.department ? 1 : -1;
      });
    } else {
      console.error("myHealthService.getMyHealth  error", "No New Hires");
      return [];
    }
  }
  return results;
};

import React, { Component } from "react";
import config from "../config.json";
import { CanViewReports } from "../services/AuthService";
import { isBrowser } from "react-device-detect";
import { getEnvironment } from "../services/EnvironmentVariable";
import { isMobile } from "react-device-detect";

class Footer extends Component {
  render() {
    const { showAnnounceList, isAdmin } = this.props;
    const reportsAllowed = CanViewReports();
    const adminAndReports = isAdmin && reportsAllowed;
    const adminOrReports = isAdmin || reportsAllowed;

    const environ = getEnvironment();
    let reportsURL = config.reportsLink;
    console.log("footer environ", environ);
    if (environ !== "DEFAULT") {
      //We are not in production environment
      //
      if (isMobile) {
        //However we are testing on a mobile device so use the Mobile api
        reportsURL = config.reportsLink;
      } else {
        //We are not testing on a mobile device, so we will use the test API
        reportsURL = config.test_reportsLink;
      }
    }

    return (
      <footer>
        <div className="footer">
          <div className="container">
            <div className="row">
              <div className="col-2" style={{ minWidth: "110px" }}>
                <div className="hrlogo" title="My Hr Dashboard"></div>
              </div>
              <div className="col">
                {isBrowser ? (
                  <div className=" hideMobile">
                    <a
                      href={config.feedbackLink}
                      className="footerLink"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      Give Feedback
                    </a>
                    {adminOrReports ? (
                      <span>&nbsp;|&nbsp;</span>
                    ) : (
                      <React.Fragment></React.Fragment>
                    )}
                    {isAdmin ? (
                      <button
                        className="btn btn-link"
                        onClick={showAnnounceList}
                        style={{
                          fontSize: "14px",
                          color: "white",
                          fontFamily: "nw-primary",
                          textDecoration: "underline"
                        }}
                      >
                        Admin
                      </button>
                    ) : (
                      <React.Fragment></React.Fragment>
                    )}
                    {adminAndReports ? (
                      <span>&nbsp;|&nbsp;</span>
                    ) : (
                      <React.Fragment></React.Fragment>
                    )}
                    {reportsAllowed ? (
                      <a
                        href={reportsURL}
                        className="footerLink"
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        Reports
                      </a>
                    ) : (
                      <React.Fragment></React.Fragment>
                    )}
                  </div>
                ) : (
                  <div></div>
                )}

                <div className="footerText">
                  <p>
                    &copy; Nationwide Mutual Insurance Company.
                    <br />
                    The data displayed on this page comes from a variety of
                    sources.
                    <br />
                    Those sources remain the systems of record. Make any
                    decisions on the original sources, not from this page.
                    <br />
                    <span style={{ fontWeight: "bold" }}>
                      Nationwide is on your side &reg;
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;

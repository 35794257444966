import config from "../config.json";
import { getEnvironment } from "./EnvironmentVariable";
import { isMobile } from "react-device-detect";

export const getNurses = async () => {
  let nursesUrl = config.nursesUrl;
  let nursesKey = config.awsKey;
  const environ = getEnvironment();
  console.log("awsService.getNurses environ", environ);
  if (environ !== "DEFAULT") {
    if (isMobile) {
      nursesUrl = config.Mobile_test_nursesUrl;
    } else {
      nursesUrl = config.test_nursesUrl;
      nursesKey = config.test_awsKey;
    }
  }
  const contact = JSON.parse(sessionStorage.getItem("contact"));
  let firstTwoMailCode = "02";
  let stateCode = contact.state;

  try {
    const mailcode = contact.mailDrop;
    const mailParts = mailcode.split("-");
    firstTwoMailCode = mailParts[0];
    if (firstTwoMailCode.length < 2) firstTwoMailCode = "0" + firstTwoMailCode;
  } catch (e) {
    console.log("awsService.getNurses Error", e);
  }
  const value = firstTwoMailCode + "," + stateCode;
  let headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("Access-Control-Allow-Origin", "*");
  const token = sessionStorage.getItem("access_token");
  headers.append("Authorization", "Bearer " + token);
  headers.set("client_id", nursesKey);
  headers.append("X-NW-Message-ID", "SWaVjgXjKtqoEqHUXQUT");

  const body = {
    reportname: "NurseList",
    query: [
      {
        label: "MAIL_PREFIX",
        value1: value
      }
    ]
  };

  try {
    const response = await fetch(nursesUrl, {
      method: "post",
      headers: headers,
      body: JSON.stringify(body)
    });

    const jsondata = await response.json();
    console.log("awsService.getNurses jsondata", jsondata);
    return jsondata;
  } catch (e) {
    console.log("awsService.getNurses Exception", e);
    return null;
  }
};

export const getCounselors = async () => {
  let counselorsUrl = config.counselorsUrl;
  let counselorsKey = config.awsKey;
  const environ = getEnvironment();
  console.log("awsService.getCounselors environ", environ);
  if (environ !== "DEFAULT") {
    if (isMobile) {
      counselorsUrl = config.Mobile_test_counselorsUrl;
    } else {
      counselorsUrl = config.test_counselorsUrl;
      counselorsKey = config.test_awsKey;
    }
  }
  const contact = JSON.parse(sessionStorage.getItem("contact"));
  let contactCity = "Columbus";
  let contactState = "OH";
  try {
    contactCity = contact.city;
    contactState = contact.state;
  } catch (e) {}

  const val = contactCity + "_" + contactState;
  let headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("Access-Control-Allow-Origin", "*");
  const token = sessionStorage.getItem("access_token");
  headers.append("Authorization", "Bearer " + token);
  headers.set("client_id", counselorsKey);
  headers.append("X-NW-Message-ID", "SWaVjgXjKtqoEqHUXQUT");

  const body = {
    reportname: "CounselorList",
    query: [
      {
        label: "CITY_STATE",
        value1: val
      }
    ]
  };

  try {
    const response = await fetch(counselorsUrl, {
      method: "post",
      headers: headers,
      body: JSON.stringify(body)
    });

    const jsondata = await response.json();
    console.log("awsService.getCounselors jsondata", jsondata);
    return jsondata;
  } catch (e) {
    console.log("awsService.getCounselors Exception", e);
    return null;
  }
};

import React, { Component } from "react";
import {
  CollapsedPortlet,
  Portlet,
  PortletError,
  PortletLoading
} from "../common/portlet";
import { PortletInfo } from "../common/portletInfo";
import Spinner from "../common/spinner";
import config from "../config.json";

class Giving extends Component {
  state = {
    blurred: "yes",
    blurImage: "eye-show",
    displayInfo: false
  };

  handleBlurChange = () => {
    if (this.state.blurred === "yes") {
      this.setState({ blurred: "no", blurImage: "eye-show" });
    } else {
      this.setState({ blurred: "yes", blurImage: "eye-hide" });
    }
  };

  handleRefresh = async () => {
    this.props.fetchGivingData();
  };

  showInformation = () => {
    const { displayInfo } = this.state;
    console.log("Giving.displayInfo", displayInfo);
    this.setState({ displayInfo: !displayInfo });
  };

  returnContent(givingData, givingDefaultActive, deactivateGiving) {
    if (givingData) {
      if (this.state.blurred === "yes") {
        console.log("We are in a blurred state!");
      } else {
        console.log("Not a blurred state!");
      }
      let informationMessge =
        "We’re proud to say that by participating in our company giving and volunteerism programs, our associates are making a powerful difference in their communities. Please consider supporting your community through our Nationwide Cares campaign and each other through Associates Helping Associates. The Nationwide Foundation will match your contributions dollar-for-dollar, doubling the impact.";
      return givingData.error ? (
        <div>
          <div>
            <h2 className="tile-header">
              <button
                style={
                  givingDefaultActive === "N"
                    ? { display: "inline-block" }
                    : { display: "none" }
                }
                onClick={deactivateGiving}
                className="link"
              >
                <img
                  src="/chevron_up.png"
                  alt="Click to collapse Associate Giving Campaigns"
                  width="19px"
                  style={{ marginRight: "10px" }}
                />
              </button>
              Associate Giving Campaigns
            </h2>
            <div className="tile-blur">
              <button
                className="btn btn-link"
                onClick={this.handleBlurChange}
                title={
                  this.state.blurred === "yes"
                    ? "Click to show"
                    : "Click to hide"
                }
              >
                <img
                  style={{
                    height: "20px",
                    width: "20px"
                  }}
                  alt={this.state.blurred === "yes" ? "Click to show Giving Campaigns" : "Click to hide Giving Campaigns"}
                  src={
                    this.state.blurred === "yes"
                      ? "/eye-blue.png"
                      : "hide-blue.png"
                  }
                />
              </button>
            </div>
            <hr className="tile-line" />
            <div
              className={this.state.blurred === "yes" ? "obfuscate" : ""}
              style={{
                fontSize: "14px",
                paddingLeft: "20px",
                paddingRight: "20px"
              }}
            ></div>
            <div className="tile-apierroricon">
              <div className="portletErrorAlert">!</div>
              {/* <bolt-icon name= "notification-error" label = "error" color = "blue" size = "lg"></bolt-icon> */}
            </div>
            <div className="tile-apierrortext">Oops, this is unexpected.</div>
            <div className="tile-apierrortext1">Error code: 500</div>
            <div className="tile-apierrortext1">
              An error has occurred and we’re working to fix the issue.
            </div>
            <div className="tile-apierrortext1">
              <span>If the issue persists please </span>
              <a
                className="tile-apierrorcontactus"
                href="https://nwproduction.service-now.com/csm"
                rel="noopener noreferrer"
                target="_blank"
              >
                contact us.
              </a>
            </div>
            <div
              className="tile-apierror-refresh"
              onClick={this.handleRefresh}
              title="Refresh"
            >
              Refresh
            </div>
            <div style={{ paddingTop: "25px" }}></div>
          </div>
        </div>
      ) : (
        <div>
          <div>
            <h2 className="tile-header" style={{ paddingRight: "40px" }}>
              <button
                style={givingDefaultActive === "N" ? {} : { display: "none" }}
                onClick={deactivateGiving}
                className="link"
              >
                <img
                  src="/chevron_up.png"
                  alt="click to collapse Associate Giving Campaigns"
                  width="19px"
                  style={{ marginRight: "10px" }}
                />
              </button>
              Associate Giving Campaigns
              <span
                style={{ marginLeft: "6px" }}
                className="infoLink"
                onClick={this.showInformation}
              >
                {this.state.displayInfo ? "X" : "?"}
              </span>
            </h2>

            <div className="tile-blur">
              <button
                className="btn btn-link"
                onClick={this.handleBlurChange}
                title={
                  this.state.blurred === "yes"
                    ? "Click to show"
                    : "Click to hide"
                }
              >
                <img
                  style={{
                    height: "20px",
                    width: "20px"
                  }}
                  alt={this.state.blurred === "yes" ? "click to show Associate Giving Campaigns" : "click to hide Associate Giving Campaigns"}
                  src={
                    this.state.blurred === "yes"
                      ? "/eye-blue.png"
                      : "hide-blue.png"
                  }
                />
              </button>
            </div>
            <hr className="tile-line" />
            <div
              className={this.state.blurred === "yes" ? "obfuscate" : ""}
              style={{
                fontSize: "14px",
                paddingLeft: "20px",
                paddingRight: "20px"
              }}
            >
              {this.renderTile(
                givingData,
                givingDefaultActive,
                deactivateGiving
              )}
            </div>
            <div className="tile-subbox2" style={{ marginTop: "15px" }}>
              <div className="row">
                <div
                  className="tile-rectangle"
                  style={{ marginLeft: "35px", paddingRight: "15px" }}
                >
                  <img
                    className="tile-externallink"
                    alt="external-link"
                    src="/external-link.svg"
                  />
                  <a
                    className="tile-recttitle"
                    href={config.Community_Connect_Link}
                    rel="noopener noreferrer"
                    target="_blank"
                    title="Click - Community Connect link (VPN is required to access)"
                  >
                    Community Connect
                  </a>
                </div>
                <div
                  className="tile-rectangle"
                  style={{ marginLeft: "20px", paddingRight: "15px" }}
                >
                  <img
                    className="tile-externallink"
                    alt="external-link"
                    src="/external-link.svg"
                  />
                  <a
                    className="tile-recttitle"
                    href={config.LearnMoreLink}
                    rel="noopener noreferrer"
                    target="_blank"
                    title="Click - Associate Giving Learn More link"
                  >
                    Learn More
                  </a>
                </div>
              </div>
            </div>
          </div>
          <PortletInfo
            position="-120px"
            message={informationMessge}
            showing={this.state.displayInfo}
            title=""
          />
        </div>
      );
    } else {
      return <Spinner />;
    }
  }

  // renders version of tile if user contributes to one of the three community giving programs
  renderGiver(givingData, givingDefaultActive, deactivateGiving) {
    let community = givingData.data.communityGiving,
      associates = givingData.data.associatesHelpingAssociates

    return (
      <div className="givingContainer row">
        <div className="giveLeftCont">
          <div className="giverHeart"></div>
          <div className="giveOfferText">Our associates have big hearts</div>
          <div className="giveInfoText2">
            Thank you for making a difference.
          </div>
        </div>
        <div className="col">
          <div className="giveBox">
            <div className="giveBoxTopText">YOUR YEAR TO DATE SUPPORT:</div>
            <div className="greyLine"></div>
            <div className="enrolledContainer">
              <div className="row">
                <div
                  className={community === "Y" ? "giverImage" : "nonGiverImage"}
                ></div>
                <div className="giveInfoText3">
                  <b>Nationwide Cares</b>
                </div>
              </div>
              <div className="lineSeparator"></div>
              <div className="row">
                <div
                  className={
                    associates === "Y" ? "giverImage" : "nonGiverImage"
                  }
                ></div>
                <div className="giveInfoText3">
                  <b>Associates Helping Associates</b>
                </div>
              </div>
              <div className="lineSeparator"></div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  // Renders version if user does not contribute to any of the commmunity giving programs.
  // Will display information on each giving program the user can contribute/participate in.
  renderNonGiver(givingData, givingDefaultActive, deactivateGiving) {
    return (
      <div className="givingContainer">
        <div className="giveOfferText">
          Click below if you would like to participate in any Associate Giving
          Campaign.
        </div>
        <div className="giveInfoText">
          <b>Did you know?</b>
          <br></br>
          The Nationwide Foundation matches, dollar-for-dollar, associate
          contributions to maximize the impact to our communities.
        </div>
      </div>
    );
  }

  // Determines which view to render based off giving status
  renderTile(givingData, givingDefaultActive, deactivateGiving) {
    let community = givingData.data.communityGiving,
      associates = givingData.data.associatesHelpingAssociates

    let giver_bool =
      community == "Y" ||
      associates == "Y"
        ? true
        : false;

    if (this.state.blurred === "yes") {
      giver_bool = false;
    }
    // console.log("calc giving ", giver_bool);
    return giver_bool ? (
      <div>
        {this.renderGiver(givingData, givingDefaultActive, deactivateGiving)}
      </div>
    ) : (
      <div>
        {this.renderNonGiver(givingData, givingDefaultActive, deactivateGiving)}
      </div>
    );
  }

  render() {
    const {
      activePortal,
      givingData,
      activateGivingPortal,
      fetchGivingData,
      deactivateGiving,
      givingDefaultActive
    } = this.props;
    if (activePortal === "Y") {
      if (givingData) {
        const content = this.returnContent(
          givingData,
          givingDefaultActive,
          deactivateGiving
        );
        if (givingData.error) {
          return <PortletError content={content} />;
        } else {
          return <Portlet content={content} />;
        }
      } else {
        activateGivingPortal();
        return <PortletLoading title="Associate Giving Campaigns" />;
      }
    } else {
      return (
        <CollapsedPortlet
          dataFetch={fetchGivingData}
          title={"Associate Giving Campaigns"}
        ></CollapsedPortlet>
      );
    }
  }
}

export default Giving;

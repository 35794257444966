import React from "react";

export const PortletInfo = ({
  position,
  message,
  title,
  showing,
  top,
  id,
  upsideDown
}) => {
  if (showing) {
    let topPadding = "0px";
    if (top) {
      topPadding = top;
    }
    return (
      <div
        className={upsideDown ? "portalInfoDown" : "portalInfo"}
        id={id}
        style={
          position
            ? {
                display: "block",
                backgroundPositionX: position,
                marginTop: topPadding
              }
            : {
                display: "block",
                marginTop: topPadding
              }
        }
      >
        <div style={{ fontWeight: "bold" }}>{title}</div>
        {message}
      </div>
    );
  } else {
    return <React.Fragment></React.Fragment>;
  }
};

export const PortletInfoProduct = ({
  position,
  message1,
  message2,
  message3,
  title,
  showing,
  top,
  id,
  upsideDown
}) => {
  if (showing) {
    let topPadding = "0px";
    if (top) {
      topPadding = top;
    }
    return (
      <div
        className={upsideDown ? "portalInfoDown" : "portalInfo"}
        id={id}
        style={
          position
            ? {
                display: "block",
                backgroundPositionX: position,
                marginTop: topPadding
              }
            : {
                display: "block",
                marginTop: topPadding
              }
        }
      >
        <div style={{ fontWeight: "bold" }}>{title}</div>
        {message1}
        <br></br>
        <br></br>
        {message2}
        <br></br>
        <br></br>
        {message3}
      </div>
    );
  } else {
    return <React.Fragment></React.Fragment>;
  }
};

export const PortletBenefitInfo = ({
  position,
  message,
  title,
  showing,
  top
}) => {
  if (showing) {
    let topPadding = "0px";
    if (top) {
      topPadding = top;
    }
    return (
      <div
        className="portalInfo"
        style={{
          display: "block",
          // backgroundPositionX: position,
          marginTop: topPadding,
          marginLeft: position,
          width: "95%"
        }}
      >
        <div style={{ fontWeight: "bold" }}>{title}</div>
        {message}
      </div>
    );
  } else {
    return <React.Fragment></React.Fragment>;
  }
};

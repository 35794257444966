import React, { Component } from "react";
import { isBrowser, isMobile } from "react-device-detect";
import Announcements from "../common/announcements";
import LinksBar from "../common/linksbar";
import Payslip from "../components/Payslip";
import Timeoff from "../components/Timeoff";
import AWS from "../components/aws";
import BENEFITS from "../components/benefits";
import ErrorBoundary from "../components/errorBoundary";
import FOW from "../components/fow";
import Jobs from "../components/jobs";
import { NewHires } from "../components/newHires";
import NWSavings from "../components/nwSavings";
import PortletAdministration from "../components/portletAdministration";
import GIVING from "./Giving";
import HSA from "./HSA";
import PRODUCT from "./Product";
import MYHEALTH from "./myHealth";
// import Footer from "../common/footer";

class DefaultPage extends Component {
  renderPortlet = (columnNumber, orderNumber, expanded) => {
    const {
      portlets,
      populatePayslipPortal,
      payslipData,
      payslipPortalActive,
      payslipDefaultActive,
      fetchPayslipData,
      deactivatePayslip,
      activateTimeOffPortal,
      fetchTimeOffData,
      timeoffData,
      timeoffPortalActive,
      deactivateTimeOff,
      timeoffDefaultActive,
      jobData,
      populateJobsPortal,
      jobPortalActive,
      jobDefaultActive,
      fetchJobsData,
      deactivateJobs,
      savingsData,
      savingsPortalActive,
      populateSavingsPortal,
      savingsDefaultActive,
      fetchSavingsData,
      deactivateSavings,
      awsData,
      awsPortalActive,
      deactivateAws,
      awsDefaultActive,
      activateAwsPortal,
      fetchAwsData,
      fowData,
      fowPortalActive,
      fowDefaultActive,
      deactivateFow,
      populateFOWPortal,
      fetchFowData,
      MyHealthData,
      MyHealthPortalActive,
      populateMyHealthPortal,
      MyHealthDefaultActive,
      deactivateMyHealth,
      fetchMyHealth,
      handlePayEndDateChange,
      payEndDate,
      benefitsData,
      benefitsPortalActive,
      benefitsDefaultActive,
      populateBenefitsPortal,
      deactivateBenefits,
      fetchBenefits,
      givingData,
      givingPortalActive,
      givingDefaultActive,
      activateGivingPortal,
      deactivateGiving,
      fetchGivingData,
      productData,
      productPortalActive,
      productDefaultActive,
      activateProductPortal,
      deactivateProduct,
      fetchProductData,
      hsaData,
      hsaDefaultActive,
      hsaPortalActive,
      activateHSAPortal,
      deactivateHSA,
      fetchHSAData
    } = this.props;

    let portletName = "";

    //PLACE ACTIVES
    for (let i = 0; i < portlets.length; i++) {
      const isOn = portlets[i].active;
      const col = portlets[i].colNumber;
      const ord = portlets[i].orderNumber;

      if (
        isOn === "Y" &&
        col === columnNumber &&
        ord === orderNumber &&
        expanded
      ) {
        portletName = portlets[i].static_name;
        // console.log(
        //   "DefaultPage.renderPortlet expanded portletName",
        //   portletName
        // );

        break;
      }
      if (
        isOn === "N" &&
        col === columnNumber &&
        ord === orderNumber &&
        !expanded
      ) {
        portletName = portlets[i].static_name;
        // console.log(
        //   "DefaultPage.renderPortlet collapsed portletName",
        //   portletName
        // );
        break;
      }
    }
    switch (portletName) {
      case "PAYSLIP":
        return (
          <div className="pSubColumn">
            <Payslip
              populatePayslipPortal={populatePayslipPortal}
              payslipData={payslipData}
              activePortal={payslipPortalActive}
              payslipDefaultActive={payslipDefaultActive}
              fetchPayslipData={fetchPayslipData}
              deactivatePayslip={deactivatePayslip}
              activateTimeOffPortal={activateTimeOffPortal}
            />
          </div>
        );
      case "TIMEOFF":
        return (
          <div className="pSubColumn">
            <Timeoff
              activateTimeOffPortal={activateTimeOffPortal}
              timeoffData={timeoffData}
              activePortal={timeoffPortalActive}
              handlePayEndDateChange={handlePayEndDateChange}
              payEndDate={payEndDate}
              fetchTimeOffData={fetchTimeOffData}
              deactivateTimeOff={deactivateTimeOff}
              timeoffDefaultActive={timeoffDefaultActive}
            />
          </div>
        );
      case "JOBS":
        return (
          <div className="pSubColumn">
            <Jobs
              populateJobsPortal={populateJobsPortal}
              jobData={jobData}
              activePortal={jobPortalActive}
              jobDefaultActive={jobDefaultActive}
              fetchJobsData={fetchJobsData}
              deactivateJobs={deactivateJobs}
            />
          </div>
        );
      case "NSP":
        return (
          <div className="pSubColumn">
            <NWSavings
              savingsData={savingsData}
              activePortal={savingsPortalActive}
              populateSavingsPortal={populateSavingsPortal}
              savingsDefaultActive={savingsDefaultActive}
              fetchSavingsData={fetchSavingsData}
              deactivateSavings={deactivateSavings}
            />
          </div>
        );
      case "AWS":
        return (
          <div className="pSubColumn">
            <AWS
              activateAwsPortal={activateAwsPortal}
              fetchAwsData={fetchAwsData}
              awsData={awsData}
              activePortal={awsPortalActive}
              deactivateAws={deactivateAws}
              awsDefaultActive={awsDefaultActive}
            />
          </div>
        );
      case "FOW":
        return (
          <div className="pSubColumn">
            <FOW
              populateFOWPortal={populateFOWPortal}
              fowData={fowData}
              activePortal={fowPortalActive}
              fowDefaultActive={fowDefaultActive}
              deactivateFow={deactivateFow}
              fetchFowData={fetchFowData}
            />
          </div>
        );
      case "MYHEALTH":
        return (
          <div className="pSubColumn">
            <MYHEALTH
              populateMyHealthPortal={populateMyHealthPortal}
              MyHealthData={MyHealthData}
              activePortal={MyHealthPortalActive}
              MyHealthDefaultActive={MyHealthDefaultActive}
              deactivateMyHealth={deactivateMyHealth}
              fetchMyHealth={fetchMyHealth}
            />
          </div>
        );
      case "BENEFITS":
        return (
          <div className="pSubColumn">
            <BENEFITS
              benefitsData={benefitsData}
              activePortal={benefitsPortalActive}
              benefitsDefaultActive={benefitsDefaultActive}
              populateBenefitsPortal={populateBenefitsPortal}
              deactivateBenefits={deactivateBenefits}
              fetchBenefits={fetchBenefits}
            />
          </div>
        );
      case "GIVING":
        // console.log("Giving Found");
        return (
          <div className="pSubColumn">
            <GIVING
              activateGivingPortal={activateGivingPortal}
              givingData={givingData}
              activePortal={givingPortalActive}
              fetchGivingData={fetchGivingData}
              deactivateGiving={deactivateGiving}
              givingDefaultActive={givingDefaultActive}
            />
          </div>
        );
      case "PRODUCT":
        return (
          <div className="pSubColumn">
            <PRODUCT
              productData={productData}
              activePortal={productPortalActive}
              productDefaultActive={productDefaultActive}
              activateProductPortal={activateProductPortal}
              deactivateProduct={deactivateProduct}
              fetchProductData={fetchProductData}
            />
          </div>
        );
      case "HSA":
        return (
          <div className="pSubColumn">
            <HSA
              data={hsaData}
              defaultActive={hsaDefaultActive}
              activePortal={hsaPortalActive}
              activatePortal={activateHSAPortal}
              deactivatePortal={deactivateHSA}
              retrieveData={fetchHSAData}
            />
          </div>
        );
      default:
    }
  };

  handleNoData = () => {
    sessionStorage.clear();
    window.location.reload();
    // this.props.retrievePortals();
  };
  render() {
    const {
      portlets,
      showPortletSelector,
      onChangePortletDisplay,
      portletUpdate,
      displayNewHires,
      newHires,
      locations,
      selectedLocation,
      locationFilter,
      departments,
      managers,
      jobTitles,
      hireDates,
      populateNewHires
    } = this.props;

    if (!portlets) {
      return (
        <div>
          <NewHires
            newHires={newHires}
            locations={locations}
            departments={departments}
            hireDates={hireDates}
            jobTitles={jobTitles}
            selectedLocation={selectedLocation}
            locationFilter={locationFilter}
            // addRemoveDepartmentFilter={idx =>
            //   this.props.addRemoveDepartmentFilter(idx)
            // }
            managers={managers}
            // changeDepartmentDisplay={idx =>
            //   this.props.changeDepartmentDisplay(idx)
            // }
            addRemoveManagerFilter={idx =>
              this.props.addRemoveManagerFilter(idx)
            }
            setAllManagers={show => this.props.setAllManagers(show)}
            handleNewHireDisplay={this.props.handleNewHireDisplay}
            addRemoveLocationFilter={idx =>
              this.props.addRemoveLocationFilter(idx)
            }
            // setAllLocations={show => this.props.setAllLocations(show)}
            // setAllDepartments={show => this.props.setAllDepartments(show)}
            // setAllHireDates={show => this.props.setAllHireDates(show)}
            // setAllJobTitles={show => this.props.setAllJobTitles(show)}
            // addRemoveJobTitleFilter={idx =>
            //   this.props.addRemoveJobTitleFilter(idx)
            // }
            // addRemoveHireDateFilter={idx =>
            //   this.props.addRemoveHireDateFilter(idx)
            // }
            filterNewHires={this.props.filterNewHires}
            displayNewHires={displayNewHires}
            populateNewHires={refresh => this.props.populateNewHires(refresh)}
          />
          <Announcements />
          Unable to retrieve portlets for user.
          <button className="btn btn-link" onClick={this.handleNoData}>
            Retry
          </button>
          <LinksBar />
        </div>
      );
    }

    // let noPortlets = true;
    // for (let i = 0; i < portlets.length; i++) {
    //   if (portlets[i].active === "Y") {
    //     noPortlets = false;
    //     break;
    //   }
    // }
    const noPortlets = false;
    return (
      <React.Fragment>
        <NewHires
          newHires={newHires}
          locations={locations}
          selectedLocation={selectedLocation}
          locationFilter={locationFilter}
          departments={departments}
          hireDates={hireDates}
          jobTitles={jobTitles}
          addRemoveDepartmentFilter={idx =>
            this.props.addRemoveDepartmentFilter(idx)
          }
          managers={managers}
          changeDepartmentDisplay={idx =>
            this.props.changeDepartmentDisplay(idx)
          }
          addRemoveManagerFilter={idx => this.props.addRemoveManagerFilter(idx)}
          setAllManagers={show => this.props.setAllManagers(show)}
          handleNewHireDisplay={this.props.handleNewHireDisplay}
          addRemoveLocationFilter={idx =>
            this.props.addRemoveLocationFilter(idx)
          }
          setAllLocations={show => this.props.setAllLocations(show)}
          setAllDepartments={show => this.props.setAllDepartments(show)}
          setAllHireDates={show => this.props.setAllHireDates(show)}
          setAllJobTitles={show => this.props.setAllJobTitles(show)}
          addRemoveJobTitleFilter={idx =>
            this.props.addRemoveJobTitleFilter(idx)
          }
          addRemoveHireDateFilter={idx =>
            this.props.addRemoveHireDateFilter(idx)
          }
          filterNewHires={this.props.filterNewHires}
          displayNewHires={displayNewHires}
          populateNewHires={refresh => this.props.populateNewHires(refresh)}
        />
        <div
          id="topHalf"
          style={displayNewHires && isMobile ? { display: "none" } : {}}
        >
          <Announcements />
          <LinksBar />
        </div>
        {/* <Announcements />
        <LinksBar /> */}
        <div className="portalsbar">
          <div className="container">
            <div className="row">
              <div className="pColumn">
                {isBrowser ? (
                  <div
                    className="pSubColumn hideMobile"
                    style={noPortlets ? { display: "none" } : {}}
                  >
                    <div
                      className="Tile"
                      style={{ color: "31C57A5", fontSize: "14px" }}
                    >
                      <button
                        className="btn btn-link "
                        style={{ color: "#1c57a5" }}
                        id="customizeButton"
                        onClick={onChangePortletDisplay}
                      >
                        <img src="gear.png" alt=""></img> Customize my dashboard
                      </button>
                    </div>
                  </div>
                ) : (
                  <div></div>
                )}

                {this.renderPortlet(1, 1, true)}
                {this.renderPortlet(1, 2, true)}
                {this.renderPortlet(1, 3, true)}
                {this.renderPortlet(1, 1, false)}
                {this.renderPortlet(1, 2, false)}
                {this.renderPortlet(1, 3, false)}
                {this.renderPortlet(1, 4, false)}
                {this.renderPortlet(1, 5, false)}
                {this.renderPortlet(1, 6, false)}
              </div>
              <div style={{ width: "40px", display: "inline-block" }}></div>
              <div className="pColumn">
                {this.renderPortlet(2, 1, true)}
                {this.renderPortlet(2, 2, true)}
                {this.renderPortlet(2, 3, true)}
                {this.renderPortlet(2, 1, false)}
                {this.renderPortlet(2, 2, false)}
                {this.renderPortlet(2, 3, false)}
                {this.renderPortlet(2, 4, false)}
                {this.renderPortlet(2, 5, false)}
                {this.renderPortlet(2, 6, false)}
              </div>
            </div>

            {/* {portlets && portlets.length > 0 ? (
              <div
                className="row"
                style={
                  noPortlets
                    ? {
                        color: "#1C57A5",
                        fontSize: "18px",
                        fontWeight: "bold"
                      }
                    : { display: "none" }
                }
              >
                <div className="col" style={{ textAlign: "center" }}>
                  There are currently no visible tiles on your dashboard. Click
                  below to add tiles to your page.
                  <div className="Tile">
                    <button
                      className="btn btn-link"
                      onClick={onChangePortletDisplay}
                    >
                      <img src="gear.png"></img> Customize my dashboard
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              <span />
            )} */}
          </div>
        </div>

        <ErrorBoundary>
          <div>
            <PortletAdministration
              portlets={portlets}
              showPortletSelector={showPortletSelector}
              onChangePortletDisplay={onChangePortletDisplay}
              portletUpdate={portletUpdate}
            />
          </div>
        </ErrorBoundary>

        {/* <Footer showAnnounceList={showAnnounceList} isAdmin={isAdmin} /> */}
      </React.Fragment>
    );
  }
}

export default DefaultPage;

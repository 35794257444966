import config from "../config.json";
import { getRandomString } from "./AuthService";
import { getEnvironment } from "./EnvironmentVariable";
import { isMobile } from "react-device-detect";

export const getFOW = async () => {
  //fowAPI
  const environ = getEnvironment();
  let fowAPI = config.fowAPI;
  let fowKey = config.fowKey;
  console.log("fowService.getFOW environ", environ);
  if (environ !== "DEFAULT") {
    //We are not in production environment
    //
    if (isMobile) {
      //However we are testing on a mobile device so use the Mobile api
      fowAPI = config.Mobile_test_fowAPI;
    } else {
      //We are not testing on a mobile device, so we will use the test API
      fowAPI = config.test_fowAPI;
      fowKey = config.test_fowKey;
    }
  }
  const userInfo = JSON.parse(sessionStorage.getItem("userinfo"));
  const shortName = userInfo.shortName
    ? userInfo.shortName.toUpperCase()
    : userInfo.username.toUpperCase();
  const message = getRandomString(25);
  const token = sessionStorage.getItem("access_token");
  let headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("Authorization", "Bearer " + token);
  headers.append("X-NW-Message-ID", message);
  headers.set("client_id", fowKey);
  console.log("fowService.getFOW  myHealthURL", fowAPI);
  const body = {
    shortName: shortName
  };
  let data = null;
  let today = new Date();
  //Default the fowYear value to the year from the current date.
  let fowYear = today.getFullYear();
  console.log("fowService.getFOW default year", fowYear);
  try {
    const response = await fetch(fowAPI, {
      method: "post",
      headers: headers,
      body: JSON.stringify(body)
    });
    if (response.ok) {
      try {
        data = await response.json();
      } catch (e) {
        //Response status was OK, but we got an error getting the json data
        console.log("fowService.getFOW  error", e);
        return { fowYear, error: e };
      }
    }
  } catch (E) {
    //There was an error calling the API
    console.log("fowService.getFOW  error", E);
    return { fowYear, error: E };
  }

  // data = {
  //   totalFoWHours: 7.866667,
  //   hoursLIALearning: 3.116667,
  //   FoWCompleted: 0.889583,
  //   LIACompleted: 0.779167,
  //   hoursDigitalLearning: 4.75,
  //   digitalCompleted: 1,
  //   hireDate: "2004-12-31",
  //   fowYear: 2021
  // };

  if (!data) {
    //No data was returned
    console.log("fowService.getFOW - no data returned");
    return { fowYear, error: "No data returned" };
  }
  if (data.userMessage) {
    //There is an error message in the data that was returned
    return { fowYear, error: data.userMessage };
  }
  //Get year from the data
  if (data.fowYear) {
    fowYear = parseInt(data.fowYear);
    console.log("fowService.getFOW set Year from fowYear", fowYear);
  }

  const hDate = data.hireDate;
  const arydt = hDate.split("-");
  //create the hireDate for the associatefrom the string date (yyyy-mm=dd).
  const hireDate = new Date(
    parseInt(arydt[0]),
    parseInt(arydt[1]) - 1,
    parseInt(arydt[2])
  );

  //Create the newHireDate -- used to determine if the user is considered a new hire.
  //New hire date is June 1st of the fowYear
  let newHireDate = new Date();
  newHireDate.setMonth(6);
  newHireDate.setDate(1);
  newHireDate.setFullYear(fowYear);

  //Create the notNeededHireDate -- used to determine if the user doesn't have to compelte FOW this fowYear
  let notNeededHireDate = new Date();
  notNeededHireDate.setMonth(10);
  notNeededHireDate.setDate(2);
  notNeededHireDate.setFullYear(fowYear);

  //calculated how many minutes of fow are required based on whether the user is a new hire
  const target = hireDate >= newHireDate ? 120 : 240;
  //create newHire.  True if the user hireDate is on or after the new hired cuttoff date.
  const newHire = hireDate >= newHireDate;
  //create required.  True if the user hireDate is prior to the notNeededHireDate
  const required = hireDate < notNeededHireDate;
  //create noFowRequired.  True if the user hireDate is on or after the notNeededHireDate
  const noFowRequired = hireDate >= notNeededHireDate;
  //create leadMinutes.   This is the number of minutes of lead learning completed by the user.
  let leadMinutes = parseFloat(data.hoursLIALearning) * 60;
  if (leadMinutes > target) {
    leadMinutes = target;
  }
  //create devMinutes.   This is the number of minutes of DEV learning completed by the user.
  let devMinutes = parseFloat(data.hoursDigitalLearning) * 60;
  if (devMinutes > target) {
    devMinutes = target;
  }

  let digitalCompletion = 0;
  //WORKDAY APPEARS TO ALWAYS ROUND UP, SO USE THIS CALCULATION
  // digitalCompletion = Math.ceil((devMinutes / target) * 10000) / 100;
  // if (digitalCompletion > 100) {
  //   digitalCompletion = 100;
  // }
  if (data.digitalCompleted) {
    digitalCompletion =
      parseInt(parseFloat(data.digitalCompleted) * 10000) / 100;
  }
  if (digitalCompletion > 100) {
    digitalCompletion = 100;
  }

  let leadCompletion = 0;
  //WORKDAY APPEARS TO ALWAYS ROUND UP, SO USE THIS CALCULATION
  // leadCompletion = Math.ceil((leadMinutes / target) * 10000) / 100;
  if (data.LIACompleted) {
    leadCompletion = parseInt(parseFloat(data.LIACompleted) * 10000) / 100;
  }
  if (leadCompletion > 100) {
    leadCompletion = 100;
  }

  let overall = 0;
  //WORKDAY APPEARS TO ALWAYS ROUND UP, SO USE THIS CALCULATION
  // overall =
  //   Math.ceil(((leadMinutes + devMinutes) / (target * 2)) * 10000) / 100;

  if (data.FoWCompleted) {
    overall = parseInt(parseFloat(data.FoWCompleted) * 10000) / 100;
  }
  if (overall > 100) {
    overall = 100;
  }

  // console.log(
  //   "digitalCompletion",
  //   digitalCompletion,
  //   Math.ceil(parseFloat(data.digitalCompleted) * 10000) / 100
  // );
  // console.log(
  //   "leadCompletion",
  //   leadCompletion,
  //   Math.ceil(parseFloat(data.LIACompleted) * 10000) / 100
  // );
  // console.log(
  //   "overall",
  //   overall,
  //   Math.ceil(parseFloat(data.FoWCompleted) * 10000) / 100
  // );

  const fow = {
    fowYear,
    noFowRequired,
    hireDate,
    required,
    newHire,
    devMinutes,
    leadMinutes,
    target,
    overall,
    digitalCompletion,
    leadCompletion,
    error: null,
    ...data
  };

  return fow;
};

import React, { Component } from "react";
import {
    CollapsedPortlet,
    PortletLoading,
    WorkdayPortlets
} from "../common/portlet";
import Spinner from "../common/spinner";
// import { toast } from "react-toastify";
import "@nationwide-bolt/css-core/dist/css/bolt-core.css";
import "react-toastify/dist/ReactToastify.css";
import "../main.css";

// import Slider from "../common/slider";
class Jobs extends Component {
  state = {
    blurred: "no"
  };

  handleBlurChange = () => {
    if (this.state.blurred === "yes") {
      this.setState({ blurred: "no" });
    } else {
      this.setState({ blurred: "yes" });
    }
  };

  handleRefresh = async () => {
    this.props.populateJobsPortal(true);
    // toast.success("Your Time has been refreshed");
  };

  returnContent(jobData, jobDefaultActive, deactivateJobs) {
    console.log("jobs.returnContent jobData", jobData);
    if (jobData) {
      let openJobs = "";
      let activeapps = "";
      const state = jobData.state;
      if (this.state.blurred === "yes") {
        if (jobData.openJobs === -9999) {
          openJobs = -9999;
        } else {
          openJobs = "####";
        }
        if (jobData.activeapps === -9999) {
          activeapps = -9999;
        } else {
          activeapps = "####";
        }
      } else {
        openJobs = jobData.openJobs;
        activeapps = jobData.activeapps;
      }
      return jobData.openJobs === -9999 && jobData.activeapps === -9999 ? (
        <div>
          <div className="tile-apierroricon">
            <div className="portletErrorAlert">!</div>
            {/* <bolt-icon name= "notification-error" label = "error" color = "red" size = "lg"></bolt-icon> */}
          </div>
          <div className="tile-apierrortext">Oops, this is unexpected.</div>
          <div className="tile-apierrortext1">Error code: 500</div>
          <div className="tile-apierrortext1">
            An error has occurred and we’re working to fix the issue.
          </div>
          <div className="tile-apierrortext1">
            <span>If the issue persists please </span>
            <a
              className="tile-apierrorcontactus"
              href="https://nwproduction.service-now.com/csm"
              rel="noopener noreferrer"
              target="_blank"
            >
              contact us.
            </a>
          </div>
          <div
            className="tile-apierror-refresh"
            onClick={this.handleRefresh}
            title="Refresh"
          >
            Refresh
          </div>
          <div style={{ paddingTop: "25px" }}></div>
        </div>
      ) : (
        <div>
          <h2 className="tile-header">
            <button
              style={
                jobDefaultActive === "N"
                  ? { display: "inline-block" }
                  : { display: "none" }
              }
              onClick={deactivateJobs}
              className="link"
            >
              <img
                src="/chevron_up.png"
                alt="Click to collapse Job info"
                width="19px"
                style={{ marginRight: "10px" }}
              />
            </button>
            Jobs
          </h2>
          <div className="tile-blur">
            <button
              className="btn btn-link"
              onClick={this.handleBlurChange}
              title={
                this.state.blurred === "yes" ? "Click to show" : "Click to hide"
              }
            >
              <img
                style={{
                  height: "20px",
                  width: "20px"
                }}
                alt={this.state.blurred === "yes" ? "click to show jobs" : "click to hide jobs"}
                src={
                  this.state.blurred === "yes"
                    ? "/eye-blue.png"
                    : "hide-blue.png"
                }
              />
            </button>
          </div>
          <hr className="tile-line" />
          <div className={this.state.blurred === "yes" ? "obfuscate" : ""}>
            <div className="container-fluid">
              <div className="row">
                <div className="col jobsColumn">
                  {state === "" ? (
                    <span
                      className="tile-title"
                      style={{ paddingLeft: "10px" }}
                    >
                      All Open Jobs{" "}
                    </span>
                  ) : (
                    <span
                      className="tile-title"
                      style={{ paddingLeft: "10px" }}
                    >
                      All Open Jobs in {state}
                    </span>
                  )}
                  <div>
                    {openJobs === -9999 ? (
                      <div className="awsDataError">
                        <div className="row">
                          <div
                            className="col col-sm-1"
                            style={{ paddingTop: "10px" }}
                          >
                            <div className="smallPortletErrorAlert">!</div>
                          </div>
                          <div className="col ">
                            <div
                              style={{
                                color: "#D32426",
                                fontSize: "12px",
                                fontWeight: "normal"
                              }}
                            >
                              An error occurred.
                              <div
                                className="tile-apierror-refresh"
                                style={{ paddingTop: "0px", textAlign: "left" }}
                                onClick={this.handleRefresh}
                                title="Refresh"
                              >
                                Refresh
                              </div>
                            </div>
                          </div>
                          <div style={{ paddingLeft: "40px" }}></div>
                        </div>
                      </div>
                    ) : (
                      <div>
                        <span
                          className="tile-values openJobsValue"
                          // style={{
                          //   fontSize: "25px",
                          //   marginLeft: "10px",
                          //   paddingTop: "10px"
                          // }}
                        >
                          {openJobs}
                        </span>
                      </div>
                    )}
                  </div>
                </div>
                <div className="col jobsColumn">
                  <span className="tile-title">My Active Applications</span>
                  <div>
                    {activeapps === -9999 ? (
                      <div className="awsDataError">
                        <div className="row" style={{ marginLeft: "-10px" }}>
                          <div
                            className="col col-sm-1"
                            style={{ paddingTop: "10px" }}
                          >
                            <div className="smallPortletErrorAlert">!</div>
                          </div>
                          <div className="col ">
                            <div
                              style={{
                                color: "#D32426",
                                fontSize: "12px",
                                fontWeight: "normal"
                              }}
                            >
                              An error occurred.
                              <div
                                className="tile-apierror-refresh"
                                style={{ paddingTop: "0px", textAlign: "left" }}
                                onClick={this.handleRefresh}
                                title="Refresh"
                              >
                                Refresh
                              </div>
                            </div>
                          </div>
                          <div style={{ paddingLeft: "40px" }}></div>
                        </div>
                      </div>
                    ) : (
                      <div>
                        <span
                          className="tile-values activeAppsValue"
                          // style={{
                          //   fontSize: "25px",
                          //   marginLeft: "10px",
                          //   paddingTop: "10px"
                          // }}
                        >
                          {activeapps}
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {/* <div className="row"> */}
              {/* {openJobs === -9999 ? (
                  <div className="awsDataError">
                    <div className="row">
                      <div
                        className="col col-sm-1"
                        style={{ paddingTop: "10px" }}
                      >
                        <div className="smallPortletErrorAlert">!</div>
                      </div>
                      <div className="col jobsColumn">
                        <div
                          style={{
                            color: "#D32426",
                            fontSize: "12px",
                            fontWeight: "normal"
                          }}
                        >
                          An error occurred.
                          <div
                            className="tile-apierror-refresh"
                            style={{ paddingTop: "0px", textAlign: "left" }}
                            onClick={this.handleRefresh}
                            title="Refresh"
                          >
                            Refresh
                          </div>
                        </div>
                      </div>
                      <div style={{ paddingLeft: "40px" }}></div>
                    </div>
                  </div>
                ) : (
                  <div className="col jobsColumn">
                    <span
                      className="tile-values"
                      style={{
                        fontSize: "25px",
                        marginLeft: "10px",
                        paddingTop: "10px"
                      }}
                    >
                      {openJobs}
                    </span>
                  </div>
                )} */}
              {/* {activeapps === -9999 ? (
                  <div className="awsDataError">
                    <div className="row" style={{ marginLeft: "-10px" }}>
                      <div
                        className="col col-sm-1"
                        style={{ paddingTop: "10px" }}
                      >
                        <div className="smallPortletErrorAlert">!</div>
                      </div>
                      <div className="col jobsColumn">
                        <div
                          style={{
                            color: "#D32426",
                            fontSize: "12px",
                            fontWeight: "normal"
                          }}
                        >
                          An error occurred.
                          <div
                            className="tile-apierror-refresh"
                            style={{ paddingTop: "0px", textAlign: "left" }}
                            onClick={this.handleRefresh}
                            title="Refresh"
                          >
                            Refresh
                          </div>
                        </div>
                      </div>
                      <div style={{ paddingLeft: "40px" }}></div>
                    </div>
                  </div>
                ) : (
                  <div className="col jobsColumn">
                    <span
                      className="tile-values"
                      style={{ fontSize: "25px", paddingTop: "10px" }}
                    >
                      {activeapps}
                    </span>
                  </div>
                )} */}
              {/* </div> */}
            </div>
          </div>
          <div className="tile-subbox" style={{ marginTop: "15px" }}>
            <div className="row">
              <div className="col tileThreecolumn">
                <div
                  className="tile-rectangle jobsButtonContainer1"
                  // style={{ marginLeft: "10px", width: "115px", height: "38px" }}
                >
                  <img
                    className="tile-externallink"
                    alt="external-link"
                    src="/external-link.svg"
                  />
                  <a
                    className="tile-recttitle"
                    href="https://www.myworkday.com/nationwide/d/task/1422$961.htmld"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Find Jobs
                  </a>
                </div>
              </div>
              <div className="col tileThreecolumn">
                <div
                  className="tile-rectangle jobsButtonContainer2"
                  // style={{
                  //   marginLeft: "18px",
                  //   width: "141px",
                  //   height: "38px"
                  // }}
                >
                  <img
                    className="tile-externallink"
                    alt="external-link"
                    src="/external-link.svg"
                  />
                  <a
                    className="tile-recttitle"
                    href="https://www.myworkday.com/nationwide/d/task/2998$39218.htmld"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    My Job Alerts
                  </a>
                </div>
              </div>
              <div className="col tileThreecolumn">
                <div
                  className="tile-rectangle jobsButtonContainer3"
                  // style={{ marginLeft: "10px", width: "133px", height: "38px" }}
                >
                  <img
                    className="tile-externallink"
                    alt="external-link"
                    src="/external-link.svg"
                  />
                  <a
                    className="tile-recttitle"
                    href="https://www.myworkday.com/nationwide/d/task/1422$2482.htmld"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    My Referrals
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div style={{ paddingTop: "8px" }}></div>
        </div>
      );
    } else {
      return <Spinner />;
    }
  }

  render() {
    const {
      jobData,
      activePortal,
      fetchJobsData,
      jobDefaultActive,
      deactivateJobs
    } = this.props;

    if (activePortal === "Y") {
      if (jobData) {
        const content = this.returnContent(
          jobData,
          jobDefaultActive,
          deactivateJobs
        );
        return <WorkdayPortlets content={content} />;
      } else {
        this.props.populateJobsPortal();
        return <PortletLoading title="Jobs" />;
      }
    } else {
      return (
        <CollapsedPortlet
          dataFetch={fetchJobsData}
          title="Jobs"
        ></CollapsedPortlet>
      );
    }
  }
}

export default Jobs;

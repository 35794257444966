import config from "../config.json";
import { getRandomString } from "../services/AuthService";
import { getEnvironment } from "./EnvironmentVariable";
// import productTest from "../product_dataTest.json";
import products from "../products.json";

export const lookupCustomer = async () => {
  const environ = getEnvironment();

  // Set API Endpoints
  let lookupAPI = config.ECIF_LookupAPI;
  let portalKey = config.ECIF_key;
  if (environ !== "DEFAULT") {
    portalKey = config.ECIF_test_key;
    lookupAPI = config.ECIF_test_LookupAPI;
  }

  // Temp test person lookup via name

  const userInfo = JSON.parse(sessionStorage.getItem("userinfo"));
  const message = getRandomString(25);
  const token = sessionStorage.getItem("access_token");

  let headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("Authorization", "Bearer " + token);
  headers.append("X-NW-Message-ID", message);
  headers.set("client_id", portalKey);

  const body = JSON.stringify({ shortName: userInfo.shortName });

  let jsondata = null;

  try {
    const response = await fetch(lookupAPI, {
      method: "post",
      headers: headers,
      body: body
    });
    if (response.ok) {
      try {
        jsondata = await response.json();
      } catch (e) {
        console.log("getProductData error", e);
        return { error: "There was a problem with the product service" };
      }
    }
  } catch (e1) {
    console.log("getProductData error", e1);
    return { error: e1 };
  }

  // Determine ECN
  try {
    console.log("Search Results product", jsondata);
    let search1 = jsondata.Search1;

    let search2 = jsondata.Search2;
    let search = search1.length > 1 ? search2 : search1;

    try {
      if (search.messages[0].userMessage) {
        // No customer
        return "None";
      }
    } catch (z) {
      try {
        return search[0].enterpriseCustomerNumber;
      } catch (y) {
        return "None";
      }
    }

    return search[0].enterpriseCustomerNumber;
  } catch (err) {
    console.log("getProductData error");
    return { error: err };
  }
};

export const getProductData = async () => {
  const environ = getEnvironment();

  // Set API Endpoints

  let portalApi = config.ECIF_API;
  let portalKey = config.ECIF_key;
  if (environ !== "DEFAULT") {
    portalApi = config.ECIF_test_API;
    portalKey = config.ECIF_test_key;
  }

  let ECN = await lookupCustomer();

  if (ECN.error) {
    return ECN;
  }

  console.log("customer resp 1", ECN);
  if (ECN !== "None") {
    const userInfo = JSON.parse(sessionStorage.getItem("userinfo"));
    const message = getRandomString(25);
    const token = sessionStorage.getItem("access_token");

    let headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Authorization", "Bearer " + token);
    headers.append("X-NW-Message-ID", message);
    headers.set("client_id", portalKey);

    const body = JSON.stringify({ shortName: userInfo.shortName, ecn: ECN });
    let jsondata = null;
    try {
      const response = await fetch(portalApi, {
        method: "post",
        headers: headers,
        body: body
      });
      if (response.ok) {
        try {
          jsondata = await response.json();
        } catch (e) {
          console.log("getProductData error", e);
          return { error: e };
        }
      }
    } catch (e1) {
      console.log("getProductData error", e1);
      return { error: e1 };
    }

    // Temp test file
    let resp = jsondata;
    // let resp = productTest.customerRoles;
    console.log("Customer Resp ", resp);
    let output = new Map();

    for (let x = 0; x < resp.length; x++) {
      let node = resp[x].agreement;
      // Determines if the agreement is active
      if (node.agreementStatus === "Active") {
        // Parse out active agreements and determines if they are a product type that is in scope
        // Auto
        let pc = node.businessOrgUnit === products.PrivateClient ? true : false;
        if (products.Auto_Types.includes(node.productType) && !pc) {
          output.set("Auto", "Auto");
        }
        // Homeowners
        if (products.Homeowners_Types.includes(node.productType) && !pc) {
          output.set("Homeowners", "Homeowners");
        }
        // Condo
        if (products.Condo_Types.includes(node.productType) && !pc) {
          output.set("Condo", "Condo");
        }
        // Renters
        if (products.Renters_Types.includes(node.productType) && !pc) {
          output.set("Renters", "Renters");
        }
        // Boat
        if (products.Boat_Types.includes(node.productType) && !pc) {
          // if (node.productType === "Boat") {
          output.set("Boat", "Boat");
        }
        // Motorcycle
        if (products.Motorcycle_Types.includes(node.productType) && !pc) {
          output.set("Motorcycle", "Motorcycle");
        }
        // Life
        if (products.Life_Line.includes(node.lineOfBusiness) && !pc) {
          output.set("Life", "Life");
        }
        // Pet
        if (products.Pet_Line.includes(node.lineOfBusiness) && !pc) {
          output.set("Pet", "Pet");
        }
        // Umbrella
        if (products.Umbrella_Line.includes(node.lineOfBusiness) && !pc) {
          output.set("Umbrella", "Umbrella");
        }
        // RV
        if (products.RV_Types.includes(node.productType) && !pc) {
          output.set("RV", "RV");
        }
        if (pc) {
          output.set("Private Client", "Private Client");
        }
        // Annuities
        if ((products.Annuities.includes(node.productType) || products.Annuities.includes(node.lineOfBusiness)) && !pc){
          output.set("Annuities", "Annuities");
        }
      }
    }

    let outArray = [];
    let i = 0;
    for (let value of output.values()) {
      outArray[i] = value;
      i++;
    }
    console.log("Map of Accurate: ", outArray);
    return outArray;
  }
  return [];
};

import React, { Component } from "react";
import { getAnnouncement } from "../services/announcementService";
import Spinner from "../common/spinner";

class DisplayAnnouncement extends Component {
  state = { announcement: null };

  async componentDidMount() {
    const id = this.props.match.params.id;
    const announcement = await getAnnouncement(id);
    this.setState({ announcement });
  }

  renderAnnouncement() {
    const { announcement } = this.state;

    if (announcement) {
      if (announcement.messages && announcement.messages.length > 0) {
        return (
          <div>
            <img src="/noimage.jpg" alt="Not found" />
          </div>
        );
      } else {
        const base64Image =
          "data:" + announcement.contentType + ";base64," + announcement.img;
        return <img src={base64Image} alt={announcement.title} />;
      }
    } else {
      return (
        <div>
          <img src="/noimage.jpg" alt="Not found" />
        </div>
      );
    }
  }

  render() {
    const { announcement } = this.state;
    if (announcement) {
      return <div>{this.renderAnnouncement()}</div>;
    }
    return <Spinner />;
  }
}

export default DisplayAnnouncement;

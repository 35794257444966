import config from "../config.json";
import { getRandomString } from "./AuthService";
import { getEnvironment } from "./EnvironmentVariable";
import { isMobile } from "react-device-detect";

export const getJobs = async () => {
  // const jobsAPI = config.jobsURL;
  const environ = getEnvironment();
  let jobsAPI = config.jobsURL;
  let portalKey = config.jobsKey;
  console.log("jobService.getJobs environ", environ);
  if (environ !== "DEFAULT") {
    if (isMobile) {
      jobsAPI = config.Mobile_test_jobsURL;
    } else {
      jobsAPI = config.test_jobsURL;
      portalKey = config.test_jobsKey;
    }
  }

  // const user = JSON.parse(sessionStorage.getItem("user"));
  const userInfo = JSON.parse(sessionStorage.getItem("userinfo"));
  const message = getRandomString(25);
  const token = sessionStorage.getItem("access_token");

  let headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("X-NW-Message-ID", message);
  headers.append("Authorization", "Bearer " + token);
  headers.set("client_id", portalKey);
  const shortName = userInfo.shortName
    ? userInfo.shortName.toUpperCase()
    : userInfo.username.toUpperCase();
  const body = {
    shortName: shortName
  };

  const response = await fetch(jobsAPI, {
    method: "POST",
    headers: headers,
    body: JSON.stringify(body)
  });

  if (response.ok) {
    try {
      const jsondata = await response.json();
      return jsondata;
    } catch (e) {
      console.log("jobService.getJobs error", e);
      return null;
    }
  }
  return null;
};

export const getOpenJobs = async state => {
  // console.log("state", state)
  // const openjobsAPI = config.openjobsURL;
  const environ = getEnvironment();
  let openjobsAPI = config.openjobsURL;
  let portalKey = config.jobsKey;
  const message = getRandomString(25);
  if (state === "") {
    return null;
  } else {
    if (environ !== "DEFAULT") {
      console.log("jobService.getOpenJobs environ", environ);
      if (isMobile) {
        openjobsAPI = config.Mobile_test_openjobsURL;
      } else {
        openjobsAPI = config.test_openjobsURL;
        portalKey = config.test_jobsKey;
      }
    }
    const body = {
      state: state
    };

    let headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("X-NW-Message-ID", message);

    const token = sessionStorage.getItem("access_token");
    //const token = config.token;
    headers.append("Authorization", "Bearer " + token);
    headers.set("client_id", portalKey);

    const response = await fetch(openjobsAPI, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(body)
    });
    if (response.ok) {
      try {
        const jsondata = await response.json();
        return jsondata;
      } catch (e) {
        console.log("jobService.getOpenJobs error", e);
        return null;
      }
    }
    return null;
  }
};

export const getState = async () => {
  const userInfo = JSON.parse(sessionStorage.getItem("userinfo"));
  const message = getRandomString(25);
  const environ = getEnvironment();
  let callupAPI = config.callupAPI;
  let callupKey = config.callupKey;
  if (environ !== "DEFAULT") {
    console.log("jobService.getState environ", environ);
    if (isMobile) {
      callupAPI = config.Mobile_test_callupAPI;
    } else {
      callupAPI = config.test_callupAPI;
      callupKey = config.test_callupKey;
    }
  }
  const shortName = userInfo.shortName
    ? userInfo.shortName.toUpperCase()
    : userInfo.username.toUpperCase();
  const body = {
    shortName: encodeURIComponent(shortName)
  };

  let headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("X-NW-Message-ID", message);

  const token = sessionStorage.getItem("access_token");
  //const token = config.token;
  headers.append("Authorization", "Bearer " + token);
  headers.set("client_id", callupKey);

  const response = await fetch(callupAPI, {
    method: "POST",
    headers: headers,
    body: JSON.stringify(body)
  });

  if (response.ok) {
    try {
      const jsondata = await response.json();
      return jsondata;
    } catch (e) {
      console.log("jobService.getState error", e);
      return null;
    }
  }
  return null;
};

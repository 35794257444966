import React, { Component } from "react";
import {
    CollapsedPortlet,
    PortletLoading,
    WorkdayPortletError,
    WorkdayPortletwithDate
} from "../common/portlet";
import Spinner from "../common/spinner";
import config from "../config.json";
import "../main.css";
//import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import Slider from "../common/slider";
// import "@nationwide-bolt/bundle-core/dist/bolt-core.js";
// import "@nationwide-bolt/css-core/dist/css/bolt-core.css";

class Timeoff extends Component {
  state = {
    blurred: "no",
    blurImage: "eye-show"
  };

  handleBlurChange = () => {
    if (this.state.blurred === "yes") {
      this.setState({ blurred: "no", blurImage: "eye-show" });
    } else {
      this.setState({ blurred: "yes", blurImage: "eye-hide" });
    }
  };

  handleRefresh = async () => {
    // console.log("REFRESH TIMEOFF");
    this.props.fetchTimeOffData();
    //toast.success("Your Time has been refreshed");
  };

  returnContent(timeoffData, timeoffDefaultActive, deactivateTimeOff) {
    let currentTime = "";
    let currentDays = "";
    let yearendTime = "";
    let yearendDays = "";
    let cTime = null;
    let yTime = null;
    if (timeoffData) {
      if (this.state.blurred === "yes") {
        currentTime = "###";
        currentDays = "####";
        yearendTime = "###";
        yearendDays = "####";
      } else {
        cTime = timeoffData.currentTime;
        currentTime = cTime === -9999 ? "error" : cTime;
        currentDays = cTime === -9999 ? "error" : timeoffData.currentDays;
        // var currentDays = cTime === -9999 ? "error" : "24.95";
        yTime = timeoffData.yearendTime;
        yearendTime = yTime === -9999 ? "error" : yTime;
        yearendDays = yTime === -9999 ? "error" : timeoffData.yearendDays;
      }
      // var yearendDays = yTime === -9999 ? "error" : "24.95";}

      return cTime === -9999 || yTime === -9999 ? (
        <div>
          <div className="tile-apierroricon">
            <div className="portletErrorAlert">!</div>
            {/* <bolt-icon name= "notification-error" label = "error" color = "blue" size = "lg"></bolt-icon> */}
          </div>
          <div className="tile-apierrortext">Oops, this is unexpected.</div>
          <div className="tile-apierrortext1">Error code: 500</div>
          <div className="tile-apierrortext1">
            An error has occurred and we’re working to fix the issue.
          </div>
          <div className="tile-apierrortext1">
            <span>If the issue persists please </span>
            <a
              className="tile-apierrorcontactus"
              href="https://nwproduction.service-now.com/csm"
              rel="noopener noreferrer"
              target="_blank"
            >
              contact us.
            </a>
          </div>
          <div
            className="tile-apierror-refresh"
            onClick={this.handleRefresh}
            title="Refresh"
          >
            Refresh
          </div>
          <div style={{ paddingTop: "25px" }}></div>
        </div>
      ) : (
        <div>
          <h2 className="tile-header">
            <button
              style={
                timeoffDefaultActive === "N"
                  ? { display: "inline-block" }
                  : { display: "none" }
              }
              onClick={deactivateTimeOff}
              className="link"
            >
              <img
                src="/chevron_up.png"
                alt="Click to collapse your time"
                width="19px"
                style={{ marginRight: "10px" }}
              />
            </button>
            Your Time
          </h2>
          <div className="tile-refresh">
            <button
              className="btn btn-link"
              onClick={this.handleRefresh}
              title="Refresh"
            >
              <img
                style={{
                  height: "14px",
                  width: "14px"
                }}
                alt="refresh"
                src="/refresh.svg"
              />
            </button>
          </div>
          <div className="tile-blur">
            <button
              className="btn btn-link"
              onClick={this.handleBlurChange}
              title={
                this.state.blurred === "yes" ? "Click to show" : "Click to hide"
              }
            >
              <img
                style={{
                  height: "20px",
                  width: "20px"
                }}
                alt={this.state.blurred === "yes" ? "click to show Time off" : "click to hide Time off"}
                src={
                  this.state.blurred === "yes"
                    ? "/eye-blue.png"
                    : "hide-blue.png"
                }
              />
            </button>
          </div>
          <hr className="tile-line" />
          <div className={this.state.blurred === "yes" ? "obfuscate" : ""}>
            <div className="container-fluid">
              <div className="row">
                <div className="col timeoffColumn">
                  <h2 className="tile-title">Current Your Time Balance</h2>
                  <span className="tile-values timeoffDays">{currentDays}</span>
                  <span
                    className="tile-values"
                    style={{ fontSize: "16px", paddingLeft: "1px" }}
                  >
                    {" "}
                    days
                  </span>
                  <span className="tile-title2 timeoffHours">
                    {currentTime} hours
                  </span>
                </div>
                <div className="col timeoffColumn">
                  <div className="tile-title">Potential Year-end Balance</div>
                  <span className="tile-values timeoffDays">{yearendDays}</span>
                  <span
                    className="tile-values"
                    style={{ fontSize: "16px", marginLeft: "1px" }}
                  >
                    {" "}
                    days
                  </span>
                  <span className="tile-title2 timeoffHours">
                    {yearendTime} hours
                  </span>
                </div>
              </div>
            </div>
          </div>

          <hr className="tile-line" />
          <div className={this.state.blurred === "yes" ? "obfuscate" : ""}>
            <div className="container-fluid">
              <div className="row">
                <div className="col timeoffColumn">
                  <div className="tile-title"> Team Calendar </div>
                </div>
              </div>
            </div>

            <div className="tile-subbox">
              <div className="row">
                <div className="col tileTwocolumn">
                  <span
                    className="tile-title2"
                    style={{ fontSize: "14px", marginLeft: "14px" }}
                  >
                    {" "}
                    View my teammates' time off <div></div>
                  </span>

                  <span
                    className="tile-title2"
                    style={{ fontSize: "14px", marginLeft: "14px" }}
                  >
                    {" "}
                    on a shared calendar
                  </span>
                </div>
              </div>
              <div className="col tileTwocolumn">
                <div
                  className="tile-rectangle2 timeoffButtonContainer3"
                  style={{ borderRadius: "7px" }}
                >
                  <img
                    className="tile-externallink"
                    alt="Calendar"
                    src="/Group 3175.svg"
                  />
                  <a
                    className="tile-recttitle"
                    href={config.teamnatestimeoffcal}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    View Teammates' Time Off
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="tile-subbox2" style={{ marginTop: "15px" }}>
            <div className="row">
              <div className="col tileTwocolumn">
                <div className="tile-rectangle timeoffButtonContainer1">
                  <img
                    className="tile-externallink"
                    alt="external-link"
                    src="/external-link.svg"
                  />
                  <a
                    className="tile-recttitle"
                    href={config.addAbsenceUrl}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Request an Absence
                  </a>
                </div>
              </div>
              <div className="col tileTwocolumn">
                <div className="tile-rectangle timeoffButtonContainer2">
                  <img
                    className="tile-externallink"
                    alt="external-link"
                    src="/external-link.svg"
                  />
                  <a
                    className="tile-recttitle"
                    href={config.absenceDashboardUrl}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Absence Dashboard
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div style={{ paddingTop: "8px" }}></div>
        </div>
      );
    } else {
      return <Spinner />;
    }
  }

  render() {
    const {
      timeoffData,
      activePortal,
      fetchTimeOffData,
      timeoffDefaultActive,
      deactivateTimeOff
    } = this.props;

    if (activePortal === "Y") {
      if (timeoffData) {
        const content = this.returnContent(
          timeoffData,
          timeoffDefaultActive,
          deactivateTimeOff
        );
        if (
          timeoffData.currentTime === -9999 ||
          timeoffData.yearendTime === -9999
        ) {
          return <WorkdayPortletError content={content} />;
        } else {
          return (
            <WorkdayPortletwithDate
              content={content}
              datetimeLabel={"Balance as of"}
            />
          );
        }
      } else {
        this.props.activateTimeOffPortal();
        return <PortletLoading title="Time Off" />;
      }
    } else {
      return (
        <CollapsedPortlet
          // activateAwsPortal={this.handleExpand}
          dataFetch={fetchTimeOffData}
          title="Time Off"
        ></CollapsedPortlet>
      );
      // return null;
    }
  }
}

export default Timeoff;

import React from "react";

export const Gauge = ({ displayPct, labelText, id }) => {
  if (displayPct) {
    return (
      <div className="sc-gauge">
        <div className="sc-background">
          <div className="sc-percentage" id={id} title={displayPct + "%"}></div>
          <div className="sc-mask-top">
            <div className="sc-mask"></div>
          </div>
          <span className="sc-value">
            {displayPct}
            <span className="sc-value-pct">%</span>
          </span>
        </div>
        <span className="sc-min"></span>
        <span className="sc-max"></span>
        <div className="sc-label">{labelText}</div>
      </div>
    );
  }
  return <div>No data</div>;
};

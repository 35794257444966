import React, { Component } from "react";
// import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
// import config from "../config.json";
import menu from "../menu.json";
import { isBrowser, isEdge } from "react-device-detect";


class MenuBar extends Component {
  state = {
    payandbenefits: false,
    peopleandculture: false,
    career: false
  };

  clickMenu = stateVariable => {
    try {
      switch (stateVariable) {
        case "pay & benefits":
          const { payandbenefits } = this.state;
          // if (payandbenefits) {
          //   console.log("menubar.clickMenu transform none");
          //   document.getElementById("payAndBenefitsMenu").style.transform =
          //     "none";
          // } else {
          //   console.log("menubar.clickMenu transform rotate(0.5turn)");
          //   document.getElementById("payAndBenefitsMenu").style.transform =
          //     "rotate(180deg)";
          // }
          this.setState({
            payandbenefits: !payandbenefits,
            peopleandculture: false,
            career: false
          });
          break;
        case "people & culture":
          const { peopleandculture } = this.state;
          this.setState({
            peopleandculture: !peopleandculture,
            payandbenefits: false,
            career: false
          });
          break;
        case "career":
          const { career } = this.state;
          this.setState({
            career: !career,
            payandbenefits: false,
            peopleandculture: false
          });
          break;
        default:
          break;
      }
    } catch (e) {
      console.log("menubar>clickMenu Error", e);
    }
  };

  onMouseEnter = event => {
    try {
      const stateVariable = event.currentTarget.children[0].textContent.toLowerCase();
      switch (stateVariable) {
        case "pay & benefits":
          this.setState({ payandbenefits: true });
          break;
        case "people & culture":
          this.setState({ peopleandculture: true });
          break;
        case "career":
          this.setState({ career: true });
          break;
        default:
          break;
      }
    } catch (e) {
      console.log("menubar>onMouseEnter Error", e);
    }
  };

  onMouseLeave = event => {
    try {
      const stateVariable = event.currentTarget.children[0].textContent.toLowerCase();
      switch (stateVariable) {
        case "pay & benefits":
          this.setState({ payandbenefits: false });
          break;
        case "people & culture":
          this.setState({ peopleandculture: false });
          break;
        case "career":
          this.setState({ career: false });
          break;
        default:
          break;
      }
    } catch (e) {
      console.log("menubar>onMouseLeave Error", e);
    }
  };

  launchNewHireDisplay(){
    const {showDefault, handleNewHireDisplay} = this.props;
    showDefault();
    handleNewHireDisplay();
  }

  logout() {
    console.log("LOGOUT");
    sessionStorage.clear();
    localStorage.clear();
    document.location = "https://nationwide.com";
  }

  goToHome() {
    const { showDefault, changeMenuExpanded } = this.props;
    showDefault();
    changeMenuExpanded();
  }
  goToLeaderConnect() {
    window.location.href = "https://leaderconnect.nationwide.com";
  }
  render() {
    const { payandbenefits, peopleandculture, career } = this.state;
    const payandbenefitsMnu = menu.payandbenefits;
    const careerMnu = menu.career;
    const peopleandcultureMnu = menu.peopleandculture;
    const { menuExpanded, mobile } = this.props;

    let isManager = false;
    const contact = JSON.parse(sessionStorage.getItem("contact"));
    if (contact) {
      if (contact.managerFlag === "1" || contact.isPortalAdmin === "Y") {
        isManager = true;
      }
    }

    // console.log("header isManager", isManager);
    // console.log("header isBrowser", isBrowser);
    // console.log("header isEdge", isEdge);
    const showLeaderConnectLink = isManager && (isBrowser || isEdge);

    return (
      <React.Fragment>
        <nav
          id={mobile ? "mobileMenu2" : "mobileMenu1"}
          className={
            menuExpanded
              ? "header-dropdowns menuMobileDisplay menuMobileExpanded"
              : "header-dropdowns menuMobileDisplay"
          }
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%"
            // flexBasis: "100%"
          }}
        >
          <div
            id={mobile ? "mobileMenuSub2" : "mobileMenuSub1"}
            style={menuExpanded ? { display: "block" } : { display: "none" }}
          >
            <div className="showWhenMobile mobileMenu">
              <button
                id={mobile ? "homeButton2" : "homeButton1"}
                className="btn btn-link mnuLogoutButton"
                onClick={() => this.goToHome()}
              >
                Home
              </button>
            </div>
            <div className="showWhenMobile donotshowfocus">
              <div
                style={
                  payandbenefits
                    ? { backgroundColor: "#3FCDD7", width: "100%" }
                    : { backgroundColor: "#007d8a", width: "100%" }
                }
              >
                <button
                  tabIndex="0"
                  id={
                    mobile
                      ? "payAndBenefitsMenuButtonMobile"
                      : "payAndBenefitsMenuButton"
                  }
                  className={
                    payandbenefits
                      ? "btn bntLink activeMobileMenu mobileMenu"
                      : "btn bntLink inactiveMobileMenu mobileMenu"
                  }
                  onClick={() => this.clickMenu("pay & benefits")}
                  // onMouseEnter={this.onMouseEnter}
                  // onMouseLeave={this.onMouseLeave}
                >
                  {payandbenefitsMnu.text}{" "}
                  <div
                    className={
                      payandbenefits
                        ? "activeMobileMenuImage"
                        : "inactiveMobileMenuImage"
                    }
                    id={mobile ? "payAndBenefitsMenu2" : "payAndBenefitsMenu1"}
                  >
                    <img src="/icon-chevron-down.svg" alt=""></img>
                  </div>
                </button>
              </div>
              <div
                className={
                  payandbenefits
                    ? "mobileExpandedContainer"
                    : "mobileCollapsedContainer"
                }
              >
                <div
                  className={
                    payandbenefits
                      ? "mobileExpandedItemsContainer"
                      : "mobileCollapsedContainer"
                  }
                >
                  {payandbenefitsMnu.items.map(item => (
                    <div key={item.id} className="menuLinkContainer">
                      <a
                        href={item.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="mobileMenuLink"
                      >
                        {item.text}
                      </a>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="showWhenMobile donotshowfocus">
              <div
                style={
                  career
                    ? { backgroundColor: "#3FCDD7", width: "100%" }
                    : { backgroundColor: "#007d8a", width: "100%" }
                }
              >
                <button
                  tabIndex="0"
                  className={
                    career
                      ? "btn bntLink activeMobileMenu mobileMenu"
                      : "btn bntLink inactiveMobileMenu mobileMenu"
                  }
                  onClick={() => this.clickMenu("career")}
                  // onMouseEnter={this.onMouseEnter}
                  // onMouseLeave={this.onMouseLeave}
                  id={mobile ? "careerMenuButton2" : "careerMenuButton1"}
                >
                  {careerMnu.text}{" "}
                  <div
                    className={
                      career
                        ? "activeMobileMenuImage"
                        : "inactiveMobileMenuImage"
                    }
                    id={mobile ? "careerMenu2" : "careerMenu1"}
                  >
                    <img src="/icon-chevron-down.svg" alt=""></img>
                  </div>
                </button>
              </div>

              <div
                className={
                  career
                    ? "mobileExpandedContainer"
                    : "mobileCollapsedContainer"
                }
              >
                <div
                  className={
                    career
                      ? "mobileExpandedItemsContainer"
                      : "mobileCollapsedContainer"
                  }
                >
                  {careerMnu.items.map(item => (
                    <div key={item.id} className="menuLinkContainer">
                      <a
                        href={item.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="mobileMenuLink"
                      >
                        {item.text}
                      </a>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="showWhenMobile donotshowfocus">
              <div
                style={
                  peopleandculture
                    ? { backgroundColor: "#3FCDD7", width: "100%" }
                    : { backgroundColor: "#007d8a", width: "100%" }
                }
              >
                <button
                  tabIndex="0"
                  className={
                    peopleandculture
                      ? "btn bntLink activeMobileMenu mobileMenu"
                      : "btn bntLink inactiveMobileMenu mobileMenu"
                  }
                  onClick={() => this.clickMenu("people & culture")}
                  id={
                    mobile
                      ? "peopleandcultureMenuButton2"
                      : "peopleandcultureMenuButton1"
                  }
                >
                  {peopleandcultureMnu.text}{" "}
                  <div
                    className={
                      peopleandculture
                        ? "activeMobileMenuImage"
                        : "inactiveMobileMenuImage"
                    }
                    id={
                      mobile ? "peopleandcultureMenu2" : "peopleandcultureMenu1"
                    }
                  >
                    <img src="/icon-chevron-down.svg" alt=""></img>
                  </div>
                </button>
              </div>
              <div
                className={
                  peopleandculture
                    ? "mobileExpandedContainer"
                    : "mobileCollapsedContainer"
                }
              >
                <div
                  className={
                    peopleandculture
                      ? "mobileExpandedItemsContainer"
                      : "mobileCollapsedContainer"
                  }
                >
                  {peopleandcultureMnu.items.map(item => (
                    <div key={item.id} className="menuLinkContainer">
                      <a
                        href={item.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="mobileMenuLink"
                      >
                        {item.text}
                      </a>
                    </div>
                  ))}
                  <div className="mobileMenuLink" >
                    <a
                    onClick={() => this.launchNewHireDisplay()}
                  >
                      Meet our new Nationwiders
                    </a>
                  </div>
                  
                </div>
              </div>
            </div>
            {showLeaderConnectLink ? (
              <div className="showWhenMobile mobileMenu">
                <button
                  className="btn btn-link mnuLogoutButton"
                  onClick={() => this.goToLeaderConnect()}
                  id={mobile ? "LeaderConnectButton2" : "LeaderConnectButton1"}
                >
                  LeaderConnect
                </button>
              </div>
            ) : (
              <React.Fragment></React.Fragment>
            )}

            <div className="showWhenMobile">
              <button
                id={mobile ? "logoutButton2" : "logoutButton1"}
                className="btn btn-link mnuLogoutButton"
                onClick={() => this.logout()}
              >
                Log Out
              </button>
            </div>
          </div>
        </nav>

        <nav className="header-dropdowns menuDesktopDisplay xcol-md-6">
          <Dropdown
            tabIndex="0"
            show={payandbenefits}
            onFocus={this.onMouseEnter}
            onBlur={this.onMouseLeave}
            onMouseEnter={this.onMouseEnter}
            onMouseLeave={this.onMouseLeave}
            id={
              mobile
                ? "payandbenefits-dropdown-item-button2"
                : "payandbenefits-dropdown-item-button1"
            }
            style={{
              backgroundColor: "transparent",
              position: "relative"
            }}
            className={
              payandbenefits
                ? "dropdown-item-button menu active-menu"
                : "dropdown-item-button menu inactive-menu"
            }
          >
            <Dropdown.Toggle
              className={
                payandbenefits ? "xno-chevron active-menu-item" : "xno-chevron"
              }
            >
              {payandbenefitsMnu.text}
            </Dropdown.Toggle>
            <Dropdown.Menu
              className="dropdown-menu-custom-style"
              style={{ minWidth: "265px" }}
            >
              {payandbenefitsMnu.items.map(item => (
                <Dropdown.Item
                  as="a"
                  key={item.id}
                  href={item.url}
                  target="_blank"
                >
                  {item.text}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>

          <Dropdown
            tabIndex="0"
            show={career}
            onFocus={this.onMouseEnter}
            onBlur={this.onMouseLeave}
            onMouseEnter={this.onMouseEnter}
            onMouseLeave={this.onMouseLeave}
            id={
              mobile
                ? "career-dropdown-item-button2"
                : "career-dropdown-item-button1"
            }
            style={{
              backgroundColor: "transparent",
              // flexBasis: flexAmount,
              position: "relative"
            }}
            // className="menu dropdown-toggle"
            className={
              career
                ? "dropdown-item-button menu active-menu"
                : "dropdown-item-button menu inactive-menu"
            }
          >
            <Dropdown.Toggle
              className={
                career ? "xno-chevron active-menu-item" : "xno-chevron"
              }
            >
              {careerMnu.text}
            </Dropdown.Toggle>
            <Dropdown.Menu
              className="dropdown-menu-custom-style"
              style={{ width: "100%", boxShadow: "none", minWidth: "265px" }}
            >
              {careerMnu.items.map(item => (
                <Dropdown.Item
                  as="a"
                  key={item.id}
                  href={item.url}
                  target="_blank"
                  // onClick={() => showMessage("This is just a demo link")}
                >
                  {item.text}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
          <Dropdown
            tabIndex="0"
            show={peopleandculture}
            onFocus={this.onMouseEnter}
            onBlur={this.onMouseLeave}
            onMouseEnter={this.onMouseEnter}
            onMouseLeave={this.onMouseLeave}
            id={
              mobile
                ? "peopleandculture-dropdown-item-button2"
                : "peopleandculture-dropdown-item-button1"
            }
            style={{
              backgroundColor: "transparent",
              // flexBasis: flexAmount,
              position: "relative"
            }}
            // className="menu dropdown-toggle"
            className={
              peopleandculture
                ? "dropdown-item-button menu active-menu"
                : "dropdown-item-button menu inactive-menu"
            }
          >
            <Dropdown.Toggle
              className={
                peopleandculture
                  ? "xno-chevron active-menu-item"
                  : "xno-chevron"
              }
            >
              {peopleandcultureMnu.text}
            </Dropdown.Toggle>
            <Dropdown.Menu
              className="dropdown-menu-custom-style"
              style={{ width: "100%", boxShadow: "none", minWidth: "265px" }}
            >
              {peopleandcultureMnu.items.map(item => (
                <Dropdown.Item
                  as="a"
                  key={item.id}
                  href={item.url}
                  target="_blank"
                  // onClick={() => console.log("This is just a demo link")}
                >
                  {item.text}
                </Dropdown.Item>
                
              ))}
              <Dropdown.Item
                tabIndex="0"
                class="newHires"
                // title="View New Hires"
                onClick={() => this.launchNewHireDisplay()}
              >
                <div>
                  Meet our new Nationwiders
                </div>
              </Dropdown.Item>

            </Dropdown.Menu>
            
          </Dropdown>

          <div
            tabIndex="0"
            id={
              mobile
                ? "logout-dropdown-item-button2"
                : "logout-dropdown-item-button1"
            }
            className="dropdown-item-button menu inactive-menu "
            style={{ backgroundColor: "transparent", position: "relative" }}
          >
            <button
              aria-haspopup="true"
              aria-expanded="false"
              type="button"
              className=" btn btn-primary"
              style={{ border: "none" }}
              onClick={() => this.logout()}
            >
              Log Out
            </button>
          </div>
        </nav>
      </React.Fragment>
    );
  }
}

export default MenuBar;

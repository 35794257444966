
import React, { Component } from "react";
import "react-toastify/dist/ReactToastify.css";
import links from "../links.json";
class ErrorPage extends Component {
  state = {};
  goToHrConnect() {
    // console.log("Header goToHrConnect");
    // window.location = "https://hrconnect.nationwide.com";
    window.location.href = "https://hrconnect.nationwide.com";
  }

 
  renderHeader() {
    return (
      <React.Fragment>
        <div className="topStrip topColor blockHideWhenMobile">
          <div className="container">
            <div className="row">
              <div className="col"></div>
              {/* <div className="col col-lg-3 welcomediv">
                  Welcome back, {user ? user.firstName : ""}{" "}
                  {user ? user.lastName : ""}
                </div> */}
            </div>
          </div>
        </div>
        <div className="header">
          <div className="container" style={{ display: "flex" , justifyContent:"center"}}>
            <div className="row" style={{ whiteSpace: "nowrap" }}>
              <div className="xcol-2 hideWhenMobile">
                <div className="nwlogo" title="Nationwide Logo"></div>
                <div
                  className="hrlogo"
                  style={{ cursor: "pointer"}}
                  title="My Hr Dashboard"
                ></div>
                <div
                  className="showInlineWhenMobile"
                  style={{
                    paddingTop: "30px",
                    paddingRight: "30px",
                    float: "none"
                  }}
                ></div>
              </div>

              <div className="hideWhenMobile xcol"></div>
              <div className="searchFieldContainer hideWhenMobile xcol-2"></div>
              <div
                className="showWhenMobile"
                style={{ width: "100%", paddingLeft: "10px" }}
              >
                <div
                  className="nwlogo"
                  style={{ display: "inline-block" }}
                  title="Nationwide Logo"
                  
                ></div>
                <div
                  className="hrlogo"
                  style={{ display: "inline-block", cursor: "pointer" }}
                  title="My Hr Dashboard"
                ></div>
                <div
                  className="showInlineWhenMobile"
                  style={{
                    paddingTop: "30px",
                    paddingRight: "30px",
                    float: "right"
                  }}
                ></div>
              </div>
              <div
                className="showWhenMobile"
                style={{ marginLeft: "10px", width: "100%" }}
              >
                <div
                  className="searchFieldContainer"
                  id="searchDiv"
                  style={{ display: "none" }}
                ></div>
              </div>
              <div className="showWhenMobile" style={{ width: "100%" }}></div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }

  render() {
    var letterStyle = {
      padding: 10,
      margin: 10,
      color: " #085574",
      fontFamily: "arial",
      fontSize: 28,
      textAlign: "center"
  };
    return (
      <React.Fragment>
        {this.renderHeader()}
        <div style={{ width: "100%", textAlign: "center" }}>
          <h2>
                    
            Sorry, the page you requested is not available at this time. </h2>
          <div style={letterStyle}>
           You’re in luck! All the same information is stored within Workday.
           </div>
           <div style={letterStyle}>
           Please access by clicking this link {" "} 
           <a href={links.workday}><b>Workday</b></a>.  </div>
           <div>
           </div>
           <div>
           <b>We apologize for the inconvenience and are working to restore service.</b> </div>
          
        </div>
      </React.Fragment>
    );
  }
}

export default ErrorPage;

import config from "../config.json";
import { getRandomString } from "./AuthService";
import { getEnvironment } from "./EnvironmentVariable";
import { isMobile } from "react-device-detect";

export const getSavings = async () => {
  const environ = getEnvironment();
  let savingsURL = config.savingsURL;
  let savingsKey = config.savingsKey;
  if (environ !== "DEFAULT") {
    console.log("nwSavingsService.getSavings environ", environ);
    if (isMobile) {
      savingsURL = config.Mobile_test_savingsURL;
    } else {
      savingsURL = config.test_savingsURL;
      savingsKey = config.test_savingsKey;
    }
  }

  const userInfo = JSON.parse(sessionStorage.getItem("userinfo"));
  const message = getRandomString(25);
  const token = sessionStorage.getItem("access_token");

  let headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("Authorization", "Bearer " + token);
  headers.append("X-NW-Message-ID", message);
  headers.set("client_id", savingsKey);
  const shortName = userInfo.shortName
    ? userInfo.shortName.toUpperCase()
    : userInfo.username.toUpperCase();
  const body = {
    shortName: shortName
  };

  // const body = {shortName:"MOORJ42"};

  try {
    const response = await fetch(savingsURL, {
      method: "post",
      headers: headers,
      body: JSON.stringify(body)
    });
    if (response.ok) {
      try {
        const jsondata = await response.json();
        return jsondata;
      } catch (e) {
        return null;
      }
    }
  } catch (E) {
    console.log("nwSavingsService.getSavings  error", E);
    return null;
  }
};

import { slice } from "lodash";
import React, { Component } from "react";
import { isMobile } from "react-device-detect";
import "react-toastify/dist/ReactToastify.css";
import Spinner from "../common/spinner";
//added this

class PeopleOrgChart extends Component {
  state = {
    expandLvl1: []
  };

  handleExpand = id => {
    this.setState({ [id]: "Y" });
  };

  handleShrink = id => {
    this.setState({ [id]: "N" });
  };

  boolExpanded(id) {
    if (this.state[id]) {
      // dynamic state has been expanded and is still expanded
      if (this.state[id] === "Y") return true;
      // dynamic state has been expanded then collapsed again
      if (this.state[id] === "N") return false;
    } else {
      // No dynamic state exists so collapsed by default
      return false;
    }
  }

  backToSearchPage = () => {
    this.props.showSearchResults();
  };

  returnContent(data) {
    if (data) {
      if (data.error) {
        return <div>{this.renderError()}</div>;
      }
      return (
        <div className="contentOrg container">
          <div className="row">
            <div
              className={!isMobile ? "col" : ""}
              style={!isMobile ? { maxWidth: "14%" } : {}}
            >
              <div
                className="row backButton"
                title="Go back to search page"
                onClick={() => this.backToSearchPage()}
              >
                <div className="backButtonImage"></div>
                <div className="backButtonFont">Back</div>
              </div>
            </div>
            <hr className={isMobile ? "org-line2" : ""} />
            <div className={!isMobile ? "col" : ""}>
              {this.renderPeople(data)}
            </div>
          </div>
          <div
            className="org-BackToTopContainer"
            onClick={this.handleBackToTop}
          >
            <div className="row">
              <div className="backToTop_Org_Image">
                <div className="backToTop_Org_Text">Back to Top</div>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return <Spinner />;
    }
  }

  includeSubs(data) {
    let people = [];
    let count = 0;
    for (let i = 0; i < data.length; i++) {
      people[count++] = { TeamMate: data[i].TeamMate, lvl: 1 };
      let node = data[i].Reports;
      if (node.length > 0) {
        for (let x = 0; x < node.length; x++) {
          people[count++] = { TeamMate: node[x], lvl: 2 };
        }
      }
    }
    return people;
  }

  renderPeople(data) {
    const { orgChart_Image } = this.props;
    let lead = data[0].TeamMate;
    let reports = slice(data, 1);
    return (
      <div className="container">
        {/* Org head */}
        <div className="personBox">
          <div
            className="row"
            style={!isMobile ? { paddingLeft: "20px" } : { paddingLeft: "4px" }}
          >
            {this.boolExpanded(lead.emplid) ? (
              <div onClick={() => this.handleShrink(lead.emplid)}>
                <img
                  className="expandPeopleOrg"
                  alt={"Click to collapse people org. for " + this.buildNameString(lead)}
                  src="/bolt-chevron-up.svg"
                />
              </div>
            ) : (
              <div onClick={() => this.handleExpand(lead.emplid)}>
                <img
                  className="expandPeopleOrg"
                  alt={"Click to collapse people org. for " + this.buildNameString(lead)}
                  src="/bolt-chevron-down.svg"
                />
              </div>
            )}
            {orgChart_Image.length > 0 ? (
              <div>
                {this.foundImage(lead.emplid) ? (
                  <img
                    className="people-image-org"
                    src={this.getImagefromArray(lead.emplid)}
                    alt="Associate image"
                  />
                ) : (
                  <img
                    className="people-image-org"
                    alt="no-image"
                    src="/no-image.svg"
                  />
                )}
              </div>
            ) : (
              <img
                className="people-image-org"
                alt="no-image"
                src="/no-image.svg"
              />
            )}
            {/* Person name */}
            <div
              className={!isMobile ? "orgPersonName" : "orgPersonNameMobile"}
            >
              {this.buildNameString(lead)}
            </div>
            {/* Person Title */}
            <div
              className={!isMobile ? "orgPersonTitle" : "orgPersonMobile row"}
            >
              {this.calcTitle(lead)}
            </div>
          </div>
          <div
            style={this.boolExpanded(lead.emplid) ? {} : { display: "none" }}
          >
            {this.expandRegion(lead, false)}
          </div>
        </div>
        {this.renderReports(reports)}
      </div>
    );
  }

  getOrgChart = item => {
    const { showOrgChart, fetchOrgChartData } = this.props;
    showOrgChart(item);
    const orgResp = fetchOrgChartData(item);
    // console.log("Org chart", orgResp);
  };

  getWorkGroup = item => {
    const { showWorkGroup, fetchWorkGroupData } = this.props;
    showWorkGroup(item);
    const orgResp = fetchWorkGroupData(item.managerShortName);
    // console.log("Org chart", orgResp);
  };

  formatPhone(phone) {
    let phone_new = "";
    if (phone) {
      phone_new =
        "1-" +
        phone
          .replace(/[^\d]+/g, "")
          .replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");
    }
    return phone_new;
  }

  formatPhoneExtenstion(item) {
    if (item.workPhoneExtension) {
      let phone_new = item.workPhoneExtension.replace(/[^\d]+/g, "");
      return ' +' + phone_new;
    } else {
      return '';
    }
  }

  renderAssistant(assistantShortName) {
    const assistantRender = assistantShortName;
    console.log("ServiceNowSearch.renderManager", assistantRender);
    this.props.handleManagerHyperlink(assistantRender);
  }

  renderManager(managerShortName) {
    const Managerrender = managerShortName;
    console.log("ServiceNowSearch.renderManager", Managerrender);
    this.props.handleManagerHyperlink(Managerrender);
  }

  getCurrentAdmins(node) {
    const { AdminNameData } = this.props;
    //let count = 0;
    console.log("node", node, AdminNameData);
    for (let i = 0; i < AdminNameData.length; i++) {
      try {
        console.log("node adminID", node.adminID, AdminNameData[i].contacts);
        console.log(
          "node adminID",
          node.adminID,
          AdminNameData[i].contacts[0].shortName
        );
        if (node.adminID === AdminNameData[i].contacts[0].shortName) {
          console.log("Get admins", AdminNameData[i].contacts[0].shortName);
          return this.adminNameString(AdminNameData[i].contacts[0]);
        }
      } catch {
        return "";
      }
    }
  }

  adminNameString(person) {
    let fName = person.firstName;
    if (person.prefFirstName) {
      fName = person.prefFirstName;
    }
    let lName = person.lastName;
    if (person.prefLastName) {
      lName = person.prefLastName;
    }
    return lName + ", " + fName;
  }

  renderError() {
    return (
      <React.Fragment>
        <div
          style={
            isMobile
              ? { display: "flex", marginLeft: "-70px" }
              : { display: "flex" }
          }
        >
          <div
            className="portletErrorAlert"
            style={
              isMobile
                ? {
                    marginLeft: "50px",
                    marginTop: "25px",
                    paddingTop: "5px",
                    paddingLeft: "12px"
                  }
                : { marginLeft: "50px", marginTop: "25px", paddingTop: "5px" }
            }
          >
            !
          </div>
          <div
            className="tile-apierrortext"
            style={{
              marginLeft: "10px",
              textAlign: "left",
              marginTop: "25px",
              paddingTop: "5px"
            }}
          >
            Oops, this is unexpected.
          </div>
        </div>
        <div
          className="search-apierrortext1"
          style={isMobile ? { marginLeft: "24px" } : {}}
        >
          Error code: 500
        </div>
        {isMobile ? (
          <div className="search-apierrortext1" style={{ marginLeft: "24px" }}>
            An error has occurred and <br></br>we’re working to fix the issue.
          </div>
        ) : (
          <div className="search-apierrortext1">
            An error has occurred and we’re working to fix the issue.
          </div>
        )}
        <div
          className="search-apierrortext1"
          style={isMobile ? { marginLeft: "24px" } : {}}
        >
          <span>If the issue persists please </span>
          <a
            className="tile-apierrorcontactus"
            href="https://nwproduction.service-now.com/csm"
            rel="noopener noreferrer"
            target="_blank"
          >
            contact us.
          </a>
        </div>
        <div style={{ paddingTop: "25px" }}></div>
      </React.Fragment>
    );
  }

  expandRegion(person, orgLead) {
    return (
      <div className={!isMobile ? "expandContainer" : "expandContainerMobile"}>
        <div className="row">
          <div
            className={!isMobile ? "col" : ""}
            style={!isMobile ? { minWidth: "54%" } : {}}
          >
            <div className="row">
              <div className="people-phone">OFFICE PHONE NUMBER:</div>
              <div className="people-subtitle" style={{ paddingLeft: "4px" }}>
                {this.formatPhone(person.workPhone)}{this.formatPhoneExtenstion(person)}
              </div>
            </div>
            <div className="row">
              <a className="people-email" href={`mailto:${person.workEmail}`}>
                <img
                  style={{
                    marginLeft: "25px",
                    marginTop: "-4px"
                  }}
                  alt="email"
                  src="/email.svg"
                />
                <span
                  style={{
                    paddingLeft: "4px",
                    position: "absolute"
                  }}
                >
                  Email
                </span>
              </a>
              <a
                className="people-chat"
                href={`https://teams.microsoft.com/l/chat/0/0?users=${this.getChatLink(
                  person.emplid,
                  person
                )}`}
                rel="noopener noreferrer"
                target="_blank"
              >
                <img
                  style={{
                    marginLeft: "25px",
                    marginTop: "-4px"
                  }}
                  alt="chat"
                  src="/chat.svg"
                />
                <span
                  style={{
                    paddingLeft: "4px",
                    position: "absolute"
                  }}
                >
                  Chat
                </span>
              </a>
            </div>
            <div style={{ paddingTop: "12px" }}></div>
            <div className="row">
              <div className="people-phone">SHORT NAME:</div>
              <div className="people-subtitle" style={{ paddingLeft: "4px" }}>
                {person.shortName}
              </div>
            </div>
            <div className="rowPadding"></div>
            <div className="row">
              <div className="people-phone">WORK GROUP:</div>
              <div className="people-subtitle" style={{ paddingLeft: "4px" }}>
                {person.department}
              </div>
            </div>
            <div className="rowPadding"></div>
            <div className="row">
              <div className="people-phone">REPORTS TO:</div>
              <div
                className="people-subtitle"
                style={{
                  paddingLeft: "4px",
                  textDecoration: "underline",
                  color: "#1C57A5",
                  cursor: "pointer"
                }}
                onClick={() => this.renderManager(person.managerShortName)}
                title="View Manager"
              >
                {person.managerPrefName}
              </div>
            </div>
            <div className="rowPadding"></div>
            {person.adminID ? (
              <div className="row">
                <div className="people-phone">ADMINISTRATIVE ASSISTANT: </div>
                <div
                  className="people-subtitle"
                  style={{
                    textDecoration: "underline",
                    color: "#1C57A5",
                    cursor: "pointer",
                    paddingLeft: "4px"
                  }}
                  onClick={() => this.renderAssistant(person.adminID)}
                  title="View assistant"
                >
                  {this.getCurrentAdmins(person)}
                </div>
              </div>
            ) : (
              <div> </div>
            )}

            <div className="row">
              <a
                className="people-workGroup"
                // href={"/orgchart"}
                rel="noopener noreferrer"
                target="_blank"
                title="Open Work Group"
                style={{ marginTop: "10px" }}
                onClick={() => this.getWorkGroup(person)}
              >
                <img
                  style={{
                    marginLeft: "12px",
                    marginTop: "-4px"
                  }}
                  alt="Work Group"
                  src="/work-group-icon.svg"
                />
                <span
                  style={{
                    paddingLeft: "6px",
                    position: "absolute"
                  }}
                >
                  Work Group
                </span>
              </a>
              <a
                className="people-org"
                // href={'/orgchart'}
                rel="noopener noreferrer"
                target="_blank"
                style={orgLead ? { display: "none" } : { marginTop: "10px" }}
                title="Open OrgChart"
                onClick={() => this.getOrgChart(person)}
              >
                <img
                  style={{
                    marginLeft: "12px",
                    marginTop: "-4px"
                  }}
                  alt="Org Chart"
                  src="/org-chart-icon.svg"
                />
                <span
                  style={{
                    color: "#1c57a5",
                    paddingLeft: "6px",
                    position: "absolute"
                  }}
                >
                  Org Chart
                </span>
              </a>
            </div>
            <div className="rowPadding"></div>
          </div>
          <div className={!isMobile ? "col" : ""}>
            <div className="row">
              <div className="people-phone">MAIL CODE:</div>
              <div className="people-subtitle" style={{ paddingLeft: "4px" }}>
                {person.mailDrop}
              </div>
            </div>
            <div className="rowPadding"></div>
            <div className="row">
              <div className="people-phone">DISBURSMENT CODE:</div>
              <div className="people-subtitle" style={{ paddingLeft: "4px" }}>
                {person.disbCode}
              </div>
            </div>
            <div className="rowPadding"></div>
            <div className="row">
              <div className="people-phone">
                HR BUSINESS LOCATION (LOCATION ID):{" "}
              </div>
            </div>

            <div className="row">
              <div className="people-subtitle">{person.locName}</div>
            </div>

            <div className="row">
              <div className="people-subtitle">
                {person.state}
                {", "}
                {person.postal}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  getChatLink(emplid, person) {
    const { orgChart_Image } = this.props;
    // NOTE, I DID A SPEED COMPARISON AND THE .find METHOD WAS
    // ACTUALLY SLOWER THAT LOOPING THROUGH THE ARRAY--BUT NOT NOTICABLY
    // UNTIL THE ARRAY HELD MILLIONS OF OBJECTS.  SO I USED THE .find
    // METHOD BECUASE IT LOOKS CLEANER (IMHO)
    let obj = orgChart_Image.find(o => o.Emplid === emplid);
    if (obj) {
      //Found the emplid in the callup_image data
      return obj.userPrincipalName;
    } else {
      //Did not find the emplid in the callup_image data
      return person.workEmail;
    }
  }

  calcTitle(person) {
    if (person.employeeType === "Non-Employee") {
      return "Non-Employee";
    } else {
      return person.jobTitle;
    }
  }

  renderReports(data) {
    const { orgChart_Image } = this.props;

    return (
      <div>
        {data.map((item, i) => (
          <div key={i}>
            <div className={item.Superior ? "personBox" : "subpersonBox"}>
              <div
                className="row"
                style={
                  !isMobile ? { paddingLeft: "20px" } : { paddingLeft: "4px" }
                }
              >
                {this.boolExpanded(item.TeamMate.emplid) ? (
                  <div onClick={() => this.handleShrink(item.TeamMate.emplid)}>
                    <img
                      className="expandPeopleOrg"
                      alt={"Click to collapse people org. for " + this.buildNameString(item.TeamMate)}
                      src="/bolt-chevron-up.svg"
                    />
                  </div>
                ) : (
                  <div onClick={() => this.handleExpand(item.TeamMate.emplid)}>
                    <img
                      className="expandPeopleOrg"
                      alt={"Click to expand people org. for " + this.buildNameString(item.TeamMate)}
                      src="/bolt-chevron-down.svg"
                    />
                  </div>
                )}
                {orgChart_Image.length > 0 ? (
                  <div>
                    {this.foundImage(item.TeamMate.emplid) ? (
                      <img
                        className="people-image-org"
                        src={this.getImagefromArray(item.TeamMate.emplid)}
                        alt="Associate image"
                      />
                    ) : (
                      <img
                        className="people-image-org"
                        alt="no-image"
                        src="/no-image.svg"
                      />
                    )}
                  </div>
                ) : (
                  <img
                    className="people-image-org"
                    alt="no-image"
                    src="/no-image.svg"
                  />
                )}
                {/* Person name */}
                <div
                  className={
                    !isMobile ? "orgPersonName" : "orgPersonNameMobile"
                  }
                >
                  {this.buildNameString(item.TeamMate)}
                </div>
                {/* Person Title */}
                <div
                  className={
                    !isMobile ? "orgPersonTitle" : "orgPersonMobile row"
                  }
                >
                  {this.calcTitle(item.TeamMate)}
                </div>
              </div>
              <div
                style={
                  this.boolExpanded(item.TeamMate.emplid)
                    ? {}
                    : { display: "none" }
                }
              >
                {" "}
                {this.expandRegion(item.TeamMate, false)}
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  }

  handleBackToTop = () => {
    document.body.scrolltop = 0;
    document.documentElement.scrollTop = 0;
  };

  buildNameString(person) {
    let dataRow = person;
    let output = "";
    
    if (
      dataRow.prefFirstName &&
      dataRow.prefFirstName.replace(" ", "") !== ""
    ) {
      output += dataRow.prefFirstName + " ";
    } else {
      output += dataRow.firstName + " ";
    }
    if (dataRow.prefLastName && dataRow.prefLastName.replace(" ", "") !== "") {
      output += dataRow.prefLastName + " ";
    } else {
      output += dataRow.lastName + " ";
    }

    return output;
  }

  foundImage(emplid) {
    // const imageArray = await GetPhotos(emplid);
    // this.setState({Image_Array: imageArray})
    if (this.props.orgChart_Image.length > 0) {
      let i = this.props.orgChart_Image.length;
      for (var j = 0; j < i; j++) {
        if (emplid === this.props.orgChart_Image[j].Emplid) {
          if (this.props.orgChart_Image[j].Image) {
            if (this.props.orgChart_Image[j].Image !== "") {
              return true;
            }
          }
        }
      }
    }
    return false;
  }

  getImagefromArray(emplid) {
    if (this.props.orgChart_Image.length > 0) {
      let i = this.props.orgChart_Image.length;
      for (var j = 0; j < i; j++) {
        if (emplid === this.props.orgChart_Image[j].Emplid) {
          let returnvar =
            "data:image/jpeg;base64," + this.props.orgChart_Image[j].Image;
          if (returnvar) {
            return returnvar;
          }
        }
      }
    }
  }

  render() {
    const { orgChartData } = this.props;
    //    handleSearchQueryCallup("gattoa2");
    // console.log("Org chart callup");
    // handleSearchQueryCallup("gattoa2");

    return (
      <div className="orgBackground">
        <div className="orgChartContainer">
          <div className="container">
            <div className="row" style={{ paddingLeft: "35px" }}>
              <div className="searchTitle">Org Chart for:</div>
              <div className="searchText">
                {this.buildNameString(this.props.orgChartDisplay)}
              </div>
            </div>
            <hr className="org-line" />
          </div>
          {this.returnContent(orgChartData)}
        </div>
      </div>
    );
  }
}

export default PeopleOrgChart;

import config from "../config.json";
import { getRandomString } from "../services/AuthService";
import { getEnvironment } from "./EnvironmentVariable";
import { isMobile } from "react-device-detect";

export const getPaycheck = async () => {
  const environ = getEnvironment();
  let portalApi = config.payCheckURL;
  let paycheckApiKey = config.payCheckKey;
  if (environ !== "DEFAULT") {
    console.log("paycheckService.getPaycheck environ", environ);
    if (isMobile) {
      portalApi = config.Mobile_test_payCheckURL;
    } else {
      portalApi = config.test_payCheckURL;
      paycheckApiKey = config.test_payCheckKey;
    }
  }
  const userInfo = JSON.parse(sessionStorage.getItem("userinfo"));
  const message = getRandomString(25);
  const token = sessionStorage.getItem("access_token");

  let headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("Authorization", "Bearer " + token);
  headers.append("X-NW-Message-ID", message);
  headers.set("client_id", paycheckApiKey);
  const shortName = userInfo.shortName
    ? userInfo.shortName.toUpperCase()
    : userInfo.username.toUpperCase();
  const body = JSON.stringify({ shortName: shortName });
  try {
    const response = await fetch(portalApi, {
      method: "post",
      headers: headers,
      body: body
    });
    if (response.ok) {
      try {
        const jsondata = await response.json();
        return jsondata;
      } catch (e) {
        console.log("paycheckService.getPaycheck  error", e);
        return null;
      }
    }
  } catch (e1) {
    console.log("paycheckService.getPaycheck  error", e1);
    return null;
  }

  return null;
};

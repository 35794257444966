import React, { Component } from "react";
import Header from "../common/header";
import Authorization from "../components/authorization";
import ServiceNowSearch from "../components/serviceNowSearch";
import AdvancedPeopleSearch from "../components/AdvancedPeopleSearch";
import AnnouncementList from "../components/admin/announcementList";
import DefaultPage from "../components/defaultPage";
import AnnouncementEdit from "../components/admin/announcementEdit";
import { getAzureAuthUrl } from "../services/AuthService";
import { toast } from "react-toastify";
import Spinner from "../common/spinner";
import Footer from "../common/footer";
import { isBrowser } from "react-device-detect";
import * as microsoftTeams from "@microsoft/teams-js";
import PeopleOrgChart from "./PeopleOrgChart";
import PeopleWorkGroup from "./PeopleWorkGroup";

class Home extends Component {
  state = {
    displayDefaultPage: "block",
    displaySearchResults: "none",
    displayAdvancedPeopleSearch: "none",
    displayAnnounceList: "none",
    displayAnnounceEdit: "none",
    displayAdminUserList: "none",
    forceAnnounceSearchReload: "Y",
    forceAdminUserSearchReload: "N",
    displayEts: "none",
    displayOrgChart: "none",
    displayWorkGroup: "none",
    orgChartDisplay: "",
    workGroupDisplay: ""
  };

  componentDidMount() {
    // const contact = JSON.parse(sessionStorage.getItem("contact"));
    let teamsFlag = false;
    if (isBrowser) {
      console.log("BROWSER TYPE", "DESKTOP");
    } else {
      console.log("BROWSER TYPE", "MOBILE");
    }
    const userinfo = JSON.parse(sessionStorage.getItem("userinfo"));
    if (!userinfo) {
      try {
        const search = window.location.search;
        console.log("HOME componentDidMount search", search);
        const searchP = new URLSearchParams(search);

        console.log("HOME componentDidMount searchP", searchP);
        const inTeams = searchP.get("inTeamsSSO");
        console.log("HOME componentDidMount inTeams", inTeams);
        teamsFlag = inTeams;
        if (inTeams) {
          console.log("TEAMS!");
          console.log("HOME componentDidMount Try teams initialize");
          microsoftTeams.initialize();
          var authTokenRequest = {
            successCallback: function(result) {
              console.log("HOME componentDidMount teams result", result);

              const loc = window.location.hostname;
              const url =
                "https://" + loc + "/oidc-client-callback#id_token=" + result;

              // const url = getAzureAuthUrl();
              console.log("home.componentDidMount azure url", url);

              window.location = url;
            },
            failureCallback: function(error) {
              console.error("HOME componentDidMount teams error", error);
            }
          };
          console.log(
            "HOME componentDidMount call microsoftTeams.authentication"
          );
          microsoftTeams.authentication.getAuthToken(authTokenRequest);
          console.log(
            "HOME componentDidMount callED microsoftTeams.authentication"
          );
          console.log(
            "HOME componentDidMount microsoftTeams.ErrorCode",
            microsoftTeams.ErrorCode
          );

          console.log("HOME componentDidMount microsoftTeams", microsoftTeams);
        } else {
          console.log("NOT TEAMS");
          localStorage.setItem("openedlocation", window.location.href);

          const url = getAzureAuthUrl();
          console.log("home.componentDidMount azure url", url);

          window.location = url;
        }
      } catch (e) {
        console.error(
          "HOME componentDidMount error during ms teams initalize ",
          e
        );
        toast.error(
          "HOME componentDidMount error during ms teams initalize " +
            JSON.stringify(e)
        );

        const url = getAzureAuthUrl();
        console.log("home.componentDidMount azure url", url);

        window.location = url;
      }
    } else {
      const { startPage } = this.props;
      console.log("HOME componentDidMount startPage", startPage);
      if (startPage === "AnnounceList") {
        this.showAnnounceList();
      }
      if (startPage === "AnnounceEdit") {
        this.showAnnounceEdit();
      }
      if (startPage === "ETS") {
        this.showETS();
      }
    }
    if (!teamsFlag) {
      const Searchstring = this.props.match.params.defaultSearch;

      if (Searchstring) {
        const defaultSearch = Searchstring.replaceAll("-", " ");
        this.submitSearch(defaultSearch);
      }

      console.log("Default Search", Searchstring);
    }
    const { advancedPplUrl } = this.props;
    if (advancedPplUrl) {
      console.log("In advanced people search mode: ", advancedPplUrl);
      this.showAdvancedSearch();
    }
  }

  showSearchResults = () => {
    const displayDefaultPage = "none";
    const displaySearchResults = "block";
    const displayAdvancedPeopleSearch = "none";
    const displayAnnounceList = "none";
    const displayAnnounceEdit = "none";
    const displayAdminUserList = "none";
    const displayEts = "none";
    const displayOrgChart = "none";
    const displayWorkGroup = "none";
    this.setState({
      displayDefaultPage,
      displaySearchResults,
      displayAdvancedPeopleSearch,
      displayAnnounceList,
      displayAnnounceEdit,
      displayAdminUserList,
      displayEts,
      displayOrgChart,
      displayWorkGroup
    });
  };

  showOrgChart = item => {
    const displayDefaultPage = "none";
    const displaySearchResults = "none";
    const displayAdvancedPeopleSearch = "none";
    const displayAnnounceList = "none";
    const displayAnnounceEdit = "none";
    const displayAdminUserList = "none";
    const displayEts = "none";
    const displayOrgChart = "block";
    const displayWorkGroup = "none";
    const orgChartDisplay = item;
    console.log("Showing org chart for: ", orgChartDisplay);
    this.setState({
      displayDefaultPage,
      displaySearchResults,
      displayAdvancedPeopleSearch,
      displayAnnounceList,
      displayAnnounceEdit,
      displayAdminUserList,
      displayEts,
      displayOrgChart,
      orgChartDisplay,
      displayWorkGroup
    });
  };

  showWorkGroup = item => {
    const displayDefaultPage = "none";
    const displaySearchResults = "none";
    const displayAdvancedPeopleSearch = "none";
    const displayAnnounceList = "none";
    const displayAnnounceEdit = "none";
    const displayAdminUserList = "none";
    const displayEts = "none";
    const displayOrgChart = "none";
    const displayWorkGroup = "block";
    const workGroupDisplay = item;
    console.log("Showing work group for: ", workGroupDisplay.shortName);
    this.setState({
      displayDefaultPage,
      displaySearchResults,
      displayAdvancedPeopleSearch,
      displayAnnounceList,
      displayAnnounceEdit,
      displayAdminUserList,
      displayEts,
      displayOrgChart,
      displayWorkGroup,
      workGroupDisplay
    });
  };

  showAdvancedSearch = () => {
    const displayDefaultPage = "none";
    const displaySearchResults = "none";
    const displayAdvancedPeopleSearch = "block";
    const displayAnnounceList = "none";
    const displayAnnounceEdit = "none";
    const displayAdminUserList = "none";
    const displayEts = "none";
    const displayOrgChart = "none";
    const displayWorkGroup = "none";
    this.setState({
      displayDefaultPage,
      displaySearchResults,
      displayAdvancedPeopleSearch,
      displayAnnounceList,
      displayAnnounceEdit,
      displayAdminUserList,
      displayEts,
      displayOrgChart,
      displayWorkGroup
    });
  };

  showDefault = () => {
    const displayDefaultPage = "block";
    const displaySearchResults = "none";
    const displayAdvancedPeopleSearch = "none";
    const displayAnnounceList = "none";
    const displayAnnounceEdit = "none";
    const displayAdminUserList = "none";
    const displayEts = "none";
    const displayOrgChart = "none";
    const displayWorkGroup = "none";
    this.setState({
      displayDefaultPage,
      displaySearchResults,
      displayAdvancedPeopleSearch,
      displayAnnounceList,
      displayAnnounceEdit,
      displayAdminUserList,
      displayEts,
      displayOrgChart,
      displayWorkGroup
    });
  };

  showETS = () => {
    const displayDefaultPage = "none";
    const displaySearchResults = "none";
    const displayAdvancedPeopleSearch = "none";
    const displayAnnounceList = "none";
    const displayAnnounceEdit = "none";
    const displayAdminUserList = "none";
    const displayEts = "block";
    const displayOrgChart = "none";
    const displayWorkGroup = "none";
    this.setState({
      displayDefaultPage,
      displaySearchResults,
      displayAdvancedPeopleSearch,
      displayAnnounceList,
      displayAnnounceEdit,
      displayAdminUserList,
      displayEts,
      displayOrgChart,
      displayWorkGroup
    });
  };

  showAnnounceList = (announceId, forceAnnounceSearchReload) => {
    const displayDefaultPage = "none";
    const displaySearchResults = "none";
    const displayAdvancedPeopleSearch = "none";
    const displayAnnounceList = "block";
    const displayAnnounceEdit = "none";
    const displayAdminUserList = "none";
    const displayEts = "none";
    const displayOrgChart = "none";
    const displayWorkGroup = "none";
    this.setState({
      displayDefaultPage,
      displaySearchResults,
      displayAdvancedPeopleSearch,
      displayAnnounceList,
      displayAnnounceEdit,
      displayAdminUserList,
      announceId,
      forceAnnounceSearchReload,
      displayEts,
      displayOrgChart,
      displayWorkGroup
    });
  };

  showAdminUserList = forceAdminUserSearchReload => {
    const displayDefaultPage = "none";
    const displaySearchResults = "none";
    const displayAdvancedPeopleSearch = "none";
    const displayAnnounceList = "none";
    const displayAnnounceEdit = "none";
    const displayAdminUserList = "block";
    const displayEts = "none";
    const displayOrgChart = "none";
    const displayWorkGroup = "none";
    this.setState({
      displayDefaultPage,
      displaySearchResults,
      displayAdvancedPeopleSearch,
      displayAnnounceList,
      displayAnnounceEdit,
      displayAdminUserList,
      forceAdminUserSearchReload,
      displayEts,
      displayOrgChart,
      displayWorkGroup
    });
  };

  showMessage = msg => {
    toast.info(msg);
  };

  openLinkInNewWindow = url => {
    const newWin = window.open(url, "blank");
    if (!newWin || newWin.closed || typeof newWin.closed == "undefined") {
      toast.error("You appear to have a popup blocker enabled.");
    }
  };

  showAnnounceEdit = () => {
    const displayDefaultPage = "none";
    const displaySearchResults = "none";
    const displayAdvancedPeopleSearch = "none";
    const displayAnnounceList = "none";
    const displayAnnounceEdit = "block";
    const displayAdminUserList = "none";
    const displayEts = "none";
    const displayOrgChart = "none";
    const displayWorkGroup = "none";
    this.setState({
      displayDefaultPage,
      displaySearchResults,
      displayAdvancedPeopleSearch,
      displayAnnounceList,
      displayAnnounceEdit,
      displayAdminUserList,
      displayEts,
      displayOrgChart,
      displayWorkGroup
    });
  };

  submitSearch = searchQuery => {
    this.props.onSearchQueryUpdate(searchQuery);
    this.props.onSearchQueryUpdateCallup(searchQuery);
    this.showSearchResults();
  };

  managerSearch = searchQuery => {
    this.props.handleManagerHyperlink(searchQuery);
    this.showSearchResults();
  };

  apsSearch = (short_name, body_post, body_post2) => {
    console.log("home apsSearch APS Submit here");
    this.props.onSearchQueryAPS(short_name, body_post, body_post2);
    this.showSearchResults();
  };

  testRefresh() {
    const url = getAzureAuthUrl();
    sessionStorage.setItem("refreshing", "true");
    console.log("home.testrefresh url", url);
    const t = window.open(
      url,
      "_blank",
      "height=100,width=100,location=yes,menubar=no,resizable=no,titlebar=yes"
    );

    console.log("home.testrefresh windows opened?", t);
  }

  renderDisplay() {
    const {
      displayDefaultPage,
      displaySearchResults,
      displayAnnounceList,
      displayAnnounceEdit,
      displayAdminUserList,
      displayAdvancedPeopleSearch,
      forceAnnounceSearchReload,
      forceAdminUserSearchReload,
      displayEts,
      displayOrgChart,
      orgChartDisplay,
      displayWorkGroup,
      workGroupDisplay
    } = this.state;
    const {
      user,
      searchQuery,
      searchData,
      isAdmin,
      searching,
      searchingCallup,
      announcementId,
      workingAnnouncement,
      onWorkingAnnouncementChange,
      unfilteredNumber,
      filteredNumber,
      populatePayslipPortal,
      payslipData,
      payslipPortalActive,
      payslipDefaultActive,
      fetchPayslipData,
      deactivatePayslip,
      activateTimeOffPortal,
      fetchTimeOffData,
      timeoffData,
      timeoffPortalActive,
      timeoffDefaultActive,
      deactivateTimeOff,
      jobData,
      populateJobsPortal,
      jobPortalActive,
      jobDefaultActive,
      fetchJobsData,
      deactivateJobs,
      savingsData,
      savingsPortalActive,
      populateSavingsPortal,
      savingsDefaultActive,
      fetchSavingsData,
      deactivateSavings,
      benefitsData,
      benefitsPortalActive,
      benefitsDefaultActive,
      populateBenefitsPortal,
      deactivateBenefits,
      fetchBenefits,
      awsData,
      awsPortalActive,
      awsDefaultActive,
      deactivateAws,
      activateAwsPortal,
      fetchAwsData,
      fowData,
      fowPortalActive,
      fowDefaultActive,
      deactivateFow,
      populateFOWPortal,
      fetchFowData,
      MyHealthData,
      MyHealthPortalActive,
      populateMyHealthPortal,
      MyHealthDefaultActive,
      deactivateMyHealth,
      fetchMyHealth,
      onChangePortletDisplay,
      showPortletSelector,
      portlets,
      searchHistoryId,
      retrievePortals,
      handlePayEndDateChange,
      payEndDate,
      portletUpdate,
      callUpSNData,
      Callup_Image,
      HRK_Error,
      People_Error,
      HRK_Display,
      People_Display,
      menuExpanded,
      displayNewHires,
      newHires,
      departments,
      managers,
      locations,
      selectedLocation,
      locationFilter,
      hireDates,
      jobTitles,
      populateNewHires,
      givingData,
      givingPortalActive,
      givingDefaultActive,
      activateGivingPortal,
      deactivateGiving,
      fetchGivingData,
      productData,
      productPortalActive,
      productDefaultActive,
      activateProductPortal,
      deactivateProduct,
      fetchProductData,
      loadMoreKnowledge,
      restrictKnowledgeData,
      reSearchKnowledge,
      fetchAdminNameData,
      AdminNameData,
      orgChartData,
      fetchOrgChartData,
      WorkGroupData,
      fetchWorkGroupData,
      orgChart_Image,
      hsaData,
      hsaDefaultActive,
      hsaPortalActive,
      activateHSAPortal,
      deactivateHSA,
      fetchHSAData,
      fetchDataFile,
      fetchDataFileAllowed,
      dataFileResponse,
      dataFileAllowed
    } = this.props;

    return (
      <React.Fragment>
        <div
          id="defaultPageContainer"
          className={menuExpanded ? "blockHideWhenMobile" : ""}
        >
          <div style={{ display: displayDefaultPage }}>
            <DefaultPage
              hsaData={hsaData}
              hsaDefaultActive={hsaDefaultActive}
              hsaPortalActive={hsaPortalActive}
              activateHSAPortal={activateHSAPortal}
              deactivateHSA={deactivateHSA}
              fetchHSAData={fetchHSAData}
              loadMoreKnowledge={loadMoreKnowledge}
              productData={productData}
              productPortalActive={productPortalActive}
              productDefaultActive={productDefaultActive}
              activateProductPortal={activateProductPortal}
              deactivateProduct={deactivateProduct}
              fetchProductData={fetchProductData}
              givingData={givingData}
              givingPortalActive={givingPortalActive}
              givingDefaultActive={givingDefaultActive}
              activateGivingPortal={activateGivingPortal}
              deactivateGiving={deactivateGiving}
              fetchGivingData={fetchGivingData}
              populatePayslipPortal={populatePayslipPortal}
              payslipData={payslipData}
              payslipPortalActive={payslipPortalActive}
              payslipDefaultActive={payslipDefaultActive}
              fetchPayslipData={fetchPayslipData}
              deactivatePayslip={deactivatePayslip}
              activateTimeOffPortal={activateTimeOffPortal}
              fetchTimeOffData={fetchTimeOffData}
              timeoffData={timeoffData}
              timeoffPortalActive={timeoffPortalActive}
              deactivateTimeOff={deactivateTimeOff}
              timeoffDefaultActive={timeoffDefaultActive}
              jobData={jobData}
              jobPortalActive={jobPortalActive}
              jobDefaultActive={jobDefaultActive}
              populateJobsPortal={populateJobsPortal}
              fetchJobsData={fetchJobsData}
              deactivateJobs={deactivateJobs}
              savingsData={savingsData}
              savingsPortalActive={savingsPortalActive}
              populateSavingsPortal={populateSavingsPortal}
              savingsDefaultActive={savingsDefaultActive}
              fetchSavingsData={fetchSavingsData}
              deactivateSavings={deactivateSavings}
              awsData={awsData}
              activateAwsPortal={activateAwsPortal}
              fetchAwsData={fetchAwsData}
              awsPortalActive={awsPortalActive}
              deactivateAws={deactivateAws}
              awsDefaultActive={awsDefaultActive}
              fowData={fowData}
              fowPortalActive={fowPortalActive}
              fowDefaultActive={fowDefaultActive}
              deactivateFow={deactivateFow}
              populateFOWPortal={populateFOWPortal}
              fetchFowData={fetchFowData}
              MyHealthData={MyHealthData}
              MyHealthPortalActive={MyHealthPortalActive}
              MyHealthDefaultActive={MyHealthDefaultActive}
              populateMyHealthPortal={populateMyHealthPortal}
              deactivateMyHealth={deactivateMyHealth}
              fetchMyHealth={fetchMyHealth}
              portlets={portlets}
              portletUpdate={portletUpdate}
              showPortletSelector={showPortletSelector}
              onChangePortletDisplay={onChangePortletDisplay}
              searchHistoryId={searchHistoryId}
              retrievePortals={retrievePortals}
              handlePayEndDateChange={handlePayEndDateChange}
              payEndDate={payEndDate}
              showAnnounceList={this.showAnnounceList}
              isAdmin={isAdmin}
              benefitsData={benefitsData}
              benefitsPortalActive={benefitsPortalActive}
              benefitsDefaultActive={benefitsDefaultActive}
              populateBenefitsPortal={populateBenefitsPortal}
              deactivateBenefits={deactivateBenefits}
              fetchBenefits={fetchBenefits}
              newHires={newHires}
              locations={locations}
              selectedLocation={selectedLocation}
              locationFilter={locationFilter}
              managers={managers}
              departments={departments}
              hireDates={hireDates}
              jobTitles={jobTitles}
              handleNewHireDisplay={this.props.handleNewHireDisplay}
              displayNewHires={displayNewHires}
              filterNewHires={this.props.filterNewHires}
              // addRemoveDepartmentFilter={idx =>
              //   this.props.addRemoveDepartmentFilter(idx)
              // }
              // setAllDepartments={show => this.props.setAllDepartments(show)}
              changeDepartmentDisplay={idx =>
                this.props.changeDepartmentDisplay(idx)
              }
              addRemoveManagerFilter={idx =>
                this.props.addRemoveManagerFilter(idx)
              }
              setAllManagers={show => this.props.setAllManagers(show)}
              addRemoveLocationFilter={idx =>
                this.props.addRemoveLocationFilter(idx)
              }
              populateNewHires={refresh => this.props.populateNewHires(refresh)}
            />
          </div>
        </div>
        <div style={{ display: displaySearchResults }}>
          <ServiceNowSearch

            showWorkGroup={this.showWorkGroup}
            WorkGroupData={WorkGroupData}
            fetchWorkGroupData={fetchWorkGroupData}
            orgChartData={orgChartData}
            fetchOrgChartData={fetchOrgChartData}
            showOrgChart={this.showOrgChart}
            fetchAdminNameData={fetchAdminNameData}
            AdminNameData={AdminNameData}
            reSearchKnowledge={reSearchKnowledge}
            restrictKnowledgeData={restrictKnowledgeData}
            loadMoreKnowledge={loadMoreKnowledge}
            user={user}
            searchQuery={searchQuery}
            searchData={searchData}
            searching={searching}
            searchingCallup={searchingCallup}
            unfilteredNumber={unfilteredNumber}
            filteredNumber={filteredNumber}
            searchHistoryId={searchHistoryId}
            callUpSNData={callUpSNData}
            Callup_Image={Callup_Image}
            HRK_Error={HRK_Error}
            People_Error={People_Error}
            HRK_Display={HRK_Display}
            People_Display={People_Display}
            onSearchQueryUpdate={searchQuery => this.submitSearch(searchQuery)}
            onSearchQueryUpdateCallup={searchQuery =>
              this.submitSearch(searchQuery)
            }
            handleManagerHyperlink={searchQuery =>
              this.managerSearch(searchQuery)
            }
            onSearchQueryAPS={(short_name, body_post, body_post2) =>
              this.apsSearch(short_name, body_post, body_post2)
            }
            showAdvancedSearch={this.showAdvancedSearch}
          />
        </div>
        <div style={{ display: displayAdvancedPeopleSearch }}>
          <AdvancedPeopleSearch
            onSearchQueryAPS={(short_name, body_post, body_post2) =>
              this.apsSearch(short_name, body_post, body_post2)
            }
            fetchDataFile={fetchDataFile}
            fetchDataFileAllowed={fetchDataFileAllowed}
            dataFileResponse={dataFileResponse}
            dataFileAllowed={dataFileAllowed}
          />
        </div>
        <div style={{ display: displayOrgChart }}>
          <PeopleOrgChart
            onSearchQueryAPS={(short_name, body_post, body_post2) =>
              this.apsSearch(short_name, body_post, body_post2)
            }
            handleManagerHyperlink={searchQuery =>
              this.managerSearch(searchQuery)
            }
            showOrgChart={this.showOrgChart}
            orgChartDisplay={orgChartDisplay}
            orgChartData={orgChartData}
            fetchOrgChartData={fetchOrgChartData}
            showSearchResults={this.showSearchResults}
            Callup_Image={Callup_Image}
            orgChart_Image={orgChart_Image}
            AdminNameData={AdminNameData}
            WorkGroupData={WorkGroupData}
            fetchWorkGroupData={fetchWorkGroupData}
            showWorkGroup={this.showWorkGroup}
          />
        </div>
        <div style={{ display: displayWorkGroup }}>
          <PeopleWorkGroup
            onSearchQueryAPS={(short_name, body_post, body_post2) =>
              this.apsSearch(short_name, body_post, body_post2)
            }
            handleManagerHyperlink={searchQuery =>
              this.managerSearch(searchQuery)
            }
            orgChartData={orgChartData}
            fetchOrgChartData={fetchOrgChartData}
            showSearchResults={this.showSearchResults}
            Callup_Image={Callup_Image}
            orgChart_Image={orgChart_Image}
            AdminNameData={AdminNameData}
            WorkGroupData={WorkGroupData}
            workGroupDisplay={workGroupDisplay}
            fetchWorkGroupData={fetchWorkGroupData}
            showWorkGroup={this.showWorkGroup}
            showOrgChart={this.showOrgChart}
          />
        </div>
        <div style={{ display: displayAnnounceList }}>
          <AnnouncementList
            displayAnnounceList={displayAnnounceList}
            user={user}
            searchQuery={searchQuery}
            searchData={searchData}
            showDefault={this.showDefault}
            isAdmin={isAdmin}
            showAnnounceEdit={this.showAnnounceEdit}
            announcementId={announcementId}
            showAnnounceList={this.showAnnounceList}
            forceAnnounceSearchReload={forceAnnounceSearchReload}
            onAnnounceIdUpdate={announcmentId =>
              this.props.onAnnouncementIdUpdate(announcmentId)
            }
          />
        </div>
        <div style={{ display: displayAnnounceEdit }}>
          <AnnouncementEdit
            displayAnnounceEdit={displayAnnounceEdit}
            user={user}
            searchQuery={searchQuery}
            searchData={searchData}
            showDefault={this.showDefault}
            announcementId={announcementId}
            showAnnounceList={this.showAnnounceList}
            workingAnnouncement={workingAnnouncement}
            onWorkingAnnouncementChange={onWorkingAnnouncementChange}
            onAnnounceIdUpdate={announcmentId =>
              this.props.onAnnouncementIdUpdate(announcmentId)
            }
            isAdmin={isAdmin}
          />
        </div>
      </React.Fragment>
    );
  }

  render() {
    const {
      user,
      searchQuery,
      isAdmin,
      menuExpanded,
      changeMenuExpanded
    } = this.props;
    // const userInfo = sessionStorage.getItem("userinfo");
    const contact = JSON.parse(sessionStorage.getItem("contact"));
    if (!contact) {
      return (
        <div>
          <Spinner />
        </div>
      );
    } else {
      return (
        <React.Fragment>
          <div
            style={{
              display: "block",
              // height: "150px",
              // position: "fixed",
              width: "100%",
              top: "0px",
              left: "0px",
              zIndex: "150"
            }}
          >
            <Header
              handleNewHireDisplay ={this.props.handleNewHireDisplay}
              menuExpanded={menuExpanded}
              changeMenuExpanded={changeMenuExpanded}
              user={user}
              searchQuery={searchQuery}
              showDefault={this.showDefault}
              showAnnounceList={this.showAnnounceList}
              showAdminUserList={this.showAdminUserList}
              showMessage={this.showMessage}
              openLinkInNewWindow={this.openLinkInNewWindow}
              isAdmin={isAdmin}
              onSearchQueryUpdate={searchQuery =>
                this.submitSearch(searchQuery)
              }
              onSearchQueryUpdateCallup={searchQuery =>
                this.submitSearch(searchQuery)
              }
              handleManagerHyperlink={searchQuery =>
                this.managerSearch(searchQuery)
              }
              onSearchQueryAPS={(short_name, body_post, body_post2) =>
                this.apsSearch(short_name, body_post, body_post2)
              }
            />
          </div>
          <div
            style={{
              display: "block",
              // marginTop: "150px",
              width: "100%"
            }}
          >
            {this.renderDisplay()}
            <Footer
              showAnnounceList={this.showAnnounceList}
              isAdmin={isAdmin}
            />
          </div>
          {/* <button onClick={this.testRefresh}>refrest test</button> */}

          <Authorization onUserUpate={user => this.props.onUserUpate(user)} />
        </React.Fragment>
      );
    }
  }
}

export default Home;

import React, { Component } from "react";
import SearchBar from "./searchBar";
// import Logo from "../nw-logo-name.png";
import MenuBar from "./menubar";
import { isBrowser, isEdge } from "react-device-detect";

class Header extends Component {
  goToLeaderConnect() {
    window.location.href = "https://leaderconnect.nationwide.com";
  }

  render() {
    const {
      user,
      onSearchQueryUpdate,
      onSearchQueryUpdateCallup,
      searchQuery,
      showDefault,
      showMessage,
      openLinkInNewWindow,
      callUpSNData,
      menuExpanded,
      changeMenuExpanded
    } = this.props;

    let isManager = false;
    const contact = JSON.parse(sessionStorage.getItem("contact"));
    if (contact) {
      if (contact.managerFlag === "1" || contact.isPortalAdmin === "Y") {
        isManager = true;
      }
    }

    // console.log("header isManager", isManager);
    // console.log("header isBrowser", isBrowser);
    // console.log("header isEdge", isEdge);
    const showLeaderConnectLink = isManager && (isBrowser || isEdge);

    return (
      <React.Fragment>
        <header role="banner">
          <div className="topStrip topColor blockHideWhenMobile">
            <div className="container">
              <div className="row">
                <div className="col" style={{ textAlign: "right" }}>
                  {showLeaderConnectLink ? (
                    <button
                      onClick={() => this.goToLeaderConnect()}
                      className="btn leaderConnectButton"
                    >
                      <span style={{ color: "#c7d0dc", fontSize: "15px" }}>
                        Switch to{" "}
                      </span>
                      <span style={{ color: "white" }}>Leader</span>
                      <span style={{ color: "#c7d0dc" }}>Connect</span>
                    </button>
                  ) : (
                    <React.Fragment></React.Fragment>
                  )}
                </div>
                <div className="col col-lg-3 welcomediv">
                  Welcome back, {user ? user.firstName : ""}{" "}
                  {user ? user.lastName : ""}
                </div>
              </div>
            </div>
          </div>
          <div className="header">
            <div className="container">
              <div className="row" style={{ whiteSpace: "nowrap" }}>
                <div className="xcol-2 hideWhenMobile">
                  <div className="nwlogo" title="Nationwide Logo"></div>
                  <div
                    className="hrlogo"
                    onClick={showDefault}
                    style={{ cursor: "pointer" }}
                    title="My Hr Dashboard"
                  ></div>
                  <div
                    className="showInlineWhenMobile"
                    style={{
                      paddingTop: "28px",
                      paddingRight: "28px",
                      float: "right"
                    }}
                  >
                    <button
                      onClick={changeMenuExpanded}
                      className={
                        menuExpanded
                          ? "btn btn-link menuDisplayed"
                          : "btn btn-link menuClosed"
                      }
                    ></button>
                  </div>
                </div>

                <div className="hideWhenMobile xcol">
                  <MenuBar
                    handleNewHireDisplay ={this.props.handleNewHireDisplay}
                    menuExpanded={menuExpanded}
                    showMessage={showMessage}
                    openLinkInNewWindow={openLinkInNewWindow}
                    showDefault={showDefault}
                    changeMenuExpanded={changeMenuExpanded}
                    mobile={false}
                  />
                </div>
                <div className="searchFieldContainer hideWhenMobile xcol-2">
                  <SearchBar
                    searchQuery={searchQuery}
                    onSearchQueryUpdate={searchQuery =>
                      onSearchQueryUpdate(searchQuery)
                    }
                    onSearchQueryUpdateCallup={searchQuery =>
                      onSearchQueryUpdateCallup(searchQuery)
                    }
                    callUpSNData={callUpSNData}
                    mobile={false}
                  />
                </div>
                <div
                  className="showWhenMobile"
                  style={{ width: "100%", paddingLeft: "10px" }}
                >
                  <div
                    className="nwlogo"
                    title="Nationwide Logo"
                    style={
                      menuExpanded
                        ? { display: "none" }
                        : { display: "inline-block" }
                    }
                  ></div>
                  <div
                    className="hrlogo"
                    onClick={showDefault}
                    style={
                      menuExpanded
                        ? { display: "none" }
                        : { display: "inline-block", cursor: "pointer" }
                    }
                    title="My Hr Dashboard"
                  ></div>
                  <div
                    className="showInlineWhenMobile"
                    style={{
                      paddingTop: "28px",
                      paddingRight: "7px",
                      float: "right"
                    }}
                  >
                    <button
                      id="mobileMenuHideShowButton"
                      onClick={changeMenuExpanded}
                      className={
                        menuExpanded
                          ? "btn btn-link menuDisplayed"
                          : "btn btn-link menuClosed"
                      }
                    ></button>
                  </div>
                </div>
                <div
                  className="showWhenMobile"
                  style={{ marginLeft: "10px", width: "100%" }}
                >
                  <div
                    className="searchFieldContainer"
                    id="searchDiv"
                    style={
                      menuExpanded
                        ? { display: "block", width: "100%" }
                        : { display: "none" }
                    }
                  >
                    <SearchBar
                      searchQuery={searchQuery}
                      onSearchQueryUpdate={searchQuery =>
                        onSearchQueryUpdate(searchQuery)
                      }
                      onSearchQueryUpdateCallup={searchQuery =>
                        onSearchQueryUpdateCallup(searchQuery)
                      }
                      callUpSNData={callUpSNData}
                      mobile={true}
                    />
                  </div>
                </div>
                <div className="showWhenMobile" style={{ width: "100%" }}>
                  <MenuBar
                    handleNewHireDisplay ={this.props.handleNewHireDisplay}
                    menuExpanded={menuExpanded}
                    showMessage={showMessage}
                    openLinkInNewWindow={openLinkInNewWindow}
                    showDefault={showDefault}
                    changeMenuExpanded={changeMenuExpanded}
                    mobile={true}
                  />
                </div>
              </div>
            </div>
          </div>
        </header>
      </React.Fragment>
    );
  }
}
export default Header;

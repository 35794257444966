import React, { Component } from "react";
import Overlay from "react-bootstrap/Overlay";
import Popover from "react-bootstrap/Popover";
import { isMobile } from "react-device-detect";
import {
  CollapsedPortlet,
  PortletError,
  PortletLoading,
  PortletwithDate
} from "../common/portlet";
import Spinner from "../common/spinner";
import config from "../config.json";
import productsList from "../products.json";

class Product extends Component {
  state = {
    blurred: "yes",
    blurImage: "eye-show",
    displayInfo: false,
    displayInfo2: false
  };

  showInformation = () => {
    const { displayInfo } = this.state;
    console.log("Product.displayInfo", displayInfo);
    this.setState({ displayInfo: !displayInfo });
  };

  showInformation2 = event => {
    const { displayInfo2 } = this.state;
    const target2 = event.target;
    // console.log("MyCalendar.displayInfo", displayInfo);
    this.setState({ displayInfo2: !displayInfo2, target2 });
  };

  handleBlurChange = () => {
    if (this.state.blurred === "yes") {
      this.setState({ blurred: "no", blurImage: "eye-show" });
    } else {
      this.setState({ blurred: "yes", blurImage: "eye-hide" });
    }
  };

  handleRefresh = async () => {
    this.props.fetchProductData();
  };

  returnContent(productData, productDefaultActive, deactivateProduct) {
    if (productData) {
      // if (1 == 1) {
      // if (this.state.blurred === "yes") {
      //   console.log("We are in a blurred state!");
      // } else {
      //   console.log("Not a blurred state!");
      // }
      return productData.error ? (
        // return 1 != 1 ? (
        <div>
          <div className="tile-apierroricon">
            <div className="portletErrorAlert">!</div>
            {/* <bolt-icon name= "notification-error" label = "error" color = "blue" size = "lg"></bolt-icon> */}
          </div>
          <div className="tile-apierrortext">Oops, this is unexpected.</div>
          <div className="tile-apierrortext1">Error code: 500</div>
          <div className="tile-apierrortext1">
            An error has occurred and we’re working to fix the issue.
          </div>
          <div className="tile-apierrortext1">
            <span>If the issue persists please </span>
            <a
              className="tile-apierrorcontactus"
              href="https://nwproduction.service-now.com/csm"
              rel="noopener noreferrer"
              target="_blank"
            >
              contact us.
            </a>
          </div>
          <div
            className="tile-apierror-refresh"
            onClick={this.handleRefresh}
            title="Refresh"
          >
            Refresh
          </div>
          <div style={{ paddingTop: "25px" }}></div>
        </div>
      ) : (
        <div>
          {this.renderTile(
            productData,
            productDefaultActive,
            deactivateProduct
          )}
        </div>
      );
    } else {
      return <Spinner />;
    }
  }

  renderTile(productData, productDefaultActive, deactivateProduct) {
    //let productsEnrolled = ["Auto"];
    let productsEnrolled = productData;
    let productsAll = productsList.Products_All;
    let availableProducts = productsList.Available_Products;

    let noProduct = productsEnrolled.length > 0 ? false : true;

    if (this.state.blurred === "yes") {
      noProduct = true;
    } else {
      noProduct = productsEnrolled.length > 0 ? false : true;
    }

    if (noProduct) {
      return (
        <div>
          {this.header(deactivateProduct, productDefaultActive)}
          <hr className="tile-line" />
          {this.noEnrollment()}
          <div className="tile-subbox2" style={{ marginTop: "15px" }}>
            <div className="row">
              <div className="eeBottomText">
                The one-stop shop to make it effortless for you to learn more
                about Nationwide products and discounts exclusive to you.
              </div>
              <div className="row">
                <div className="buttonProduct tile-rectangle">
                  <img
                    className="tile-externallink"
                    alt="external-link"
                    src="/external-link.svg"
                  />
                  <a
                    className="tile-recttitle"
                    href={config.ETE_Link}
                    rel="noopener noreferrer"
                    target="_blank"
                    title="Link - Experience the Extraordinary website"
                  >
                    Experience the Extraordinary
                  </a>
                </div>
                <div className="buttonProduct2 tile-rectangle">
                  <img
                    className="tile-externallink"
                    alt="external-link"
                    src="/external-link.svg"
                  />
                  <a
                    className="tile-recttitle"
                    href={config.Agent_Locator}
                    rel="noopener noreferrer"
                    target="_blank"
                    title="Use our online Agent Locator tool to find a new agent in your area or find your existing agent"
                  >
                    Agent Locator
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      let notEnrolled = this.calcNotEnrolled(productsEnrolled, availableProducts);
      return (
        <div>
          {this.header(deactivateProduct, productDefaultActive)}
          <hr className="tile-line" />
          <div className={this.state.blurred === "yes" ? "obfuscate" : ""}>
            <div className="row">
              <div className="eeTopText">Your current Nationwide products</div>
              {/* <span className="infoLink" onClick={this.showInformation}>
                {this.state.displayInfo ? "X" : "?"}
              </span> */}
            </div>
            <div className="row">
              {this.currentProducts(productsEnrolled, productsAll)}
            </div>
            <div style={{ paddingLeft: "20px" }}>
              <hr className="tile-line" style={{ marginTop: "10px" }} />
            </div>
            <div
              className="eePlain"
              style={
                notEnrolled.length > 0
                  ? { paddingLeft: "25px", paddingRight: "25px" }
                  : { display: "none" }
              }
            >
              Consider how Nationwide products could meet your protection needs
              and earn you exclusive associate discounts.
            </div>
            <div className="row">{this.offerProducts(notEnrolled)}</div>
          </div>
          <div className="tile-subbox2" style={{ marginTop: "15px" }}>
            <div className="row">
              <div className="eeBottomText">
                The one-stop shop to make it effortless for you to learn more
                about Nationwide products and discounts exclusive to you.
              </div>

              <div className="row">
                <div className="buttonProduct tile-rectangle">
                  <img
                    className="tile-externallink"
                    alt="external-link"
                    src="/external-link.svg"
                  />
                  <a
                    className="tile-recttitle"
                    href={config.ETE_Link}
                    rel="noopener noreferrer"
                    target="_blank"
                    title="Link - Experience the Extraordinary website"
                  >
                    Experience the Extraordinary
                  </a>
                </div>
                <div className="buttonProduct2 tile-rectangle">
                  <img
                    className="tile-externallink"
                    alt="external-link"
                    src="/external-link.svg"
                  />
                  <a
                    className="tile-recttitle"
                    href={config.Agent_Locator}
                    rel="noopener noreferrer"
                    target="_blank"
                    title="Use our online Agent Locator tool to find a new agent in your area or find your existing agent"
                  >
                    Agent Locator
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }

  goToNationwide = item => {
    if (item === "Commercial") {
      window.open("https://www.nationwide.com/business/login");
    }  else if (item === "Private Client") {
      window.open(
        "https://connect.nationwideprivateclient.com/#/access/signin"
      );
      }else {
      window.open("https://www.nationwide.com/personal/login");
    }
  };

  goToNationwideOffers = item => {
    if (item === "Commercial") {
      window.open("https://ete.apps.nwie.net/commercialAgribusiness");
    }
    if (item === "Pet") {
      window.open("https://ete.apps.nwie.net/pet");
    }
    if (item === "Life") {
      window.open("https://ete.apps.nwie.net/lifeInsurance");
    }
    if (item === "Annuities") {
      window.open("https://ete.apps.nwie.net/annuities");
    }
    if (item === "Renters") {
      window.open("https://ete.apps.nwie.net/renters");
    }
    if (item === "Boat") {
      window.open("https://ete.apps.nwie.net/boat");
    }
    if (item === "Homeowners") {
      window.open("https://ete.apps.nwie.net/homeowners");
    }
    if (item === "Auto") {
      window.open("https://ete.apps.nwie.net/auto");
    }
    if (item === "Umbrella") {
      window.open("https://ete.apps.nwie.net/umbrella");
    }
    if (item === "Condo") {
      window.open("https://ete.apps.nwie.net/condo");
    }
    if (item === "Motorcycle") {
      window.open("https://ete.apps.nwie.net/motorcycle");
    }
    if (item === "RV") {
      window.open("https://ete.apps.nwie.net/rv");
    }
  };

  currentProducts(productsEnrolled, productsAll) {
    productsEnrolled = this.orderProducts(productsEnrolled, productsAll);
    return (
      <div
        className={
          productsEnrolled.length > 3
            ? "scrollingProduct"
            : "noScrollingProduct"
        }
      >
        {productsEnrolled.map((item, index) => (
          <div>
            <div
              className="productEntry"
              onClick={() => this.goToNationwide(item)}
            >
              <div className="container">
                <div className="row">
                  <div>
                    <div className="checkMarkDiv">
                      <div className="checkMark"></div>
                    </div>
                  </div>
                  <div className="col">
                    <div className="eeLinktext" style={{ marginTop: "10px" }}>
                      {item}
                    </div>
                  </div>
                  <a className="col nationwideLogin">
                    <u>Go to Nationwide.com</u>
                  </a>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  }

  calcNotEnrolled(productsEnrolled, availableProducts) {
    let output = [];
    let count = 0;
    for (let i = 0; i < availableProducts.length; i++) {
      if (!productsEnrolled.includes(availableProducts[i])) {
        output[count] = availableProducts[i];
        count++;
      }
    }
    return this.orderProducts(output, availableProducts);
  }

  header(deactivateProduct, productDefaultActive) {
    return (
      <div>
        <h2 className="tile-header row" style={{ marginLeft: "0px" }}>
          <button
            style={
              productDefaultActive === "N"
                ? { display: "inline-block" }
                : { display: "none" }
            }
            onClick={deactivateProduct}
            className="link"
          >
            <img
              src="/chevron_up.png"
              alt="Click to collapse Nationwide Products"
              width="19px"
              style={{ marginRight: "10px" }}
            />
          </button>
          Nationwide Products
          <div className="infoContainer4">
            <button
              onClick={e => this.showInformation2(e)}
              className="infoLink"
              title="Disclaimer"
            >
              {this.state.displayInfo2 ? "X" : "?"}
            </button>
          </div>
          <Overlay
            show={this.state.displayInfo2}
            target={this.state.target2}
            placement="bottom"
          >
            <Popover id="Disclaimer PopOver">
              {/* <Popover.Title as="div">Disclaimer</Popover.Title> */}
              <Popover.Content>
                If you are not the primary owner (named insured) listed on a
                policy you may not see that particular policy appear here.
                <br></br>
                <br></br>
                If you own a motorcycle policy, it may not be individually
                reflected as it is currently recognized in our data as an
                additional vehicle on your Auto policy. You should see an Auto
                policy reflected in this scenario.
                <br></br>
                <br></br>
                You may own other products that are not displayed here. We hope
                to continue to iterate this HR tile to include more product
                lines in the future.
              </Popover.Content>
            </Popover>
          </Overlay>
        </h2>

        <div className="tile-refresh">
          <button
            className="btn btn-link"
            onClick={this.handleRefresh}
            title="Refresh"
          >
            <img
              style={{
                height: "14px",
                width: "14px"
              }}
              alt="refresh"
              src="/refresh.svg"
            />
          </button>
        </div>
        <div className="tile-blur">
          <button
            className="btn btn-link"
            onClick={this.handleBlurChange}
            title={
              this.state.blurred === "yes" ? "Click to show" : "Click to hide"
            }
          >
            <img
              style={{
                height: "20px",
                width: "20px"
              }}
              alt={this.state.blurred === "yes" ? "click to show nationwide products" : "click to hide nationwide products"}
              src={
                this.state.blurred === "yes" ? "/eye-blue.png" : "hide-blue.png"
              }
            />
          </button>
        </div>
      </div>
    );
  }

  orderProducts(products, productsAll) {
    let output = [];
    let count = 0;
    for (let i = 0; i < productsAll.length; i++) {
      if (products.includes(productsAll[i])) {
        output[count] = productsAll[i];
        count++;
      }
    }
    return output;
  }

  offerProducts(productsNotEnrolled) {
    // Calculate amount in col 1
    let l1 = Math.ceil(productsNotEnrolled.length / 2);
    // Slice array
    let a1 = productsNotEnrolled.slice(0, l1),
      a2 = productsNotEnrolled.slice(l1, productsNotEnrolled.length);
      //console.log("products offered for not enrolled ", productsNotEnrolled)

    // Set image urls

    return (
      <div className="container" style={{ marginTop: "10px" }}>
        <div className="row">
          <div className="col">
            {a1.map((item, index) => (
              <div
                className="row"
                style={
                  item.length > 17
                    ? { paddingLeft: "40px", display: "inline-block" }
                    : { paddingLeft: "40px" }
                }
              >
                <div className="iconContainer">
                  <img
                    src={"/" + item.replaceAll(" ", "-") + "-product-icon.svg"}
                    alt="Icon"
                  />
                </div>
                <a
                  className="eeLinktext"
                  onClick={() => this.goToNationwideOffers(item)}
                  rel="noopener noreferrer"
                  target="blank"
                  title={"Click - " + item + " ETE link"}
                >
                  <u>{item}</u>
                </a>
              </div>
            ))}
          </div>
          <div className="col">
            {a2.map((item, index) => (
              <div
                className="row"
                style={
                  item.length > 17
                    ? { marginRight: "40px", display: "inline-block" }
                    : { marginRight: "35px" }
                }
              >
                <div className="iconContainer">
                  <img
                    src={"/" + item.replaceAll(" ", "-") + "-product-icon.svg"}
                    alt="Icon"
                  />
                </div>
                <a
                  className="eeLinktext"
                  onClick={() => this.goToNationwideOffers(item)}
                  rel="noopener noreferrer"
                  target="blank"
                  title={"Click - " + item + " ETE link"}
                >
                  <u>{item}</u>
                </a>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }

  noEnrollment() {
    console.log("ismobile: ", isMobile);
    // let isMobile2 = true;
    return (
      <div className={this.state.blurred === "yes" ? "obfuscate" : ""}>
        <div className="container-fluid">
          <div
            className="productBanner"
            style={isMobile ? { display: "block" } : { display: "none" }}
          >
            <div className="col">
              <div className="eeTitle">
                <div className="row" style={{ marginLeft: "1px" }}>
                  <b>Experience</b>
                </div>
                <div className="row" style={{ marginLeft: "1px" }}>
                  <b>the Extraordinary</b>
                </div>
              </div>
              <div className="row eeSubtext">
                <b>You work hard for us.</b>
              </div>
              <div className="row eeSubtext">
                <b>Let us work hard for you.</b>
              </div>
              <div style={{ paddingBottom: "12px" }}></div>
            </div>
          </div>
          <div
            className="productBannerImage"
            style={isMobile ? { display: "none" } : { display: "block" }}
          >
            <img src="/EE_Image.png" width="445px" height="100px" />
          </div>
          <div className="eePlain">
            Consider how Nationwide products could meet your protection needs
            and earn you exclusive associate discounts.
          </div>
          <div style={{ paddingBottom: "12px" }}></div>
          <div className="container">
            <div className="row">
              <div className="col">
                <div className="row">
                  <div className="iconContainer">
                    <img src="/Auto-product-icon.svg" alt="Auto Icon" />
                  </div>
                  <a
                    className="eeLinktext"
                    href="https://ete.apps.nwie.net/auto"
                    rel="noopener noreferrer"
                    target="blank"
                    title="Click - ETE Auto link"
                  >
                    <u>Auto</u>
                  </a>
                </div>
                <div className="row">
                  <div className="iconContainer">
                    <img
                      src="/Homeowners-product-icon.svg"
                      alt="Homeowners Icon"
                    />
                  </div>
                  <a
                    className="eeLinktext"
                    href="https://ete.apps.nwie.net/homeowners"
                    rel="noopener noreferrer"
                    target="blank"
                    title="Click - ETE Homeowners link"
                  >
                    <u>Homeowners</u>
                  </a>
                </div>
                <div className="row">
                  <div className="iconContainer">
                    <img src="/Condo-product-icon.svg" alt="Condo Icon" />
                  </div>
                  <a
                    className="eeLinktext"
                    href="https://ete.apps.nwie.net/condo"
                    rel="noopener noreferrer"
                    target="blank"
                    title="Click - ETE Condo link"
                  >
                    <u>Condo</u>
                  </a>
                </div>
                <div className="row">
                  <div className="iconContainer">
                    <img src="/Renters-product-icon.svg" alt="Renters Icon" />
                  </div>
                  <a
                    className="eeLinktext"
                    href="https://ete.apps.nwie.net/renters"
                    rel="noopener noreferrer"
                    target="blank"
                    title="Click - ETE Renters link"
                  >
                    <u>Renters</u>
                  </a>
                </div>
                <div className="row">
                  <div className="iconContainer">
                    <img src="/Boat-product-icon.svg" alt="PC Icon" />
                  </div>
                  <a
                    className="eeLinktext"
                    href="https://ete.apps.nwie.net/boat"
                    rel="noopener noreferrer"
                    target="blank"
                    title="Click - ETE Boat link"
                  >
                    <u>Boat</u>
                  </a>
                </div>
                <div className="row">
                  <div className="iconContainer">
                    <img src="/RV-product-icon.svg" alt="PC Icon" />
                  </div>
                  <a
                    className="eeLinktext"
                    href=" https://ete.apps.nwie.net/rv"
                    rel="noopener noreferrer"
                    target="blank"
                    title="Click - ETE RV link"
                  >
                    <u>RV</u>
                  </a>
                </div>
              </div>

              {/* Start next column */}
              <div className="col">
                <div className="row">
                  <div className="iconContainer">
                    <img
                      src="/Motorcycle-product-icon.svg"
                      alt="Motorcyle Icon"
                    />
                  </div>
                  <a
                    className="eeLinktext"
                    href="https://ete.apps.nwie.net/motorcycle"
                    rel="noopener noreferrer"
                    target="blank"
                    title="Click - ETE Motorcycle link"
                  >
                    <u>Motorcycle</u>
                  </a>
                </div>
                <div className="row">
                  <div className="iconContainer">
                    <img src="/Life-product-icon.svg" alt="Life Icon" />
                  </div>
                  <a
                    className="eeLinktext"
                    href="https://ete.apps.nwie.net/lifeInsurance"
                    rel="noopener noreferrer"
                    target="blank"
                    title="Click - ETE Life link"
                  >
                    <u>Life</u>
                  </a>
                </div>
                <div className="row">
                  <div className="iconContainer">
                    <img src="/Pet-product-icon.svg" alt="Pet Icon" />
                  </div>
                  <a
                    className="eeLinktext"
                    href="https://ete.apps.nwie.net/pet"
                    rel="noopener noreferrer"
                    target="blank"
                    title="Click - ETE Pet link"
                  >
                    <u>Pet</u>
                  </a>
                </div>
                {/* <div className="row">
                  <img
                    src="/Commercial-product-icon.svg"
                    alt="Commerical Icon"
                  />
                  <a
                    className="eeLinktext"
                    href="https://ete.apps.nwie.net/commercialAgribusiness"
                    rel="noopener noreferrer"
                    target="blank"
                  >
                    <u>Commercial</u>
                  </a>
                </div> */}
                <div className="row">
                  <div className="iconContainer">
                    <img src="/Umbrella-product-icon.svg" alt="Umbrella Icon" />
                  </div>
                  <a
                    className="eeLinktext"
                    href="https://ete.apps.nwie.net/umbrella"
                    rel="noopener noreferrer"
                    target="blank"
                    title="Click - ETE Umbrella link"
                  >
                    <u>Umbrella</u>
                  </a>
                </div>
                <div className="row">
                  <div className="iconContainer">
                    <img
                      src="/Annuities-product-icon.svg"
                      alt="Shield Icon"
                    />
                  </div>
                  <a
                    className="eeLinktext"
                    href="https://ete.apps.nwie.net/annuities"
                    rel="noopener noreferrer"
                    target="blank"
                    title="Click - ETE Annuities link"
                  >
                    <u>Annuities</u>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    const {
      activePortal,
      productData,
      activateProductPortal,
      fetchProductData,
      deactivateProduct,
      productDefaultActive
    } = this.props;
    console.log("tile product data", productData);
    if (activePortal === "Y") {
      // if (1 == 1) {
      if (productData) {
        // if (1 == 1) {
        const content = this.returnContent(
          productData,
          productDefaultActive,
          deactivateProduct
        );
        if (productData.error) {
          // if (1 != 1) {
          return <PortletError content={content} />;
        } else {
          return <PortletwithDate content={content} datetimeLabel={"As of"} />;
        }
      } else {
        activateProductPortal();
        return <PortletLoading title="Nationwide Products" />;
      }
    } else {
      return (
        <CollapsedPortlet
          dataFetch={fetchProductData}
          title={"Nationwide Products"}
        ></CollapsedPortlet>
      );
    }
  }
}

export default Product;

import React from "react";
// import {
//   BrowserView,
//   MobileView,
//   isBrowser,
//   isMobile
// } from "react-device-detect";

export const Portlet = ({ content }) => {
  return (
    <div className="portletContainer">
      <div className="portletContent">{content}</div>
    </div>
  );
};

export const CollapsedPortlet = ({ dataFetch, title }) => {
  return (
    <div className="portletContainerCollapsed">
      <h2 className="portletContentCollapsed tile-header">
        <button onClick={dataFetch} className="link">
          <img
            src="/chevron_down.png"
            alt={"Click to expand tile " + title}
            width="19px"
            style={{ marginRight: "10px" }}
          />
        </button>
        {title}{" "}
      </h2>
    </div>
  );
};
export const PortletLoading = ({ title }) => {
  return (
    <div className="portletContainer">
      <div className="portletContent">
        <div>
          <h2 className="tile-header">
            <button onClick={null} className="link">
              <img
                src="/chevron_up.png"
                alt="Click to expand tile"
                width="19px"
                style={{ marginRight: "10px" }}
              />
            </button>
            {title}
          </h2>
          <hr className="tile-line" />
          <div
            className="progress-spinner "
            style={{ left: "50%", top: "50%" }}
          />
        </div>
      </div>
    </div>
  );
};

export const WorkdayPortlet = ({ content }) => {
  return (
    <div className="portletContainer WorkdayPortlet">
      <div className="portletContent">{content}</div>
      <div>
        <a
          className="wdPortletFooter"
          href="https://www.myworkday.com/nationwide/d/home.htmld"
          rel="noopener noreferrer"
          target="_blank"
        >
          <img src="/poweredbywd1.png" alt="Workday" />
        </a>
      </div>
    </div>
  );
};

export const WorkdayPortlets = ({ content }) => {
  return (
    <div className="portletContainer WorkdayPortlet">
      <div className="portletContent">{content}</div>
      <div>
        <a
          className="wdPortletFooter"
          href="https://www.myworkday.com/nationwide/d/home.htmld"
          rel="noopener noreferrer"
          target="_blank"
        >
          <img src="/poweredbywd.png" alt="Workday" />
        </a>
      </div>
    </div>
  );
};

export const WorkdayPortletwithDate = ({ content, datetimeLabel }) => {
  var today = new Date();
  var M = today.getMonth() + 1;
  var D = today.getDate();
  const YY = "" + today.getFullYear();
  var H = today.getHours();
  var Min = today.getMinutes();
  var z = "";
  if (H === 12) {
    z = "PM";
  }
  if (H < 1) {
    H = 12;
    z = "AM";
  }
  if (H < 12) {
    z = "AM";
  }
  if (H > 12) {
    H = H - 12;
    z = "PM";
  }

  if (H < 10) {
    H = "0" + H;
  }
  if (Min < 10) {
    Min = "0" + Min;
  }
  if (M < 10) {
    M = "0" + M;
  }
  if (D < 10) {
    D = "0" + D;
  }
  const date =
    M + "/" + D + "/" + YY.substr(2, 2) + " " + H + ":" + Min + " " + z;

  return (
    <div className="portletContainer WorkdayPortlet">
      <div className="portletContent">{content}</div>
      <span
        className="tile-portletDate"
        style={{ fontFamily: "nw-primary", fontStyle: "normal" }}
      >
        {datetimeLabel} {date}
      </span>
      <a
        className="wdPortletFooter"
        href="https://www.myworkday.com/nationwide/d/home.htmld"
        rel="noopener noreferrer"
        target="_blank"
      >
        <img src="/poweredbywd.png" alt="Workday" />
      </a>
    </div>
  );
};

export const WorkdayPortletNSP = ({ content }) => {
  var today = new Date();
  var M = today.getMonth() + 1;
  var D = today.getDate();
  const YY = "" + today.getFullYear();
  var H = today.getHours();
  var Min = today.getMinutes();
  var z = "";
  if (H === 12) {
    z = "PM";
  }
  if (H < 1) {
    H = 12;
    z = "AM";
  }
  if (H < 12) {
    z = "AM";
  }
  if (H > 12) {
    H = H - 12;
    z = "PM";
  }

  if (H < 10) {
    H = "0" + H;
  }
  if (Min < 10) {
    Min = "0" + Min;
  }
  if (M < 10) {
    M = "0" + M;
  }
  if (D < 10) {
    D = "0" + D;
  }
  const date =
    M + "/" + D + "/" + YY.substr(2, 2) + " " + H + ":" + Min + " " + z;

  return (
    <div className="portletContainer WorkdayPortlet NSPPortlet">
      <div className="portletContent">{content}</div>
      <span
        className="tile-portletDate"
        style={{ fontFamily: "nw-primary", fontStyle: "normal" }}
      >
        As of {date} Updated Bi-Weekly
      </span>
      <a
        className="wdPortletFooter"
        href="https://www.myworkday.com/nationwide/d/home.htmld"
        rel="noopener noreferrer"
        target="_blank"
      >
        <img src="/poweredbywd.png" alt="Workday" />
      </a>
    </div>
  );
};

export const WorkdayPortletError = ({ content }) => {
  return (
    <div className="portletContainer WorkdayPortlet">
      <div className="portletContent">{content}</div>
      <span
        className="tile-portletDate"
        style={{ fontFamily: "nw-primary", fontStyle: "normal" }}
      >
        Error
      </span>
      <a
        className="wdPortletFooter"
        href="https://www.myworkday.com/nationwide/d/home.htmld"
        rel="noopener noreferrer"
        target="_blank"
      >
        <img src="/poweredbywd.png" alt="Workday" />
      </a>
    </div>
  );
};

export const PortletError = ({ content }) => {
  return (
    <div className="portletContainer WorkdayPortlet">
      <div className="portletContent">{content}</div>
      <span
        className="tile-portletDate"
        style={{ fontFamily: "nw-primary", fontStyle: "normal" }}
      >
        Error
      </span>
    </div>
  );
};

export const PortletwithDate = ({ content, datetimeLabel }) => {
  var today = new Date();
  var M = today.getMonth() + 1;
  var D = today.getDate();
  const YY = "" + today.getFullYear();
  var H = today.getHours();
  var Min = today.getMinutes();
  var z = "";
  if (H === 12) {
    z = "PM";
  }
  if (H < 1) {
    H = 12;
    z = "AM";
  }
  if (H < 12) {
    z = "AM";
  }
  if (H > 12) {
    H = H - 12;
    z = "PM";
  }

  if (H < 10) {
    H = "0" + H;
  }
  if (Min < 10) {
    Min = "0" + Min;
  }
  if (M < 10) {
    M = "0" + M;
  }
  if (D < 10) {
    D = "0" + D;
  }
  const date =
    M + "/" + D + "/" + YY.substr(2, 2) + " " + H + ":" + Min + " " + z;

  return (
    <div className="portletContainer WorkdayPortlet">
      <div className="portletContent">{content}</div>
      <span
        className="tile-portletDate"
        style={{ fontFamily: "nw-primary", fontStyle: "normal" }}
      >
        {datetimeLabel} {date}
      </span>
    </div>
  );
};





import { getRandomString } from "../services/AuthService";
import config from "../config.json";
import axios from "axios";
import { getEnvironment } from "../services/EnvironmentVariable";
import { isMobile } from "react-device-detect";

export const GetPhotos = async callUpSNData => {
  // console.log("GetPhotos emplid", callUpSNData);
  const environ = getEnvironment();
  //getting token from Microsoft Graph From Pics
  let token = "";
  let nfound_token = true;
  let Results_Image = [];
  let Image_Array = [];
  let emplid = "";
  var curr_date = new Date();
  if (localStorage.getItem("photoexpireDate")) {
    var storage_date = new Date(localStorage.getItem("photoexpireDate"));
    if (storage_date > curr_date) {
      console.log("GetPhotos Photo Token is not yet expired");
      token = localStorage.getItem("photoToken");
      nfound_token = false;
    }
  }
  try {
    if (nfound_token) {
      let apigeelinkphoto = config.apigeelinkphoto;
      let photoKey = config.photoclient_id;
      if (environ !== "DEFAULT") {
        if (isMobile) {
          apigeelinkphoto = config.Mobile_test_apigeelinkphoto;
        } else {
          apigeelinkphoto = config.test_apigeelinkphoto;
          photoKey = config.test_photoclient_id;
        }
      }
      console.log("GetPhotos Photo Token is expired - calling apigeelinkphoto");
      const accesstoken = sessionStorage.getItem("access_token");
      const resp_test = await axios.post(
        `${apigeelinkphoto}`,
        {
          client_id: "f713acd9-62af-4103-9499-b300c9284b64",
          // "client_secret": "3*NtOBRuK2DkSI2EMNkYP+[h9/SgP0+_",
          client_secret: "-u521OlBx.~Eb6B1aLs~-vD_AW~6oVQSyX",
          scope: "https://graph.microsoft.com/.default",
          grant_type: "client_credentials"
        },
        {
          headers: {
            client_id: photoKey,
            Authorization: "Bearer " + accesstoken,
            "X-NW-Message-ID": "callupapiprod",
            "Content-Type": "application/json"
          }
        }
      );
      // console.log("photo response", resp_test);
      token = "Bearer " + resp_test.data.access_token;
      var date_called = new Date();
      date_called.setSeconds(resp_test.data.expires_in);
      localStorage.setItem("photoToken", token);
      // localStorage.setItem('tokenExp', resp_test.data.expires_in);
      localStorage.setItem("photoexpireDate", date_called);
    }
  } catch (err) {
    console.log("GetPhotos Error in apigeelinkphoto API POST:", err);
  }

  let remaining = callUpSNData ? callUpSNData.length : 0;

  for (var i = 0; i < remaining; i++) {
    emplid = callUpSNData[i].emplid;

    //getting the principle name of the Associate
    let Microsoft_array = [];
    let userPrincipalName = null;
    try {
      if (token) {
        const resp3 = await axios.get(
          `https://graph.microsoft.com/v1.0/users?$filter=extension_27d8898f82fe4d76a4e4e277e740070e_employeeNumber eq '${emplid}'`,
          {
            headers: {
              Authorization: token
            }
          }
        );

        Microsoft_array = resp3.data.value;
      }
    } catch (err) {}
    //checking for Photo
    try {
      if (Microsoft_array[0].userPrincipalName) {
        userPrincipalName = Microsoft_array[0].userPrincipalName;
        const resp4 = await axios.get(
          `https://graph.microsoft.com/v1.0/users/${Microsoft_array[0].userPrincipalName}/photo/$value`,
          {
            headers: {
              Authorization: token
            },
            responseType: "arraybuffer"
          }
        );
        const avatar = new Buffer(resp4.data, "binary").toString("base64");
        let temp_image = [];
        if (Image_Array === "") {
          temp_image = [];
        } else {
          temp_image = Image_Array;
        }
        temp_image.push({ Emplid: emplid, Image: avatar, userPrincipalName });
        Image_Array = temp_image;
      }
    } catch (err) {
      let temp_image = [];
      if (Image_Array === "") {
        temp_image = [];
      } else {
        temp_image = Image_Array;
      }
      //temp_image.push({ "Emplid": emplid, "Image": "", "matrixOrgs": matrixOrgs });
      temp_image.push({ Emplid: emplid, Image: "", userPrincipalName });
      Image_Array = temp_image;
    }
  }
  // console.log("GetPhotos Image_Array in Service", Image_Array);
  return Image_Array;
};

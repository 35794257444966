import React, { Component } from "react";
import Overlay from "react-bootstrap/Overlay";
import Popover from "react-bootstrap/Popover";
import {
  CollapsedPortlet,
  Portlet,
  PortletError,
  PortletLoading
} from "../common/portlet";
import Spinner from "../common/spinner";

class HSA extends Component {
  state = {
    blurred: "yes",
    blurImage: "eye-show",
    displayInfo: false,
    displayInfo2: false,
    displayInfo3: false,
    displayInfo4: false
  };

  handleBlurChange = () => {
    if (this.state.blurred === "yes") {
      this.setState({ blurred: "no", blurImage: "eye-show" });
    } else {
      this.setState({ blurred: "yes", blurImage: "eye-hide" });
    }
  };

  showInformation = event => {
    const { displayInfo } = this.state;
    const target = event.target;
    // console.log("MyCalendar.displayInfo", displayInfo);
    this.setState({ displayInfo: !displayInfo, target });
  };
  showInformation2 = event => {
    const { displayInfo2 } = this.state;
    const target2 = event.target;
    // console.log("MyCalendar.displayInfo", displayInfo);
    this.setState({ displayInfo2: !displayInfo2, target2 });
  };
  showInformation3 = event => {
    const { displayInfo3 } = this.state;
    const target3 = event.target;
    // console.log("MyCalendar.displayInfo", displayInfo);
    this.setState({ displayInfo3: !displayInfo3, target3 });
  };
  showInformation4 = event => {
    const { displayInfo4 } = this.state;
    const target4 = event.target;
    // console.log("MyCalendar.displayInfo", displayInfo);
    this.setState({ displayInfo4: !displayInfo4, target4 });
  };

  handleRefresh = async () => {
    this.props.retrieveData();
  };

  returnContent(data, defaultActive, deactivatePortal) {
    if (data) {
      if (this.state.blurred === "yes") {
        console.log("We are in a blurred state!");
      } else {
        console.log("Not a blurred state!");
      }
      return data.error ? (
        <div>
          <div className="tile-apierroricon">
            <div className="portletErrorAlert">!</div>
            {/* <bolt-icon name= "notification-error" label = "error" color = "blue" size = "lg"></bolt-icon> */}
          </div>
          <div className="tile-apierrortext">Oops, this is unexpected.</div>
          <div className="tile-apierrortext1">Error code: 500</div>
          <div className="tile-apierrortext1">
            An error has occurred and we’re working to fix the issue.
          </div>
          <div className="tile-apierrortext1">
            <span>If the issue persists please </span>
            <a
              className="tile-apierrorcontactus"
              href="https://nwproduction.service-now.com/csm"
              rel="noopener noreferrer"
              target="_blank"
            >
              contact us.
            </a>
          </div>
          <div
            className="tile-apierror-refresh"
            onClick={this.handleRefresh}
            title="Refresh"
          >
            Refresh
          </div>
          <div style={{ paddingTop: "25px" }}></div>
        </div>
      ) : (
        <div>{this.renderTile(data, defaultActive, deactivatePortal)}</div>
      );
    } else {
      return <Spinner />;
    }
  }

  isEmpty(obj) {
    return Object.keys(obj).length === 0;
  }

  renderTile(data, defaultActive, deactivatePortal) {
    // Info no enrollment
    let noEnrollInfo =
      "If you are newly enrolled in an HSA, the information will not populate until contributions are made.";
    // Info message Amount
    let informationMessge =
        "This balance includes pending transactions. To see your amount pending, log into your account on HealthEquity.",
      // Info message YTD
      informationMessge2 =
        "This includes your YTD contributions (including Catch-up Contributions), My Health reward dollars (if applicable), and Nationwide’s contributions.";

    let Amount = 0;
    if (this.isEmpty(data.hsaBalance)) {
      Amount = {};
    } else {
      Amount = (Math.round(data.hsaBalance * 100) / 100).toFixed(2);
    }
    let YTD_ER = data["er-ytd"];
    let YTD_EE = data["ee-ytd"]; // Using brackets because json data contains a dash. JS sees the dash as a minus sign
    let healthCredit = data["health-credit-ytd"];
    let YTD = +YTD_EE + +YTD_ER + +healthCredit; // The plus sign in front casts the value as a numeric value so that can be added instead of concat
    YTD = (Math.round(YTD * 100) / 100).toFixed(2);
    let invested = (Math.round(data.hsaInvestments * 100) / 100).toFixed(2);
    let enrolledAll =
        !this.isEmpty(data.hsaBalance) && invested > 0 ? true : false,
      enrolledOne = !this.isEmpty(data.hsaBalance) ? true : false,
      enrolledNone = this.isEmpty(data.hsaBalance) ? true : false;

    // Add commas & fraction digits to number string
    Amount = Number(Amount).toLocaleString(undefined, {
      minimumFractionDigits: 2
    });
    YTD = Number(YTD).toLocaleString(undefined, { minimumFractionDigits: 2 });
    invested = Number(invested).toLocaleString(undefined, {
      minimumFractionDigits: 2
    });

    // Demos Start
    // **1- Show no contribution
    // enrolledAll = false;
    // enrolledOne = false;
    // enrolledNone = true;

    // **2- Show balance but no investments
    // enrolledAll = false;
    // enrolledOne = true;
    // enrolledNone = false;
    // Amount = "88,240.11";
    // YTD = "1,450.32";

    // **3- Show balance enrolled with investments
    // enrolledAll = true;
    // Amount = "88,240.11";
    // YTD = "1,450.32";
    // invested = "822.21";

    // Change numbers in blur
    if (this.state.blurred === "yes") {
      Amount = "88,240.11";
      YTD = "1,450.32";
      invested = "822.21";
      enrolledAll = true;
    }

    if (enrolledAll) {
      return (
        <div>
          {this.header(deactivatePortal, defaultActive)}
          {this.blurOverlay()}
          <hr className="tile-line" />
          <div className={this.state.blurred === "yes" ? "obfuscateMore2" : ""}>
            <div className="row container">
              <div className="col" style={{ width: "48%" }}>
                <div className="row">
                  <img
                    className="pigImg"
                    alt="Piggy Bank"
                    src="/Piggy_Bank.svg"
                  />
                </div>
                <div className="row piggyText">
                  Nationwide will match your contributions up to $500/single or
                  $1,000/family coverage.
                </div>
              </div>
              <div className="col" style={{ paddingLeft: "35px" }}>
                <div className="row">
                  <div className="piggyText">Available to Spend</div>
                  <div className="infoContainer">
                    <button
                      onClick={e => this.showInformation(e)}
                      className="infoLink"
                      title="Click for more information"
                    >
                      {this.state.displayInfo ? "X" : "?"}
                    </button>
                  </div>
                  <Overlay
                    show={this.state.displayInfo}
                    target={this.state.target}
                    placement="bottom"
                  >
                    <Popover id="Avilable to spend PopOver">
                      <Popover.Title as="div">Available to Spend</Popover.Title>
                      <Popover.Content>{informationMessge}</Popover.Content>
                    </Popover>
                  </Overlay>
                </div>
                <div className="moneyText">
                  <b>${Amount}</b>
                </div>
                <div className="row">
                  <div className="moneyHeaderText">Contribution YTD</div>
                  <div className="infoContainer2">
                    <button
                      onClick={e => this.showInformation2(e)}
                      className="infoLink"
                      title="Click for more information"
                    >
                      {this.state.displayInfo2 ? "X" : "?"}
                    </button>
                  </div>
                  <Overlay
                    show={this.state.displayInfo2}
                    target={this.state.target2}
                    placement="bottom"
                  >
                    <Popover id="YTD PopOver">
                      <Popover.Title as="div">
                        Current YTD Contributions
                      </Popover.Title>
                      <Popover.Content>
                        This includes your YTD contributions (including{" "}
                        <a
                          href="https://nwproduction.service-now.com/kb_view.do?sysparm_article=KM339951"
                          rel="noopener noreferrer"
                          target="_blank"
                        >
                          Catch-up Contributions
                        </a>
                        ),{" "}
                        <a
                          href="https://onyourside.sharepoint.com/sites/AssociateWellbeingandSafety/SitePages/My-Health.aspx"
                          rel="noopener noreferrer"
                          target="_blank"
                        >
                          My Health
                        </a>{" "}
                        reward dollars (if applicable), and Nationwide’s
                        contributions.
                      </Popover.Content>
                    </Popover>
                  </Overlay>
                </div>
                <div className="moneyText">
                  <b>${YTD}</b>
                </div>
                <div className="row">
                  <div className="moneyHeaderText">Invested Balance</div>
                  <div className="infoContainer2">
                    <button
                      onClick={e => this.showInformation3(e)}
                      className="infoLink"
                      title="Click for more information"
                    >
                      {this.state.displayInfo3 ? "X" : "?"}
                    </button>
                  </div>
                  <Overlay
                    show={this.state.displayInfo3}
                    target={this.state.target3}
                    placement="bottom"
                  >
                    <Popover id="Investments PopOver">
                      <Popover.Title as="div">Invested Balance</Popover.Title>
                      <Popover.Content>
                        This balance is not available to spend and must be
                        transferred to your <strong>Available to Spend</strong>{" "}
                        account in order to use. To learn more about investing,
                        log into your account at HealthEquity.
                      </Popover.Content>
                    </Popover>
                  </Overlay>
                </div>
                <div className="moneyText">
                  <b>${invested}</b>
                </div>
              </div>
              <div style={{ marginTop: "35px" }}></div>
              <hr className="tile-line-HSA" />

              <div
                className="balancesButton"
                onClick={
                  this.state.blurred === "yes" ? "" : this.handleBlurChange
                }
              >
                <img
                  style={{
                    height: "20px",
                    width: "20px",
                    marginLeft: "10px"
                  }}
                  src={"/eye-blue.png"}
                />
                <div className="tile-recttitle">Hide All Balances</div>
              </div>
            </div>
          </div>
          <div className="row" style={{ paddingBottom: "8px" }}>
            <div className="tile-subbox2 subBoxExtraStyle">
              <div className="row">
                <div
                  className="HealthEquityButton"
                  title={"Go to HealthEquity"}
                >
                  <img
                    className="tile-externallink"
                    alt="external-link"
                    src="/external-link.svg"
                  />
                  <a
                    className="tile-recttitle"
                    href="https://learn.healthequity.com/nationwide/hsa/"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    HealthEquity
                  </a>
                </div>
              </div>
              <div className="row">
                <div className="HSAButton" title={"Learn About HSA"}>
                  <img
                    className="tile-externallink"
                    alt="external-link"
                    src="/external-link.svg"
                  />
                  <a
                    className="tile-recttitle"
                    href="https://onyourside.sharepoint.com/sites/Associate-Benefits/SitePages/Medical-Savings-Accounts.aspx"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Learn About HSA
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
    if (enrolledOne) {
      return (
        <div>
          {this.header(deactivatePortal, defaultActive)}
          <hr className="tile-line" />
          <div className={this.state.blurred === "yes" ? "obfuscateMore2" : ""}>
            <div className="row container">
              <div className="col" style={{ width: "48%" }}>
                <div className="row">
                  <img
                    className="pigImg"
                    alt="Piggy Bank"
                    src="/Piggy_Bank.svg"
                  />
                </div>
                <div className="row piggyText">
                  Nationwide will match your contributions up to $500/single or
                  $1,000/family coverage.
                </div>
              </div>
              <div className="col" style={{ paddingLeft: "35px" }}>
                <div className="row">
                  <div className="piggyText">Available to Spend</div>
                  <div className="infoContainer">
                    <button
                      onClick={e => this.showInformation(e)}
                      className="infoLink"
                      title="Click for more information"
                    >
                      {this.state.displayInfo ? "X" : "?"}
                    </button>
                  </div>
                  <Overlay
                    show={this.state.displayInfo}
                    target={this.state.target}
                    placement="bottom"
                  >
                    <Popover id="Avilable to spend PopOver">
                      <Popover.Title as="div">Available to Spend</Popover.Title>
                      <Popover.Content>{informationMessge}</Popover.Content>
                    </Popover>
                  </Overlay>
                </div>
                <div className="moneyText">
                  <b>${Amount}</b>
                </div>
                <div className="row">
                  <div className="moneyHeaderText">Contribution YTD</div>
                  <div className="infoContainer2">
                    <button
                      onClick={e => this.showInformation2(e)}
                      className="infoLink"
                      title="Click for more information"
                    >
                      {this.state.displayInfo2 ? "X" : "?"}
                    </button>
                  </div>
                  <Overlay
                    show={this.state.displayInfo2}
                    target={this.state.target2}
                    placement="bottom"
                  >
                    <Popover id="YTD PopOver">
                      <Popover.Title as="div">
                        Current YTD Contributions
                      </Popover.Title>
                      <Popover.Content>
                        {" "}
                        This includes your YTD contributions (including{" "}
                        <a
                          href="https://nwproduction.service-now.com/kb_view.do?sysparm_article=KM339951"
                          rel="noopener noreferrer"
                          target="_blank"
                        >
                          Catch-up Contributions
                        </a>
                        ),{" "}
                        <a
                          href="https://onyourside.sharepoint.com/sites/AssociateWellbeingandSafety/SitePages/My-Health.aspx"
                          rel="noopener noreferrer"
                          target="_blank"
                        >
                          My Health
                        </a>{" "}
                        reward dollars (if applicable), and Nationwide’s
                        contributions.
                      </Popover.Content>
                    </Popover>
                  </Overlay>
                </div>
                <div className="moneyText">
                  <b>${YTD}</b>
                </div>
                <div style={{ minHeight: "60px" }}></div>
              </div>
              <div style={{ marginTop: "20px" }}></div>
              <hr className="tile-line-HSA" />

              <div className="balancesButton" onClick={this.handleBlurChange}>
                <img
                  style={{
                    height: "20px",
                    width: "20px",
                    marginLeft: "10px"
                  }}
                  src={"/eye-blue.png"}
                />
                <div className="tile-recttitle">Hide All Balances</div>
              </div>
            </div>
          </div>
          <div className="row" style={{ paddingBottom: "8px" }}>
            <div className="tile-subbox2 subBoxExtraStyle">
              <div className="row">
                <div
                  className="HealthEquityButton"
                  title={"Go to HealthEquity"}
                >
                  <img
                    className="tile-externallink"
                    alt="external-link"
                    src="/external-link.svg"
                  />
                  <a
                    className="tile-recttitle"
                    href="https://learn.healthequity.com/nationwide/hsa/"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    HealthEquity
                  </a>
                </div>
              </div>
              <div className="row">
                <div className="HSAButton" title={"Learn About HSA"}>
                  <img
                    className="tile-externallink"
                    alt="external-link"
                    src="/external-link.svg"
                  />
                  <a
                    className="tile-recttitle"
                    href="https://onyourside.sharepoint.com/sites/Associate-Benefits/SitePages/Medical-Savings-Accounts.aspx"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Learn About HSA
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
    if (enrolledNone) {
      return (
        <div>
          {this.header(deactivatePortal, defaultActive)}
          <hr className="tile-line" />
          <div className={this.state.blurred === "yes" ? "obfuscateMore2" : ""}>
            <div className="row container">
              <div>
                <div className="HSAnoEnrollmentText">
                  You are not currently enrolled in a Health Savings Account
                  (HSA).
                  <button
                    onClick={e => this.showInformation4(e)}
                    className="infoLinkPadding infoLink"
                    title="Click for more information"
                  >
                    {this.state.displayInfo4 ? "X" : "?"}
                  </button>
                  <Overlay
                    show={this.state.displayInfo4}
                    target={this.state.target4}
                    placement="bottom"
                  >
                    <Popover id="No enroll PopOver">
                      <Popover.Title as="div">
                        Health Savings Account Status
                      </Popover.Title>
                      <Popover.Content>{noEnrollInfo}</Popover.Content>
                    </Popover>
                  </Overlay>
                </div>
              </div>
              <div className="HSAnoEnrollmentSmallText">
                Visit the Associate Benefit site to learn more about a HSA.
              </div>
              <div style={{ marginTop: "35px" }}></div>
              <hr className="tile-line-HSA" />
              <div className="balancesButton" onClick={this.handleBlurChange}>
                <img
                  style={{
                    height: "20px",
                    width: "20px",
                    marginLeft: "10px"
                  }}
                  src={"/eye-blue.png"}
                />
                <div className="tile-recttitle">Hide All Balances</div>
              </div>
            </div>
          </div>
          <div className="row" style={{ paddingBottom: "8px" }}>
            <div className="tile-subbox2 subBoxExtraStyle">
              <div className="row">
                <div className="HSAButton" title={"Learn About HSA"}>
                  <img
                    className="tile-externallink"
                    alt="external-link"
                    src="/external-link.svg"
                  />
                  <a
                    className="tile-recttitle"
                    href="https://onyourside.sharepoint.com/sites/Associate-Benefits/SitePages/Medical-Savings-Accounts.aspx"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Learn About HSA
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }

  blurOverlay() {
    return (
      <div
        className="blurContent" onClick={this.handleBlurChange}
        style={
          this.state.blurred === "yes"
            ? { display: "block" }
            : { display: "none" }
        }
      >
        <div className="blurText">
          By clicking below, you are agreeing to view your current YTD
          contributions, available balance to spend, and invested balance.
        </div>
        <div className="showBalancesButton" onClick={this.handleBlurChange}>
          <img
            style={{
              height: "20px",
              width: "20px",
              marginLeft: "10px"
            }}
            src={"/hide-blue.png"}
          />
          <div className="tile-recttitle">See HSA Information</div>
        </div>
      </div>
    );
  }

  header(deactivatePortal, defaultActive) {
    return (
      <div>
        <h2 className="tile-header">
          <button
            style={
              defaultActive === "N"
                ? { display: "inline-block" }
                : { display: "none" }
            }
            onClick={deactivatePortal}
            className="link"
          >
            <img
              src="/chevron_up.png"
              alt="Click to collapse HSA info"
              width="19px"
              style={{ marginRight: "10px" }}
            />
          </button>
          My Health Savings Account (HSA)
        </h2>
      </div>
    );
  }

  render() {
    let {
      data,
      defaultActive,
      activePortal,
      activatePortal,
      deactivatePortal,
      retrieveData
    } = this.props;

    if (activePortal === "Y") {
      if (data) {
        const content = this.returnContent(
          data,
          defaultActive,
          deactivatePortal
        );
        if (data.error) {
          return <PortletError content={content} />;
        } else {
          return <Portlet content={content} />;
        }
      } else {
        activatePortal();
        return <PortletLoading title="My Health Savings Account (HSA)" />;
      }
    } else {
      return (
        <CollapsedPortlet
          dataFetch={retrieveData}
          title={"My Health Savings Account (HSA)"}
        ></CollapsedPortlet>
      );
    }
  }
}

export default HSA;

import React, { Component } from "react";
import config from "../config.json";
import Spinner from "../common/spinner";
import "react-toastify/dist/ReactToastify.css";
import Button from "react-bootstrap/Button";
import { getCallupData } from "../services/callupService";
import { async } from "@nationwide-bolt/bundle-core/dist/bolt-core";
import { toast } from "react-toastify";
import { getDataFileAllowed } from "../services/dataExtractService";
import allowedGroups from "../extractGroups.json";

class AdvancedPeopleSearch extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ShortName: "",
      lastName: "",
      firstName: "",
      phone: "",
      MailCode: "",
      DisbCode: "",
      LocID: "",
      downloadCliked: false,
      showDisclaimer: false,
      dataFileAllowed: "N"
    };

    this.handleShortname = this.handleShortname.bind(this);
    this.handlelastName = this.handlelastName.bind(this);
    this.handlefirstName = this.handlefirstName.bind(this);
    this.handlePhone = this.handlePhone.bind(this);
    this.handleMailCode = this.handleMailCode.bind(this);
    this.handleLocID = this.handleLocID.bind(this);
    this.handleDisbCode = this.handleDisbCode.bind(this);
    this.handleSearchQuery = this.handleSearchQuery.bind(this);
    this.clearSearch = this.clearSearch.bind(this);
  }

  async componentDidMount() {
    
    const fileAllowedResponse = await getDataFileAllowed();
    console.log("response from user info API: ", fileAllowedResponse);
    let ADGroups = fileAllowedResponse.groups;
    console.log("ADGROUPs list: ", ADGroups);
    /*if (ADGroups.includes("HRIT_UCD_Developers")) {
      this.setState({dataFileAllowed: "Y"})
    }*/
    let groupArray = allowedGroups.groups;
    if (ADGroups.some(e1 => groupArray.includes(e1))) {
      this.setState({dataFileAllowed: "Y"})
    }

    console.log("USER IS ALLOWED TO EXTRACT: ", this.state.dataFileAllowed);

  }

  handleShortname(event) {
    const re = /^[a-zA-Z0-9\b]+$/;
    if (event.target.value === "" || re.test(event.target.value)) {
      this.setState({ ShortName: event.target.value });
    }
  }

  handlelastName(event) {
    const re = /^[a-zA-Z0-9"'"\b]+$/;
    if (event.target.value === "" || re.test(event.target.value)) {
      this.setState({ lastName: event.target.value });
    }
  }

  handlefirstName(event) {
    const re = /^[a-zA-Z0-9,\b]+$/;
    if (event.target.value === "" || re.test(event.target.value)) {
      this.setState({ firstName: event.target.value });
    }
  }

  handlePhone(event) {
    const re = /^[0-9\b]+$/;
    if (event.target.value === "" || re.test(event.target.value)) {
      this.setState({ phone: event.target.value });
    }
  }

  handleMailCode(event) {
    const re = /^[a-zA-Z0-9-\b]+$/;
    if (event.target.value === "" || re.test(event.target.value)) {
      let val = event.target.value;
      val = val.toUpperCase(); // forces user to only enter uppercase search
      this.setState({ MailCode: val });
    }
  }

  handleDisbCode(event) {
    const re = /^[a-zA-Z0-9-\b]+$/;
    if (event.target.value === "" || re.test(event.target.value)) {
      this.setState({ DisbCode: event.target.value });
    }
  }

  handleLocID(event) {
    const re = /^[a-zA-Z0-9-\b]+$/;
    if (event.target.value === "" || re.test(event.target.value)) {
      let val = event.target.value;
      val = val.toUpperCase(); // forces user to only enter uppercase search
      this.setState({ LocID: val });
    }
  }

  handleExtractDownload = async () => {
    const { fetchDataFile } = this.props;
    this.setState({downloadCliked: true, showDisclaimer: false});
    toast('Please stay on this page while extract is prepared!', { 
      position: toast.POSITION.TOP_LEFT });
    const resp = await fetchDataFile();
  }

  downloadStatus() {
    const { dataFileResponse } = this.props;
    if (this.state.downloadCliked) {
      if (dataFileResponse) {
        if (dataFileResponse.Loading === 'Loading') {
          return 'Loading';
        }
        if (dataFileResponse.Success === 'Success') {
          return 'Success';
        }
        if (dataFileResponse.error) {
          return 'Error';
        }
      }
    } else {
      return 'none';
    }
  }

  clearSearch(event) {
    this.setState({
      ShortName: "",
      lastName: "",
      firstName: "",
      phone: "",
      MailCode: "",
      DisbCode: "",
      LocID: ""
    });
  }

  async handleSearchQuery(event) {
    if (event) {
      event.preventDefault();
    }
    var body_post = {};
    var body_post2 = {};

    if (this.state.lastName) {
      console.log(
        "AdvancedPeopleSearch.handleSearchQuery  event lastName",
        this.state.lastName
      );
      body_post["lastName"] = this.state.lastName;
      body_post2["preferredLastName"] = this.state.lastName;
    }

    if (this.state.firstName) {
      if (this.state.lastName) {
        console.log(
          "App.handleSearchQueryCallup event firstName",
          this.state.firstName
        );
        body_post["firstName"] = this.state.firstName;
        body_post2["preferredFirstName"] = this.state.firstName;
        body_post["lastName"] = this.state.lastName;
        body_post2["preferredLastName"] = this.state.lastName;
      } else {
        body_post["firstName"] = this.state.firstName;
        body_post2["preferredFirstName"] = this.state.firstName;
      }
    }

    if (this.state.phone) {
      console.log(
        "AdvancedPeopleSearch.handleSearchQuery  event phone",
        this.state.phone
      );
      body_post["workPhone"] = this.state.phone;
      body_post2["workPhone"] = this.state.phone;
    }

    if (this.state.MailCode) {
      console.log(
        "AdvancedPeopleSearch.handleSearchQuery  event MailCode",
        this.state.MailCode
      );
      body_post["mailDrop"] = this.state.MailCode;
      body_post2["mailDrop"] = this.state.MailCode;
    }

    if (this.state.LocID) {
      console.log(
        "AdvancedPeopleSearch.handleSearchQuery  event LocID",
        this.state.LocID
      );
      body_post["locID"] = this.state.LocID;
      body_post2["locID"] = this.state.LocID;
    }

    if (this.state.DisbCode) {
      console.log(
        "AdvancedPeopleSearch.handleSearchQuery  event DisbCode",
        this.state.DisbCode
      );
      body_post["disbCode"] = this.state.DisbCode;
      body_post2["disbCode"] = this.state.DisbCode;
    }

    this.props.onSearchQueryAPS(this.state.ShortName, body_post, body_post2);
    // this.props.onSearchQueryAPS(this.state.ShortName, body_post2);
    this.setState({
      ShortName: "",
      firstName: "",
      lastName: "",
      phone: "",
      MailCode: "",
      LocID: "",
      DisbCode: ""
    });
  }

  renderError() {
    return (
      <React.Fragment>
        <div
          style={{ display: "flex" }}
        >
          <div
            className="portletErrorAlert"
            style={{ marginLeft: "50px", marginTop: "25px", paddingTop: "5px" }
            }
          >
            !
          </div>
          <div
            className="tile-apierrortext"
            style={{
              marginLeft: "10px",
              textAlign: "center",
              marginTop: "25px",
              paddingTop: "5px"
            }}
          >
            Oops, this is unexpected.
          </div>
        </div>
        
          <div className="search-apierrortext1">
            An error has occurred and we’re working to fix the issue.
          </div>
        <div
          className="search-apierrortext1"
          style={{ marginLeft: "96px" } }
        >
          <span>If the issue persists please </span>
          <a
            className="tile-apierrorcontactus"
            href="https://nwproduction.service-now.com/csm"
            rel="noopener noreferrer"
            target="_blank"
          >
            contact us.
          </a>
        </div>
        <div style={{ paddingTop: "25px" }}></div>
      </React.Fragment>
    );
  }

  renderDisclaimer() {
    return (
      <React.Fragment>
        <div className="disclaimerBox">
          <div className="disclaimerText">
          Per Enterprise Data Office standards, this data is considered Private- personal information (name and contact information) or other information related to internal functions, policies, procedures and standards.
          <br></br><br></br>You may access and use such information only if you have express authorization to do so and only to the extent necessary to perform your job. And you may only discuss or share such information with others who have authorization to receive it.
          </div>
          <div className="row">
            <button
              className="disclaimerClearbutton"
              onClick={() => this.flashDisclaimer()}
            >
              <div
                style={{
                  fontSize: "14px",
                  fontWeight: "bold",
                  color: "#1C57A5",
                  fontFamily: "nw-primary"
                }}
              >
                Cancel
              </div>
            </button>
            <button
              className="disclaimerDownload"
              onClick={() => this.handleExtractDownload()}
            >
              <img
                src="/DownloadIcon.svg"
                style={{
                  float: "left",
                  paddingLeft: "10px",
                  paddingTop: "2px"
                }}
              />
              <div
                className="searchButtonText"
                style={{
                  fontSize: "14px",
                  float: "left",
                  paddingLeft: "7px"
                }}
              >
                Download
              </div>
            </button>
          </div>
        </div>
      </React.Fragment>
    );
  }

  flashDisclaimer = () => {
    let showDisclaimer = this.state.showDisclaimer;
    showDisclaimer = !showDisclaimer;
    this.setState({showDisclaimer});
  } 

  render() {
    let downloadStatus = this.downloadStatus();
    return (
      <div>
        <div className="announcements" style={{ height: "auto" }}>
          <div className="ASBackground">
            <div className="container">
              <div className="row">
                <div
                  className="APSannouncementContainer col"
                  style={{ height: "auto", paddingBottom: "25px" }}
                >
                  <div>
                    <div className="searchTitle">Advanced People Search</div>
                    <div className="searchTitleLine"></div>
                    <div style={downloadStatus == 'none' || downloadStatus == 'Success' ? { display: "flex", paddingBottom: "15px" } : {display: 'none'}}>
                      <div style={this.state.showDisclaimer ? {}: {display: 'none'}}>{this.renderDisclaimer()}</div>
                      <div style={{ position: "relative", left: "109px" }}>
                        <div className="AdvancedSearchText">
                          You can search using one or more of the following
                          search criteria:
                        </div>
                        <form onSubmit={this.handleSearchQuery}>
                          <div className="form-group">
                            <label
                              htmlFor="ShortName"
                              className="ASLabel"
                              style={{
                                display: "block"
                              }}
                            >
                              Shortname
                            </label>
                            <input
                              type="text"
                              id="ShortName"
                              value={this.state.ShortName}
                              onChange={this.handleShortname}
                              placeholder="Enter Short Name"
                              className="formInputBox form-control form-control-sm"
                              // class="form-control form-control-sm"
                              style={{
                                width: "494px",
                                height: "30px"
                              }}
                            />
                          </div>
                          <div className="form-group">
                            <label
                              htmlFor="LastName"
                              className="ASLabel"
                              style={{
                                display: "block"
                              }}
                            >
                              Last Name
                            </label>
                            <input
                              type="text"
                              id="LastName"
                              value={this.state.lastName}
                              onChange={this.handlelastName}
                              placeholder="Enter Last Name"
                              className="formInputBox form-control form-control-sm"
                              // class="form-control form-control-sm"
                              style={{
                                width: "494px",
                                height: "30px"
                              }}
                            />
                          </div>
                          <div className="form-group">
                            <label
                              htmlFor="FirstName"
                              className="ASLabel"
                              style={{
                                display: "block"
                              }}
                            >
                              First Name
                            </label>
                            <input
                              type="text"
                              id="FirstName"
                              value={this.state.firstName}
                              onChange={this.handlefirstName}
                              placeholder="Enter First Name"
                              className="formInputBox form-control form-control-sm"
                              // class="form-control form-control-sm"
                              style={{
                                width: "494px",
                                height: "30px"
                              }}
                            />
                          </div>
                          <div className="form-group">
                            <label
                              htmlFor="Phone"
                              className="ASLabel"
                              style={{
                                display: "block"
                              }}
                            >
                              Phone Number
                            </label>
                            <input
                              type="tel"
                              placeholder="Enter Work Phone number"
                              id="Phone"
                              value={this.state.phone}
                              onChange={this.handlePhone}
                              className="formInputBox form-control form-control-sm"
                              // class="form-control form-control-sm"
                              style={{
                                width: "494px",
                                height: "30px"
                              }}
                            />
                          </div>
                          <div className="form-group">
                            <label
                              htmlFor="DisbCode"
                              className="ASLabel"
                              style={{
                                display: "block"
                              }}
                            >
                              Disbursement Code
                            </label>
                            <input
                              type="text"
                              id="DisbCode"
                              value={this.state.DisbCode}
                              onChange={this.handleDisbCode}
                              placeholder="Enter Disbursement Code"
                              className="formInputBox form-control form-control-sm"
                              // class="form-control form-control-sm"
                              style={{
                                width: "494px",
                                height: "30px"
                              }}
                            />
                          </div>
                          <div className="form-group">
                            <label
                              htmlFor="MailCode"
                              className="ASLabel"
                              style={{
                                display: "block"
                              }}
                            >
                              Mail Code (Work Space)
                            </label>
                            <input
                              type="text"
                              id="MailCode"
                              value={this.state.MailCode}
                              onChange={this.handleMailCode}
                              placeholder="Enter Mail Code"
                              className="formInputBox form-control form-control-sm"
                              // class="form-control form-control-sm"
                              style={{
                                width: "494px",
                                height: "30px"
                              }}
                            />
                          </div>
                          <div className="form-group">
                            <label
                              htmlFor="LocID"
                              className="ASLabel"
                              style={{
                                display: "block"
                              }}
                            >
                              HR Business Location (Location ID)
                            </label>
                            <input
                              type="text"
                              id="LocID"
                              value={this.state.LocID}
                              onChange={this.handleLocID}
                              placeholder="Enter Location ID"
                              className="formInputBox form-control form-control-sm"
                              // class="form-control form-control-sm"
                              style={{
                                width: "494px",
                                height: "30px"
                              }}
                            />
                          </div>

                          <div className="row">
                            <button
                              onClick={this.clearSearch}
                              type="reset"
                              className="ASClearbutton"
                            >
                              <div
                                style={{
                                  fontSize: "12px",
                                  fontWeight: "bold",
                                  color: "#1C57A5",
                                  fontFamily: "nw-primary"
                                }}
                              >
                                Clear Search
                              </div>
                            </button>

                            <button type="submit" className="ASButton">
                              <img
                                src="/APSearch.svg"
                                style={{
                                  float: "left",
                                  paddingLeft: "10px",
                                  paddingTop: "2px"
                                }}
                              />
                              <div
                                className="searchButtonText"
                                style={{
                                  fontSize: "14px",
                                  float: "left",
                                  paddingLeft: "7px"
                                }}
                              >
                                Search
                              </div>
                            </button>
                          </div>
                        </form>
                      </div>
                      <div
                        className="announcementContainer col"
                        style={{
                          height: "auto",
                          float: "left",
                          marginLeft: "150px",
                          marginTop: "15px", 
                          paddingBottom: "12px"
                        }}
                      >
                        <div className="ASTips" style={{ height: "370px" }}>
                          <div className="APSHelpTitle">Search Tips</div>
                          <div className="APSHelpLine"></div>
                          <div className="APSHelpText">
                            If you’re searching by name, you can search by{" "}
                            <b>last name</b> and/or <b>first name</b>. If you
                            don’t know the person’s full first or last name,
                            enter as much as you know.
                            <br />
                            <br />
                            Searching by name is not case sensitive. Results
                            will include similar spellings and hyphenated names.
                            <br />
                            <br />
                            Searches by <b>phone number</b> will match any phone
                            number containing your search term.
                            <br />
                            <br />
                            Searches by <b>short name</b> or <b>mail code</b>{" "}
                            must be exact and are not case sensitive.
                          </div>
                        </div>
                        <div
                          className="ASTips"
                          style={{ marginTop: "15px", height: "115px" }}
                        >
                          <div className="APSHelpTitle">Need Help?</div>
                          <div className="APSHelpLine"></div>
                          <div className="APSHelpText">
                            <div>
                              Didn’t find what you are looking for or need help?
                            </div>
                            <a
                              className="APSHelplink"
                              href="https://nwproduction.service-now.com/csm"
                              rel="noopener noreferrer"
                              target="_blank"
                            >
                              Get in touch with us.
                            </a>
                          </div>
                        </div>
                        <div
                          className="APSDataExtract">
                          <div className="APSHelpTitle">Call Up Data Extract</div>
                          <div className="APSHelpLine"></div>
                          <div className="APSHelpText">
                            { this.state.dataFileAllowed == 'Y' &&
                              <><div>
                                An extract of data for both active associates and contractors. It includes the most current HR, Mail/Location and Reports To/organization. The data will be updated nightly and will not include any historical data.
                              </div><button
                                className="APSHelplink"
                                title="Click here to download extract file"
                                onClick={() => this.flashDisclaimer()}
                                style={{ border: 'none', outline: 'none', marginLeft: '-6px', background: 'none' }}
                              >
                                  Download extract.
                                </button></>

                            }

                            { this.state.dataFileAllowed == 'N' &&
                              <><div>
                                An extract of data for both active associates and contractors. It includes the most current HR, Mail/Location and Reports To/organization. The data will be updated nightly and will not include any historical data. If you need access to this file, please request access to the Callup Data Extract group in IIQ
                              </div></>

                            }
                            
                          </div>
                        </div>
                      </div>
                    </div>
                    <div style={downloadStatus == 'Loading'? {height: '500px'}: {display: 'none'}}>
                      <Spinner />
                      <div className="APSHelpTitle">
                        Please do not leave or refresh this page... Extract file is being prepared
                      </div>
                    </div>
                    <div style={downloadStatus == 'Error'? {height: '250px'}: {display: 'none'}}>
                      {this.renderError()}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AdvancedPeopleSearch;

import "@nationwide-bolt/bundle-core/dist/bolt-core.js";
import "@nationwide-bolt/css-core/dist/css/bolt-core.css";
import React, { Component } from "react";
import "react-toastify/dist/ReactToastify.css";
import {
  CollapsedPortlet,
  PortletLoading,
  WorkdayPortlets
} from "../common/portlet";
import Spinner from "../common/spinner";
import config from "../config.json";
import "../main.css";

class Payslip extends Component {
  state = {
    blurred: "yes",
    payDate: "",
    gross: "",
    net: ""
  };

  handleBlurChange = () => {
    if (this.state.blurred === "yes") {
      this.setState({ blurred: "no" });
    } else {
      this.setState({ blurred: "yes" });
    }
  };

  handleRefresh = async () => {
    this.props.populatePayslipPortal();
  };

  convertYYYYMMDD(aDateString) {
    try {
      const parts = aDateString && aDateString.split("-");
      const out = parts ? parts[1] + "/" + parts[2] + "/" + parts[0] : "";
      return out;
    } catch (e) {
      return "";
    }
  }

  formatMoney(value) {
    try {
      let number = value;
      if (process.env.NODE_ENV === "development") {
        if (process.env.NODE_ENV === "development") {
          number = parseFloat(value) / (Math.random() * 5 + 5);
        } else {
          number = parseFloat(value);
        }
      }

      const thouSep = ",";
      const decSep = ".";
      const decPlaces = 2;
      let sign = number < 0 ? "-" : "";
      let i = String(
        parseInt((number = Math.abs(Number(number) || 0).toFixed(decPlaces)))
      );
      let j = 0;
      j = (j = i.length) > 3 ? j % 3 : 0;

      return (
        sign +
        (j ? i.substr(0, j) + thouSep : "") +
        i.substr(j).replace(/(\decSep{3})(?=\decSep)/g, "$1" + thouSep) +
        (decPlaces
          ? decSep +
            Math.abs(number - i)
              .toFixed(decPlaces)
              .slice(2)
          : "")
      );
    } catch (e) {
      return "error";
    }
  }

  returnContent(payslipData, payslipDefaultActive, deactivatePayslip) {
    if (payslipData) {
      // const today = new Date();
      // const year = today.getFullYear();
      // const payCalUrl1 = "https://inside.nwie.net/media";
      // const payCalUrl2 = "/pay_" + year.toString() + "payrollcalendar.pdf";
      // const payCalUrl = payCalUrl1 + payCalUrl2;
      const payCalUrl =
        "https://onyourside.sharepoint.com/sites/Compensation-and-Pay/SitePages/Pay-Calendars.aspx";

      const message =
        "The pay information shown reflects your most recent payment. Go to the Pay Dashboard for more information.";

      let payDate = "";
      let gross = "";
      let net = "";
      // const gross = this.formatMoney(parseFloat(payslipData.grossAmt));
      if (this.state.blurred === "yes") {
        payDate = "##/##/####";
        gross = "####";
        net = "####";
      } else {
        if (this.state.payDate === "") {
          if (payslipData.shortName === "Error") {
            payDate = "Error";
            gross = this.formatMoney(payslipData.grossAmt);
            net = this.formatMoney(payslipData.netAmt);
            console.log("Payslip.returnContent > error setState");
            // this.setState({ payDate, gross, net, nextPayDate });
          } else {
            payDate = this.convertYYYYMMDD(payslipData.payDate);
            gross = this.formatMoney(payslipData.grossAmt);
            net = this.formatMoney(payslipData.netAmt);
            // this.setState({ payDate, gross, net, nextPayDate });
          }
        }
      }
      return payDate === "Error" ? (
        <div>
          <div className="tile-apierroricon">
            <div className="portletErrorAlert">!</div>
            {/* <bolt-icon name= "notification-error" label = "error" color = "red" size = "lg"></bolt-icon> */}
          </div>
          <div className="tile-apierrortext">Oops, this is unexpected.</div>
          <div className="tile-apierrortext1">Error code: 500</div>
          <div className="tile-apierrortext1">
            An error has occurred and we’re working to fix the issue.
          </div>
          <div className="tile-apierrortext1">
            <span>If the issue persists please </span>
            <a
              className="tile-apierrorcontactus"
              href="https://nwproduction.service-now.com/csm"
              rel="noopener noreferrer"
              target="_blank"
            >
              contact us.
            </a>
          </div>
          <div
            className="tile-apierror-refresh"
            onClick={this.handleRefresh}
            title="Refresh"
          >
            Refresh
          </div>
          <div style={{ paddingTop: "25px" }}></div>
        </div>
      ) : (
        <div>
          <h2 className="tile-header">
            <button
              style={
                payslipDefaultActive === "N"
                  ? { display: "inline-block" }
                  : { display: "none" }
              }
              onClick={deactivatePayslip}
              className="link"
            >
              <img
                src="/chevron_up.png"
                alt="Click to collapse pay info"
                width="19px"
                style={{ marginRight: "10px" }}
              />
            </button>
            My Pay
          </h2>
          <div
            className="tile-blur"
            //onClick={this.handleBlurChange}
          >
            <button
              className="btn btn-link"
              onClick={this.handleBlurChange}
              title={
                this.state.blurred === "yes" ? "click to show pay info" : "click to hide pay info"
              }
            >
              <img
                style={{
                  height: "20px",
                  width: "20px"
                }}
                alt={this.state.blurred === "yes" ? "show" : "hide"}
                src={
                  this.state.blurred === "yes"
                    ? "/eye-blue.png"
                    : "hide-blue.png"
                }
              />
            </button>
          </div>
          <hr className="tile-line" />
          <div className={this.state.blurred === "yes" ? "obfuscate" : ""}>
            <React.Fragment>
              <div className="container-fluid">
                <div className="row">
                  <div className="col myPayColumn">
                    <span
                      className="tile-title"
                      style={{ paddingLeft: "10px" }}
                    >
                      Payment Date:{" "}
                    </span>
                    <span className="tile-values" style={{ fontSize: "18px" }}>
                      {payDate}
                    </span>
                  </div>
                </div>
                <div className="row">
                  <div className="col myPayColumn">
                    <span
                      className="tile-title"
                      style={{ paddingLeft: "10px" }}
                    >
                      Gross Pay
                    </span>
                    <br />
                    <span
                      className="tile-values"
                      style={{ fontSize: "25px", marginLeft: "10px" }}
                    >
                      ${gross}
                    </span>
                  </div>
                  <div className="col myPayColumn">
                    <span
                      className="tile-title"
                      style={{ paddingLeft: "10px" }}
                    >
                      Net Pay
                    </span>
                    <br />
                    <span
                      className="tile-values"
                      style={{ fontSize: "25px", marginLeft: "10px" }}
                    >
                      ${net}
                    </span>
                  </div>
                </div>
                {/* <div className="row">
                  <div className="col">
                    <span
                      className="tile-values"
                      style={{ fontSize: "25px", marginLeft: "10px" }}
                    >
                      ${gross}
                    </span>
                  </div>
                  <div className="col">
                    <span className="tile-values" style={{ fontSize: "25px" }}>
                      ${net}
                    </span>
                  </div>
                </div> */}
                <div
                  className="tile-title"
                  style={{ paddingLeft: "10px", paddingTop: "10px" }}
                >
                  {message}
                </div>
              </div>
            </React.Fragment>
          </div>
          <div className="tile-subbox" style={{ marginTop: "15px" }}>
            <div className="row">
              <div className="col tileTwocolumn">
                <div
                  className="tile-rectangle myPayButtonContainer1"
                  // style={{
                  //   marginLeft: "18px",
                  //   width: "150px",
                  //   height: "38px"
                  // }}
                >
                  <img
                    className="tile-externallink"
                    alt="external-link"
                    src="/external-link.svg"
                  />
                  <a
                    className="tile-recttitle"
                    href={config.payDashboardUrl}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Benefits &amp; Pay Hub
                  </a>
                </div>
              </div>
              <div className="col tileTwocolumn">
                <div
                  className="tile-rectangle myPayButtonContainer2"
                  // style={{
                  //   marginLeft: "18px",
                  //   width: "222px",
                  //   height: "38px"
                  // }}
                >
                  <img
                    className="tile-externallink"
                    alt="external-link"
                    src="/external-link.svg"
                  />
                  <a
                    className="tile-recttitle"
                    href={payCalUrl}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Current Year Pay Calendar
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div style={{ paddingTop: "8px" }}></div>
        </div>
      );
    } else {
      return <Spinner />;
    }
  }

  render() {
    const {
      payslipData,
      activePortal,
      fetchPayslipData,
      payslipDefaultActive,
      deactivatePayslip
    } = this.props;
    if (activePortal === "Y") {
      if (payslipData) {
        const content = this.returnContent(
          payslipData,
          payslipDefaultActive,
          deactivatePayslip
        );
        return <WorkdayPortlets content={content} />;
      } else {
        this.props.populatePayslipPortal();
        return <PortletLoading title="My Pay" />;
      }
    } else {
      return (
        <CollapsedPortlet
          // activateAwsPortal={this.handleExpand}
          dataFetch={fetchPayslipData}
          title="My Pay"
        ></CollapsedPortlet>
      );
    }
  }
}

export default Payslip;

import React, { useReducer, useState } from "react";
import { isMobile } from "react-device-detect";
import Spinner from "../common/spinner";
import links from "../links.json";

export function NewHires({
  displayNewHires,
  newHires,
  handleNewHireDisplay,
  filterNewHires,
  locations,
  selectedLocation,
  locationFilter,
  departments,
  managers,
  hireDates,
  jobTitles,
  addRemoveLocationFilter,
  addRemoveDepartmentFilter,
  addRemoveManagerFilter,
  addRemoveHireDateFilter,
  addRemoveJobTitleFilter,
  changeDepartmentDisplay,
  setAllHireDates,
  setAllJobTitles,
  setAllLocations,
  setAllDepartments,
  setAllManagers,
  populateNewHires
}) {
  let win;
  const person = (firstName, lastName) => ({
    first: firstName,
    last: lastName
  });

  const formatMMDDYYYY = strDate => {
    try {
      const aryDateParts = strDate.split("-");
      return aryDateParts[1] + "/" + aryDateParts[2] + "/" + aryDateParts[0];
    } catch (e) {
      console.log("newHires formatMMDDYYYY ", strDate, e);
      return strDate;
    }
  };

  let startDate = "";
  let endDate = "";
  // if (hireDates && hireDates.length > 0) {
  //   endDate = formatMMDDYYYY(hireDates[0].hireDate);
  //   startDate = formatMMDDYYYY(hireDates[hireDates.length - 1].hireDate);
  // } else {
  const today = new Date();
  endDate =
    today.getMonth() + 1 + "/" + today.getDate() + "/" + today.getFullYear();
  const nextDate = new Date();
  nextDate.setDate(nextDate.getDate() - 13);
  startDate =
    nextDate.getMonth() +
    1 +
    "/" +
    nextDate.getDate() +
    "/" +
    nextDate.getFullYear();
  // }

  const switchValue = aValue => {
    if (newHires) {
      const show = !aValue;
      if (!show) {
        //The filter is being hidden so apply the changes
        // filterNewHires();
      }
      return show;
    }
  };

  let showLocations = true;
  const changeShowLocations = event => {
    console.log("change showlocations", showLocations);
    showLocations = !showLocations;
    console.log("change showlocations", showLocations);
    try {
      if (showLocations) {
        document.getElementById("locationsSelect").style.display = "block";
      } else {
        document.getElementById("locationsSelect").style.display = "none";
      }
    } catch (e) {
      console.error(e);
    }
  };

  // const [showLocations, changeShowLocations] = useReducer(
  //   showLocations => switchValue(showLocations),
  //   0
  // );

  const [displayLocations, changeDisplayLocations] = useReducer(
    displayLocations => switchValue(displayLocations),
    0
  );

  const [showDepartments, changeShowDepartments] = useReducer(
    showDepartments => switchValue(showDepartments),
    0
  );
  const [showManagers, changeShowManagers] = useReducer(
    showManagers => switchValue(showManagers),
    0
  );
  const [showHireDates, changeShowHireDates] = useReducer(
    showHireDates => switchValue(showHireDates),
    0
  );
  const [showJobTitles, changeShowJobTitles] = useReducer(
    showJobTitles => switchValue(showJobTitles),
    0
  );

  const [selectedLocationIndex, changeLocation] = useState(0);

  //

  //
  const openEmail = email => {
    window.location.href = "mailto:" + email + "@nationwide.com";
  };

  const setUpMeeting = userPrincipalName => {
    const URL =
      "https://teams.microsoft.com/l/meeting/new?subject=Touchbase%20Meeting&attendees=" +
      userPrincipalName;
    window.open(URL);
  };

  const openChat = (email, chattype) => {
    win = window.open(
      "https://teams.microsoft.com/l/" + chattype + "/0/0?users=" + email,
      "Chat"
    );
  };

  const openBravo = () => {
    window.open(links.bravo);
  };
  // const [currentImage, changeCurrentImage] = useState("/no-image.svg");
  // const [currentName, changeCurrentName] = useState("n/a");

  // const [showModalImage, changeShowModalImage] = useReducer(showModalImage => {
  //   const show = !showModalImage;
  //   console.log("showModalImage", showModalImage);
  //   return show;
  // }, 0);
  // const showImage = imageSource => {
  //   const img = imageSource.image
  //     ? "data:image/jpeg;base64," + imageSource.image
  //     : "/no-image.svg";
  //   changeCurrentImage(img);
  //   // document.getElementById("modalImage").src = imageSource.image
  //   //   ? "data:image/jpeg;base64," + imageSource.image
  //   //   : "/no-image.svg";
  //   changeCurrentName(imageSource.name);
  //   // document.getElementById("modalImageName").innerHTML = imageSource.name;
  //   changeShowModalImage();
  //   console.log("showImage", "DONE", showModalImage);
  //   // document.getElementById("modalImageContainer").modal("show");
  //   // document.getElementById("btnModalImage").focus();
  // };
  // const handleLocationChange = event => {
  //   try {
  //     console.log("newHires.handleLocationChange", event.target.value);
  //     addRemoveLocationFilter(event.target.value);
  //   } catch (e) {
  //     console.log("newHires.handleLocationChange error", e);
  //   }
  // };

  const handleLocationChange = idx => {
    try {
      console.log("newHires.handleLocationChange", idx);
      changeDisplayLocations();
      addRemoveLocationFilter(idx);
    } catch (e) {
      console.log("newHires.handleLocationChange error", e);
    }
  };

  const handleRefresh = async () => {
    populateNewHires(true);
    // toast.success("Your Time has been refreshed");
  };

  const renderLocations = () => {
    if (locations && locations.length > 0) {
      //The locations object exists and has at least 1 item in it.
      console.log("renderLocations selectedLocation", selectedLocation);
      return (
        <React.Fragment>
          {locations.map((item, index) => (
            <div
              className="newHireFilterRow"
              key={"locations_" + index}
              style={item.on ? { color: "#0000ff" } : { color: "black" }}
            >
              <button
                // className="btn-primary"
                onClick={() => handleLocationChange(item.id)}
                style={
                  item.id === selectedLocation
                    ? { color: "green", fontWeight: "bold", fontSize: "13px" }
                    : {
                        color: "#4D4F53",
                        fontWeight: "normal",
                        fontSize: "13px"
                      }
                }
              >
                {item.location}&nbsp;({item.total})
              </button>
              <hr></hr>
            </div>
          ))}
        </React.Fragment>
      );
    }
  };

  const renderDepartments = () => {
    if (departments && departments.length > 0) {
      //The departments object exists and has at least 1 item in it.
      return (
        <React.Fragment>
          {departments.map((item, index) => (
            <div
              className="filterRow"
              key={"departments_" + index}
              style={item.on ? { color: "#0000ff" } : { color: "black" }}
            >
              <button
                className="btn-primary"
                onClick={() => addRemoveDepartmentFilter(index)}
                style={{ width: "24px" }}
                title={
                  item.on
                    ? `Click to Remove ${item.department} from filter`
                    : `Click to Add ${item.department} to filter`
                }
              >
                {item.on ? "-" : "+"}
              </button>
              {item.department}
            </div>
          ))}
        </React.Fragment>
      );
    }
  };
  const renderManagers = () => {
    if (managers && managers.length > 0) {
      //The managers object exists and has at least 1 item in it.
      return (
        <React.Fragment>
          {managers.map((item, index) => (
            <div
              className="filterRow"
              key={"managers_" + index}
              style={item.on ? { color: "#0000ff" } : { color: "black" }}
            >
              <button
                className="btn-primary"
                onClick={() => addRemoveManagerFilter(index)}
                style={{ width: "24px" }}
                title={
                  item.on
                    ? `Click to Remove ${item.managerName} from filter`
                    : `Click to Add ${item.managerName} to filter`
                }
              >
                {item.on ? "-" : "+"}
              </button>
              {item.managerName}
            </div>
          ))}
        </React.Fragment>
      );
    }
  };
  const renderHireDates = () => {
    if (hireDates && hireDates.length > 0) {
      //The Hire Dates object exists and has at least 1 item in it.
      return (
        <React.Fragment>
          {hireDates.map((item, index) => (
            <div
              className="filterRow"
              key={"hireDates_" + index}
              style={item.on ? { color: "#0000ff" } : { color: "black" }}
            >
              <button
                className="btn-primary"
                onClick={() => addRemoveHireDateFilter(index)}
                style={{ width: "24px" }}
                title={
                  item.on
                    ? `Click to Remove ${item.hireDate} from filter`
                    : `Click to Add ${item.hireDate} to filter`
                }
              >
                {item.on ? "-" : "+"}
              </button>
              {item.hireDate}
            </div>
          ))}
        </React.Fragment>
      );
    }
  };
  const renderJobTitles = () => {
    if (jobTitles && jobTitles.length > 0) {
      //The Job Titles object exists and has at least 1 item in it.
      return (
        <React.Fragment>
          {jobTitles.map((item, index) => (
            <div
              className="filterRow"
              key={"jobTitles_" + index}
              style={item.on ? { color: "#0000ff" } : { color: "black" }}
            >
              <button
                className="btn-primary"
                onClick={() => addRemoveJobTitleFilter(index)}
                style={{ width: "24px" }}
                title={
                  item.on
                    ? `Click to Remove ${item.jobTitle} from filter`
                    : `Click to Add ${item.jobTitle} to filter`
                }
              >
                {item.on ? "-" : "+"}
              </button>
              {item.jobTitle}
            </div>
          ))}
        </React.Fragment>
      );
    }
  };

  const renderDepartment = (item, index) => {
    console.log("newHires.renderDepartment item", item);

    // let contacts = [...item.contacts];
    console.log("newHires.renderDepartment contacts before", item.contacts);
    // contacts.filter(contact => contact.showing);
    let contacts = item.contacts.filter(x => x.showing === "on");
    // contacts.filter(x => x.showing === "on");
    console.log("newHires.renderDepartment contacts after", contacts);
    return (
      <div
        key={item.id}
        className="newHiresDeptContainer"
        style={
          contacts.length === 0 ? { display: "none" } : { display: "block" }
        }
      >
        <div>
          <div
            style={{
              display: "inline-block",
              width: "60px",
              height: "25px"
            }}
          >
            <button
              className="newHiresToggleButton"
              onClick={() => changeDepartmentDisplay(index)}
              id={"newHiresToggleButton" + index}
            >
              <img
                src={item.on ? "/chevron_up.png" : "/chevron_down.png"}
                alt={item.on ? "Collapse Department field" : "Expand department field"}
                width="19px"
                style={{ marginRight: "10px" }}
              />
            </button>
          </div>

          <div className="newHiresDepartmentHeading">
            <label for={"newHiresToggleButton" + index}>
              {item.yourDepartment ? "Your " : ""}
              Department: {item.department} ({contacts.length})
            </label>
            <div
              style={
                item.on
                  ? {
                      display: "block"
                    }
                  : { display: "none" }
              }
            >
              {contacts.map((contact, index) => (
                <div
                  className="newHiresContactContainer"
                  // style={
                  //   contact.showing === "on"
                  //     ? { display: "block" }
                  //     : { display: "none" }
                  // }
                  key={"contact_" + contact.userid}
                >
                  <div className="newHiresDataContainer">
                    <div
                      className=""
                      style={{
                        borderTopColor: "#dddedd",
                        borderTopStyle: "solid",
                        borderTopWidth: "1px",
                        width: "100%"
                      }}
                    >
                      <div className="newHireName">{contact.name}</div>
                    </div>
                    <div className="">
                      <div className="newHireValue"> {contact.jobTitle}</div>
                    </div>
                    <div className=" row-striped">
                      <div className="newHireLabel ">Location:</div>
                      <div className="newHireValue"> {contact.location}</div>
                    </div>
                    <div className=" row-striped">
                      <div className="newHireLabel ">Reports To:</div>
                      <div className="newHireValue"> {contact.managerName}</div>
                    </div>
                    <div
                      className=" "
                      style={{ paddingBottom: "18px", paddingTop: "10px" }}
                    >
                      <button
                        className="btn newHiresWelcomeNote"
                        // disabled={contact.loading}
                        onClick={() => openEmail(contact.userid)}
                      >
                        Send a Welcome Note
                      </button>
                      <button
                        className="btn newHiresCoffeeTalk"
                        disabled={contact.loading}
                        onClick={() => setUpMeeting(contact.userPrincipalName)}
                      >
                        Schedule a Coffee Talk
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderNewHires = () => {
    if (newHires) {
      //The New Hires object exists

      if (newHires.error) {
        return (
          <div>
            <div className="tile-apierroricon">
              <div className="portletErrorAlert">!</div>
              {/* <bolt-icon name= "notification-error" label = "error" color = "red" size = "lg"></bolt-icon> */}
            </div>
            <div className="tile-apierrortext">Oops, this is unexpected.</div>
            <div className="tile-apierrortext1">Error code: 500</div>
            <div className="tile-apierrortext1">
              An error has occurred and we’re working to fix the issue.
            </div>
            <div className="tile-apierrortext1">
              <span>If the issue persists please </span>
              <a
                className="tile-apierrorcontactus"
                href="https://nwproduction.service-now.com/csm"
                rel="noopener noreferrer"
                target="_blank"
              >
                contact us.
              </a>
            </div>
            <div
              className="tile-apierror-refresh"
              onClick={handleRefresh}
              title="Refresh"
            >
              Refresh
            </div>
            <div style={{ paddingTop: "25px" }}></div>
          </div>
        );
      }

      if (newHires.length === 0) {
        //The new hires object is empty
        return <div>No new hires found</div>;
      } else {
        const contact = JSON.parse(sessionStorage.getItem("contact"));

        let yourDept = [];
        let otherDept = [];
        let row = 0;
        let otherRow = 0;
        for (let i = 0; i < newHires.length; i++) {
          if (newHires[i].yourDepartment) {
            yourDept[row++] = newHires[i];
          } else {
            otherDept[otherRow++] = newHires[i];
          }
        }
        // if (yourDept.length === 0) {
        //   yourDept[0] = {
        //     contacts: [],
        //     department: contact.department,
        //     id: "yd_0",
        //     on: false,
        //     order: 1,
        //     yourDepartment: true
        //   };
        // }
        console.log("newHires.renderNewHires yourDept", yourDept);
        return (
          <React.Fragment>
            <div className="newHiresHeading">
              <div className="newHireHeadingC1">
                <div style={{ display: "inline-block", paddingRight: "10px" }}>
                  <span className="newHireDescriptionTitle">
                    Inspire a New Hire
                  </span>
                  <br />
                  <div className="newHireDescription">
                    Send a welcome note or set up a quick 15 minute video chat
                    over coffee to get to know a new hire associate.
                  </div>
                </div>
              </div>
              <div className="newHireHeadingC2">
                <div className="newHiresFilterTitle">
                  Filter by State and City
                </div>
                <div className="newHireLocationSelect">
                  <button
                    onClick={() => changeDisplayLocations()}
                    style={
                      displayLocations
                        ? { backgroundImage: "url(chevron_up.png)" }
                        : { backgroundImage: "url(chevron_down.png)" }
                    }
                    title={locationFilter}
                  >
                    <div className="newHiresFilterButtonText">
                      {locationFilter}
                    </div>
                  </button>
                </div>
                {/* <select
                  className="newHireLocationSelect"
                  value={selectedLocation}
                  onChange={e => handleLocationChange(e)}
                >
                  {locations.map((item, index) => (
                    <option key={item.id} value={item.id}>
                      {item.location}
                    </option>
                  ))}
                </select> */}
              </div>
            </div>

            <div className="newHiresContainerPadding">
              <div
                className={
                  isMobile ? "newHiresMobileContainer" : "newHiresContainer"
                }
              >
                {yourDept && yourDept.length > 0 ? (
                  <React.Fragment>
                    {yourDept.map((item, index) => (
                      <React.Fragment>
                        {renderDepartment(item, index)}
                      </React.Fragment>
                    ))}
                  </React.Fragment>
                ) : (
                  <React.Fragment></React.Fragment>
                )}

                <div
                  style={
                    yourDept.length > 0
                      ? {
                          fontSize: "14px",
                          borderTop: "#bcbdbc solid 1px",
                          borderBottom: "#bcbdbc solid 1px",
                          fontWeight: "bold",
                          color: "#4d4f53"
                        }
                      : {
                          fontSize: "14px",
                          borderBottom: "#bcbdbc solid 1px",
                          fontWeight: "bold",
                          color: "#4d4f53"
                        }
                  }
                >
                  All New Hires Across Nationwide:
                </div>
                {otherDept.map((item, index) => (
                  <React.Fragment>
                    {renderDepartment(item, index)}
                  </React.Fragment>
                ))}
              </div>
            </div>
          </React.Fragment>
        );
      }
    } else {
      //The New Hires object does not exist
      return <Spinner />;
    }
  };

  let newHiresShowCount = 0;
  let newHiresCount = 0;
  if (newHires) {
    //The newHires object is populated, filter out any that are not showing

    // const nhFilters = newHires.filter(x => x.contacts.find(showing => showing));
    // let nhFilters = newHires.filter(function(e) {
    //   return e.contacts.filter(function(y) {
    //     return y.showing;
    //   });
    // });
    // let nhFilters = [...newHires];
    for (let d = 0; d < newHires.length; d++) {
      for (let c = 0; c < newHires[d].contacts.length; c++) {
        if (newHires[d].contacts[c].showing === "on") {
          newHiresShowCount++;
        }
        newHiresCount++;
      }
    }
    // newHiresShowCount = nhFilters.length;
    // for (let i = 0; i < newHires.length; i++) {
    //   newHiresCount += newHires[i].contacts.length;
    // }
  }
  let displayCount = "(" + newHiresCount + ")";
  if (newHiresCount !== newHiresShowCount) {
    //The filtered count is not equal to the non-filtered count
    // displayCount = "(" + newHiresCount + ")";
    displayCount = "(" + newHiresShowCount + "/" + newHiresCount + ")";
  }
  return (
    <React.Fragment>
      <div
        style={
          displayNewHires && !isMobile
            ? {
                display: "block",
                position: "fixed",
                top: "0px",
                left: "0px",
                width: "100%",
                height: "100%",
                backgroundColor: "white",
                opacity: "0.5",
                zIndex: "200"
              }
            : {
                display: "hidden"
              }
        }
      ></div>
      <div
        className={
          isMobile ? "newHireMobilePopupContainer" : "newHiresPopupContainer"
        }
        style={
          displayNewHires
            ? {
                display: "block",
                position: "absolute"
                // overflow: "hidden"
              }
            : { display: "none", position: "relative" }
        }
      >
        <div className="newHireCell" style={isMobile ? {} : { height: "100%" }}>
          <div className="newHireTitleBar ">
            <div className="newHireTitle">
              Meet Our New Nationwiders
              <span className="newHireSubTitle">
                {" "}
                from {startDate} to {endDate}
              </span>
            </div>
            <div
              style={{
                textAlign: "right",
                display: "inline-block"
              }}
            >
              <button
                className="btn newHiresCloseButton"
                onClick={handleNewHireDisplay}
                style={{ fontSize: "20px" }}
                title="Close New Hires display"
              ></button>
            </div>
          </div>

          {renderNewHires()}
        </div>
        <div
          className="newHiresLocations"
          style={displayLocations ? { display: "block" } : { display: "none" }}
        >
          {renderLocations()}
        </div>
      </div>
    </React.Fragment>
  );
}

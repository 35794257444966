import React, { Component } from "react";
import {
  CollapsedPortlet,
  PortletLoading,
  WorkdayPortletError,
  WorkdayPortletNSP
} from "../common/portlet";
import Spinner from "../common/spinner";
// import { toast } from "react-toastify";
import "@nationwide-bolt/css-core/dist/css/bolt-core.css";
import { isMobile } from "react-device-detect";
import "react-toastify/dist/ReactToastify.css";
import "../main.css";

// import Slider from "../common/slider";
class NWSavings extends Component {
  state = {
    blurred: "no",
    expandTop: true,
    expandBottom: false
  };

  handleBlurChange = () => {
    if (this.state.blurred === "yes") {
      this.setState({ blurred: "no" });
    } else {
      this.setState({ blurred: "yes" });
    }
  };

  handleExpandTop = () => {
    // console.log("calendar.handleTimeOffClick");
    let expandTop = !this.state.expandTop;
    this.setState({ expandTop });
  };

  handleExpandBottom = () => {
    // console.log("calendar.handleTimeOffClick");
    let expandBottom = !this.state.expandBottom;
    this.setState({ expandBottom });
  };

  handleRefresh = async () => {
    this.props.populateSavingsPortal(true);
  };

  returnContent(savingsData, savingsDefaultActive, deactivateSavings) {
    if (savingsData) {
      const preTax = savingsData.preTax;
      const preTaxCU = savingsData.preTaxCU;
      const roth = savingsData.roth;
      const nonRoth = savingsData.nonRoth;
      const rothCU = savingsData.rothCU;

      const R1C1 =
        preTax > 0
          ? "Pre-Tax Contribution"
          : roth > 0
          ? "Roth After-Tax Contribution"
          : preTaxCU > 0
          ? "Pre-tax Catch-up Contribution"
          : nonRoth > 0
          ? "After-Tax Non-Roth Contribution"
          : rothCU > 0
          ? "Roth Catch-up Contribution"
          : "none";
      const R1C1Value =
        preTax > 0
          ? preTax
          : roth > 0
          ? roth
          : preTaxCU > 0
          ? preTaxCU
          : nonRoth > 0
          ? nonRoth
          : rothCU > 0
          ? rothCU
          : null;
      const A =
        preTax > 0 && roth > 0
          ? "Roth After-Tax Contribution"
          : preTaxCU > 0
          ? "Pre-tax Catch-up Contribution"
          : nonRoth > 0
          ? "After-Tax Non-Roth Contribution"
          : rothCU > 0
          ? "Roth Catch-up Contribution"
          : "none";
      const R1C2 = R1C1 === A ? "none" : A;
      const R1C2Value =
        R1C2 === "none"
          ? null
          : preTax > 0 && roth > 0
          ? roth
          : preTaxCU > 0
          ? preTaxCU
          : nonRoth > 0
          ? nonRoth
          : rothCU > 0
          ? rothCU
          : null;
      const B =
        preTax > 0 && roth > 0 && preTaxCU > 0
          ? "Pre-tax Catch-up Contribution"
          : nonRoth > 0
          ? "After-Tax Non-Roth Contribution"
          : rothCU > 0
          ? "Roth Catch-up Contribution"
          : "none";
      const C =
        preTax > 0 && roth > 0 && preTaxCU > 0 && nonRoth > 0
          ? "After-Tax Non-Roth Contribution"
          : rothCU > 0
          ? "Roth Catch-up Contribution"
          : "none";
      const D =
        preTax > 0 && roth > 0 && preTaxCU > 0 && nonRoth > 0 && rothCU > 0
          ? "Roth Catch-up Contribution"
          : "none";

      const R2C1 =
        B === R1C1 || B === R1C2
          ? C === "none" || C === R1C1 || C === R1C2
            ? D === "none" || D === R1C1 || D === R1C2
              ? "none"
              : D
            : C
          : B;
      const R2C1Value =
        R2C1 === "none"
          ? null
          : R2C1 === "Pre-tax Catch-up Contribution"
          ? preTaxCU
          : R2C1 === "After-Tax Non-Roth Contribution"
          ? nonRoth
          : R2C1 === "Roth Catch-up Contribution"
          ? rothCU
          : null;

      const R2C2 =
        C === R1C1 || C === R1C2 || C === R2C1
          ? D === "none" || D === R1C1 || D === R1C2 || D === R2C1
            ? "none"
            : D
          : C;
      const R2C2Value =
        R2C2 === "none"
          ? null
          : R2C2 === "After-Tax Non-Roth Contribution"
          ? nonRoth
          : R2C2 === "Roth Catch-up Contribution"
          ? rothCU
          : null;
      const R3C1 =
        D === R1C1 || D === R1C2 || D === R2C1 || D === R2C2 ? "none" : D;
      const R3C1Value = R3C1 === "none" ? null : rothCU;

      return savingsData.savingsLength === -9999 ? (
        <div>
          <div className="tile-apierroricon">
            <div className="portletErrorAlert">!</div>
            {/* <bolt-icon name= "notification-error" label = "error" color = "red" size = "lg"></bolt-icon> */}
          </div>
          <div className="tile-apierrortext">Oops, this is unexpected.</div>
          <div className="tile-apierrortext1">Error code: 500</div>
          <div className="tile-apierrortext1">
            An error has occurred and we’re working to fix the issue.
          </div>
          <div className="tile-apierrortext1">
            <span>If the issue persists please </span>
            <a
              className="tile-apierrorcontactus"
              href="https://nwproduction.service-now.com/csm"
              rel="noopener noreferrer"
              target="_blank"
            >
              contact us.
            </a>
          </div>
          <div
            className="tile-apierror-refresh"
            onClick={this.handleRefresh}
            title="Refresh"
          >
            Refresh
          </div>
          <div style={{ paddingTop: "25px" }}></div>
        </div>
      ) : (
        <div>
          <h2 className="tile-header" style={{ marginRight: "35px" }}>
            <button
              style={
                savingsDefaultActive === "N"
                  ? { display: "inline-block" }
                  : { display: "none" }
              }
              onClick={deactivateSavings}
              className="link"
            >
              <img
                src="/chevron_up.png"
                alt="Clcik to collapse Retirement benefits"
                width="19px"
                style={{ marginRight: "10px" }}
              />
            </button>
            Your Nationwide Retirement Benefits
          </h2>
          <div className="tile-blur">
            <button
              className="btn btn-link"
              onClick={this.handleBlurChange}
              title={
                this.state.blurred === "yes" ? "Click to show" : "Click to hide"
              }
            >
              <img
                style={{
                  height: "20px",
                  width: "20px"
                }}
                alt={this.state.blurred === "yes" ? "click to show Retirement Benefits" : "click to hide Retirement Benefits"}
                src={
                  this.state.blurred === "yes"
                    ? "/eye-blue.png"
                    : "hide-blue.png"
                }
              />
            </button>
          </div>
          <hr className="tile-line" />
          <div className={this.state.blurred === "yes" ? "obfuscate" : ""}>
            {savingsData.savingsLength === 0 ? (
              <div className="container-fluid">
                <div className="row">
                  {this.state.expandTop ? (
                    <div onClick={e => this.handleExpandTop(e)}>
                      <img
                        className="expandSavings"
                        alt="Click to collapse savings plan info"
                        src="/bolt-chevron-up.svg"
                        title={"Click to Collapse"}
                      />
                    </div>
                  ) : (
                    <div onClick={e => this.handleExpandTop(e)}>
                      <img
                        className="expandSavings"
                        alt="Click to expand savings plan info"
                        src="/bolt-chevron-down.svg"
                        title={"Click to Expand"}
                      />
                    </div>
                  )}
                  <div
                    className={
                      !isMobile
                        ? "savingsTileHeader"
                        : "savingsTileHeaderMobile"
                    }
                  >
                    401(k)
                  </div>
                </div>
                <div
                  className="savingsOptOutText"
                  style={this.state.expandTop ? {} : { display: "none" }}
                >
                  Did you know that Nationwide matches up to{" "}
                  <span style={{ fontWeight: "bold" }}>50% </span>
                  (up to 8%). Go to Fidelity to take advantage of this great
                  opportunity.
                </div>
                <div className="row">
                  {this.state.expandBottom ? (
                    <div onClick={e => this.handleExpandBottom(e)}>
                      <img
                        className="expandSavings"
                        alt="Click to collapse savings plan info"
                        src="/bolt-chevron-up.svg"
                        title="Click to Collapse"
                      />
                    </div>
                  ) : (
                    <div onClick={e => this.handleExpandBottom(e)}>
                      <img
                        className="expandSavings"
                        alt="Click to expand savings plan info"
                        src="/bolt-chevron-down.svg"
                        title="Click to Expand"
                      />
                    </div>
                  )}
                  <div
                    className={
                      !isMobile
                        ? "savingsTileHeader"
                        : "savingsTileHeaderMobile"
                    }
                  >
                    Pension
                  </div>
                </div>
                <div className="row">
                  <div
                    className="savingsOptOutTextBottom"
                    style={this.state.expandBottom ? {} : { display: "none" }}
                  >
                    Nationwide provides eligible associates with a 100% company
                    paid pension benefit. Find more info on how the Pension
                    works{" "}
                    <a
                      href="https://onyourside.sharepoint.com/sites/Associate-Benefits/SitePages/Retirement-Benefits.aspx"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      here
                    </a>
                    .
                  </div>
                </div>
              </div>
            ) : (
              <div>
                <div className="container-fluid">
                  <div className="row">
                    {this.state.expandTop ? (
                      <div onClick={e => this.handleExpandTop(e)}>
                        <img
                          className="expandSavings"
                          alt="Click to collapse savings plan info"
                          src="/bolt-chevron-up.svg"
                          title={"Click to Collapse"}
                        />
                      </div>
                    ) : (
                      <div onClick={e => this.handleExpandTop(e)}>
                        <img
                          className="expandSavings"
                          alt="Click to expand savings plan info"
                          src="/bolt-chevron-down.svg"
                          title={"Click to Expand"}
                        />
                      </div>
                    )}
                    <div
                      className={
                        !isMobile
                          ? "savingsTileHeader"
                          : "savingsTileHeaderMobile"
                      }
                    >
                      401(k)
                    </div>
                  </div>
                  <div style={this.state.expandTop ? {} : { display: "none" }}>
                    <div className="row">
                      <div className="col">
                        <span
                          className="tile-title"
                          style={{ paddingLeft: "10px", fontSize: "12.6px" }}
                        >
                          {R1C1}
                        </span>
                      </div>
                      {R1C2 === "none" ? (
                        <div></div>
                      ) : (
                        <div className="col">
                          <span
                            className="tile-title"
                            style={{ fontSize: "12.6px" }}
                          >
                            {R1C2}
                          </span>
                        </div>
                      )}
                    </div>
                    <div className="row">
                      <div className="col">
                        {this.state.blurred === "yes" ? (
                          <span
                            className="tile-values"
                            style={{ fontSize: "25px", marginLeft: "10px" }}
                          >
                            ####%
                          </span>
                        ) : (
                          <span
                            className="tile-values"
                            style={{ fontSize: "25px", marginLeft: "10px" }}
                          >
                            {R1C1Value}%
                          </span>
                        )}
                      </div>
                      {R1C2 === "none" ? (
                        <div></div>
                      ) : (
                        <div className="col">
                          {this.state.blurred === "yes" ? (
                            <span
                              className="tile-values"
                              style={{ fontSize: "25px" }}
                            >
                              ####%
                            </span>
                          ) : (
                            <span
                              className="tile-values"
                              style={{ fontSize: "25px" }}
                            >
                              {R1C2Value}%
                            </span>
                          )}
                        </div>
                      )}
                    </div>
                    {R2C1 === "none" && R2C2 === "none" ? (
                      <div></div>
                    ) : (
                      <div>
                        <div className="row" style={{ marginTop: "20px" }}>
                          <div className="col">
                            <span
                              className="tile-title"
                              style={{
                                paddingLeft: "10px",
                                fontSize: "12.6px"
                              }}
                            >
                              {R2C1}
                            </span>
                          </div>
                          {R2C2 === "none" ? (
                            <div></div>
                          ) : (
                            <div className="col">
                              <span
                                className="tile-title"
                                style={{ fontSize: "12.6px" }}
                              >
                                {R2C2}
                              </span>
                            </div>
                          )}
                        </div>
                        <div className="row">
                          <div className="col">
                            {this.state.blurred === "yes" ? (
                              <span
                                className="tile-values"
                                style={{ fontSize: "25px", marginLeft: "10px" }}
                              >
                                ####%
                              </span>
                            ) : (
                              <span
                                className="tile-values"
                                style={{ fontSize: "25px", marginLeft: "10px" }}
                              >
                                {R2C1Value}%
                              </span>
                            )}
                          </div>
                          {R2C2 === "none" ? (
                            <div></div>
                          ) : (
                            <div className="col">
                              {this.state.blurred === "yes" ? (
                                <span
                                  className="tile-values"
                                  style={{ fontSize: "25px" }}
                                >
                                  ####%
                                </span>
                              ) : (
                                <span
                                  className="tile-values"
                                  style={{ fontSize: "25px" }}
                                >
                                  {R2C2Value}%
                                </span>
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                    {R3C1 === "none" ? (
                      <div></div>
                    ) : (
                      <div>
                        <div className="row" style={{ marginTop: "20px" }}>
                          <div className="col">
                            <span
                              className="tile-title"
                              style={{
                                paddingLeft: "10px",
                                fontSize: "12.6px"
                              }}
                            >
                              {R3C1}
                            </span>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col">
                            {this.state.blurred === "yes" ? (
                              <span
                                className="tile-values"
                                style={{ fontSize: "25px", marginLeft: "10px" }}
                              >
                                ####%
                              </span>
                            ) : (
                              <span
                                className="tile-values"
                                style={{ fontSize: "25px", marginLeft: "10px" }}
                              >
                                {R3C1Value}%
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <hr className="tile-line" style={{ marginTop: "15px" }} />
                <div className="container-fluid">
                  <div className="row">
                    <div className="col">
                      <span
                        className="tile-title"
                        style={{ paddingLeft: "10px" }}
                      >
                        Employer Matching Contribution:
                      </span>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <span
                        className="tile-values"
                        style={{ paddingLeft: "10px", fontSize: "18px" }}
                      >
                        50%
                      </span>
                      <span
                        className="tile-values"
                        style={{
                          paddingLeft: "10px",
                          fontSize: "18px",
                          fontWeight: "normal"
                        }}
                      >
                        on Contributions up to
                      </span>
                      <span
                        className="tile-values"
                        style={{ paddingLeft: "10px", fontSize: "18px" }}
                      >
                        8%
                      </span>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <span
                        className="tile-values"
                        style={{
                          paddingLeft: "10px",
                          fontSize: "14px",
                          fontWeight: "normal"
                        }}
                      >
                        (Available for Pre-Tax and Roth After-Tax Contributions)
                      </span>
                    </div>
                  </div>
                </div>
                <hr className="tile-line" style={{ marginTop: "15px" }} />

                <div className="row">
                  {this.state.expandBottom ? (
                    <div onClick={e => this.handleExpandBottom(e)}>
                      <img
                        className="expandPension"
                        alt="click to collapse Pension info"
                        src="/bolt-chevron-up.svg"
                        title="Click to Collapse"
                      />
                    </div>
                  ) : (
                    <div onClick={e => this.handleExpandBottom(e)}>
                      <img
                        className="expandPension"
                        alt="Click to expand Pension info"
                        src="/bolt-chevron-down.svg"
                        title="Click to Expand"
                      />
                    </div>
                  )}
                  <div
                    className={
                      !isMobile
                        ? "savingsTileHeader"
                        : "savingsTileHeaderMobile"
                    }
                  >
                    Pension
                  </div>
                </div>
                <div className="row">
                  <div
                    className="pensionText"
                    style={this.state.expandBottom ? {} : { display: "none" }}
                  >
                    Nationwide provides eligible associates with a 100% company
                    paid pension benefit. Find more info on how the Pension
                    works{" "}
                    <a
                      href="https://onyourside.sharepoint.com/sites/Associate-Benefits/SitePages/Retirement-Benefits.aspx"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      here
                    </a>
                    .
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="tile-subbox" style={{ marginTop: "15px" }}>
            <div
              className="tile-rectangle"
              style={{
                marginLeft: "18px",
                width: "139px",
                height: "38px"
              }}
            >
              <img
                className="tile-externallink"
                alt="external-link"
                src="/external-link.svg"
              />
              <a
                className="tile-recttitle"
                href="https://nb.fidelity.com/public/nb/nationwide/home"
                rel="noopener noreferrer"
                target="_blank"
                title="Open pension Sharepoint - https://nb.fidelity.com/public/nb/nationwide/home"
              >
                Go to Fidelity
              </a>
            </div>
          </div>
          <div style={{ paddingTop: "8px" }}></div>
        </div>
      );
    } else {
      return <Spinner />;
    }
  }

  render() {
    const {
      savingsData,
      activePortal,
      fetchSavingsData,
      savingsDefaultActive,
      deactivateSavings
    } = this.props;
    if (activePortal === "Y") {
      if (savingsData) {
        const content = this.returnContent(
          savingsData,
          savingsDefaultActive,
          deactivateSavings
        );
        if (savingsData.savingsLength === -9999) {
          return <WorkdayPortletError content={content} />;
        } else {
          return <WorkdayPortletNSP content={content} />;
        }
      } else {
        this.props.populateSavingsPortal();
        return <PortletLoading title="Your Nationwide Retirement Benefits" />;
      }
    } else {
      return (
        <CollapsedPortlet
          // activateAwsPortal={this.handleExpand}
          dataFetch={fetchSavingsData}
          title="Your Nationwide Retirement Benefits"
        ></CollapsedPortlet>
      );
    }
  }
}

export default NWSavings;

import React, { Component } from "react";
import { saveUserPortlets } from "../services/portalsService";
import { ToastContainer } from "react-toastify";

class PortletAdministration extends Component {
  state = {};
  componentDidMount() {
    this.aryDropZones = ["Y11", "Y21", "Y12", "Y22", "Y13", "Y23"];
    this.aryAvailZones = [
      "N11",
      "N21",
      "N12",
      "N22",
      "N13",
      "N23",
      "N14",
      "N24",
      "N15",
      "N25",
      "N16",
      "N26"
    ];
  }
  closeAdmin = async => {
    const result = saveUserPortlets(this.props.portlets);
    // console.log("PortletAdministration.closeAdmin result", result);
    this.props.onChangePortletDisplay();
  };

  // onPortletClick = (e, index) => {
  //   let portlets = this.props.portlets;
  //   if (portlets[index].active === "Y") {
  //     portlets[index].active = "N";
  //   } else {
  //     portlets[index].active = "Y";
  //   }
  //   this.props.portletUpdate(portlets);
  // };

  updatePortlets = async () => {
    let portlets = this.props.portlets;
    for (let i = 0; i < this.aryAvailZones.length; i++) {
      const any = document
        .getElementById(this.aryAvailZones[i])
        .hasChildNodes();
      if (any) {
        const item = document.getElementById(this.aryAvailZones[i])
          .childNodes[0];
        console.log(
          "PortletAdministration.updatePortlets",
          this.aryAvailZones[i],
          item.id
        );
        for (let p = 0; p < portlets.length; p++) {
          if (portlets[p].static_name === item.id) {
            console.log(
              "PortletAdministration.updatePortletsFOUND portlet",
              portlets[p]
            );
            const cId = this.aryAvailZones[i];
            const col = cId.substr(1, 1);
            const ord = cId.substr(2, 1);
            portlets[p].orderNumber = parseInt(ord);
            portlets[p].colNumber = parseInt(col);
            portlets[p].active = "N";
          }
        }
      }
    }

    for (let i = 0; i < this.aryDropZones.length; i++) {
      const any = document.getElementById(this.aryDropZones[i]).hasChildNodes();
      if (any) {
        const item = document.getElementById(this.aryDropZones[i])
          .childNodes[0];
        console.log(
          "PortletAdministration.updatePortlets",
          this.aryDropZones[i],
          item.id
        );
        for (let p = 0; p < portlets.length; p++) {
          if (portlets[p].static_name === item.id) {
            console.log(
              "PortletAdministration.updatePortlets FOUND portlet",
              portlets[p]
            );
            const cId = this.aryDropZones[i];
            const col = cId.substr(1, 1);
            const ord = cId.substr(2, 1);
            portlets[p].orderNumber = parseInt(ord);
            portlets[p].colNumber = parseInt(col);
            portlets[p].active = "Y";
          }
        }
      }
    }
    console.log("PortletAdministration.updatePortlets call saveUserPortlets");
    const result = saveUserPortlets(portlets);
    console.log("PortletAdministration.updatePortlets result", result);
    this.props.portletUpdate(portlets);
  };

  onDragOver = index => {
    const draggedOverItem = this.props.portlets[index];

    // if the item is dragged over itself, ignore
    if (this.draggedItem === draggedOverItem) {
      return;
    }

    // filter out the currently dragged item
    let portlets = this.props.portlets.filter(
      item => item !== this.draggedItem
    );

    // add the dragged item after the dragged over item
    portlets.splice(index, 0, this.draggedItem);

    this.props.portletUpdate(portlets);
  };

  allowDrop(ev) {
    ev.preventDefault();
  }

  drag(ev) {
    ev.dataTransfer.setData("text", ev.target.id);
    console.log(
      "PortletAdministration.drag currentParent",
      ev.target.parentNode.id
    );
    this.startZone = ev.target.parentNode.id;
    this.dragging = ev.target.id;
  }

  drop(ev) {
    ev.preventDefault();
    console.log("portletAdministration.drop", ev);
    let exchange = false;
    let exchangeid = "";

    //Try Active drop zones firse  aryAvailZones
    let found = this.aryDropZones.find(element => element === ev.target.id);
    let foundInactive = this.aryAvailZones.find(
      element => element === ev.target.id
    );
    if (!found && !foundInactive) {
      console.log(
        "portletAdministration.drop the target was not a valid drop zone--see if the target parent has a drop zone"
      );
      //the target was not a valid drop zone--see if the target parent has a drop zone.
      let foundp1 = this.aryDropZones.find(
        element => element === ev.target.parentNode.id
      );
      let foundp1INactive = this.aryAvailZones.find(
        element => element === ev.target.parentNode.id
      );
      if (!foundp1 && !foundp1INactive) {
        console.log(
          "portletAdministration.drop the target parent was also not a valid drop zone"
        );
        return false;
      } else {
        console.log("portletAdministration.drop targetZone exchange", foundp1);
        const targetZone = foundp1 ? foundp1 : foundp1INactive;
        exchange = true;
        exchangeid = ev.target.id;
        console.log(
          "portletAdministration.drop  Move " +
            exchangeid +
            " to " +
            this.startZone
        );
        document
          .getElementById(this.startZone)
          .appendChild(document.getElementById(exchangeid));
        document
          .getElementById(targetZone)
          .appendChild(document.getElementById(this.dragging));
      }
    }
    if (!exchange) {
      console.log("portletAdministration.drop targetZone move - no exchange");
      var any = ev.target.innerHTML;
      if (any === "") {
        var data = ev.dataTransfer.getData("text");
        console.log("portletAdministration.drop data", data);

        console.log("portletAdministration.drop ev.target.id", ev.target.id);
        ev.target.appendChild(document.getElementById(data));
        document.getElementById(data).classList.remove("notadded");
        document.getElementById(data).classList.add("added");
      }
    }
    this.changeDropZoneBorders();
  }

  changeDropZoneBorders() {
    this.collapseActive();
    this.collapseInActive();
    for (let i = 0; i < this.aryDropZones.length; i++) {
      var any = document.getElementById(this.aryDropZones[i]).hasChildNodes();
      //bstyle = (!any)?'dashed':'solid';
      //document.getElementById(aryDropZones[i]).style.borderStyle=bstyle;
      document
        .getElementById(this.aryDropZones[i])
        .classList.remove("availableActivePlace");
      if (!any) {
        document
          .getElementById(this.aryDropZones[i])
          .classList.add("availableActivePlace");
      }
    }
  }

  collapseActive() {
    const col1 = ["Y11", "Y12", "Y13"];
    const col2 = ["Y21", "Y22", "Y23"];
    console.log("PortletAdministration.collapseActive");
    for (var x = 0; x < 2; x++) {
      for (let i = 0; i < col1.length - 1; i++) {
        let cell = document.getElementById(col1[i]).hasChildNodes();
        if (!cell) {
          console.log(
            "PortletAdministration.collaspeActive " + col1[i] + " is empty"
          );
          let nextcell = document.getElementById(col1[i + 1]).hasChildNodes();
          if (nextcell) {
            console.log(
              "PortletAdministration.collaspeActive " +
                col1[i + 1] +
                " is not empty, so move it to " +
                col1[i]
            );
            let move = document.getElementById(col1[i + 1]).childNodes[0];
            document.getElementById(col1[i]).appendChild(move);
          }
        }
      }
      for (let i = 0; i < col2.length - 1; i++) {
        let cell = document.getElementById(col2[i]).hasChildNodes();
        if (!cell) {
          console.log(
            "PortletAdministration.collaspeActive " + col2[i] + " is empty"
          );
          let nextcell = document.getElementById(col2[i + 1]).hasChildNodes();
          if (nextcell) {
            console.log(
              "PortletAdministration.collaspeActive " +
                col2[i + 1] +
                " is not empty, so move it to " +
                col1[i]
            );
            let move = document.getElementById(col2[i + 1]).childNodes[0];
            document.getElementById(col2[i]).appendChild(move);
          }
        }
      }
    }
  }
  collapseInActive() {
    const col1 = ["N11", "N12", "N13", "N14", "N15", "N16"];
    const col2 = ["N21", "N22", "N23", "N24", "N25", "N26"];

    console.log("PortletAdministration.collapseInActive");
    for (var x = 0; x < col1.length; x++) {
      for (var i = 0; i < col1.length - 1; i++) {
        try {
          console.log(
            "PortletAdministration.collapseInActive col1[i] ",
            i,
            col1[i]
          );
          let cell = document.getElementById(col1[i]).hasChildNodes();
          if (!cell) {
            console.log(
              "PortletAdministration.collapseInActive " + col1[i] + " is empty"
            );
            console.log(
              "PortletAdministration.collapseInActive col1[i + 1] ",
              i,
              col1[i + 1]
            );
            let nextcell = document.getElementById(col1[i + 1]).hasChildNodes();
            if (nextcell) {
              console.log(
                "PortletAdministration.collapseInActive " +
                  col1[i + 1] +
                  " is not empty, so move it to " +
                  col1[i]
              );
              let move = document.getElementById(col1[i + 1]).childNodes[0];
              document.getElementById(col1[i]).appendChild(move);
            }
          }
        } catch (e) {
          console.log("PortletAdministration.collapseInActive error", e);
        }
      }
      for (let ii = 0; ii < col2.length - 1; ii++) {
        try {
          console.log(
            "PortletAdministration.collapseInActive col2[ii] ",
            ii,
            col2[ii]
          );
          let cell = document.getElementById(col2[ii]).hasChildNodes();
          if (!cell) {
            console.log(
              "PortletAdministration.collapseInActive " + col2[ii] + " is empty"
            );
            let nextcell = document
              .getElementById(col2[ii + 1])
              .hasChildNodes();
            if (nextcell) {
              console.log(
                "PortletAdministration.collapseInActive " +
                  col2[ii + 1] +
                  " is not empty, so move it to " +
                  col1[ii]
              );
              let move = document.getElementById(col2[ii + 1]).childNodes[0];
              document.getElementById(col2[ii]).appendChild(move);
            }
          }
        } catch (e) {
          console.log("portletAdministration.collapseInActive error", e);
        }
      }
    }
  }
  addremove = portalid => {
    console.log("PortletAdministration.addRemove portalid", portalid);
    var item = document.getElementById(portalid);
    if (item.classList.contains("added")) {
      console.log("PortletAdministration.addRemove Remove", portalid);

      for (let i = 0; i < this.aryAvailZones.length; i++) {
        var any = document
          .getElementById(this.aryAvailZones[i])
          .hasChildNodes();
        console.log("PortletAdministration.addRemove any " + i, any);
        //console.log(document.getElementById(aryAvailZones[i]).childNodes[0]);
        if (!any) {
          console.log("PortletAdministration.addRemove removing...");
          document.getElementById(this.aryAvailZones[i]).appendChild(item);
          item.classList.remove("added");
          item.classList.add("notadded");
          break;
        }
      }
    } else {
      console.log("PortletAdministration.addRemove Add", portalid);
      for (let i = 0; i < this.aryDropZones.length; i++) {
        console.log(
          "PortletAdministration.addRemove check children of ",
          this.aryDropZones[i]
        );
        console.log(
          this.aryDropZones[i],
          document.getElementById(this.aryDropZones[i]).hasChildNodes()
        );
        let any = document.getElementById(this.aryDropZones[i]).hasChildNodes();
        console.log(
          document.getElementById(this.aryAvailZones[i]).childNodes[0]
        );
        if (!any) {
          document.getElementById(this.aryDropZones[i]).appendChild(item);
          item.classList.remove("notadded");
          item.classList.add("added");
          break;
        }
      }
    }
    this.changeDropZoneBorders();
  };

  renderPortletCell = (column, order, active) => {
    const { portlets } = this.props;
    const id = active + column + order;
    console.log("params", column, order, active);
    try {
      for (let i = 0; i < portlets.length; i++) {
        const p = portlets[i];
        console.log(
          "checking placement for  portal " + i,
          p.colNumber,
          p.orderNumber,
          p.active
        );

        if (
          p.colNumber === column &&
          p.orderNumber === order &&
          p.active === active
        ) {
          console.log("FOUND SPOT FOR THIS PORTAL");
          return (
            <div
              id={id}
              className={
                active === "Y" ? "dropable" : "dropable availablePlace"
              }
              candrop="true"
              onDrop={e => this.drop(e)}
              onDragOver={e => this.allowDrop(e)}
            >
              <div
                id={p.static_name}
                className={
                  active === "Y" ? "candrag added" : "candrag notadded"
                }
                draggable="true"
                onDragStart={e => this.drag(e)}
              >
                <button
                  onClick={() => this.addremove(p.static_name)}
                  className="addremportalbtn"
                ></button>
                {p.title}
              </div>
            </div>
          );
        }
      }
    } catch (e) {
      console.log(
        "portletAdministration renderPortletCell ERROR AT renderPortletCell",
        e
      );
    }
    console.log("NO MATCH FOUND");
    return (
      <div
        className={
          active === "Y"
            ? "dropable availableActivePlace"
            : "dropable availablePlace"
        }
        candrop="true"
        id={id}
        onDrop={e => this.drop(e)}
        onDragOver={e => this.allowDrop(e)}
      ></div>
    );
  };

  render() {
    const { showPortletSelector } = this.props;
    try {
      if (showPortletSelector === "Y") {
        return (
          <div id="pageCover" className="pageCover">
            <div
              id="dateEntryContainer"
              className="portletSelectContainer"
              style={{ overflow: "auto" }}
            >
              <div className="modalTitleBar">
                Customize My Dashboard
                <button
                  className="close"
                  aria-label="Close"
                  onClick={this.closeAdmin}
                >
                  X
                </button>
              </div>
              <p
                style={{
                  marginRight: "20px",
                  marginLeft: "20px",
                  fontSize: "14px"
                }}
              >
                Arrange tiles on your dashboard to see your most used tiles at
                the top every time you visit HRConnect. Grab and drag the tiles
                to rearrange the order of the tiles, then Click “Save” at the
                bottom to update your dashboard.
              </p>
              <div className="portalsbar">
                <div className="container">
                  <div className="row">
                    <div>
                      {/* <div className="col-2 customizeInstrHead">
                        Active
                        <br />
                        Tiles
                      </div> */}
                      <div
                        style={{
                          fontSize: "11px",
                          width: "152px",
                          paddingTop: "50px"
                        }}
                      >
                        PLACE YOUR TOP 6 TILES IN THIS SECTION TO KEEP THEM
                        EXPANDED FOR QUICK REFERENCE.
                      </div>
                    </div>
                    <div className=" portletDesignContainer">
                      <div className="container">
                        <div className="row" style={{ maxWidth: "836px" }}>
                          <div>
                            <div>{this.renderPortletCell(1, 1, "Y")}</div>
                            <div>{this.renderPortletCell(1, 2, "Y")}</div>
                            <div>{this.renderPortletCell(1, 3, "Y")}</div>
                          </div>
                          <div>
                            <div>{this.renderPortletCell(2, 1, "Y")}</div>
                            <div>{this.renderPortletCell(2, 2, "Y")}</div>
                            <div>{this.renderPortletCell(2, 3, "Y")}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <hr
                style={{
                  borderTopStyle: "dashed",
                  borderTopWidth: "1px",
                  borderTopColor: "#0072cf",
                  margin: "0px"
                }}
              />
              {/* <div style={{ width: "100%", backgroundColor: "#dee6f2" }}>
                <div
                  className="container"
                  style={{ backgroundColor: "#dee6f2", height: "10px" }}
                >
                  <div className="row">
                    <div className="col-2"></div>
                    <div className="col-4">
                      <hr
                        style={{
                          borderTopStyle: "dashed",
                          borderTopWidth: "1px",
                          borderTopColor: "#0072cf",
                          margin: "0px"
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div> */}
              <div className="portalsbar">
                <div className="container">
                  <div className="row">
                    <div>
                      {/* <div className="col-2 customizeInstrHead">
                        Inactive
                        <br />
                        Tiles
                      </div> */}
                      <div
                        style={{
                          fontSize: "11px",
                          width: "152px",
                          paddingTop: "50px"
                        }}
                      >
                        PLACE LESS USED TILES IN THIS SECTION TO KEEP THEM
                        COLLAPSED.
                      </div>
                    </div>
                    <div className="c portletDesignContainer">
                      <div className="container">
                        <div className="row" style={{ maxWidth: "836px" }}>
                          <div>
                            <div>{this.renderPortletCell(1, 1, "N")}</div>
                            <div>{this.renderPortletCell(1, 2, "N")}</div>
                            <div>{this.renderPortletCell(1, 3, "N")}</div>
                            <div>{this.renderPortletCell(1, 4, "N")}</div>
                            <div>{this.renderPortletCell(1, 5, "N")}</div>
                            <div>{this.renderPortletCell(1, 6, "N")}</div>
                          </div>
                          <div>
                            <div>{this.renderPortletCell(2, 1, "N")}</div>
                            <div>{this.renderPortletCell(2, 2, "N")}</div>
                            <div>{this.renderPortletCell(2, 3, "N")}</div>
                            <div>{this.renderPortletCell(2, 4, "N")}</div>
                            <div>{this.renderPortletCell(2, 5, "N")}</div>
                            <div>{this.renderPortletCell(2, 6, "N")}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div style={{ width: "100%", backgroundColor: "white" }}>
                <div className="container">
                  <div className="row">
                    <div className="col-md-auto"></div>
                    <div className="col-md-8">
                      <button
                        className="btn btn-primary demoLink ml-5"
                        type="button"
                        onClick={this.updatePortlets}
                        aria-label="Save and return to dashboard"
                        style={{ float: "right" }}
                      >
                        Save and return to dashboard
                      </button>
                      <button
                        className="btn btn-outline-primary"
                        type="button"
                        onClick={this.props.onChangePortletDisplay}
                        aria-label="Cancel and return to dashboard"
                        style={{ float: "right" }}
                      >
                        Cancel and return to dashboard
                      </button>
                    </div>
                    <div className="col-md-auto"></div>
                  </div>
                </div>
              </div>
            </div>
            <ToastContainer />
          </div>
        );
      } else {
        console.log("PortletAdministration Do Not Show");
        return <React.Fragment></React.Fragment>;
      }
    } catch (e) {
      console.log("PortletAdministration ERROR");
      return <div>{e}</div>;
    }
  }
}

export default PortletAdministration;

import config from "../config.json";
import { getRandomString } from "../services/AuthService";
import { getEnvironment } from "./EnvironmentVariable";
import { isMobile } from "react-device-detect";

export const getDataFile = async () => {
  const environ = getEnvironment();
  let portalApi = config.Extract_API;
  let portalKey = config.Extract_Key;
  if (environ !== "DEFAULT") {
    console.log("dataExtractService.getDataFile environ", environ);
    if (isMobile) {
      portalApi = config.stage_Extract_API;
    } else {
      portalApi = config.test_Extract_API;
      portalKey = config.test_Extract_Key;
    }
  }

  let headers = new Headers();
  headers.append("referrer-policy", "no-referrer");
  headers.append("Accept", "*/*");
  headers.append("Connection", "keep-alive");
  headers.append("Access-Control-Allow-Origin", "*");
  const token = sessionStorage.getItem("access_token");
  headers.append("Authorization", "Bearer " + token);
  headers.append("client_id", portalKey);
  const message = getRandomString(25);
  headers.append("X-NW-Message-ID", message);
  const response = await fetch(portalApi, {
    method: "get",
    headers: headers
  });
  let respParse = null;
  if (response.ok) {
    try {
        // Parse response to blob
        respParse = await response.blob();
        if (respParse) {
            console.log("dataExtractService.getDataFile: ");
            
            // Create blob link element
            const url = window.URL.createObjectURL(new Blob([respParse]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `CallupDataExtract.csv`);
            
            // Inject into HTML
            document.body.appendChild(link);

            // Start download
            link.click();

            // Clean up and remove the link
            link.parentNode.removeChild(link);
            return { Success:'Success'};
        }

    } catch (e) {
        return {error: 'Error parsing blob repsonse: ' + e};
    }
  } else {
    try {
        respParse = await response.json();
        return {error: respParse};
    } catch (e) {
        return {error: 'Error parsing error repsonse: ' + e};
    }
  }

  const jsondata = await response.json();
  console.log("GivingService.GetGivingData API: ", jsondata);

  if (jsondata.userMessage) {
    return { error: "Error retrieving Giving data" };
  }
  return jsondata;
};

export const getDataFileAllowed = async () => {
  const message = getRandomString(25);
  const environ = getEnvironment();
  const token = sessionStorage.getItem("access_token");
  let user_properties_url = config.user_properties;
  let user_properties_key = config.callupKey;
  if (environ !== "DEFAULT") {
    if (isMobile) {
      user_properties_url = config.user_properties;
    } else {
      console.log("dataExtractService.getDataFileAllowed environ", environ);
      user_properties_url = config.test_user_properties;
      user_properties_key = config.test_callupKey;
    }
  }
  try {
    let headers = new Headers();
    headers.append("Accept", "*/*");
    headers.append("Connection", "keep-alive");
    headers.append("Authorization", "Bearer " + token);
    const response = await fetch(user_properties_url, {
      method: "get",
      headers: headers
    });
    const responseData = await response.json();
    console.log("dataExtractService.getDataFileAllowed response", responseData);

    // const jsondata = await response.json();
    // console.log("BodyPost", jsondata);
    return responseData;
  } catch (e) {
    console.log("dataExtractService.getDataFileAllowed error retrieving user properties", e);
    return null;
  }
};

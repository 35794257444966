import config from "../config.json";
import { getRandomString } from "../services/AuthService";
import { getEnvironment } from "./EnvironmentVariable";

export const getHSAData = async () => {
  const environ = getEnvironment();

  let portalApi = config.HSA_API;
  let portalKey = config.HSA_Key;
  if (environ !== "DEFAULT") {
    portalApi = config.test_HSA_API;
    portalKey = config.test_HSA_Key;
    // portalApi = config.HSA_Stage;
    // portalKey = config.client_id;
  }
  const userInfo = JSON.parse(sessionStorage.getItem("userinfo"));
  const message = getRandomString(25);
  let token = sessionStorage.getItem("access_token");
  //if (environ !== "DEFAULT") {
    //token = await getStageToken();
  //}
  let headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("Authorization", "Bearer " + token);
  headers.append("X-NW-Message-ID", message);
  headers.set("client_id", portalKey);

  const body = JSON.stringify({ shortName: userInfo.shortName });
  try {
    const response = await fetch(portalApi, {
      method: "post",
      headers: headers,
      body: body
    });
    console.log("HSA response", response);
    if (response.ok) {
      try {
        const jsondata = await response.json();
        return jsondata;
      } catch (e) {
        console.log("getHSAData error", e);
        return { error: e };
      }
    } else {
      console.log("getHSAData error", " - Status: " + response.status);
      return { error: response.status };
    }
  } catch (e1) {
    console.log("getHSAData error", e1);
    return { error: e1 };
  }
};

export const getStageToken = async () => {
  const environ = getEnvironment();
  const APIGEE_CLIENT_ID = config.client_id;
  let APIGEE_AUTHORITY = "";
  let token = "";

  if (environ !== "DEFAULT") {
    console.log("SNOW API Not in production");
    APIGEE_AUTHORITY = config.stage_authority;
  } else {
    console.log("SNOW API in production");
    APIGEE_AUTHORITY = config.prod_authority;
  }

  const idToken = sessionStorage.getItem("idToken");
  token = sessionStorage.getItem("SNOW_AT");
  const SNOW_expires = sessionStorage.getItem("SNOW_expires");
  const expDate = new Date(SNOW_expires * 1000);
  const now = new Date();

  //IF NO TOKEN OR EXPIRED TOKEN
  if (token === "" || expDate - now < 3000) {
    console.log(
      "ServiceNowService.getFromServiceNow SNOW API token is blank or expired"
    );

    const body = [
      `${encodeURIComponent("grant_type")}=${encodeURIComponent(
        "urn:ietf:paramsoap:oauth:grant-type:jwt-bearer"
      )}`,
      `${encodeURIComponent("assertion")}=${encodeURIComponent(idToken)}`,
      `${encodeURIComponent("client_id")}=${encodeURIComponent(
        APIGEE_CLIENT_ID
      )}`,
      `${encodeURIComponent("scope")}=${encodeURIComponent("openid")}`,
      `${encodeURIComponent("realm")}=${encodeURIComponent("employee")}`,
      `${encodeURIComponent("auth_method")}=${encodeURIComponent("azure-jwt")}`,
      `${encodeURIComponent("identity_method")}=${encodeURIComponent("nwie")}`
    ].join("&");

    const response = await fetch(APIGEE_AUTHORITY, {
      method: "post",
      headers: {
        "Content-Type": `application/x-www-form-urlencoded`
      },
      body: body
    });
    console.log(
      "ServiceNowService.getFromServiceNow SNOW API token response",
      response
    );

    if (response.ok) {
      const jsondata = await response.json();
      console.log("SNOW API token jsondata response", jsondata);
      token = jsondata.access_token;
      sessionStorage.setItem("SNOW_AT", token);
      let d = new Date();
      d.setSeconds(d.getSeconds() + parseInt(jsondata.expires_in));
      const sec = Math.round(d.getTime() / 1000);
      sessionStorage.setItem("SNOW_expires", sec);
      console.log("SNOW API token jsondata ", token);
    } else {
      token = "";
      console.log("SNOW API token jsondata error");
    }
  }

  console.log(
    "ServiceNowService.getFromServiceNow SNOW API token jsondata Final Value",
    token
  );
  return token;
};
